// color labels
.side-label-group {
	position: absolute;
	top: 10px;
	left: -8px;
	@include r(767) {
		left: 0; }
	.side-label {
		position: relative;
		top: 0;
		left: 0;
		margin-bottom: 20px;
		@include r(767) {
			margin-bottom: 5px;
			padding: 5px 10px;
			right: 0;
			left: 0;
			height: auto;
			border-radius: 0 3px 3px 0; } } }

.side-label {
	position: absolute;
	top: 25px;
	left: -8px;
	height: 50px;
	padding: 10px 10px 0 10px;
	background: $red;
	color: #fff;
	text-align: center;
	z-index: 1;
	border-radius: 0 3px 3px 0;
	&.is-promo {
		background: #FFB000;
		&:before {
			@include arr(8,8,#FFB000,tr);
			@include r(767) {
				border-color: #FFB000 transparent transparent transparent; } }
		&:after {
			border-color: #FFB000 transparent #FFB000 #FFB000;
			@include r(767) {
				border-color: #FFB000 #FFB000 #FFB000 transparent; } } }
	@include r(767) {
		top: 10px;
		left: -5px; }
	&:before {
		content: '';
		position: absolute;
		left: 0;
		bottom: -8px;
		@include arr(8,8,#c60000,tr);
		@include r(767) {
			left: 0;
			bottom: -5px;
			@include arr(5,5,#c60000,tr); } }
	&_vertical {
		top: -4px;
		left: 20px;
		padding: 13px 2px 13px 3px;
		width: 44px;
		@include r(767) {
			top: -3px;
			left: 20px;
			right: auto;
			border-radius: 4px 0 0 0; }
		&:before {
			top: 0;
			bottom: auto;
			left: auto;
			right: -4px;
			border-width: 4px 0 0 4px;
			border-color: transparent transparent transparent $red;
			@include r(767) {
				right: auto;
				bottom: auto;
				border-width: 4px 0 0 4px;
				border-color: transparent transparent transparent $red; } }
		&:after {
			top: auto;
			right: auto;
			bottom: -10px;
			left: 0;
			border-width: 0 22px 10px 22px;
			border-color: $red $red transparent $red;
			@include r(767) {
				left: 0;
				border-width: 0 22px 10px 22px;
				border-color: $red $red transparent $red; } } }
	&_weekend {
		background-color: #B055FF;
		border-radius: 0 3px 3px 0;
		font-size: 12px;
		line-height: 14px; }
	&-wrap,
	&__help {
		display: inline-block;
		vertical-align: top; }
	&__value {
		font-size: 22px;
		font-weight: 700;
		line-height: 1;
		@include r(767) {
			font-size: 11px;
			float: right;
			line-height: 1.2; } }
	&__title {
		font-size: 10px;
		font-weight: 500;
		text-transform: uppercase;
		@include r(767) {
			font-size: 11px;
			float: left;
			margin-right: 5px; } }
	&__help {
		width: 13px;
		height: 13px;
		margin: -2px -6px 0 0;
		background-color: #ff6d6d;
		font-size: 10px;
		color: #FFF;
		line-height: 13px;
		@include r(767) {
			display: none; } }
	&__promo {
		font-size: 14px;
		max-width: 70px;
		text-align: left;
		font-weight: bold;
		line-height: 1.2; } }
