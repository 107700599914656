.tooltip {
    position: relative;
    &.tooltip_narrow {
        .tooltip__content {
            text-align: center; } }
    &__wrap {
        display: none;
        position: absolute;
        top: 50%;
        left: 100%;
        min-width: 164px;
        min-height: 50px;
        padding-left: 10px;
        transform: translateY(-50%);
        z-index: 2;
        &_w-auto {
            min-width: inherit; } }
    &__stars {
        span {
            margin-left: 10px; }
        svg {
            color: #FFC900;
            font-size: 10px;
            margin-right: -3px; } }
    &__value {
        display: inline-block;
        vertical-align: text-bottom;
        font-size: 13px;
        line-height: 15px;
        color: #ffffff;
        font-weight: 400;
        &:after {
            font-size: 13px;
            font-weight: 400;
            content: '/10';
            color: #8A8A8A; } }
    &__progress {
        width: 100px;
        height: 6px;
        border-radius: 3px;
        overflow: hidden;
        position: relative;
        float: left;
        margin-top: 5px;
        background: #595959;
        &:after {
            content: ' ';
            display: table;
            clear: both; }
        &-line {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background: #FFC900;
            border-radius: 3px; } }
    &__rating {
        font-size: 13px;
        line-height: 15px;
        position: relative;
        padding-right: 65px;
        text-align: left;
        font-weight: 700;
        margin-top: 5px;
        .tooltip__value {
            font-size: 25px;
            line-height: 29px;
            font-weight: 700;
            position: absolute;
            right: 0;
            top: -5px; } }
    &__title,
    &__text {
        font-size: 13px;
        line-height: 15px; }
    &__text {
        margin-top: 5px; }
    &__table {
        display: table;
        width: 100%; }
    &__row {
        display: table-row;
        &:last-child {
            .tooltip__cell {
                padding-bottom: 0; } } }
    &__cell {
        display: table-cell;
        padding-bottom: 5px;
        padding-right: 10px;
        &:first-child {
            text-align: center;
            width: 30px; }
        .icon-bort-wifi {
            font-size: 10px; }
        .icon-flash2 {
            font-size: 14px; }
        .icon-bort-video {
            font-size: 10px; }
        .icon-seat2 {
            font-size: 12px; } }
    &__content {
        display: block;
        position: relative;
        padding: 13px 16px;
        background: #282828;
        border-radius: 3px;
        box-shadow: 0 14px 40px rgba(#000,.35);
        font-size: 13px;
        line-height: 15px;
        color: #fff;
        text-align: center;
        max-width: 164px;
        white-space: normal;
        text-transform: none;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: -7px;
            margin-top: -7px;
            width: 0;
            height: 0;
            @include arr(7,14,#282828,l);
            border-style: solid;
            border-width: 7px 7px 7px 0;
            border-color: transparent #282828 transparent transparent; }
        &_center {
            text-align: center; }
        &_left {
            text-align: left; }
        .loader.is-on {
            padding-bottom: 40px;
            .tooltip__row {
                display: none; } } }
    &__close {
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 9px;
        color: #FFFFFF;
        display: inline-block;
        z-index: 1; }
    &_simple &__wrap {
        min-width: 0; }
    &_left &__wrap {
        left: auto;
        right: 100%;
        padding: 0 10px 0 0; }
    &_left &__content {
        text-align: right;
        &:before {
            left: auto;
            right: -7px;
            margin-top: -7px;
            @include arr(7,14,#282828,r); } }
    &_top &__wrap {
        top: auto;
        left: 50%;
        bottom: 100%;
        padding: 0 0 10px;
        transform: translate(-50%,0);
        font-weight: 400; }
    &_top &__content {
        &:before {
            top: auto;
            left: 50%;
            bottom: -7px;
            margin-left: -7px;
            @include arr(14,7,#282828,b); } }
    &_bottom &__wrap {
        bottom: auto;
        left: 50%;
        top: 100%;
        padding: 0 0 10px;
        transform: translate(-50%,0);
        font-weight: 400; }
    &_bottom &__content {
        &:before {
            bottom: auto;
            left: 50%;
            top: 0;
            margin-left: -7px;
            @include arr(14,7,#282828,b);
            transform: rotate(180deg); } }
    &_top_shifted_left &__wrap {
        top: auto;
        left: -30%;
        bottom: 100%;
        padding: 0 0 10px;
        transform: translate(-50%,0);
        font-weight: 400; }
    &_top_shifted_left &__content {
        text-align: center;
        &:before {
            top: auto;
            left: 70%;
            bottom: -7px;
            margin-left: -7px;
            @include arr(14,7,#282828,b); } }
    &:hover {
        z-index: 99;
        .tooltip__wrap {
            display: block;
            &:hover {
                display: none; } } }
    &:hover &__wrap {
        display: block; }
    &_animate-top &__wrap {
        transition: transform 500ms ease-out, opacity 75ms linear, z-index 10ms 150ms linear;
        transform: translate(-50%, -30px);
        opacity: 0;
        z-index: -99;
        will-change: transform;
 }        //display: block
    &_animate-top:hover &__wrap {
        opacity: 1;
        z-index: 99;
        transition: transform 500ms cubic-bezier(0,.83,0,1.17), opacity 200ms linear, z-index 10ms linear;
        transform: translate(-50%, 0); }
    &_animate-bottom &__wrap {
        transition: transform 500ms ease-out, opacity 75ms linear, z-index 10ms 150ms linear;
        transform: translate(-50%, 0);
        opacity: 0;
        z-index: -99;
        will-change: transform;
        display: block; }
    &_animate-bottom:hover &__wrap {
        opacity: 1;
        z-index: 99;
        transition: transform 500ms cubic-bezier(0,.83,0,1.17), opacity 200ms linear, z-index 10ms linear;
        transform: translate(-50%, 20px); }
    &_share {
        .tooltip__wrap {
            min-width: 290px; } }
    &_top {
        .tooltip__wrap {
            top: auto;
            left: 50%;
            bottom: 100%;
            padding: 0 0 10px;
            transform: translate(-50%,0);
            font-weight: 400;
            margin-bottom: 1px; }
        .tooltip__content {
            &:before {
                top: auto;
                left: 50%;
                bottom: -7px;
                margin-left: -7px;
                border-width: 7px 7px 0;
                border-color: #282828 transparent transparent; } } }
    &_wide {
        .tooltip__wrap {
            min-width: 340px!important; }
        .tooltip__content {
            max-width: 340px!important;
            font-size: 13px;
            line-height: 16px; } }
    &_200 {
        .tooltip__wrap {
            min-width: 200px!important; }
        .tooltip__content {
            max-width: 200px!important;
            padding: 10px 15px; } }
    &_240 {
        .tooltip__wrap {
            min-width: 240px!important; }
        .tooltip__content {
            max-width: 240px!important;
            padding: 10px 15px; } }
    &_280 {
        .tooltip__wrap {
            min-width: 280px!important; }
        .tooltip__content {
            max-width: 280px!important;
            padding: 10px 15px; } }
    &_290 {
        .tooltip__wrap {
            min-width: 290px!important; }
        .tooltip__content {
            max-width: 290px!important;
            padding: 15px; } }
    &_triggers {
        .tooltip {
            &__wrap {
                min-width: 300px;
                z-index: 13;
                margin-top: -5px;
                &:hover {
                    display: block!important; } }
            &__content {
                max-width: 300px;
                padding: 20px 15px; }
            &__link {
                text-decoration: underline;
                margin-left: 5px;
                display: inline-block;
                &:hover {
                    text-decoration: none; } } } }
    &_r-stops {
        .tooltip__wrap {
            left: 85%;
            padding-right: 7px; } }
    &_l-stops {
        .tooltip__wrap {
            right: 100%;
            left: 5px; } }
    &_stops {
        .tooltip__wrap {
            transform: translate(-50%,-7%); }
        .tooltip__content {
            font-size: 13px;
            line-height: 15px;
            p.alarm {
                color: #E42C23; } } }
    &_dashboard {
        .tooltip__content {
            padding: 10px;
            max-width: 190px; }
        .tooltip__wrap {
            display: block;
            top: inherit;
            left: inherit;
            &:hover {
                display: block!important; } } }
    .loader {
        &.is-on {
            .tooltip__content {
                * {
                    display: none; } } }
        .tooltip__content {
            min-height: 50px; }
        &__spinner {
            width: 22px;
            height: 22px; }
        &__center {
            padding: 15px 0;
            background: transparent;
            top: 0;
            margin-left: -115px; } } }

.small-rate {
    color: #fff;
    margin-bottom: 1em;
    &:last-child {
        margin-bottom: 0; }
    &__title {
        float: left;
        font-size: 13px;
        font-weight: bold;
        margin-top: 10px; }
    &__mark {
        float: right;
        font-size: 16px;
        font-weight: bold;
        position: relative;
        top: -3px; }
    &__visual {
        clear: both;
        border-radius: 3px;
        height: 4px;
        background: #fff;
        margin-top: 3px;
        i {
            display: block;
            border-radius: 3px;
            height: 4px;
            background: #00bfff; } } }
