.footer {
	position: relative;
	padding: 50px 0 25px;
	background: #373C40;
	&__content {
		margin-bottom: 40px;
		padding-bottom: 45px;
		border-bottom: 1px solid #191919;
		font-size: 13px;
		color: #7A7A7A;
		p {
			margin: 0 0 2px; } }
	&__nav {
		font-size: 0;
		@include r(1023) {
			margin-bottom: 20px;
			text-align: center; }
		a {
			display: inline-block;
			margin-right: 30px;
			color: #FFF;
			line-height: 30px;
			font-size: 14px;
			vertical-align: top;
			@include r(1023) {
				margin: 0 10px; }
			&:hover {
				color: $blue; } } }
	&__copyright {
		float: left;
		margin-top: 18px;
		font-size: 0;
		&-year, .icon {
			display: inline-block;
			vertical-align: top; }
		.icon {
			font-size: 12px;
			color: #888; }
		&-year {
			margin-left: 5px;
			font-size: 12px;
			color: #7a7a7a; } }
	&__row {
		@include clr; }
	&__row + &__row {
		margin-top: 30px; }
	&__col {
		float: left;
		width: 25%;
		padding-right: 30px;
		&:last-child {
			padding: 0; } }
	&__col_w2 {
		width: 50%; }
	&__col_w3 {
		width: 75%; }
	&__col {
		@include r(1023) {
			width: 100%;
			padding: 0; } }
	&__copyright {
		float: none;
		margin: 0 0 15px;
		.icon {
			color: #FFF; } }
	&__text {
		color: #7a7a7a;
		font-size: 12px;
		@include r(1023) {
			margin-bottom: 30px; } }
	&__directions {
		font-size: 13px;
		line-height: 1.4;
		@include r(1023) {
			margin-bottom: 30px; }
		&-title {
			margin-bottom: 5px;
			font-weight: 500;
			color: #fff;
			text-transform: uppercase; }
		&-link {
			display: inline-block;
			margin-right: 10px;
			color: #787878;
			font-weight: 700;
			&:hover {
				color: #fff; } } }
	&__booking {
		@include r(1023) {
			margin: 0 -10px;
			@include clr; }
		&-item {
			@include r(1023) {
				float: left;
				width: 50%;
				padding: 0 10px; }
			@include r(767) {
				float: none;
				width: 100%; } }
		&-link {
			display: block;
			width: 100%;
			border-radius: 3px;
			&_avia {
				margin-bottom: 20px;
				background: #16bcff;
				@include r(1023) {
					margin: 0; }
				@include r(767) {
					margin-bottom: 10px; } }
			&_hotels {
				background: #0044A4; } }
		&-cell {
			display: table-cell;
			height: 54px;
			vertical-align: middle;
			&:first-child {
				width: 60px;
				text-align: center; }
			&:nth-child(2) {
				font-size: 12px;
				text-transform: uppercase;
				color: #fff;
				font-weight: 700; } }
		.icon {
			font-size: 22px;
			color: #FFF;
			vertical-align: middle; } }
	&__social {
		float: right;
		font-size: 0;
		@include r(1023) {
			float: none;
			text-align: center; }
		@include r(767) {
			@include justify; }
		&-link {
			display: inline;
			margin-left: 10px;
			vertical-align: top;
			&:first-child {
				margin: 0; } }
		.icon {
			font-size: 30px;
			color: #888;
			&:hover {
				color: $orange; } }
		.icon-social-facebook {
			color: #3A5BA0; }
		.icon-social-twitter {
			color: #2ca7e0; }
		.icon-social-googleplus {
			color: #E13719; }
		.icon-social-instagram {
			color: #527FA3; }
		.icon-social-rss {
			color: #F2890A; }
		.icon-social-telegram,
		.icon-telegram-new {
			color: #3FAEE6; } }
	&__left {
		max-width: 800px;
		@include r(1023) {
			max-width: inherit; }
		.footer__text {
			@include r(767) {
				margin-bottom: 10px; } }
		.footer__social {
			margin: 20px 0 60px;
			float: none;
			@include r(1023) {
				position: absolute;
				left: 0;
				right: 0;
				bottom: -35px;
				margin: 0; } }
		.footer__nav {
			//position: absolute
			//left: 0
			//right: 0
			//bottom: -15px
			//+r(767)
			//	bottom: -5px
			//	text-align: left
			a {
				text-decoration: underline;
				@include r(767) {
					margin: 0 5px 5px 0;
					line-height: 1.2;
					font-size: 13px; } } } }
	&__right {
		position: absolute;
		right: 0;
		top: 0;
		width: 220px;
		@include r(1023) {
			position: static;
			width: 100%; }
		.footer__booking {
			display: inline-block;
			text-align: left;
			@include r(1023) {
				display: block;
				text-align: center; }
			@include r(767) {
				text-align: left; } }
		.footer__booking-link {
			padding-right: 20px;
			&:hover {
				background: $orange; }
			@include r(1023) {
				line-height: 54px; } }
		.footer__booking-cell {
			@include r(1023) {
				display: inline-block;
				vertical-align: middle; } } } }

.footer_new {
	padding: 60px 0 55px;
	background: #303437;
	@include r(1023) {
		padding-top: 40px; }
	@include r(767) {
		padding-top: 30px; }
	.footer__row {
		position: relative;
		padding-right: 250px;
		@include r(1023) {
			padding-right: 0;
			padding-bottom: 20px; } } }
