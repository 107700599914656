.city {
    position: relative;
    &__field {
        position: relative;
        background: #fff;
        border-radius: 3px;
        font-size: 0; }
    &__field.active &__input {
        display: none; }
    &__field.active &__res {
        display: block; }
    &__title {
        position: absolute;
        top: 6px;
        left: 15px;
        font-size: 9px;
        text-transform: uppercase;
        color: #9F9F9F;
        z-index: 6;
        @include r(767) {
            top: -12px;
            left: 0;
            color: #fff; } }
    &__res {
        display: none;
        position: relative;
        cursor: pointer;
        z-index: 2;
        &-code {
            float: right;
            padding: 0 15px 0 10px;
            font-size: 12px;
            line-height: 54px;
            color: #A7A7A7; }
        &-wrap {
            height: 54px;
            padding-left: 15px;
            font-size: 15px;
            @include text-overflow;
            line-height: 54px;
            @include r(767) {
                font-size: 14px; } }
        &-everywhere {
            background-color: #3D7CDA;
            color: #ffffff;
            width: 100%;
            border-radius: 3px;
            padding: 10px 15px;
            font-size: 15px;
            cursor: pointer;
            transition: background-color .2s;
            &-title {
                font-weight: 700; }
            &-text {
                font-size: 13px; }
            .icon-earth {
                font-size: 24px;
                float: left;
                margin-top: 5px;
                margin-right: 10px; }
            &:hover {
                background-color: #40CFFF; }
            &:active {
                background-color: #00A9E0; } } }
    &__input {
        position: relative;
        width: 100%;
        height: 54px;
        padding: 0 15px;
        background: #fff;
        border-radius: 3px;
        font-size: 15px;
        font-weight: 700;
        z-index: 4;
        @include placeholder {
            font-weight: 400; }
        @include r(767) {
            padding: 0 10px;
            font-size: 14px; }
        &.active,
        &:focus {
            box-shadow: 0 0 0 2px $orange;
            z-index: 40; } }
    &__drop {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 100%;
        max-width: 450px;
        max-height: 327px;
        margin-top: 2px;
        background: #FFF;
        border-radius: 3px;
        box-shadow: 0 14px 40px rgba(0, 0, 0, 0.34);
        overflow: auto;
        z-index: 99;
        font-size: 15px;
        &.back {
            left: 50%;
            &:after {
                left: 114px; }
            @include r(1330) {
                &:after {
                    left: 217px; } } }
        &__everywhere {
            min-width: 50%;
            @include r(767) {
                min-width: 100%; } } }

    &__available {
        padding: 6px 15px;
        background: $orange;
        border-radius: 3px 3px 0 0;
        color: #FFF; }
    &__history {
        padding: 11px 15px;
        border-bottom: 1px solid $border;
        @include r(767) {
            display: none; }
        &-head {
            @include clr; }
        &-reset {
            float: right;
            margin-top: 2px;
            font-size: 13px;
            font-weight: 300;
            color: $cl; }
        &-title {
            display: table;
            font-size: 15px;
            font-weight: 700; }
        &-list {
            display: table; }
        &-item {
            display: table-row;
            white-space: nowrap; }
        &-icon,
        &-codes,
        &-time {
            display: table-cell;
            padding-top: 11px;
            vertical-align: middle; }
        &-code,
        &-arrow {
            display: inline-block;
            vertical-align: middle; }
        &-codes {
            padding-right: 15px; }
        &-icon,
        &-arrow {
            font-size: 0; }
        &-icon {
            padding-right: 10px;
            .icon {
                font-size: 16px; } }
        &-arrow {
            .icon {
                font-size: 8px; } }
        &-code {
            font-size: 15px;
            font-weight: 700; } }
    &__list &__list &__item {
        padding-left: 30px; }
    &__last {
        padding: 10px;
        cursor: pointer;
        transition: background .2s;
        font-size: 15px;
        font-weight: bold;
        &-link {
            float: right;
            color: $cl;
            text-decoration: underline;
            font-weight: 100;
            @include r(767) {
                font-size: 13px; } }
        &.active {
            background: #F0F0F0; } }
    &__title {
        &-wrap {
            border-bottom: 1px solid #EDEDED; }
        &-destination {
            display: inline-block;
            min-width: 135px;
            @include r(767) {
                display: inline; }
            svg.icon-arrow-two-sides,
            svg.icon-arrow-right-side {
                margin: 0 5px;
                font-size: 9px; }
            svg.icon-return-time {
                font-size: 16px;
                margin-right: 10px;
                vertical-align: text-top; } }
        &-city {
            display: inline-block;
            font-weight: 400;
            @include r(767) {
                display: inline;
                margin-left: 20px; } }

        &-item {
            padding: 10px;
            transition: background .2s;
            font-size: 15px;
            font-weight: bold;
            cursor: pointer;
            &.active {
                background: #F0F0F0; }
            @include r(767) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: text-top;
                padding-right: 25px; } } }

    &__item {
        padding: 10px 15px;
        cursor: pointer;
        transition: background .2s;
        @include clr;
        @include r(767) {
            padding: 10px 10px; }
        &:first-child {
            border-radius: 3px 3px 0 0; }
        &:last-child {
            border-radius: 0 0 3px 3px; }
        &.active {
            background: #F0F0F0; }
        &-code {
            float: right;
            padding-top: 2px;
            font-size: 12px;
            text-transform: uppercase;
            color: #a7a7a7; }
        &-title {
            display: table; }
        &-everywhere {
            padding: 0;
            @include r(767) {
                padding: 0; } } }
    &__gray {
        margin-left: 3px;
        color: #A7A7A7; }
    &__bold {
        font-weight: 700; }
    &__change {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 24px;
        margin: -12px 0 0 -13px;
        padding: 0;
        background: #FFF;
        border: 1px solid $border;
        border-radius: 50%;
        font-size: 0;
        z-index: 4;
        @include r(767) {
            display: none; }
        &:hover {
            background: $orange;
            border-color: $orange;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.32);
            color: #FFF; }
        .icon {
            font-size: 10px; } }
    &_group &__head {
        font-size: 0; }
    &_group &__field {
        display: inline-block;
        width: 50%;
        border-radius: 0 3px 3px 0;
        vertical-align: top;
        @include r(767) {
            width: 100%;
            border-radius: 3px; }
        &:first-child {
            border-radius: 3px 0 0 3px;
            border-right: 1px solid $border;
            @include r(767) {
                margin-bottom: 5px;
                border-radius: 3px;
                border: none; } } }
    &_group &__field:nth-child(1) &__input {
        border-radius: 3px 0 0 3px;
        @include r(767) {
            border-radius: 3px; } }
    &_group &__field:nth-child(2) &__input {
        padding-left: 20px;
        border-radius: 0 3px 3px 0;
        @include r(767) {
            padding-left: 10px;
            border-radius: 3px; } }
    &_group &__field:nth-child(2) &__res-wrap {
        @include r(767) {
            padding-left: 10px; } }
    &_group &__drop {
        @include r(1023) {
            max-width: 50%;
            &.back {
                min-width: 50%; } }
        @include r(767) {
            max-width: 100%;
            top: 54px;
            &.back {
                top: 118px;
                left: 0;
                width: 100%; } } }
    &_back {
        .city__drop {
            left: 50%;
            @include r(767) {
                top: 118px;
                left: 0; } } }
    &_sm &__title {
        top: -12px;
        left: 0;
        color: #fff; }
    &_sm &__input {
        height: 40px;
        padding: 0 10px; }
    &_sm &__res {
        height: 40px;
        line-height: 40px; }
    &_sm &__res-wrap {
        height: 40px;
        line-height: 40px; }
    &_sm &__res-code {
        height: 40px;
        line-height: 42px; } }
