//.select
.select {
    position: relative;
    border-radius: 6px;
    background: #fff;
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -2px;
        @include arr(10,5,$cl,b); }
    &__top {
        display: none;
        position: relative;
        min-height: 50px;
        background: #33A7DE;
        border-radius: 6px 6px 0 0;
        @include r(767) {
            display: block; }
        &-title {
            padding: 0 50px;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 700;
            color: #fff;
            text-align: center;
            line-height: 50px;
            &_back {
                display: none; } }
        &-close {
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            font-size: 0;
            color: #fff;
            .icon {
                font-size: 12px; } } }
    &__head {
        position: relative;
        height: 54px;
        padding: 0 35px 0 15px;
        border-radius: 6px;
        font-size: 15px;
        font-weight: 700;
        @include text-overflow;
        line-height: 54px;
        cursor: pointer;
        z-index: 2;
        @include r(767) {
            padding: 0 30px 0 10px;
            font-size: 14px; }
        &.focus {
            box-shadow: 0 0 0 2px $orange; }
        &-text {
            display: inline;
            margin-left: 2px;
            font-weight: 400; }
        &_input {
            position: relative;
            width: 100%;
            background: none;
            cursor: text;
            z-index: 2;
            @include placeholder {
                font-weight: 400; }
            &:focus {
                box-shadow: 0 0 0 2px $orange; } } }
    &__input {
        display: none;
        position: relative;
        width: 100%;
        background: none;
        cursor: text;
        z-index: 2;
        padding: 0 35px 0 15px;
        @include placeholder {
            font-weight: 400; }
        &:focus {
            box-shadow: 0 0 0 2px $orange; } }
    &__drop {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 286px;
        min-width: 100%;
        margin-top: 2px;
        background: white;
        border-radius: 6px;
        box-shadow: 0 14px 40px rgba(0, 0, 0, 0.34);
        z-index: 99;
        .select__people {
            padding: 0 0 11px; }
        &.select__drop_hotels {
            .select__foot {
                padding: 20px 0 0;
                .people {
                    padding: 0; } } } }
    &__body {
        padding: 10px 20px 20px; }
    &__list {
        padding: 3px 0;
        border-radius: 6px;
        overflow: hidden; }
    &__item {
        padding: 11px 15px;
        transition: background .2s;
        font-size: 15px;
        font-weight: 700;
        cursor: pointer;
        &:hover,
        &.active {
            background: #f5f8fa; }
        .icon-search {
            display: inline-block;
            margin: -4px 10px 0 0;
            font-size: 18px;
            vertical-align: middle; } }
    &__foot {
        padding: 20px 0;
        border-top: 1px solid $border;
        p {
            margin-bottom: 10px;
            font-size: 15px; } }
    &__btn {
        padding: 20px 0 0;
        border-top: 1px solid $border;
        text-align: center;
        &-item {
            width: 120px; } }
    &_sm &__head {
        height: 40px;
        padding: 0 25px 0 10px;
        line-height: 40px; }
    &_popup &__drop {
        @include r(767) {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            bottom: 0;
            margin: 0;
            background: rgba(#000,.8);
            padding: 60px 20px;
            overflow: auto;
            @include vertical;
            z-index: 999; } }
    &_popup &__wrap {
        @include r(767) {
            display: inline-block;
            width: 280px;
            margin: 0 auto;
            text-align: left;
            vertical-align: middle; } }
    &_popup &__body {
        @include r(767) {
            background: #FFF;
            border-radius: 0 0 6px 6px; } }
    &.edit {
        .select__head {
            display: none; }
        .select__input {
            display: block; } }
    &.open {
        &:after {
            transform: rotate(180deg); } }
    &_input {
        &:after {
            display: none; }
        .select__drop {
            display: none; }
        .select__input,
        .select__head {
            padding: 0 10px; } }
    &__label-left {
        position: absolute;
        width: 25px;
        text-align: right;
        color: $cl;
        font-size: 12px;
        height: 44px;
        line-height: 44px;
        left: -30px; }
    &__name {
        color: #fff;
        font-size: 12px;
        line-height: 14px;
        position: absolute;
        margin-top: -16px;
        font-weight: 400;
        white-space: nowrap; }
    &__example {
        margin-top: 5px;
        a {
            color: #3D7CDA;
            font-size: 15px;
            line-height: 18px;
            font-weight: 700;
            margin-right: 10px;
            border-bottom: 1px dashed #3D7CDA;
            &:hover {
                border-bottom: 0; } } } }



//.mselect
.mselect {
    position: relative;
    z-index: 4;
    &.active &__head {
        box-shadow: 0 0 0 2px $orange; }
    &__head {
        position: relative;
        padding: 11px 11px 6px 11px;
        background: #FFF;
        border-radius: 6px;
        cursor: pointer;
        transition: box-shadow .2s;
        font-size: 0;
        overflow: hidden;
        @include r(767) {
            padding: 11px 10px 6px 10px; }
        &.focus {
            box-shadow: 0 0 0 2px $orange; } }
    &__btn,
    &__field {
        position: relative;
        z-index: 2; }
    &__btn,
    &__input {
        height: 32px;
        font-size: 14px; }
    &__label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: text; }
    &__btn {
        float: left;
        height: 32px;
        margin: 0 5px 5px 0;
        padding: 0 10px 0 7px;
        background: #F0F0F0;
        border-radius: 6px;
        font-size: 0;
        vertical-align: top;
        &-title,
        &-type,
        &-icon {
            display: inline-block;
            vertical-align: middle; }
        &-title,
        &-type {
            font-size: 15px;
            @include r(767) {
                font-size: 14px; } }
        &-title {
            font-weight: 700; }
        &-type {
            margin-left: 5px;
            color: #A7A7A7; }
        &-icon {
            margin-left: 9px;
            font-size: 0;
            .icon {
                font-size: 12px; } } }
    &__field {
        float: left;
        width: 100%;
        margin-bottom: 5px;
        &_sm {
            max-width: 65px; } }
    &__input {
        width: 100%;
        padding-left: 5px;
        font-size: 15px;
        font-weight: 700;
        @include placeholder {
            font-weight: 400; }
        @include r(767) {
            font-size: 14px; } }
    &__drop {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 100%;
        max-height: 320px;
        margin-top: 2px;
        background: #FFF;
        border-radius: 6px;
        box-shadow: 0 14px 40px rgba(0, 0, 0, 0.35);
        overflow: auto;
        z-index: 100; }
    &__list {
        border-radius: 6px;
        overflow: hidden; }
    &__item {
        padding: 11px 18px;
        transition: background .2s;
        font-size: 15px;
        color: $cl;
        cursor: pointer;
        overflow: hidden;
        @include r(767) {
            font-size: 13px; }
        &.active {
            background: #F0F0F0; }
        &-type {
            float: right;
            margin-left: 20px;
            color: #A7A7A7;
            @include r(767) {
                display: none; } }
        &-city,
        &-region {
            float: left; }
        &-region {
            margin-left: 5px;
            color: #A7A7A7; }
        &-bold {
            font-weight: 700; } }
    &__places {
        &-head {
            padding: 10px 15px;
            font-size: 15px;
            font-weight: 700; }
        &-list {
            padding: 2px 0;
            vertical-align: top; }
        &-list {
            column-count: 5;
            column-gap: 0;
            @include r(1023) {
                column-count: 4; }
            @include r(767) {
                column-count: 1; } }
        &-item {
            display: inline-block;
            width: 100%;
            padding: 7px 10px;
            transition: background .2s;
            cursor: pointer;
            &:hover {
                background: #ECECEC; } }
        &-flag {
            float: left;
            width: 16px;
            padding-top: 2px;
            margin-right: 6px; }
        &-img {
            display: block;
            max-width: 100%; }
        &-title {
            font-size: 15px;
            overflow: hidden;
            @include r(767) {
                font-size: 14px; } } } }



//.mselect_new
.mselect_new {
    &.active {
        .mselect__head {
            box-shadow: none; }
        .mselect__field_sm {
            max-width: 65px; }
        .mselect__input {
            @include placeholder {
                color: transparent; } } }
    .mselect__head {
        padding: 5px 5px 0;
        background: rgba(#fff, .3); }
    .mselect__btn {
        margin: 0 3px 5px 0;
        padding: 0 12px 0 15px;
        height: 36px;
        .icon {
            color: #008FBF;
            font-size: 10px; } }
    .mselect__btn-title,
    .mselect__btn-type {
        font-size: 14px;
        font-weight: 400; }
    .mselect__input {
        color: #fff;
        @include placeholder {
            color: #fff; } }
    .mselect__field_sm {
        max-width: 330px; } }
