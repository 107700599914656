//breadcrumbs
.breadcrumbs {
  font-size: 0;
  margin: 40px 0 0 0;
  @include r(767) {
    margin: 20px 0 0 0; }
  &__item {
    display: inline;
    font-size: 14px;
    color: #9C9C9C; }
  &__item::after {
    content: "/";
    margin: 0 5px; }
  &__item:last-child::after {
    display: none; }
  &__link {
    color: #9e9d9d;
    text-decoration: underline; } }

//page-aside_mob-top
.page-aside_mob-top {
  width: 300px!important;
  @include r(1023) {
    position: static;
    width: 100%!important;
    min-width: 100%!important;
    background: none;
    visibility: visible;
    opacity: 1;
    transition: none; } }

//main-faq
.main-faq {
  position: relative;
  height: calc(100vh - 50px);
  min-height: 550px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  text-align: center;
  color: #ffffff;
  &_main {
    min-height: 140px;
    height: 140px;
    @include r(1023) {
      min-height: 140px!important;
      height: 140px!important; }
    @include r(767) {
      min-height: 80px!important;
      height: initial!important; } }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  &:before {
    content: '';
    background: rgba(#000,.3); }
  @include r(1023) {
    height: calc(100vh - 44px);
    min-height: 800px; }
  @include r(767) {
    min-height: 620px; }
  &__wrap {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    color: #FFF;
    transform: translateY(-175px);
    z-index: 2;
    padding: 20px;
    @include rm(1024) {
      @include rh(720) {
        transform: translateY(-155px); } }
    @include r(1023) {
      transform: translateY(-242px); }
    @include r(767) {
      top: 0;
      transform: translateY(0);
      line-height: 1.14;
      padding: 20px 25px; } }
  &__title {
    font-size: 40px;
    line-height: 47px;
    font-weight: 700;
    margin: 0;
    &_main {
      position: relative;
      top: 50px; }
    @include r(767) {
      font-size: 24px;
      line-height: 28px;
      top: 0;
      padding: 25px; }
    &-h2 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
      margin: 0 auto;
      max-width: 800px;
      @include r(767) {
        font-size: 15px;
        line-height: 18px;
        margin-top: 10px; }
      &--line {
        position: relative;
        z-index: 1;
        overflow: hidden;
        text-align: center;
        margin: 45px auto 0;
        max-width: 980px;
        @include r(767) {
          overflow: inherit;
          margin-top: 35px; }
        &:before,
        &:after {
          content: '';
          display: inline-block;
          width: 100%;
          height: 1px;
          background: #ffffff;
          z-index: -1;
          position: relative;
          vertical-align: middle; }
        &:before {
          right: 15px;
          margin: 0 0 0 -100%;
          @include r(767) {
            width: 150px;
            position: absolute;
            margin: 0 auto;
            right: 0;
            left: 0;
            top: -20px; } }
        &:after {
          left: 15px;
          margin: 0 -100% 0 0;
          @include r(767) {
            display: none; } } } } }
  &-menu {
    margin-top: 20px;
    @include r(767) {
      margin-top: 0; }
    &__item {
      display: inline-block;
      height: 56px;
      line-height: 56px;
      border-radius: 30px;
      background: rgba(255,255,255,0.15);
      margin: 10px;
      color: #ffffff;
      padding: 0 30px;
      font-size: 22px;
      font-weight: 700;
      transition: background .2s;
      &:hover {
        background: rgba(#ff6700,0.7); }
      @include r(767) {
        width: 100%;
        background: $blue;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
        margin: 15px auto 0;
        border-radius: 6px;
        text-align: left;
        padding-left: 25px;
        &:hover {
          background: #40CFFF; }
        &:active {
          background: #00A9E0; }
        span {
          position: absolute;
          left: 95px; } }
      svg {
        display: none;
        color: #B3EAFF;
        @include r(767) {
          display: inline-block;
          margin-right: 25px;
          vertical-align: middle; } }
      .icon-tmd {
        font-size: 9px; }
      .icon-dynpacks {
        font-size: 22px; }
      .icon-aviatickets {
        font-size: 18px; }
      .icon-hotels {
        font-size: 17px; }
      .icon-cars {
        font-size: 16px; } } } }

//.faq-menu
.faq-menu {
  font-size: 20px;
  color: $cl;
  margin-top: 10px;
  &__item {
    background-color: #ffffff;
    &.selected {
      .faq-menu__item-head {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background-color: #EDEDED; } }
      .faq-menu__item-subparagraph {
        display: block;
        line-height: inherit;
        height: inherit;
        &-link {
          color: $cl;
          padding: 20px 25px;
          display: block; } } }
    &.active {
      .faq-menu__item-subparagraph {
        height: inherit; }
      .faq-menu__item-head {
        background: #CFF1FF;
        color: #3D7CDA;
        &:after {
          display: none; }
        svg {
          color: #3D7CDA; } } } }
  &__item-subparagraph {
    display: none;
    font-size: 16px;
    background: #e4f2ff;
    &.active {
      background-color: darken(#e4f2ff, 5%);
      color: #3D7CDA;
      svg {
        color: #3D7CDA; }
      .advice {
        @include r(1023) {
          display: block; } }
      .faq-menu__item-subparagraph-link {
        background-color: darken(#e4f2ff, 5%);
        color: #3D7CDA; } }
    &.selected {
      @include r(1023) {
        height: inherit;
        display: block; } }
    .advice {
      display: none;
      &__list {
        margin-top: 0; }
      &__head {
        padding: 20px 40px;
        span {
          position: static; } }
      &__content {
        span {
          position: static; } } }
    &:first-child {
      margin-top: 0; } }
  &__item-head {
    position: relative;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 500;
    display: block;
    color: $cl; }
  &__item-head,
  &__item-subparagraph {
    height: 60px;
    line-height: 60px;
    border-top: 1px solid #ededed;
    cursor: pointer;
    &:hover {
      background-color: darken(#e4f2ff, 5%);
      color: #3D7CDA;
      svg {
        color: #3D7CDA; }
      .faq-menu__item-subparagraph-link {
        background-color: darken(#e4f2ff, 5%);
        color: #3D7CDA; } }
    span {
      position: absolute;
      left: 60px; }
    svg {
      color: #ABABAB;
      vertical-align: middle;
      &.icon-faq-top {
        font-size: 25px; }
      &.icon-faq-why {
        font-size: 24px; }
      &.icon-faq-train {
        font-size: 26px;
        margin-left: 4px; }
      &.icon-faq-plane {
        font-size: 22px;
        margin-left: 4px; }
      &.icon-faq-hotels {
        font-size: 22px;
        margin-left: 4px; }
      &.icon-faq-more {
        font-size: 22px;
        margin-left: 2px; }
      &.icon-faq-insurance {
        font-size: 24px;
        margin-left: 4px; } } }

  &__item-head+&__item-subparagraph {
    border-top: none; } }

//.advice
.advice {
  &__list {
    margin-top: 10px; }
  &__item {
    background-color: #ffffff;
    border-top: 1px solid #ededed;
    &.open {
      margin: 10px auto 9px;
      .advice__head {
        background-color: darken(#e4f2ff, 5%);
        svg {
          transform: rotate(180deg); } }
      .advice__content {
        display: block; }
      @include r(1023) {
        margin: 0 auto; } } }
  &__head {
    padding: 20px 50px;
    font-size: 16px;
    line-height: 19px;
    color: #3D7CDA;
    font-weight: 500;
    cursor: pointer;
    background-color: #ffffff;
    position: relative;
    &:hover {
      background-color: darken(#e4f2ff, 5%); }
    svg {
      font-size: 6px;
      transform: rotate(90deg);
      color: $cl;
      position: absolute;
      left: 20px;
      top: 25px; } }
  &__content {
    font-size: 16px;
    line-height: 21px;
    display: none;
    padding: 15px 50px;
    background-color: #ffffff;
    color: $cl;
    @include r(1023) {
      padding: 20px; }
    @include r(767) {
      padding: 10px; }
    p {
      margin-top: 10px; }
    img {
      margin-top: 10px;
      height: 100%;
      width: 100%; }
    .bold {
      font-weight: 500; } } }

.faq-feedback {
  margin-top: 60px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(#000000, .5);
  margin-bottom: 40px;
  border-radius: 6px;
  &_thanks {
    margin: 40px auto;
    display: none;
    .faq-feedback__head {
      padding: 20px;
      border-radius: 6px; } }
  &__head {
    position: relative;
    text-align: center;
    padding: 25px 20px 20px;
    color: #ffffff;
    border-radius: 6px 6px 0 0;
    background: linear-gradient(to right, rgba(92,203,251,1) 0%, rgba(17,123,199,1) 100%); }
  &__img-question {
    position: absolute;
    margin-left: -40px;
    top: -46px;
    width: 89px;
    @include r(1023) {
      margin-left: -37px;
      width: 71px;
      top: -37px; } }
  &__img-mail {
    position: absolute;
    top: 25px;
    margin-left: -450px;
    @include r(1023) {
      display: none; } }
  &__title {
    font-size: 40px;
    line-height: 47px;
    font-weight: 700;
    @include r(767) {
      font-size: 20px;
      line-height: 24px; }
    &-h2 {
      font-size: 24px;
      line-height: 28px;
      font-weight: 400;
      @include r(767) {
        font-size: 16px;
        line-height: 19px; } } }
  &__content {
    margin-bottom: 40px; }
  &__comment {
    padding: 25px 20px 45px;
    @include r(767) {
      padding: 10px; }
    &-form {
      max-width: 740px;
      margin: 0 auto; }
    &-field {
      margin-bottom: 35px;
      font-size: 0;
      @include r(767) {
        margin-bottom: 5px; }
      &_smb {
        margin-bottom: 40px;
        @include r(767) {
          margin-bottom: 10px; } } }
    &-col {
      display: inline-block;
      vertical-align: top;
      width: calc(50% - 15px);
      @include r(767) {
        width: 100%;
        margin: 10px 0!important; }
      &:first-child {
        margin-right: 15px; }
      &:last-child {
        margin-left: 15px; }
      &.error-input {
        .faq-feedback__comment-label {
          color: #e42c23; }
        .faq-feedback__comment-input {
          color: #e42c23;
          border: 1px solid #e42c23;
          &:focus {
            box-shadow: inherit;
            border: 1px solid #e42c23; } } }
      &.correct-input {
        .faq-feedback__comment-input {
          border: 1px solid #65b004;
          &:focus {
            box-shadow: inherit;
            border: 1px solid #65b004; } } } }
    &-label {
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 700;
      color: $cl; }
    &-input {
      width: 100%;
      padding: 10px 25px;
      border-radius: 6px;
      border: 1px solid #B1BDC8;
      background-color: #F9F9F9;
      font-size: 14px;
      font-weight: 500;
      color: $cl;
      &:focus {
        box-shadow: inset 0 0 0 1px #ff7200;
        border: 1px solid #ff7200; }
      @include placeholder {
        color: #9B9B9B;
        font-size: 14px;
        font-weight: 500; }
      @include r(767) {
        padding: 10px 20px; } }
    &-textarea-caption {
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 500;
      color: $cl; }
    &-textarea {
      width: 100%;
      min-height: 170px;
      padding: 10px 15px;
      resize: none;
      border-radius: 6px;
      border: 1px solid #B1BDC8;
      background-color: #F9F9F9;
      font-size: 14px;
      color: $cl;
      @include placeholder {
        color: #9B9B9B;
        font-size: 14px;
        font-weight: 700; }
      &:focus {
        box-shadow: inset 0 0 0 1px #ff7200;
        border: 1px solid #ff7200; }
      @include r(767) {
        min-height: 75px;
        padding: 10px 20px; } }
    &-btn {
      color: #D7D7D7;
      background-color: #F4F4F4;
      border: 1px solid #E7E7E7;
      margin: 0 auto;
      height: 44px;
      line-height: 44px;
      border-radius: 6px;
      display: block;
      padding: 0 15px;
      font-size: 15px;
      font-weight: 700;
      text-align: center;
      transition: background .2s,color .2s;
      white-space: nowrap;
      cursor: default;
      &.active {
        background-color: #ff6700;
        color: #ffffff;
        border-color: #ff6700;
        cursor: pointer;
        &:hover {
          background-color: darken(#ff6700, 5%); }
        &:active {
          background-color: darken(#ff6700, 10%); } } } } }
