

.subscribe {
	position: relative;
	max-width: 810px;
	margin: 0 auto 20px;
	@include r(767) {
		margin: 0; }
	&__input {
		padding-right: 218px;
		@include r(767) {
			margin-bottom: 10px;
			padding: 0; } }
	&__btn {
		position: absolute;
		right: 0;
		top: 0;
		width: 193px;
		@include r(767) {
			position: static;
			width: 100%; }
		.btn {
			width: 100%;
			height: 54px; } } }


.subscribe_slim {
    max-width: 440px;
    @include r(767) {
        margin: 0 auto; }
    .subscribe__btn {
        width: 160px;
        @include r(767) {
            width: 100%; }
        .btn {
            height: 44px;
            padding: 0 15px; } }
    .input {
        height: 44px;
        font-size: 16px;
        @include r(767) {
            text-align: center; } }
    .subscribe__input {
        padding-right: 180px;
        @include r(767) {
            padding-right: 0; } } }


.subscribe .subscribe__input .input:focus {
    border: 1px solid #ff7200;
    padding: 0 19px; }

.subscribe {
    display: table;
    width: 100%;
    &__col {
        display: table-cell;
        position: relative;
        width: 50%;
        min-height: 1px;
        border-radius: 3px 0 0 3px;
        background-position: 50% 50%;
        background-size: cover;
        vertical-align: top;
        @include r(767) {
            display: block;
            width: 100%; }
        &:first-child {
            @include r(767) {
                height: 160px;
                border-radius: 3px 3px 0 0; } } }
    &__preview {
        display: block;
        max-width: 100%;
        border-radius: 3px 0 0 3px; }
    &__flight {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        &-wrap {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 186px;
            height: 186px;
            margin: -93px 0 0 -93px;
            padding-top: 65px;
            background: #FFF;
            border-radius: 50%;
            text-align: center;
            @include r(1023) {
                width: 162px;
                height: 162px;
                margin: -81px 0 0 -81px;
                padding-top: 62px; }
            @include r(767) {
                top: 105px;
                width: 508px;
                height: 508px;
                margin: 0 0 0 -253px;
                padding-top: 16px; } }
        &-head {
            font-size: 0; }
        &-code,
        &-arrow {
            display: inline-block;
            vertical-align: middle; }
        &-code {
            font-size: 30px;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 1.2;
            @include r(1023) {
                font-size: 27px; } }
        &-arrow {
            margin: 0 5px;
            .icon-arrow-two-sides {
                font-size: 8px;
                @include r(1023) {
                    display: none; } }
            .icon-change {
                display: none;
                font-size: 12px;
                @include r(1023) {
                    display: inline-block; } } }
        &-date {
            font-size: 13px;
            @include r(1023) {
                font-size: 11px; }
            @include r(767) {
                &:after {
                    content: '';
                    display: block;
                    width: 60px;
                    height: 1px;
                    margin: 8px auto 0;
                    background-color: #9d9d9d; } } } }
    &__form {
        padding: 45px 50px 35px;
        @include r(1023) {
            padding: 30px 30px 25px; }
        @include r(767) {
            padding: 20px; }
        &-title {
            margin-bottom: 35px;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
            @include r(1023) {
                margin-bottom: 25px;
                font-size: 20px; }
            @include r(767) {
                margin-bottom: 15px;
                font-size: 16px; } }
        &-field {
            margin-bottom: 20px;
            @include r(767) {
                margin-bottom: 10px; } }
        &-checkbox {
            margin-bottom: 35px;
            @include r(1023) {
                margin-bottom: 15px;
                font-size: 12px; }
            @include r(767) {
                font-size: 11px; } }
        &-btn {
            width: 100%;
            padding: 0 10px;
            font-size: 14px; }
        &-btn + &-btn {
            margin-top: 10px; }
        &-text {
            margin-bottom: 20px;
            font-size: 15px;
            font-weight: 400;
            color: #FFF;
            &_smb {
                margin-bottom: 10px; } }
        &-intext {
            display: block;
            @include r(767) {
                display: inline-block; } } }
    &__done {
        padding: 45px 50px;
        @include r(1023) {
            padding: 30px 30px 25px; }
        @include r(767) {
            padding: 20px; }
        &-title {
            margin-bottom: 35px;
            font-size: 54px;
            font-weight: 700;
            line-height: 1;
            @include r(1023) {
                margin-bottom: 25px;
                font-size: 38px; }
            @include r(767) {
                margin-bottom: 15px;
                font-size: 24px; } }
        &-text {
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: 700;
            @include r(1023) {
                font-size: 16px; } } }
    &__mail {
        margin-bottom: 35px;
        @include clr;
        @include r(767) {
            margin-bottom: 15px; }
        &-icon {
            float: left;
            margin-right: 5px;
            .icon {
                font-size: 24px;
                color: $green;
                @include r(1023) {
                    font-size: 20px; } } }
        &-title {
            font-size: 20px;
            @include text-overflow;
            @include r(1023) {
                font-size: 16px; } } }
    &__alert {
        font-size: 18px;
        font-weight: 700;
        color: $orange;
        @include r(1023) {
            font-size: 16px; } }
    &__label {
        position: absolute;
        top: 20px;
        left: -8px;
        width: 123px;
        height: 72px;
        padding: 3px 10px 0 0;
        background: url(../img/tape-right-sm.png) no-repeat 0 0 / 100% 100%;
        text-align: center;
        color: #FFF;
        @include r(767) {
            width: 63px;
            height: 52px;
            padding-top: 7px;
            background: url(../img/tape-right-sm-mob.png) no-repeat 0 0 / 100% 100%; }
        &-value {
            font-size: 33px;
            font-weight: 700;
            line-height: 1;
            @include r(767) {
                font-size: 18px; } }
        &-title {
            font-size: 14px;
            text-transform: uppercase;
            @include r(767) {
                font-size: 10px; } } }
    &__list {
        margin-bottom: 45px;
        @include r(1023) {
            margin-bottom: 20px; }
        &-item {
            margin-bottom: 10px;
            @include clr;
            @include r(1023) {
                margin-bottom: 7px; } }
        &-icon {
            float: left;
            margin-right: 8px;
            font-size: 0;
            .icon {
                font-size: 16px;
                color: $green; } }
        &-title {
            display: table;
            font-size: 16px;
            @include r(1023) {
                font-size: 13px; }
            &_bold {
                font-weight: 700; } } }
    &_flight &__form {
        @include r(767) {
            padding-top: 10px; } }
    &_flight &__col:first-child {
        @include r(767) {
            height: 176px;
            background-position: 50% 100%; } } }

.subscribe_subscription {
    display: block;
    background-color: #65b004;
    border-radius: 3px;
    .subscribe__form {
        @include clr;
        padding: 40px 35px 30px; }
    .subscribe__form-field {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 10px; } }
    .field__input {
        padding: 0 12px 0 30px;
        border: 1px solid transparent;
        &:focus {
            border: 1px solid #ff7200; } }
    .city__field {
        input {
            padding: 0 12px 0 30px; } }
    .subscribe__form-title {
        color: #FFF;
        font-size: 24px;
        font-weight: 400;
        @include r(767) {
            font-size: 20px; } }
    .subscribe__form-btn {
        float: right;
        max-width: 350px;
        font-size: 18px;
        font-weight: 400; }
    .s__line-checkbox {
        margin-top: 0;
        float: none; }
    .s__line-cell {
        float: left;
        padding: 0 10px;
        width: 100%;
        margin-bottom: 20px;
        &:nth-child(2),
        &:nth-child(3) {
            width: 50%; } }
    .checkbox__content {
        font-weight: 400; } }

// subscribe-tile
.subscribe-tile {
    padding: 40px 40px 27px;
    border-radius: 3px;
    background-color: #e8e8e8;
    @include r(1023) {
        padding: 30px 15px 15px 25px; }
    @include r(767) {
        padding: 15px; }
    &__row {
        position: relative;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0; } }
    &__title, &__text {
        color: #00417c; }
    &__title {
        margin-bottom: 20px;
        font-size: 32px;
        @include r(1023) {
            font-size: 26px; } }
    &__text {
        font-size: 15px;
        @include r(767) {
            font-size: 12px; } }
    &__form {
        position: relative;
        display: block;
        max-width: 495px;
        margin-left: -50px;
        padding: 33px 45px 33px 50px;
        box-shadow: 3px 2px 4px rgba(0, 0, 0, 0.11);
        border-radius: 0 3px 3px;
        &:before {
            content: "";
            position: absolute;
            top: -7px;
            left: 3px;
            width: 0;
            border: 7px solid transparent;
            transform: rotate(45deg);
            z-index: -1; }
        &_blue {
            background-color: $blue;
            &:before {
                border-left: 7px solid #00608f; } }
        &_green {
            background-color: #65b004;
            &:before {
                background-color: #305c00; } }
        @include r(1023) {
            margin-left: -35px; }
        @include r(800) {
            max-width: none;
            padding: 25px 20px 15px 30px; }
        @include r(767) {
            margin-left: -25px;
            padding: 25px 15px 15px; } }
    &__input {
        display: inline-block;
        max-width: 246px;
        width: 100%;
        height: 54px;
        margin-right: 20px;
        padding: 10px;
        border: 1px solid transparent;
        border-radius: 3px;
        background-color: #f6f6f6;
        font-size: 15px;
        font-weight: 400;
        color: $cl;
        @include placeholder {
            color: #787878; }
        &:focus {
            border: 1px solid #ff7200; }
        @include r(800) {
            display: block;
            max-width: none;
            margin-bottom: 20px; } }
    &__img {
        position: absolute;
        right: 0;
        bottom: -25px;
        @include r(1023) {
            bottom: -10px;
            right: 30px; }
        @include r(800) {
            display: none; }
        img {
            width: 105px;
            @include r(1023) {
                width: 80px; } } }
    &__btn {
        display: inline-block;
        max-width: none;
        width: auto;
        height: 54px;
        padding: 0 13px;
        font-size: 14px;
        text-transform: uppercase;
        @include r(800) {
            display: block;
            width: 100%; }
        &_orange {
            background-color: #ff7200; } } }





