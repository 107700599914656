.popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 100px 20px;
    background: rgba(#000,.4);
    overflow: auto;
    @include vertical;
    z-index: 999;
    @include r(767) {
        padding: 20px 15px; }
    &_m-thin {
        @include r(767) {
            padding: 20px 10px; } }
    &_mob-full {
        @include r(425) {
            background: #ffffff;
            padding: 0 10px 10px;
            .popup__center {
                vertical-align: top; } } }
    &_pda-full {
        @include r(1023) {
            padding: 0!important;
            .popup__center {
                vertical-align: top; } }
        @include r(767) {
            background: #ffffff;
            -webkit-overflow-scrolling: touch; } }
    &_mob-top {
        @include r(425) {
            padding: 0;
            .popup__center {
                vertical-align: top;
                border-radius: 0; } } }
    &__center {
        display: inline-block;
        position: relative;
        width: 100%;
        max-width: 690px;
        background: #fff;
        border-radius: 6px;
        vertical-align: middle;
        font-size: 14px;
        text-align: left;
        &_w800 {
            max-width: 800px;
            @include r(1023) {
                max-width: 640px; }
            @include r(767) {
                max-width: 290px; } }
        &_w801 {
            max-width: 800px;
            @include r(1023) {
                max-width: 696px; }
            @include r(767) {
                max-width: 210px; } }
        &_w730 {
            max-width: 730px;
            @include r(767) {
                max-width: 500px; } }
        &_w560 {
            max-width: 560px; }
        &_w590 {
            max-width: 590px; }
        &_w330 {
            max-width: 330px; }
        &_w415 {
            max-width: 415px;
            @include r(425) {
                vertical-align: top; } }
        &_w465 {
            max-width: 465px;
            @include r(767) {
                max-width: 290px; } }
        &_w470 {
            max-width: 470px; }
        &_w380 {
            max-width: 380px; }
        &_w700 {
            max-width: 700px; }
        &_w780 {
            max-width: 780px;
            @include r(1023) {
                max-width: 640px; }
            @include r(767) {
                max-width: 290px; } }
        &_w840 {
            max-width: 840px;
            @include r(1023) {
                max-width: 700px; }
            @include r(767) {
                max-width: 320px; } }
        &_w768 {
            max-width: 768px;
            @include r(767) {
                border-radius: 0; } }
        &_w670 {
            max-width: 670px; }
        &_w640 {
            max-width: 640px;
            @include r(767) {
                max-width: 320px; } }
        &_mw767 {
            display: none;
            max-width: 767px;
            @include r(767) {
                display: inline-block; } }
        &_w620 {
            max-width: 620px;
            @include r(767) {
                max-width: 290px; } }
        &_w625 {
            max-width: 625px; }
        &_w420 {
            max-width: 420px; }
        &_brnone {
            @include r(767) {
                border-radius: 0; } } }
    &__close {
        position: absolute;
        top: -13px;
        right: -13px;
        width: 48px;
        height: 48px;
        background: #FFF;
        border-radius: 50%;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        font-size: 0;
        z-index: 99;
        @include r(767) {
            top: -9px;
            right: -9px;
            width: 34px;
            height: 34px; }
        .icon {
            font-size: 16px;
            @include r(767) {
                font-size: 13px; } }
        &_small {
            width: 28px;
            height: 28px;
            .icon {
                font-size: 12px; } }
        &_green {
            background-color: #65b004;
            .icon {
                color: #FFF; } }
        &_gray {
            background-color: #f7f7f7;
            .icon {
                color: $cl; } }
        &_mid-gray {
            background-color: #e8e8e8; }
        &_lmob {
            @include r(767) {
                top: 14px;
                left: 14px;
                right: auto;
                box-shadow: none;
                width: 16px;
                height: 16px;
                padding: 0;
                .icon {
                    color: #868686;
                    font-size: 16px; } } }
        &_blue {
            color: #3D7CDA;
            box-shadow: none;
            right: 0;
            top: 0;
            width: 40px;
            height: 40px;
            border-radius: 0 6px 0 0;
            background-color: transparent;
            @include r(767) {
                width: 30px;
                height: 30px;
                top: 3px;
                right: 7px; }
            svg {
                font-size: 12px!important; } } }
    &__gallery {
        margin: -10px -20px;
        padding: 15px 0;
        background-color: #50575c;
        @include r(767) {
            margin: 0 -10px -10px; }
        &-item {
            position: relative;
            height: 215px;
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0; }
            img {
                width: 100%;
                max-width: 100%;
                object-fit: cover;
                height: 100%; } } }
    &__cols {
        display: table;
        width: 100%;
        @include r(1023) {
            display: block; } }
    &__row {
        display: table-row;
        @include r(767) {
            display: block; } }
    &__col {
        display: table-cell;
        vertical-align: top;
        @include r(1023) {
            display: block; }
        &_w530 {
            width: 530px;
            padding-right: 20px;
            @include r(1023) {
                width: 100%;
                padding-right: 0; } }
        &:last-child {
            @include r(767) {
                display: flex;
                flex-flow: column wrap;
                padding: 0; } } }
    &__in {
        @include r(767) {
            padding: 0 10px; } }
    &__header {
        padding: 17px 20px;
        text-align: left;
        border-bottom: 1px solid #eaeaea;
        @include r(767) {
            padding: 11px 10px; } }
    &__title {
        &_w {
            color: #000;
            font-size: 20px;
            font-weight: 700;
            line-height: 18px;
            @include r(1023) {
                font-size: 18px; }
            @include r(767) {
                font-size: 16px;
                line-height: 21px;
                text-align: center; } }
        &_sm {
            @include r(767) {
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 1.2;
                text-align: left; } } }
    &__subtitle {
        color: #a7a7a7;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        @include r(767) {
            margin-top: 6px; } }
    &__body {
        @include clr;
        padding: 15px 20px 30px;
        @include r(767) {
            padding: 0 0 30px; }
        &_padbnone {
            @include r(767) {
                padding: 0 0 10px; } } }
    &__slider {
        margin-bottom: 20px;
        @include r(767) {
            margin: 0 -10px 10px; } }
    &__description, &__features {
        margin-bottom: 10px;
        p {
            margin-bottom: 15px;
            font-size: 13px;
            color: $cl;
            line-height: 15px;
            &:last-child {
                margin-bottom: 0; }
            @include r(767) {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0; } } } }
    &__description {
        &:first-child {
            margin-bottom: 15px;
            @include r(1023) {
                margin-bottom: 10px; } }
        @include r(767) {
            order: 3;
            margin-bottom: 15px;
            border-bottom: 1px solid #eaeaea;
            padding-bottom: 15px; } }
    &__features {
        margin-bottom: 15px;
        padding-bottom: 20px;
        border-bottom: 1px solid #eaeaea;
        @include r(767) {
            order: 4;
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none; } }
    &__prices {
        @include clr;
        @include r(767) {
            order: 2;
            margin: 0 -10px 10px;
            padding: 17px 10px;
            background-color: #eaeaea; } }
    &__prices-col {
        @include r(1023) {
            display: inline-block;
            vertical-align: top;
            text-align: right;
            line-height: 1;
            &:first-child {
                margin-right: 13px;
                @include r(767) {
                    margin-right: 10px; } } } }
    &__info {
        padding: 36px 50px 40px;
        @include clr;
        @include r(767) {
            padding: 18px 20px 20px; }
        &-icon {
            float: left;
            width: 156px;
            height: 156px;
            margin-right: 40px;
            border-radius: 50%;
            background: #eef7fd;
            @include vertical;
            color: #809eb6;
            @include r(767) {
                float: none;
                margin: 0 auto 15px; }
            &_alert {
                background-color: #fcede8;
                color: #cd4f4f; }
            &_attention {
                background-color: #fff7e9;
                color: #ff9f00; }
            &_info {
                background-color: #f3fdec;
                color: #9fc188; }
            .icon {
                vertical-align: middle; }
            .icon-old-session {
                font-size: 68px; }
            .icon-no-wifi {
                font-size: 62px; }
            .icon-alert {
                font-size: 66px; }
            .icon-attention {
                font-size: 70px; }
            .icon-info {
                font-size: 80px; } }
        &-content {
            overflow: hidden;
            @include r(767) {
                text-align: center; } }
        &-title {
            margin-bottom: 15px;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
            @include r(767) {
                font-size: 20px; } }
        &-text {
            margin-bottom: 20px;
            font-size: 14px;
            @include r(767) {
                padding: 0 10px; } }
        &-btn {
            min-width: 180px;
            padding: 0 40px;
            font-size: 14px; }
        &_sm {
            text-align: center; }
        &_sm &-btn {
            width: 100%; } }
    &_filters {
        @include r(767) {
            padding: 0; } }
    &_filters &__center {
        @include r(767) {
            border-radius: 0; } }
    &_filters &__close {
        @include r(767) {
            top: 0;
            right: auto;
            left: 0;
            width: 44px;
            height: 44px;
            box-shadow: none; }
        .icon {
            @include r(767) {
                font-size: 20px;
                color: #868686; } } } }


.field {
    position: relative;
    &__error {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 3px 12px 57px;
        box-shadow: 0 0 0 2px $red;
        border-radius: 6px;
        background: $red;
        font-size: 11px;
        color: #FFF;
        opacity: 0;
        visibility: hidden;
        transition: visibility .2s, opacity .2s;
        @include r(767) {
            padding: 3px 12px 47px; } }
    &__wrap {
        position: relative;
        z-index: 2; }
    &__input {
        width: 100%;
        height: 54px;
        padding: 0 12px;
        border-radius: 6px;
        background: #FFF;
        font-size: 15px;
        @include r(767) {
            height: 44px; }
        &_gray {
            background: #f6f6f6; } }
    &.error &__error {
        opacity: 1;
        visibility: visible; } }


.follow {
    display: table;
    width: 100%;
    min-height: 340px;
    border-radius: 6px;
    background-image: linear-gradient(180deg, #3ee7be 0%, #1d83cb 100%);
    color: #FFF;
    @include r(1023) {
        min-height: 310px; }
    @include r(767) {
        min-height: 0; }
    &__col {
        display: table-cell;
        vertical-align: top;
        &:first-child {
            width: 340px;
            padding-bottom: 10px;
            @include r(1023) {
                width: 270px; }
            @include r(767) {
                display: none; } }
        &:last-child {
            padding: 28px 50px 28px 0;
            @include r(1023) {
                padding: 28px 30px 28px 0; }
            @include r(767) {
                padding: 20px; } } }
    &__preview {
        &-img {
            display: block;
            max-width: 279px;
            margin: -12px 0 0 20px;
            @include r(1023) {
                max-width: 225px;
                margin: 22px 0 4px 20px; } }
        &-text {
            width: 190px;
            margin: 0 auto;
            font-size: 13px;
            text-align: center;
            @include r(1023) {
                width: 160px;
                font-size: 11px; } } }
    &__title {
        font-size: 34px;
        font-weight: 700;
        line-height: 1.2;
        @include r(1023) {
            font-size: 20px; } }
    &__text {
        margin-bottom: 27px;
        font-size: 18px;
        line-height: 1.2;
        @include r(1023) {
            margin-bottom: 30px;
            font-size: 15px; }
        @include r(767) {
            font-size: 14px; } }
    &__form {
        display: table;
        width: 100%;
        margin-bottom: 2px;
        &-cell {
            display: table-cell;
            vertical-align: top;
            @include r(767) {
                display: block; }
            &:first-child {
                width: 260px;
                padding-right: 10px;
                @include r(1023) {
                    width: 210px; }
                @include r(767) {
                    width: 100%;
                    padding: 0 0 10px; } } }
        &-btn {
            width: 100%;
            padding: 0 15px;
            font-size: 14px; }
        &_row {
            margin-bottom: 23px;
            @include r(1023) {
                margin-bottom: 14px;
                padding-top: 13px; } }
        &_row &-cell {
            display: block;
            &:first-child {
                width: 100%;
                padding: 0 0 10px; } } }
    &__or {
        margin-bottom: 3px;
        @include r(767) {
            text-align: center; } }
    &__fb {
        width: 100%;
        height: 54px;
        margin-bottom: 13px;
        background: #3a5ba0;
        border-radius: 6px;
        font-size: 0;
        color: #FFF;
        @include r(767) {
            height: 44px; }
        &-icon,
        &-title {
            display: inline-block;
            vertical-align: middle; }
        &-icon {
            margin-right: 10px;
            @include r(767) {
                display: none; }
            .icon {
                font-size: 14px; } }
        &-title {
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase; } }
    &__info {
        font-size: 13px;
        @include r(1023) {
            font-size: 11px; }
        @include r(767) {
            text-align: center; } }
    &__mail {
        margin-bottom: 37px;
        @include clr;
        @include r(767) {
            margin-bottom: 15px; }
        &-icon {
            float: left;
            margin-right: 5px;
            .icon {
                font-size: 24px;
                color: $orange;
                @include r(1023) {
                    font-size: 20px; } } }
        &-title {
            font-size: 20px;
            @include text-overflow;
            @include r(1023) {
                font-size: 16px; } } }
    &__done {
        &-title {
            padding-top: 14px;
            font-size: 54px;
            font-weight: 700;
            letter-spacing: 1px;
            line-height: 1.1;
            @include r(1023) {
                font-size: 38px; }
            @include r(767) {
                font-size: 24px; } }
        &-text {
            margin-bottom: 18px;
            font-size: 20px;
            font-weight: 700;
            @include r(1023) {
                margin-bottom: 38px;
                font-size: 16px; }
            @include r(767) {
                margin-bottom: 15px; } }
        &-info {
            font-size: 36px;
            font-weight: 700;
            @include r(1023) {
                font-size: 24px; }
            @include r(767) {
                font-size: 20px; } } } }


.popup.wind-guide-watersport-popup {
    @include r(767) {
        padding: 0;
        .popup__center {
            max-width: 100%;
            border-radius: 0; }
        .popup__close {
            left: 10px;
            top: 10px;
            border-radius: 0;
            box-shadow: none;
            font-size: 20px; } } }


.sports {
    &__head {
        padding: 18px 30px 14px;
        font-size: 15px;
        font-weight: 700;
        @include r(767) {
            padding: 18px 20px 14px;
            text-align: center; } }
    &__body {
        border-radius: 0 0 6px 6px;
        background: #F3F3F3; }
    &__group {
        padding: 20px 30px;
        border-bottom: 1px solid #D2D8DD;
        @include r(767) {
            padding: 15px 20px; }
        &:last-child {
            border: none; } }
    &__title {
        margin-bottom: 12px;
        font-size: 15px;
        font-weight: 700; }
    &__item {
        margin-bottom: 10px;
        @include clr; }
    &__data {
        float: right;
        margin-left: 20px; }
    &__type {
        overflow: hidden; } }


.popup__comment {
    min-height: 515px;
    padding: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    background-color: #f8f8f8;
    @include r(767) {
        min-height: inherit;
        padding: 20px; }
    &-form {
        @include clr; }
    &-field {
        margin-bottom: 40px;
        font-size: 0;
        &_smb {
            margin-bottom: 20px; } }
    &-col {
        display: inline-block;
        vertical-align: top;
        margin-right: 50px;
        &:last-child {
            margin-right: 0; }
        @include r(1023) {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0; } }
        @include r(600) {
            display: block;
            margin-right: 0; }
        &.error-input {
            .popup__comment-label {
                color: #e42c23; }
            .popup__comment-input {
                color: #e42c23;
                border: 1px solid #e42c23; } }
        &.correct-input {
            .popup__comment-input {
                border: 1px solid #65b004; } } }
    &-label {
        display: block;
        margin-bottom: 5px;
        font-size: 15px;
        font-weight: 400;
        color: #000; }
    &-input {
        max-width: 200px;
        width: 100%;
        padding: 10px 15px;
        border-radius: 6px;
        border: 1px solid #eeeeee;
        background-color: #fff;
        font-size: 15px;
        color: $cl;
        @include placeholder {
            color: #9B9B9B; }
        &:focus {
            border: 1px solid #ff7200; }
        @include r(600) {
            max-width: none; } }
    &-rating {
        @include clr; }
    &-rating-value {
        float: left;
        display: inline-block;
        margin-right: 40px;
        padding-top: 4px;
        font-size: 15px;
        font-weight: 400;
        color: #000;
        @include r(500) {
            float: none;
            display: block;
            margin-bottom: 20px; } }
    &-rating-value-num {
        display: inline-block;
        padding: 0 10px; }
    &-rating-stars {
        display: inline-block; }
    &-textarea-caption {
        margin-bottom: 5px;
        font-size: 15px;
        font-weight: 400;
        color: #000; }
    &-textarea {
        width: 100%;
        min-height: 180px;
        padding: 10px 15px;
        resize: none;
        border-radius: 6px;
        border: 1px solid #f2f2f2;
        background-color: #fff;
        font-size: 15px;
        color: $cl;
        &:focus {
            border: 1px solid #ff7200; }
        @include r(767) {
            min-height: 100px; } }
    .btn-primary_flr {
        float: right;
        max-width: 200px;
        @include r(767) {
            float: none;
            margin: 0 auto; } }
    .btn-primary_center {
        margin: 0 auto; }
    &-thanks {
        max-width: 610px;
        margin: 100px auto 0;
        text-align: center;
        @include r(767) {
            margin: 40px auto 0; } }
    &-title, &-text {
        margin-bottom: 20px;
        font-size: 15px;
        font-weight: 400;
        color: #000; }
    &-text {
        font-weight: 300;
        &:last-child {
            margin-bottom: 0; } }
    .tooltip-item {
        &:before {
            bottom: auto;
            top: 100%;
            left: 0;
            width: 100%;
            margin-left: 0;
            margin-bottom: 0;
            margin-top: 14px;
            padding: 10px 15px;
            font-size: 10px;
            text-align: left; }
        &:after {
            top: auto;
            bottom: -14px;
            border-top: none;
            border-bottom: 7px solid #282828; } } }


.blog {
    max-width: 800px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    line-height: 18px;
    &__col {
        background-size: cover;
        background-position: 50%;
        &:first-child {
            display: table;
            width: 100%;
            height: 212px;
            text-align: center;
            border-radius: 6px 6px 0 0;
            padding: 10px;
            .blog__form-title {
                font-size: 42px;
                font-weight: 700;
                display: table-cell;
                vertical-align: bottom;
                @include r(1023) {
                    font-size: 38px; } }
            .blog__form-title.best-flight {
                font-size: 45px; }
            .blog__form-title.share {
                font-size: 39px;
                @include r(1023) {
                    font-size: 34px; } }
            @include r(767) {
                height: 69px; } }
        &:last-child {
            background-image: linear-gradient(105deg, #0057A5 0, #003868 50%, #0057A5 100%);
            border-radius: 0 0 6px 6px; }
        &:last-child.flight {
            background: #42464E; }
        &:last-child.share {
            background: #162A53; } }
    &__form {
        text-align: center;
        padding: 25px;
        &-title {
            font-weight: 700;
            text-transform: uppercase;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.61);
            .blog__form__partner {
                text-transform: none; }
            @include r(767) {
                margin-bottom: 15px;
                font-size: 13px;
                text-shadow: none; } }
        @include r(1023) {
            padding: 25px 20px 10px; }
        @include r(767) {
            padding: 20px 5px 5px; }
        &-text {
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
            margin-bottom: 20px;
            @include r(1023) {
                font-size: 14px; } }
        &__partner {
            margin: 10px;
            font-size: 12px;
            &-img {
                position: relative;
                width: 75px;
                top: 3px;
                left: 5px;
                @include r(767) {
                    width: 60px; } }
            @include r(767) {
                padding: 0;
                font-size: 9px; } }
        &-btn {
            display: block;
            margin: 0 auto;
            width: 220px;
            padding: 0 10px;
            color: #fff;
            font-weight: 700;
            background-image: linear-gradient(180deg, #00B3FD 0, #00417C 100%);
            border: 2px solid #fff;
            border-radius: 6px;
            @include r(767) {
                width: 180px; }
            &:hover {
                background-image: linear-gradient(180deg, #0099FD 0, #002E7C 100%); }
            &:active {
                background-image: linear-gradient(180deg, #019CDE 0, #003865 100%); } }
        &-btn.flight {
            background: #ff6700;
            border: none;
            &:hover {
                background-color: #FF9630; }
            &:active {
                background-color: #E96A00; } }
        &-btn.share {
            background: #3A5BA0;
            border: none;
            &:hover {
                background-color: #0862A8; }
            &:active {
                background-color: #003D6C; }
            .follow__fb-icon {
                width: 32px;
                height: 32px;
                background-color: white;
                border-radius: 3px;
                line-height: 44px;
                .icon {
                    font-size: 22px;
                    color: #3A5BA0; }
                @include r(767) {
                    display: inline-block; } } }
        &-btn + &-btn {
            background: none;
            border: none;
            font-weight: normal;
            color: #DADADA;
            @include r(767) {
                font-size: 14px;
                margin-top: 5px; } } } }


.blog-subsribe {
    a {
        width: 260px;
        cursor: pointer; }
    a.facebook-btn {
        background: #3B5998;
        &:hover {
            background: #0762A9; }
        &:active {
            background: #003D6C; } }
    a.vkontakte-btn {
        margin-top: 20px;
        background: #45668E;
        &:hover {
            background: #2B5082; }
        &:active {
            background: #003D6C; } }
    .popup__info-title {
        font-size: 22px;
        @include r(767) {
            font-size: 18px; } }
    .popup__info {
        padding: 30px 45px;
        @include r(767) {
            padding: 40px 10px 20px; } }
    .popup__close {
        width: 30px;
        height: 30px;
        border-radius: 0;
        background: transparent;
        box-shadow: none;
        top: 10px;
        right: 10px;
        @include r(767) {
            top: 0;
            right: 0; }
        svg {
            color: #6C6C6C; }
        &:hover {
            svg {
                color: #000000; } } } }


.popup {
    //.subscribe-blog
    .subscribe-blog {
        padding: 40px 20px;
        width: 100%;
        min-height: 300px;
        background: url("../img/no_tckpuq_s-pablo-garcia-saldana2.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
        color: #fff;
        @include r(767) {
            min-height: 274px;
            padding: 10px; }
        &__title {
            font-size: 36px;
            font-weight: 700;
            line-height: 42px;
            @include r(767) {
                font-size: 22px;
                line-height: 25px; } }
        &__text {
            font-size: 18px;
            font-weight: 700;
            line-height: 21px;
            max-width: 440px;
            margin: 0 auto;
            @include r(767) {
                font-size: 16px;
                line-height: 18px; } } }

    //subscribe-box
    .subscribe-box {
        padding: 30px;
        border-radius: 6px;
        font-size: 16px;
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        &_green {
            padding: 18px 26px 33px;
            background: #65b004; }
        &_left {
            text-align: left; }
        .subscribe-box__title {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            margin-bottom: 0; }
        .subscribe-box__form {
            padding-top: 15px;
            input {
                display: block;
                width: 100%;
                border-radius: 6px;
                height: 40px;
                margin-bottom: 15px;
                padding: 0 10px 0 20px;
                background-color: #f9f9f9;
                color: #878787;
                text-align: left;
                border: 1px solid transparent;
                @include placeholder {
                    font-size: 15px;
                    color: #878787; }
                &:focus {
                    border: 1px solid #ff7200;
                    transition: .3s;
                    @include placeholder {
                        font-size: 0; } } }
            button {
                width: 100%;
                height: 40px;
                text-align: center;
                border-radius: 6px;
                text-transform: uppercase;
                font-weight: 700;
                background-color: #ff7200;
                color: #ffffff;
                font-size: 15px;
                transition: .3s;
                &:hover {
                    background-color: #f6a623;
                    transition: .3s; }
                &:active {
                    background-color: #e95d00;
                    transition: .3s; } } }
        &_blog {
            background: transparent;
            @include r(767) {
                padding: 0 20px; }
            .subscribe-box__form {
                margin-top: 20px;
                input {
                    display: inline-block;
                    width: 260px;
                    margin-right: 10px;
                    @include r(767) {
                        width: 100%;
                        margin-right: 0;
                        text-align: center; } }
                button {
                    width: 160px;
                    font-size: 18px;
                    @include r(767) {
                        width: 100%; } } } } } }


.hotel-slider {
    position: relative;
    height: 455px;
    width: 100%;
    overflow: hidden;
    @include r(767) {
     height: 215px; }
    &_popup {
     height: 283px;
     margin-bottom: 3px;
     @include r(1023) {
         height: 200px;
         margin-bottom: 0; }
     @include r(767) {
         height: 175px; }
     .hotel-slider__slide {
         height: 283px;
         @include r(1023) {
             height: 200px; }
         @include r(767) {
             order: 1;
             height: 175px; } }
     .rect-btn {
         height: 40px;
         margin-top: -20px;
         &.rect-btn_counter {
             height: 25px;
             margin-top: 0; } } }
    &__slide {
     background: #e9f0fa center center no-repeat;
     height: 460px;
     max-height: 100%;
     min-height: 1px;
     text-align: center;
     img {
         width: 100%;
         max-width: 100%;
         object-fit: cover;
         height: 100%; } }
    &__controls {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%; } }


.popup-33 {
    background-color: #ffffff;
    text-align: center;
    padding: 30px 10px 0;
    min-height: 300px;
    border-radius: 6px;
    @include r(767) {
        padding: 15px 10px 0;
        min-height: inherit; }
    &:nth-child(3) {
        padding: 40px 10px 10px;
        @include r(767) {
            padding: 15px 10px 10px; }
        .popup-33__title,
        .popup-33__title-h3 {
            color: #3D7CDA;
            max-width: 660px;
            @include r(767) {
                font-size: 13px;
                line-height: 15px;
                margin-bottom: 20px; } }
        .popup-33__step:first-child {
            margin-top: 30px; }
        .popup-33__step.done {
            .popup-33__step-title svg {
                color: transparent!important; }
            .popup-33__step-text {
                @include r(767) {
                    margin-left: 0; } } } }
    &__btn {
        height: 46px;
        line-height: 46px;
        font-size: 18px;
        font-weight: 700;
        margin-top: 40px; }
    &__title {
        font-size: 22px;
        line-height: 25px;
        font-weight: 700;
        color: #ff6700;
        @include r(767) {
            font-size: 18px;
            line-height: 21px; }
        span {
            display: inline-block; }
        &-h3 {
            color: #ff6700;
            font-size: 18px;
            line-height: 21px;
            font-weight: 700;
            margin-top: 5px; }
        &-h2 {
            color: #ff6700;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            margin-top: 5px;
            b {
                font-weight: 500; }
            @include r(767) {
                display: none; } } }
    &__step {
        background-color: #EBF7FF;
        position: relative;
        margin: 10px -10px 0;
        min-height: 65px;
        text-align: left;
        @include r(767) {
            margin-top: 5px; }
        &.disable {
            filter: grayscale(100%);
            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                background-color: rgba(#ffffff, .7); }
            .icon-arrow-right-new {
                color: transparent; } }
        &.done {
            background-color: #ffffff;
            .popup-33__step-wrap {
                &:first-child {
                    display: none; }
                &:last-child {
                    display: table; } }
            .popup-33__step-title {
                padding: 15px 20px;
                @include r(767) {
                    padding: 15px 10px; }
                svg {
                    font-size: 20px;
                    color: #3B9400; } }
            .popup-33__step-text {
                display: inline-block;
                margin-top: 12px;
                margin-left: -7px;
                b {
                    font-weight: 500; }
                @include r(767) {
                    display: block;
                    margin-top: 5px; } }
            .popup-33__step-avatar+.popup-33__step-text {
                position: relative;
                top: -15px;
                @include r(767) {
                    top: 0;
                    font-size: 12px;
                    line-height: 14px; } }
            .popup-33__step-selector {
                margin-top: 10px;
                @include r(767) {
                    margin-top: 5px; } }
            .popup-33__step-cell {
                @include r(767) {
                    padding: 5px 10px 5px 0; } } }
        &-wrap {
            &:first-child {
                display: table; }
            &:last-child {
                display: none; } }
        &-title {
            white-space: nowrap;
            padding: 20px 15px;
            color: #2B8B02;
            font-weight: 700;
            font-size: 15px;
            margin-top: 5px;
            svg {
                vertical-align: middle;
                margin-top: -5px; }
            @include r(767) {
                padding: 10px 5px; } }
        &-text {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            text-align: left;
            padding-right: 10px;
            @include r(767) {
                font-size: 13px;
                line-height: 15px; }
            a {
                display: inline-block;
                font-weight: 700;
                text-decoration: underline; }
            b {
                font-weight: 500; } }
        &-cell {
            display: table-cell;
            vertical-align: middle;
            @include r(767) {
                padding: 10px 10px 10px 0; }
            &:nth-child(1) {
                @include r(767) {
                    padding-right: 5px; } }
            &:nth-child(2) {
                padding-right: 20px; }
            &:nth-child(3) {
                @include r(767) {
                    padding-right: 15px;
                    width: 100%; } } }
        &-avatar {
            background-image: url(../img/face2.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 6px;
            width: 40px;
            height: 40px;
            margin-right: 10px;
            margin-left: -10px;
            display: inline-block;
            @include r(767) {
                margin-right: 8px;
                float: left; } }
        &-facebook {
            display: inline-block;
            background-color: #314F98;
            color: #ffffff;
            height: 40px;
            line-height: 40px;
            font-size: 15px;
            font-weight: 500;
            text-transform: none;
            border-radius: 6px;
            padding-left: 10px;
            width: 210px;
            margin-right: 45px;
            text-align: left;
            transition: background-color .2s;
            @include r(767) {
                width: 100%;
                text-align: center;
                margin-right: 0; }
            svg {
                font-size: 24px!important;
                vertical-align: middle;
                float: left;
                margin-top: 9px;
                margin-right: 5px;
                @include r(767) {
                    float: none;
                    margin-top: 0; } }
            &:hover {
                background-color: #0762A9; }
            &:active {
                background-color: #003D6C; } }
        &-selector {
            display: inline-block;
            margin-right: 45px;
            vertical-align: middle;
            color: #787878;
            @include r(1023) {
                margin-left: 15px; }
            @include r(767) {
                margin-right: 0;
                margin-left: 0;
                width: 100%; }
            .selector {
                width: 210px;
                font-size: 13px;
                font-weight: 500;
                @include r(767) {
                    width: 100%; }
                &__text {
                    @include r(767) {
                        margin-bottom: 10px;
                        font-size: 13px;
                        line-height: 15px;
                        color: $cl;
                        a {
                            color: #3D7CDA;
                            font-weight: 500;
                            text-decoration: underline;
                            display: inline-block; } } }
                &__list {
                    background-color: #FFE3E3;
                    border-radius: 40px; }
                &__item {
                    margin: 0;
                    width: 50%;
                    text-align: center; }
                &__type {
                    text-transform: none;
                    height: 40px;
                    line-height: 40px;
                    padding: 0 5px;
                    font-size: 14px; }
                .selector__list .selector__item .selector__type {
                    background-color: #FFE3E3; }
                .selector__input:checked+.selector__type {
                    background-color: #E42C23; }
                .selector__list_accept {
                    background-color: #D9EAD0;
                    .selector__item .selector__type {
                        background-color: #D9EAD0; }
                    .selector__input:checked+.selector__type {
                        background-color: #5EC550; } } } }
        &-promo {
            display: inline-block;
            background-color: #ff6700;
            color: #ffffff;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            font-weight: 500;
            text-transform: none;
            border-radius: 6px;
            padding-left: 10px;
            width: 210px;
            margin-right: 45px;
            text-align: center;
            transition: background-color .2s;
            &:hover {
                background-color: #FF9630; }
            &:active {
                background-color: #E96A00; }

            &-text {
                display: none;
                @include r(767) {
                    display: block;
                    font-size: 13px;
                    line-height: 15px;
                    color: $cl;
                    margin-bottom: 10px; } }
            svg {
                font-size: 14px!important;
                vertical-align: middle;
                margin-right: 10px;
                margin-left: -10px; }
            @include r(767) {
                margin-right: 10px;
                text-align: center;
                width: 100%; } }
        &:nth-child(6) {
            .popup-33__step-wrap:first-child {
                .popup-33__step-cell:nth-child(3) {
                    @include r(767) {
                        width: 100%; } } } }
        &:nth-child(4).done {
            .popup-33__step-cell:nth-child(3) {
                @include r(767) {
                    padding: 5px 10px 10px 0;
                    .popup-33__step-selector {
                        margin-left: -12px; } } } }
        &.popup-33__step_important {
            margin-top: 0;
            background-color: #FFC6C6;
            &.disable {
                background-color: #EBF7FF; }
            .important {
                padding: 15px 50px;
                color: #E42C23;
                font-size: 0;
                @include r(767) {
                    padding: 10px; }
                &__title {
                    display: inline-block;
                    font-size: 18px;
                    line-height: 16px;
                    font-weight: 700;
                    vertical-align: top;
                    @include r(767) {
                        font-size: 16px; }
                    &+.important__text {
                        display: inline-block;
                        vertical-align: top;
                        margin-left: 5px; } }
                &__text {
                    font-size: 14px;
                    line-height: 16px;
                    i {
                        color: $cl;
                        font-size: 12px; }
                    @include r(767) {
                        font-size: 13px;
                        line-height: 15px; } }
                &__review {
                    background-color: #ffffff;
                    padding: 10px 15px;
                    margin: 10px auto 0;
                    line-height: 15px;
                    font-size: 0;
                    &-avatar {
                        background-image: url(../img/face2.jpg);
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        border-radius: 6px;
                        width: 40px;
                        height: 40px;
                        float: left;
                        margin-right: 10px;
                        display: inline-block; }
                    &-text {
                        font-size: 14px;
                        margin-bottom: 0;
                        color: $cl;
                        margin-top: -3px;
                        p:first-child {
                            font-weight: 500; } }
                    &+.important__text {
                        margin-top: 10px; } } } } } }


.localesettings {
    background-color: #ffffff;
    padding: 15px 30px 15px;
    text-align: center;
    border-radius: 6px;
    font-size: 15px;
    box-shadow: 0 14px 40px 0 rgba(#000000, .35);
    @include r(425) {
        box-shadow: none;
        padding: 10px; }
    &__input {
        height: 40px;
        line-height: 40px;
        box-shadow: inset 0 0 0 1px #CFCFCF;
        margin-top: 38px;
        position: relative;
        color: $cl;
        text-align: left;
        cursor: pointer;
        padding: 0 25px 0 10px;
        border-radius: 6px;
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 10px;
            margin-top: -2px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: $cl transparent transparent transparent; } }
    &__label {
        display: inline-block;
        position: absolute;
        top: -35px;
        left: 0;
        cursor: auto; }
    &__flag {
        width: 22px;
        height: 15px;
        border-radius: 1px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        margin-top: -3px; }
    &__value {
        display: inline-block;
        font-weight: 700; }
    &__code {
        display: inline-block;
        position: absolute;
        right: 25px; }
    &__btn {
        height: 42px;
        line-height: 42px;
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;
        cursor: pointer;
        margin-top: 20px;
        border-radius: 3px;
        transition: background-color .2s;
        display: block;
        width: 100%;
        span {
            border-bottom: 1px dotted #3D7CDA;
            color: #3D7CDA; }
        &:hover {
            span {
                border-bottom: none; } }
        &.close {
            margin-top: 10px; }
        &.blue {
            background-color: #3D7CDA;
            color: #ffffff;
            &:hover {
                background-color: #48A9ED; }
            &:active {
                background-color: #3D7CDA; } }
        &.disable {
            background-color: #91999E;
            cursor: auto;
            &:hover,
            &:active {
                background-color: #91999E; } } }
    &__drop {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        margin-top: 1px;
        background: #FFF;
        border-radius: 6px;
        box-shadow: 0 14px 40px rgba(0, 0, 0, 0.34);
        overflow: auto;
        z-index: 99; }
    &__item {
        padding: 0 10px;
        cursor: pointer;
        transition: background .2s;
        border-bottom: 1px solid #F0F0F0;
        &.active,
        &:hover {
            background: #F0F0F0; }
        &:last-child {
            border-bottom: none; } }
    .popup__close {
        color: #3D7CDA;
        box-shadow: none;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
        border-radius: 6px;
        @include r(425) {
            right: -10px; }
        svg {
            font-size: 12px; } } }


.pricealert {
    position: relative;
    border-radius: 3px;
    background-color: #ffffff;
    .popup__close {
        box-shadow: none;
        border-radius: 0;
        top: 0;
        right: 0;
        background: transparent;
        svg {
            color: #3D7CDA;
            font-size: 12px; } }
    &__wrap {
        position: relative;
        margin: 0 auto;
        text-align: center;
        z-index: 2;
        padding: 25px 50px 5px;
        color: $cl;
        @include r(767) {
            padding: 30px 15px 20px; } }
    &__title {
        font-size: 43px;
        line-height: 50px;
        font-weight: 700;
        @include r(767) {
            font-size: 28px;
            line-height: 33px; } }
    &__text {
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 18px;
        margin-top: 5px;
        @include r(767) {
            margin-top: 10px;
            margin-bottom: 25px; } }
    &__input {
        width: 100%;
        height: 40px;
        padding: 0 10px;
        background: #FFF;
        border-radius: 6px;
        font-size: 15px;
        @include placeholder-color(#787878);
        text-align: center;
        border: 1px solid #C7C7C7; }
    &__field {
        margin-bottom: 10px;
        &-btn {
            width: 100%;
            height: 40px;
            font-size: 16px;
            text-align: center;
            span {
                @include r(767) {
                    display: inline-block; } } }
        .btn_no {
            color: #3D7CDA;
            span {
                border-bottom: 1px dotted #3D7CDA; }
            &:hover,
            &:active {
                span {
                    border-bottom: none; } } }
        &:last-child {
            margin-top: 13px;
            @include r(767) {
                margin: 0; } } }
    &__destination {
        border-radius: 6px;
        background-color: #EBEDF0;
        padding: 10px;
        &-head,
        &-info {
            font-size: 15px;
            line-height: 24px; }
        &-city {
            font-weight: 700; }
        &-code {
            text-transform: uppercase; } } }

.pricealert-new {
    position: relative;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 14px 40px 0 rgba(#000000 ,.35);
    &+.pricealert-new {
        margin-top: 30px;
        font-size: 15px;
        line-height: 1.2;
        @include r(767) {
            font-size: 13px; }
        .pricealert-new__wrap {
            padding: 30px;
            @include r(767) {
                margin-top: 20px;
                padding: 30px 20px; } } }
    .icon-lock {
        display: inline-block;
        vertical-align: middle;
        font-size: 15px;
        margin: 0 5px; }
    .popup__close {
        box-shadow: none;
        border-radius: 0;
        width: 40px;
        height: 40px;
        top: 0;
        right: 0;
        background: transparent;
        padding: 0;
        svg {
            color: #3D7CDA;
            font-size: 12px; } }
    &__wrap {
        position: relative;
        margin: 0 auto;
        text-align: center;
        z-index: 2;
        padding: 25px 50px 20px;
        color: $cl;
        border-radius: 0 0 6px 6px;
        @include r(767) {
            padding: 30px 15px 10px; }
        &+.pricealert-new__wrap {
            padding: 15px 50px 20px;
            background-color: #EBEDF0;
            border-top: 1px solid #D2D2D2;
            .pricealert-new__text {
                margin-bottom: 10px; }
            @include r(767) {
                padding: 10px 15px 20px;
                background-color: #ffffff;
                border: none; } } }
    &__subtitle {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500; }
    &__title {
        font-size: 43px;
        line-height: 50px;
        font-weight: 700;
        @include r(767) {
            font-size: 28px;
            line-height: 33px; } }
    &__text {
        font-size: 15px;
        line-height: 18px;
        margin-top: 5px;
        @include r(767) {
            margin-top: 10px; } }
    &__input {
        display: inline-block;
        width: 260px;
        vertical-align: middle;
        height: 40px;
        padding: 0 10px;
        background: #FFF;
        border-radius: 6px;
        font-size: 15px;
        @include placeholder-color(#787878);
        border: 1px solid #C7C7C7;
        @include r(767) {
            width: 176px; } }
    &__field {
        margin-bottom: 10px;
        &-facebook {
            background-color: #3C5A99;
            box-shadow: 0 1px 3px 0 rgba(#000000, .2);
            width: 100%;
            text-align: center;
            height: 40px;
            line-height: 40px;
            border-radius: 6px;
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
            padding: 0 20px;
            transition: background-color .2s;
            svg {
                font-size: 20px;
                vertical-align: middle;
                margin-right: 5px; }
            &:hover {
                background-color: lighten(#3C5A99, 5%); }
            &:active {
                background-color: darken(#3C5A99, 5%); } }
        &-btn {
            display: inline-block;
            vertical-align: middle;
            width: 90px;
            height: 40px;
            font-size: 16px;
            text-align: center;
            box-shadow: 0 1px 3px 0 rgba(#000000, .2);
            margin-left: 10px;
            span {
                @include r(767) {
                    display: inline-block; } }
            @include r(767) {
                width: 70px; } }
        &:last-child {
            margin-top: 13px;
            @include r(767) {
                margin: 0; } } }
    &__destination {
        &-head,
        &-info {
            font-size: 15px;
            line-height: 24px; }
        &-city {
            font-weight: 700; }
        &-code {
            text-transform: uppercase; } }
    &__or {
        position: relative;
        overflow: hidden;
        font-size: 15px;
        color: #A7A7A7;
        text-align: center;
        margin: 10px auto 10px;
        width: 160px;
        &:before,
        &:after {
            content: '';
            display: inline-block;
            width: 100%;
            height: 1px;
            background: #A7A7A7;
            z-index: -1;
            position: relative;
            vertical-align: middle; }
        &:before {
            right: 15px;
            margin: 0 0 0 -100%; }
        &:after {
            left: 15px;
            margin: 0 -100% 0 0; } }
    &__hr {
        display: inline-block;
        width: 50px;
        border-bottom: 1px solid #D2D2D2; }
    &__btn {
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        text-transform: uppercase;
        box-shadow: 0 1px 3px 0 rgba(#000000, .22);
        &+.pricealert-new__btn {
            margin-top: 15px;
            margin-bottom: 0; } }
    &__buttons {
        margin-top: 20px; }
    &_continue {
        @include r(425) {
            margin: 0 -10px; }
        .pricealert-new {
            &__wrap {
                padding: 40px 65px 20px;
                @include r(767) {
                    padding: 40px 20px 20px; } }
            &__hr {
                margin: 10px auto 5px; }
            &__buttons {
                margin-top: 30px; }
            &__btn {
                font-size: 15px; } } }
    &__footer {
        border-top: 1px solid #D2D2D2;
        padding: 15px 50px;
        @include r(767) {
            padding: 15px; } }
    &__check {
        position: relative;
        display: block;
        cursor: pointer;
        &-input {
            display: none; }
        &-input:checked + &-wrap &-tick {
            background-color: #3D7CDA;
            border-color: #3D7CDA; }
        &-wrap {
            position: relative;
            display: flex;
            transition: background .2s;
            z-index: 1; }
        &-tick {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15px;
            height: 15px;
            min-width: 15px;
            margin-right: 6px;
            background-color: white;
            border: 1px solid #c7c7c7;
            border-radius: 2px;
            transition: border-color .2s, background .2s;
            font-size: 0;
            color: #FFF;
            .icon {
                font-size: 8px;
                margin-top: 1px; } }
        &-content {
            font-size: 13px;
            span {
                display: inline-block; } } } }

.singup {
    background-color: #ffffff;
    padding: 25px 40px 30px;
    border-radius: 6px;
    box-shadow: 0 14px 40px 0 rgba(#000000,.35);
    @include r(425) {
        box-shadow: none;
        padding: 25px 10px 30px; }
    input::placeholder {
        color: #A7A7A7; }
    .popup__close {
        color: #BDC7D1;
        transition: color .2s;
        &:hover {
            color: $cl; } }
    &__form {
        font-size: 14px;
        color: $cl; }
    &__col {
        margin-top: 15px;
        position: relative;
        &:first-child {
            margin-top: 0; }
        &.error-input {
            .singup__input {
                color: #e42c23;
                border: 1px solid #e42c23;
                &:focus {
                    box-shadow: inherit;
                    border: 1px solid #e42c23; } }
            .singup__error-text {
                display: inline-block;
                position: absolute;
                bottom: -17px;
                left: 0; }
            .singup__error-icon {
                display: inline-block;
                position: absolute;
                right: -20px;
                top: 35px;
                svg {
                    font-size: 15px;
                    color: #CE3730; }
                @include r(425) {
                    display: none; } } }
        &.correct-input {
            .singup__input {
                border: 1px solid #65b004;
                &:focus {
                    box-shadow: inherit;
                    border: 1px solid #65b004; } }
            .singup__correct-icon {
                display: inline-block;
                position: absolute;
                right: -20px;
                top: 35px;
                svg {
                    font-size: 13px;
                    color: #3B9400; }
                @include r(425) {
                    display: none; } } } }
    &__input {
        display: block;
        padding: 10px 15px;
        margin-top: 3px;
        border: 1px solid #B1BDC8;
        border-radius: 6px;
        width: 100%;
        background-color: #ffffff;
        &:focus {
            border-color: #ff6700; } }
    &__label {
        color: #000000;
        font-weight: 500;
        position: relative;
        &:before {
            content: '*';
            position: absolute;
            top: -5px;
            right: -5px;
            color: #CE3730;
            font-size: 14px;
            font-weight: 700; } }
    &__form-btn {
        width: 100%;
        height: 40px;
        line-height: 40px;
        margin-top: 35px;
        font-size: 16px;
        text-transform: uppercase; }
    &__error-icon,
    &__correct-icon {
        display: none; }
    &__error-text {
        color: #CE3730;
        font-size: 13px;
        display: none; }
    &__forgot {
        position: absolute;
        bottom: -20px;
        left: 0;
        font-size: 12px;
        color: #3D7CDA;
        font-weight: 500; }
    &__or {
        font-size: 14px;
        text-align: center;
        margin: 20px auto 0; }
    &__eye {
        position: absolute;
        top: 31px;
        right: 6px;
        cursor: pointer;
        color: #9E9E9E;
        &.active {
            color: #ff6700;
            .tooltip__content {
                span:first-child {
                    display: none; }
                span:last-child {
                    display: inline; } } }
        svg {
            font-size: 13px; }
        .tooltip__wrap {
            margin-left: 8px; }
        .tooltip__content {
            text-align: center;
            span:last-child {
                display: none; } } }
    .hidden {
        @include r(425) {
            display: none; } }
    &__link {
        display: inline-block;
        width: calc(50% - 5px);
        margin-top: 10px;
        color: #ffffff;
        padding: 9px 5px 9px 45px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        border-radius: 6px;
        transition: background-color .2s;
        position: relative;
        text-align: left;
        svg {
            font-size: 24px;
            position: absolute;
            left: 10px;
            top: 50%;
            margin-top: -12px; }
        &--facebook {
            background-color: #314F98;
            margin-right: 10px;
            &:hover {
                background-color: #0762A9; }
            &:active {
                background-color: #003D6C; } }
        &--google {
            background-color: #4285F4;
            &:hover {
                background-color: lighten(#4285F4, 5%); }
            &:active {
                background-color: darken(#4285F4, 10%); }
            .singup__link-icon {
                background-color: #ffffff;
                width: 25px;
                height: 25px;
                border-radius: 6px;
                position: absolute;
                left: 8px;
                top: 50%;
                margin-top: -12px;
                svg {
                    font-size: 17px;
                    top: 4px;
                    left: 4px;
                    margin-top: 0; } } } }
    &__footer {
        padding: 30px 15px 35px;
        margin: 20px -40px -30px;
        border-top: 1px solid #A7B4C1;
        @include r(425) {
            margin: 20px -20px -30px;
            padding: 25px 15px 35px; } }

    &__not-register,
    &__privacy {
        font-size: 14px;
        text-align: center;
        a {
            display: inline-block;
            font-weight: 700;
            text-decoration: underline; } }

    &__privacy {
        font-size: 14px;
        margin: -15px 0; }
    &__wrong {
        background-color: #FCF4F4;
        color: #E7473E;
        border: 1px solid #E7473E;
        border-radius: 6px;
        padding: 13px;
        font-size: 14px;
        line-height: 16px;
        margin-top: 10px;
        @include r(425) {
            margin-top: 20px; }
        &-text {
            margin-top: 15px;
            line-height: 16px; } }
    &__remind,
    &__thanks {
        .singup__form {
            margin-top: 20px; }
        .singup__form-btn {
            display: inline-block;
            width: inherit;
            margin-top: 20px;
            &.btn {
                width: 170px; }
            &:last-child {
                text-align: right;
                text-decoration: underline;
                color: #6C6C6C;
                font-weight: 500;
                float: right;
                font-size: 14px; } } }
    &__register {
        .singup__form-btn {
            margin-top: 20px; }
        .singup__or {
            position: relative;
            overflow: hidden;
            margin: 10px auto 0;
            &:before,
            &:after {
                content: '';
                display: inline-block;
                width: 100%;
                height: 1px;
                background: #A7B4C1;
                position: relative;
                vertical-align: middle; }
            &:before {
                right: 15px;
                margin: 0 0 0 -100%; }
            &:after {
                left: 15px;
                margin: 0 -100% 0 0; } } }
    &__title {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700; }
    &__thanks {
        .singup__form {
            margin-top: 15px; }
        .singup__label:before {
            display: none; } }
    &__login {
        font-size: 0; }
    &__head {
        padding-left: 60px;
        font-size: 18px;
        line-height: 21px;
        font-weight: 500;
        position: relative;
        margin-bottom: 30px;
        &-icon {
            position: absolute;
            left: 3px;
            top: 4px;
            width: 40px;
            height: 40px;
            line-height: 35px;
            border-radius: 40px;
            background-color: #ebebeb;
            color: #000000;
            font-size: 22px;
            text-align: center;
            vertical-align: middle;
            svg {
                vertical-align: middle; } }
        &-link {
            color: #3D7CDA;
            text-decoration: none; } }
    .popup__close {
        color: #3D7CDA;
        box-shadow: none;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
        border-radius: 3px;
        @include r(425) {
            right: -10px; }
        svg {
            font-size: 12px; } } }


.rtp {
    background-color: #ffffff;
    padding: 45px 20px 30px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(#000000,.5);
    position: relative;
    color: $cl;
    text-align: right;
    overflow: hidden;
    @include r(569) {
        padding: 30px 15px 15px; }
    img {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 40%;
        @include r(569) {
            display: none; } }
    &__title {
        text-transform: uppercase;
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;
        @include r(569) {
            font-size: 18px; } }
    &__text {
        font-size: 15px;
        line-height: 17px;
        margin-top: 20px;
        @include r(569) {
            margin-top: 10px; } }
    &__wrap {
        max-width: 300px;
        display: inline-block;
        vertical-align: text-top;
        text-align: left;
        position: relative;
        background-color: #ffffff;
        @include r(569) {
            max-width: 100%;
            text-align: center; } }
    &__btn {
        margin-top: 30px;
        display: block;
        height: 54px;
        line-height: 54px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        @include r(569) {
            font-size: 14px;
            margin-top: 20px; } }
    .popup__close {
        color: #3D7CDA;
        box-shadow: none;
        border-radius: 0;
        right: 5px;
        top: 0;
        width: 40px;
        height: 40px;
        @include r(569) {
            width: 30px;
            height: 30px;
            top: 3px;
            right: 7px; }
        svg {
            font-size: 12px; } } }


.aviapromo-faq {
    box-shadow: 0 14px 40px 0 rgba(#000000, .35);
    padding: 20px;
    @include r(767) {
        padding: 20px 15px; }
    p {
        margin-top: 10px; }
    &__item {
        padding: 10px 0;
        border-bottom: 1px solid #EAEAEA;
        &:nth-child(3) {
            border-bottom: 0; } }
    &__text {
        font-size: 14px;
        line-height: 18px; }
    &__title {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        position: relative;
        padding-left: 50px;
        @include r(767) {
            padding-left: 35px;
            font-size: 16px;
            line-height: 19px; } }
    &__number {
        position: absolute;
        left: 10px;
        color: #ffffff;
        font-size: 13px;
        line-height: 22px;
        font-weight: 700;
        text-align: center;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: #3D7CDA;
        display: inline-block;
        @include r(767) {
            left: 0;
            top: 0; } }
    &__center {
        text-align: center; }
    &__more {
        display: inline-block;
        text-align: center;
        padding: 10px;
        margin: 0 auto;
        span {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            border-bottom: 1px dashed #3D7CDA; }
        &:hover,
        &:active {
            span {
                border-bottom: 0; } } } }


.aviapromo-popup {
    border-radius: 6px;
    box-shadow: 0 14px 40px 0 rgba(#000000, .35);
    @include r(767) {
        border-radius: 0; }
    .singup {
        &__link {
            padding: 5px;
            padding-left: 45px;
            @include r(767) {
                padding: 10px;
                padding-left: 45px; }
            &--facebook {
                padding-left: 35px!important; }
            span {
                &:nth-child(2) {
                    @include r(560) {
                        display: none; } }
                &:last-child {
                    display: block;
                    @include r(767) {
                        display: inline-block;
                        margin-left: 5px; }
                    @include r(560) {
                        margin-left: 0; } } } }

        &__form-btn {
            text-transform: none;
            margin-top: 10px; }
        &__or {
            color: #a7a7a7;
            &:before,
            &:after {
                width: 50px; } }
        &__input {
            text-align: center;
            font-weight: 400;
            height: 40px;
            line-height: inherit;
            margin-top: 10px; } }
    &__wrap {
        border-radius: 6px;
        padding: 25px 20px 15px;
        text-align: center;
        //background: #ffffff
        &_congr {
            padding: 50px 20px; }
        @include r(425) {
            border-radius: 0; } }
    &__title {
        font-size: 26px;
        line-height: 30px;
        font-weight: 700;
        @include r(425) {
            font-size: 20px;
            line-height: 24px; }
        &-orange {
            font-size: 22px;
            line-height: 25px;
            color: #ff6700;
            font-weight: 700; } }
    &__subtitle {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        &-small {
            font-size: 15px;
            line-height: 18px;
            font-weight: 700; } }
    &__text {
        font-size: 15px;
        line-height: 18px;
        margin: 15px auto 0;
        max-width: 350px;
        color: $cl;
        p+p {
            margin-top: 10px; }
        @include r(425) {
            font-size: 14px;
            line-height: 16px; }
        @include r(425) {
            max-width: inherit; }
        &.text-left {
            text-align: left; } }
    &__btn {
        min-width: 220px;
        text-align: center;
        display: block;
        margin: 15px auto 0;
        font-size: 16px;
        &+.aviapromo-popup__btn {
            margin-top: 0; }
        span {
            @include r(767) {
                display: inline-block; } }
        .icon-bell {
            font-size: 22px;
            vertical-align: middle;
            margin-right: 10px; } }
    &__icon {
        width: 70px;
        height: 70px;
        line-height: 70px;
        border-radius: 50%;
        color: #ffffff;
        text-align: center;
        float: left;
        margin-right: -70px;
        margin-top: -10px;
        @include r(425) {
            display: none; }
        svg {
            vertical-align: middle; }
        .icon-filters {
            font-size: 24px;
            transform: rotate(90deg); }
        .icon-new-clock {
            font-size: 34px; }
        &.green {
            color: #2B8B02;
            background-color: #EBFFE0; }
        &.orange {
            color: #ff6700;
            background-color: #FFE0AE; } }
    &__table {
        display: table;
        width: 100%;
        table-layout: fixed;
        background: #ffffff;
        border-radius: 6px;
        @include r(425) {
            border-radius: 0; } }
    &__cell {
        display: table-cell;
        vertical-align: middle;
        width: 50%;
        &:nth-child(1) {
            border-radius: 6px 0 0 6px;
            .aviapromo-popup__wrap {
                padding: 10px 20px;
                @include r(425) {
                    padding-top: 35px; } }
            @include r(767) {
                border-radius: 0; } }
        &:nth-child(2) {
            border-radius: 0 6px 6px 0;
            border-left: 1px solid #D2D2D2;
            background-color: #EBEDEF;
            .aviapromo-popup__wrap {
                background-color: transparent; }
            @include r(767) {
                border-left: 0;
                border-top: 1px solid #D2D2D2; } }
        @include r(767) {
            border-radius: 0;
            display: block;
            width: 100%; } }
    &__img {
        margin-top: 20px; }
    &__destination {
        display: inline-block;
        text-align: left;
        margin: 20px auto 10px;
        font-size: 14px;
        line-height: 16px;
        position: relative;
        &_no-hr {
            &::after {
                display: none!important; } }
        &::before,
        &::after {
            content: '';
            position: absolute;
            display: inline-block;
            width: 50px;
            height: 1px;
            background: #D2D2D2;
            left: 50%;
            margin-left: -25px; }
        &::before {
            top: -10px; }
        &::after {
            bottom: -10px; }
        &-item {
            margin-top: 5px;
            display: table; }
        &-head {
            display: table-cell;
            vertical-align: top;
            width: 55px; }
        &-value {
            font-weight: 500;
            display: table-cell;
            vertical-align: top;
            width: calc(100% - 60px); } }
    &__footer {
        background-color: #EBEDEF;
        border-top: 1px solid #D2D2D2;
        border-radius: 0 0 6px 6px;
        margin-top: -3px;
        &-wrap {
            padding: 20px 50px;
            .singup__form-btn {
                margin: 0; } }

        .aviapromo-popup__text {
            text-align: center;
            margin-top: 0; }
        .singup {
            &__form {
                display: table;
                width: 100%; }
            &__register {
                padding: 15px 50px 30px;
                @include r(500) {
                    padding: 15px 30px 30px; }
                @include r(425) {
                    padding: 10px 15px 20px; } }
            &__input,
            &__form-btn {
                display: table-cell;
                vertical-align: top; }
            &__form-btn {
                width: 120px;
                margin-left: 10px;
                padding: 0 5px;
                font-size: 14px; }
            &__input {
                width: calc(100% - 130px);
                text-align: left; }
            &__link {
                padding: 10px;
                padding-left: 45px;
                span:last-child {
                    display: inline-block; } } } } }


.profile-popup {
    background: #ffffff;
    box-shadow: 0 14px 40px 0 rgba(#000000, .35);
    padding: 30px 40px 20px;
    position: relative;
    border-radius: 6px;
    color: $cl;
    font-size: 15px;
    line-height: 18px;
    @include r(500) {
        margin: 0 -10px;
        box-shadow: none;
        padding: 40px 20px 10px;
        font-size: 14px;
        line-height: 16px; }
    &__title {
        font-size: 26px;
        line-height: 30px;
        font-weight: 700;
        text-align: center;
        @include r(500) {
            font-size: 20px;
            line-height: 24px; }
        &_red {
            color: #E42C23; } }
    &__text {
        margin-top: 10px; }
    &__close {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
        border-radius: 0 3px 0 0;
        color: #3D7CDA;
        font-size: 12px;
        svg {
            vertical-align: middle; } }
    &__btn {
        padding: 0 15px;
        font-size: 16px;
        font-weight: 500;
        height: 40px;
        line-height: 40px;
        min-width: 180px;
        @include r(500) {
            display: block;
            margin: 10px auto;
            float: none!important;
            width: 100%; }
        &:first-child {
            float: left;
            font-weight: 400; }
        &:last-child {
            float: right; }
        &-wrap {
            margin-top: 20px;
            &:after {
                content: "";
                display: table;
                clear: both; }
            @include r(500) {
                margin-top: 0; } } }
    &__mail {
        font-weight: 700; } }

.trigger-popup {
    border-radius: 6px;
    font-size: 14px;
    line-height: 16px;
    box-shadow: 0 14px 40px rgba(#000000, .35);
    &__table {
        background: #ffffff;
        border-radius: 6px;
        overflow: hidden;
        @include rm(768) {
            display: table;
            width: 100%;
            table-layout: fixed; } }
    &__btn {
        min-width: 130px;
        margin-top: 20px;
        @include r(767) {
            width: 100%; } }
    &__bg-img {
        position: absolute; }
    &__cell {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        @include rm(768) {
            width: 50%;
            display: table-cell;
            vertical-align: top; }
        &:nth-child(1) {
            color: #ffffff;
            position: relative;
            overflow: hidden;
            @include r(767) {
                min-height: 110px; }
            .trigger-popup__wrap {
                padding: 30px;
                @include r(767) {
                    padding: 20px 30px 20px 20px; } } } }
    &__close {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        padding: 0;
        z-index: 1;
        @include r(767) {
            width: 30px;
            height: 30px;
            line-height: 0;
            border-radius: 50%;
            background: rgba(#000000, .5);
            right: 10px;
            top: 10px;
            color: #ffffff; }
        .icon {
            font-size: 12px;
            vertical-align: middle; } }
    &__wrap {
        padding: 40px 30px;
        @include r(767) {
            padding: 20px; } }
    &__title {
        font-size: 26px;
        line-height: 30px;
        font-weight: 900;
        @include r(767) {
            font-size: 20px;
            line-height: 20px;
            padding-right: 25px; } }
    &__text {
        margin-top: 20px;
        p+p {
            margin-top: 10px; }
        @include r(767) {
            margin-top: 15px; } }
    &__hr {
        width: 50px;
        border-bottom: 1px solid #D2D2D2;
        margin-top: 20px;
        @include r(767) {
            margin-top: 15px; } } }


.trigger-form {
    &__title {
        font-size: 18px;
        line-height: 20px;
        font-weight: 700;
        @include r(767) {
            font-size: 16px;
            line-height: 20px; } }
    &__input {
        background: rgba(#EAECEE, .52);
        height: 40px;
        text-align: center;
        width: 100%;
        border-radius: 6px;
        margin-top: 10px;
        padding: 0 10px; }
    &__submit {
        box-shadow: 0 0 1px 0 rgba(#000000, .5);
        width: 100%;
        margin-top: 10px;
        padding: 0 12px; }
    &__or {
        position: relative;
        overflow: hidden;
        font-size: 13px;
        text-align: center;
        margin: 13px auto 11px;
        width: 215px;
        &:before,
        &:after {
            content: '';
            display: inline-block;
            width: 100%;
            height: 1px;
            background: #CACACA;
            z-index: 1;
            position: relative;
            vertical-align: middle; }
        &:before {
            right: 15px;
            margin: 0 0 0 -100%; }
        &:after {
            left: 15px;
            margin: 0 -100% 0 0; } }
    &__buttons {
        &::after {
            content: '';
            display: table;
            clear: both; } }
    &__container {
        &::after {
            content: '';
            display: table;
            clear: both; }
        .trigger-form__input {
            width: calc(100% - 150px);
            float: left;
            @include r(767) {
                width: calc(100% - 140px); } }
        .trigger-form__submit {
            width: 140px;
            float: right;
            @include r(767) {
                width: 130px; } } }
    &.error {
        .trigger-form__input {
            color: #E42C23;
            box-shadow: 0 0 0 1px #E42C23; } }

    &__btn {
        width: calc(50% - 5px);
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        border-radius: 6px;
        text-align: left;
        &:nth-child(1) {
            float: left; }
        &:nth-child(2) {
            float: right; }
        &-icon {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            width: 24px;
            height: 24px;
            line-height: 22px;
            background: #ffffff;
            border-radius: 3px;
            text-align: center;
            .icon {
                vertical-align: middle; } }
        &.btn_facebook {
            .icon {
                color: #314F98;
                font-size: 16px; } }
        &.btn_google {
            .icon {
                font-size: 17px; } } }

    &__agree {
        margin-top: 10px;
        font-size: 12px;
        line-height: 14px;
        a {
            text-decoration: underline; } } }

.trigger-sbscrb {
    @include r(767) {
        text-align: center; }
    &__title {
        font-size: 26px;
        line-height: 30px;
        font-weight: 900;
        @include r(767) {
            font-size: 20px;
            line-height: 24px; } }
    &__text {
        font-size: 14px;
        line-height: 16px;
        margin-top: 20px;
        @include r(767) {
            margin-top: 15px; } }
    &__btn {
        margin-top: 35px;
        width: 100%;
        @include r(767) {
            margin-top: 25px; } } }

.icon-new-clock {
    transform-origin: 50% 75%;
    animation: shake 2.5s ease infinite;
    animation-iteration-count: 3;
    animation-delay: 3s;
    @keyframes shake {
        0%, 100%, 50% {
            transform: rotate(0); }
        10%, 15%, 20%, 25%, 30%, 35%, 40%, 5% {
            transform: rotate(6deg); }
        45% {
            transform: rotate(4deg); }
        12.5%, 17.5%, 22.5%, 27.5%, 32.5%, 37.5%, 42.5%, 7.5% {
            transform: rotate(-6deg); }
        47.5% {
            transform: rotate(-2deg); } } }

.subscribe-blog {
    padding: 40px 20px;
    margin-top: 60px;
    width: 100%;
    min-height: 300px;
    background: url("../img/no_tckpuq_s-pablo-garcia-saldana2.jpg") no-repeat;
    background-size: cover;
    text-align: center;
    background-position: center;
    color: #fff;
    @include r(767) {
        min-height: 274px;
        padding: 10px;
        margin-top: 0; }
    &__title {
        font-size: 36px;
        font-weight: 700;
        line-height: 1.2;
        @include r(767) {
            font-size: 22px; } }
    &__text {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.2;
        max-width: 440px;
        margin: 0 auto;
        @include r(767) {
            font-size: 16px; } }
    .subscribe-blog__social-link {
        display: inline-block;
        background-color: #314F98;
        color: #ffffff;
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 15px;
        font-weight: 500;
        text-transform: none;
        border-radius: 6px;
        padding-left: 10px;
        svg {
            font-size: 24px!important;
            vertical-align: middle;
            float: left;
            margin-top: 9px; } }
    .subscribe-blog__social-wrap {
        margin: 20px auto 0;
        width: 360px;
        @include r(767) {
            width: 260px;
            margin-top: 15px; }
        &:after {
            content: "";
            display: table;
            clear: both; } }
    .popup__close {
        right: 20px;
        background: #EAEAEA;
        width: 30px;
        height: 30px;
        top: -15px;
        .icon {
            font-size: 14px; } }
    .subscribe-blog__or {
        font-size: 13px;
        line-height: 1.2;
        text-transform: lowercase;
        color: #fff;
        position: relative;
        z-index: 1;
        overflow: hidden;
        text-align: center;
        margin: 15px auto 10px;
        width: 360px;
        @include r(767) {
            margin: 10px auto 10px;
            width: 260px; }
        &:before,
        &:after {
            content: '';
            display: inline-block;
            width: 100%;
            height: 1px;
            background: #ffffff;
            z-index: -1;
            position: relative;
            vertical-align: middle; }
        &:before {
            right: 15px;
            margin: 0 0 0 -100%; }
        &:after {
            left: 15px;
            margin: 0 -100% 0 0; } }
    .subscribe-box__form {
        margin-top: 0;
        padding-top: 0;
        input, button {
            display: block;
            width: 360px;
            margin: 0 auto;
            text-align: center;
            @include r(767) {
                width: 260px;
                margin: 0 auto;
                height: 38px;
                line-height: 38px; } }
        button {
            margin-top: 10px;
            font-size: 16px;
            @include r(767) {
                margin-top: 10px;
                padding: 0 10px; } } }
    .subscribe-blog__text {
        max-width: none;
        @include r(767) {
            margin-top: 5px; } }
    .subscribe-box {
        padding: 0; }
    .subscribe-blog {
        margin-top: 0;
        padding: 15px;
        @include r(767) {
            padding: 15px; } }
    &_new {
        background: url(../img/no_tckpuq_s-pablo-garcia-saldana3.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        padding: 15px 25px 25px;
        min-height: inherit!important;
        margin-top: 30px;
        @include r(767) {
            padding: 20px 25px; }
        .subscribe-blog {
            &__title {
                font-family: "Futura", sans-serif;
                font-size: 14px;
                line-height: 23px;
                font-weight: 700;
                text-transform: uppercase;
                @include r(767) {
                    font-size: 12px;
                    line-height: 18px; } }
            &__social-wrap {
                max-width: 530px;
                width: 100%!important;
                @include r(767) {
                    margin-top: 5px; } }
            &__social-link {
                font-size: 14px;
                padding-left: 0;
                @include rm(768) {
                    width: auto;
                    padding: 0 15px 0 10px;
                    font-weight: 700;
                    float: left; }
                svg {
                    float: none;
                    margin-top: 0;
                    margin-right: 10px;
                    text-align: center; } }
            &__or {
                width: auto;
                float: left;
                font-weight: 700;
                font-size: 14px;
                margin: 12px 20px;
                @include r(767) {
                    float: none;
                    margin: 12px auto; }
                &:before,
                &:after {
                    display: none; } } }
        .subscribe-box {
            &__form {
                border-radius: 6px;
                overflow: hidden;
                width: 100%;
                @include rm(768) {
                    width: 300px;
                    float: right; }
                //+shadow
                input {
                    width: calc(100% - 110px)!important;
                    float: left;
                    border-radius: 0;
                    text-align: left;
                    padding: 0 10px;
                    color: $cl;
                    @include placeholder {
                        font-size: 15px;
                        color: #B6BBBC; } }
                button {
                    float: right;
                    width: 110px!important;
                    font-size: 14px;
                    text-transform: initial;
                    margin-top: 0!important;
                    border-radius: 0; }
                &:after {
                    content: "";
                    display: table;
                    clear: both; } } } } }

.subscribe-box {
    padding: 30px;
    border-radius: 6px;
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    &_green {
        padding: 18px 26px 33px;
        background: #65b004; }
    &_left {
        text-align: left; }
    .subscribe-box__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 0; }
    .subscribe-box__form {
        input {
            display: block;
            width: 100%;
            border-radius: 6px 0 0 6px;
            height: 40px;
            margin-bottom: 15px;
            padding: 0 10px 0 20px;
            background-color: #f9f9f9;
            color: #878787;
            text-align: left;
            border: 1px solid transparent;
            @include placeholder {
                font-size: 15px;
                color: #878787; }
            &:focus {
                border: 1px solid #ff7200;
                transition: .3s;
                @include placeholder {
                    font-size: 0; } } }
        button {
            width: 100%;
            height: 40px;
            text-align: center;
            border-radius: 6px;
            text-transform: uppercase;
            font-weight: 700;
            background-color: #ff7200;
            color: #ffffff;
            font-size: 15px;
            transition: .3s;
            &:hover {
                background-color: #f6a623;
                transition: .3s; }
            &:active {
                background-color: #e95d00;
                transition: .3s; } } }
    &_blog {
        background: transparent;
        @include r(767) {
            padding: 0 20px; }
        .subscribe-box__form {
            input {
                display: inline-block;
                width: 260px;
                @include r(767) {
                    width: 100%;
                    margin-right: 0;
                    text-align: center; } }
            button {
                width: 130px;
                font-size: 14px;
                text-transform: capitalize;
                padding: 0 10px;
                @include r(767) {
                    width: 100%; } } } } }
