.trigger-alert {
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;
  margin: 10px -15px;
  &__row {
    display: table;
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid #5B5B5B;
    &:last-child {
      border-bottom: 1px solid #5B5B5B; } }
  &__cell {
    display: table-cell;
    vertical-align: middle;
    padding: 0 5px;
    text-align: center;
    &:nth-child(1) {
      width: 45px;
      text-align: right; }
    &:nth-child(2) {
      text-align: left; }
    &:nth-child(3) {
      width: 60px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        height: calc(100% - 10px);
        width: 1px;
        background: #5B5B5B;
        top: 5px;
        left: 0; } } }
  &__oval {
    display: inline-block;
    vertical-align: middle;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 28px;
    text-align: center;
    background: #E42C23;
    svg {
      font-size: 8px;
      transform: rotate(-90deg); }
    &.green {
      background: #309E00;
      svg {
        transform: rotate(90deg); } } }
  &__btn {
    color: #FFFFFF;
    font-size: 11px; } }
