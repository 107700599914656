// ## use cycles
//     @for $i from 1 through 3
//       .item-#{$i}
//            width: 2em * $i;
//     @each $s in a,b,c
//            #{$s}
//                display: none

// ## fonts
// Save fonts to /css/fonts, after that write (eot, woff, ttf):
// +font(book, cytiapro-black-webfont)

@font-face {
    font-family: 'Mazzard H';
    src: url('https://tripmydream.cc/hybrid/fonts//MazzardH-Regular.woff2?2') format('WOFF2'),
    url('https://tripmydream.cc/hybrid/fonts//MazzardH-Regular.woff?2') format('WOFF'),
    url("https://tripmydream.cc/hybrid/fonts/MazzardH-Regular.ttf?2") format("truetype"),
    url("https://tripmydream.cc/hybrid/fonts/MazzardH-Regular.eot?2") format("embedded-opentype") {}
    font-weight: normal;
    font-style: normal;
    font-display: block; }

@font-face {
    font-family: 'Mazzard H';
    src: url('https://tripmydream.cc/hybrid/fonts//MazzardH-Light.woff2?2') format('WOFF2'),
    url('https://tripmydream.cc/hybrid/fonts//MazzardH-Light.woff?2') format('WOFF'),
    url("https://tripmydream.cc/hybrid/fonts/MazzardH-Light.ttf?2") format("truetype"),
    url("https://tripmydream.cc/hybrid/fonts/MazzardH-Light.eot?2") format("embedded-opentype") {}
    font-weight: 300;
    font-style: normal;
    font-display: block; }

@font-face {
    font-family: 'Mazzard H';
    src: url('https://tripmydream.cc/hybrid/fonts//MazzardH-Medium.woff2?2') format('WOFF2'),
    url('https://tripmydream.cc/hybrid/fonts//MazzardH-Medium.woff?2') format('WOFF'),
    url("https://tripmydream.cc/hybrid/fonts/MazzardH-Medium.ttf?2") format("truetype"),
    url("https://tripmydream.cc/hybrid/fonts/MazzardH-Medium.eot?2") format("embedded-opentype") {}
    font-weight: 500;
    font-style: normal;
    font-display: block; }

@font-face {
    font-family: 'Mazzard H';
    src: url('https://tripmydream.cc/hybrid/fonts//MazzardH-Bold.woff2?2') format('WOFF2'),
    url('https://tripmydream.cc/hybrid/fonts//MazzardH-Bold.woff?2') format('WOFF'),
    url("https://tripmydream.cc/hybrid/fonts/MazzardH-Bold.ttf?2") format("truetype"),
    url("https://tripmydream.cc/hybrid/fonts/MazzardH-Bold.eot?2") format("embedded-opentype") {}
    font-weight: bold;
    font-style: normal;
    font-display: block; }

@keyframes loading-bar {
    0% {
        background-position: 0 0; }
    100% {
        background-position: 50px 50px; } }

html {
    height: 100%;
    box-sizing: border-box; }

*,
*:before,
*:after {
    box-sizing: inherit; }
body {
    height: 100%;
    min-width: 320px;
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
    line-height: 1.3;
    font-family: "Mazzard H", sans-serif;
    color: $cl; }

.banner {
    &_mg {
        margin: 10px 0; } }

.aside-open {
    overflow: hidden;
    .m-header {
        z-index: 10; } }

.page {
    &-table_mapBtn {
        @include r(1023) {
            padding: 20px 0;
            display: flex;
            align-content: center;
            justify-content: space-between; }
        .page-promoMap {
            @include r(1023) {
                display: block; } }
        .page-title-wrap {
            @include r(1023) {
                margin: 10px 0 0;
                padding-right: 20px;
                max-width: 450px; }
            @include r(767) {
                margin: 0;
                padding-right: 15px; }
            .page-title,
            .page-subtitle {
                @include r(1023) {
                    text-align: left; } } }
        .page-subtitle {
            @include r(1023) {
                font-size: 16px;
                line-height: 18px; }
            @include r(767) {
                font-size: 13px;
                line-height: 15px; } } }
    &-promoMap {
        display: none;
        position: relative;
        background-color: #3D7CDA;
        width: 130px;
        min-width: 130px;
        height: 80px;
        border-radius: 3px;
        background-image: url(../img/promoMapBgMob.svg);
        background-position: center;
        background-repeat: no-repeat;
        @include r(767) {
            width: 80px;
            min-width: 80px;
            height: 60px;
            font-size: 11px;
            line-height: 13px;
            background-size: contain;
            background-position: center 25%; }
        &-btn {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            color: #FFFFFF;
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
            text-align: center;
            padding: 8px;
            border-radius: 0 0 3px 3px;
            background: rgba(#000000, .3);
            @include r(767) {
                padding: 2px 5px;
                font-size: 11px;
                line-height: 13px; } }
        @include rm(1024) {
            display: none; } }
    &-title {
        font-size: 32px;
        line-height: 38px;
        color: $cl;
        font-weight: 700;
        text-align: center;
        @include r(767) {
            font-size: 16px;
            line-height: 19px; }
        &_h2 {
            font-size: 18px;
            line-height: 20px;
            font-weight: 700;
            text-align: center;
            @include r(767) {
                font-size: 16px; } }
        &-wrap {
            margin: 20px auto; }
        &_question {
            @include r(1023) {
                font-size: 24px;
                line-height: 28px;
                color: #3D7CDA;
                span {
                    border-bottom: 1px dashed #3D7CDA; } }
            .page-title__icon {
                display: none;
                color: #ffffff;
                font-size: 18px;
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                border-radius: 50%;
                background: #3D7CDA;
                margin-left: 5px;
                @include r(1023) {
                    display: inline-block; }
                @include r(767) {
                    height: 20px;
                    width: 20px;
                    line-height: 20px;
                    font-size: 16px; } } } }
    &-subtitle {
        font-size: 18px;
        line-height: 21px;
        color: $cl;
        text-align: center;
        @include r(767) {
            font-size: 13px;
            line-height: 15px; }
        &_small {
            font-size: 14px;
            line-height: 20px;
            color: #464646;
            text-align: center; } } }

h1.page-title {
    margin-bottom: 0; }
.hidden-desktop {
    @include rm(1023) {
        display: none !important; } }
.hidden-lg {
    @include rm(1159) {
        display: none !important; } }
.hidden-pda {
    @include r(1023) {
        display: none !important; } }
.hidden-pda-only {
    display: block !important;
    @include r(767) {
        display: block !important; }
    @include r(1023) {
        display: none !important; } }
.hidden-mob {
    @include r(767) {
        display: none !important; } }
.visible-mob {
    display: none !important;
    @include r(767) {
        display: block !important; } }
.visible-pda {
    display: none !important;
    @include r(1023) {
        display: block !important; } }
button,
input,
textarea {
    background: none;
    border: none;
    outline: none; }
a {
    text-decoration: none;
    color: $blue; }

.clr {
    @include clr; }

.fade-out {
    opacity: 0;
    transition: opacity .2s; }

.fade-in {
    opacity: 1;
    transition: opacity .2s; }

.wrapper {
    min-height: 100%;
    overflow: hidden;
    &_gray {
        background: #f3f5f7; }
    &_dgray {
        background-color: #F4F4F4; }
    &_blue {
        background-color: #ecf1f5; } }

.center {
    max-width: 1160px;
    margin: 0 auto;
    padding: 0 20px;
    @include r(767) {
        padding: 0 10px; }
    &.center_mid {
        @include r(1023) {
            padding: 0 20px; }
        @include r(767) {
            padding: 0 10px; } }
    &.center_nopad {
        padding: 0; }
    &_fixed-filter {
        @include r(1023) {
            margin-top: 60px; }
        @include r(767) {
            margin-top: 70px; } } }

.row {
    margin: 0 -10px;
    font-size: 0;
    @include r(1023) {
        margin: 0 -10px -30px; }
    &__col {
        display: inline-block;
        font-size: 14px;
        vertical-align: top;
        &_4 {
            width: 33.33%;
            padding: 0 10px;
            @include r(1023) {
                width: 50%;
                margin-bottom: 30px; }
            @include r(767) {
                width: 100%; } } }
    &_res {
        margin: 0 -7px; }
    &_res &__col {
        padding: 0 7px 30px;
        &:first-child {
            width: 21%;
            @include r(1159) {
                width: 239px; }
            @include r(1023) {
                display: none;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 0;
                background: rgba(#000,.7);
                overflow: auto;
                z-index: 999; } }
        &:nth-child(2) {
            width: 63%;
            @include r(1159) {
                width: 71%; }
            @include r(1023) {
                width: 100%; } }
        &:last-child {
            width: 16%;
            @include r(1159) {
                display: none; } } } }

.container {
    padding: 30px 0;
    background: $bg;
    @include r(767) {
        padding: 20px 0; }
    &__center {
        @include r(767) {
            padding: 0 10px; } } }

.l {
    display: table;
    position: relative;
    width: 100%;
    table-layout: fixed;
    &__container,
    &__aside {
        display: table-cell;
        vertical-align: top;

        @include r(1023) {
            display: block; } }

    &__container {
        @include r(1023) {
            margin-bottom: 15px; }
        @include r(767) {
            margin-bottom: 10px; }
        &_lmb {
            margin-bottom: 20px;
            @include r(767) {
                margin-bottom: 30px; } } }
    &__container + &__aside {
        @include r(1023) {
            margin-top: 30px; } }
    &__aside {
        width: 266px;
        padding-left: 20px;
        @include r(1023) {
            width: 100%;
            padding: 0; }
        &-fix-wrap.is-fixed {
            @include rm(1024) {
                position: fixed;
                top: 60px;
                width: 246px; } }
        &-fix-wrap.is-fixed-bottom {
            @include rm(1024) {
                position: absolute;
                bottom: 0;
                width: 246px; } }
        &_left {
            width: 290px;
            padding-right: 20px;
            padding-left: 0;
            @include r(1023) {
                display: none; } } }
    &__fixed {
        width: 246px;
        @include r(1023) {
            width: 100%; }
        &.fixed {
            @include rm(1023) {
                position: fixed;
                top: 0; } }
        &.absolute {
            @include rm(1023) {
                position: absolute;
                right: 0;
                bottom: 0; } } }
    &_short {
        padding-right: 266px;
        @include r(1023) {
            padding: 0; } } }

.btn {
    display: inline-block;
    height: 48px;
    padding: 0 20px;
    background: $orange;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 700;
    color: #FFF;
    text-align: center;
    transition: background .2s, color .2s;
    white-space: nowrap;
    &:hover {
        background: darken($orange, 5%); }
    &:active {
        background: darken($orange, 10%); }
    &[disabled] {
        background: #949494; }
    &__icon,
    &__title {
        display: inline-block;
        vertical-align: middle; }
    &_xsm {
        height: 26px;
        line-height: 26px;
        display: block;
        margin: 10px auto 0;
        font-size: 12px;
        padding: 0 10px; }
    &_sm {
        height: 34px;
        font-size: 13px; }
    &_mds {
        height: 36px;
        font-size: 14px;
        font-weight: 400; }
    &_mdb {
        height: 38px;
        font-size: 16px;
        font-weight: bold; }
    &_md {
        height: 40px;
        font-size: 14px; }
    &_mdl {
        height: 50px;
        font-size: 20px; }
    &_lg {
        height: 54px;
        @include r(767) {
            height: 44px; } }
    &.btn_block {
        display: block;
        height: 40px;
        line-height: 40px; }
    &_cancel {
        background: #ffffff!important;
        color: #9B9B9B;
        &:hover {
            color: #3D7CDA; }
        &:active {
            color: #3D7CDA; } }
    &_white {
        background: #FFF; }
    &_gray {
        background: #F2F3F4;
        color: $cl;
        &:hover {
            background: #F2F3F4; }
        &:active {
            background: #F2F3F4; } }
    &_gray-dark {
        background: #9D9D9D;
        color: #fff;
        &:hover {
            background: lighten(#9D9D9D, 5%); }
        &:active {
            background: darken(#9D9D9D, 10%); } }
    &_gray-dark-new {
        background: #B1BDC8;
        color: #fff;
        &:hover {
            background: lighten(#B1BDC8, 5%); }
        &:active {
            background: darken(#B1BDC8, 10%); } }
    &_red {
        background: #F70045;
        &:hover {
            background: lighten(#F70045,5); }
        &:active {
            background: darken(#F70045,5); } }
    &_youtube {
        background: #D60008;
        &:hover {
            background: lighten(#D60008,5); }
        &:active {
            background: darken(#D60008,5); } }
    &_telegram {
        background: #1E96C8;
        &:hover {
            background: lighten(#1E96C8,5); }
        &:active {
            background: darken(#1E96C8,5); } }
    &_red-new {
        background: #E42C23;
        &:hover {
            background: lighten(#E42C23,5); }
        &:active {
            background: darken(#E42C23,5); } }
    &_lblue {
        background-color: #3D7CDA;
        &:hover {
            background: lighten(#3D7CDA, 5); }
        &:active {
            background: darken(#3D7CDA, 5); } }
    &_facebook {
        background: #314F98;
        padding: 0 15px;
        &:hover {
            background: lighten(#314F98, 5); }
        &:active {
            background: darken(#314F98, 5); } }
    &_google {
        background: #4285F4;
        padding: 0 15px;
        &:hover {
            background: lighten(#4285F4, 5); }
        &:active {
            background: darken(#4285F4, 5); } }
    &_center {
        display: block;
        margin: 0 auto; }
    &_blue {
        background: #3D7CDA;
        &:hover {
            background: #48A9ED; }
        &:active {
            background: #3D7CDA; } }
    &_no {
        background: transparent;
        span {
            display: inline-block;
            font-size: 13px;
            border-bottom: 1px dotted #fff;
            transition: border-color .2s; }
        &:hover {
            background: transparent;
            span {
                border-color: transparent; } } }
    &_dashed {
        background: transparent;
        margin-bottom: -15px;
        box-shadow: none!important;
        span {
            color: #3D7CDA;
            font-size: 15px;
            line-height: 18px;
            border-bottom: 1px dashed #3D7CDA;
            @include r(767) {
                display: inline-block!important; } }
        &:hover,
        &:active {
            background: transparent;
            span {
                border-bottom: none; } }
        &-grey {
            span {
                color: #9B9B9B;
                border-color: #9B9B9B; } } }

    &_stroke {
        border: 2px solid #3D7CDA;
        background: #fff;
        color: #3D7CDA;
        text-transform: uppercase;
        &:hover {
            background: #3D7CDA;
            border-color: #3D7CDA;
            color: #fff; }
        &:active {
            background: #00B0EF;
            border-color: #00B0EF; }
        &_gray {
            border: 2px solid #d7d6d6;
            color: $cl;
            &:hover {
                background: #ececec;
                border-color: #ececec;
                color: $cl; }
            &:active {
                background: #dfdfdf;
                border-color: #dfdfdf; } } }
    &_another-orange {
        background: $orange; }
    &_cheaper {
        height: 50px;
        width: 100%;
        margin-bottom: 15px;
        &:hover {
            background: #fe336c; }
        &:active {
            background: #D2013B; }
        @include r(1023) {
            display: none; } }
    &_cheaper &__icon {
        margin-right: 12px;
        padding-top: 2px;
        .icon {
            font-size: 26px; } }
    &_cheaper &__title {
        font-size: 12px; }
    span {
        @include r(767) {
            display: none; } }
    .icon-bell,
    .icon-bell-shake {
        font-size: 22px;
        vertical-align: middle;
        margin-right: 10px; }
    &_ml10 {
        margin-left: 10px; } }

a.btn {
    line-height: 48px;
    height: 48px;
    &_mdb {
        height: 38px;
        line-height: 38px; }
    &_md {
        height: 40px;
        line-height: 40px; } }

.btn-primary {
    display: block;
    height: 50px;
    line-height: 50px;
    max-width: 313px;
    width: 100%;
    padding: 0 10px;
    background-color: #00417c;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 400;
    color: #FFF;
    text-align: center;
    transition: background .2s, color .2s;
    white-space: nowrap;
    &:hover {
        background-color: #2456bf; }
    &:active {
        background-color: #163c8c; }
    &.btn-primary_margin {
        margin: 48px auto 15px;
        @include r(1023) {
            margin-bottom: 30px; }
        @include r(767) {
            margin: 15px auto 20px; } }
    &.btn-primary_blue {
        width: 224px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0);
        background-color: $blue;
        text-transform: uppercase;
        font-weight: 900;
        &:hover {
            background-color: #ff8e1f; }
        &:active {
            background-color: #e66c00; } }
    &.btn-primary_orange {
        float: right;
        display: inline-block;
        background-color: #ff7200;
        max-width: 238px;
        @include r(1023) {
            float: none;
            display: block;
            max-width: none;
            width: 100%; }
        &:hover {
            background-color: #ff8e1f; }
        &:active {
            background-color: #e77700; } } }

.help {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #cce3f3;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    color: #3D7CDA;
    line-height: 16px;
    cursor: pointer;
    vertical-align: middle;
    &_stroke {
        background: transparent;
        border: 1px solid #CACACA;
        color: #CACACA;
        line-height: 14px; }
    &_blue {
        background: #3D7CDA;
        color: #fff; }
    &_green {
        background: #9acc80;
        color: #FFF; }
    &_red {
        background-color: #ff6d6d;
        color: #FFF; } }

.breadcrumbs {
    margin: 28px 0;
    ul {
        font-size: 0;
        max-width: 1120px;
        margin: 0 auto;
        li {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            &:before {
                content: "/";
                display: inline-block;
                vertical-align: middle;
                margin: 0 5px;
                color: #000;
                font-size: 14px; }
            &:first-child:before {
                display: none; }
            a {
                &:hover {
                    text-decoration: underline; } }
            @include r(767) {
                font-size: 12px; } } }
    &_dark {
        background-color: #384E5D;
        margin: 0!important;
        padding: 15px 30px;
        color: #ffffff;
        @include r(767) {
            padding: 10px 15px; }
        a {
            color: #ffffff; }
        li:before {
            color: #ffffff!important; }
        .center {
            padding: 0!important;
            max-width: 1120px!important; } } }

.h2 {
    margin: 0 0 20px;
    color: #000;
    font-size: 30px;
    font-weight: 300;
    line-height: 35px;
    @include r(767) {
        font-size: 24px;
        line-height: 28px; } }

.h3 {
    margin: 0 0 6px;
    font-size: 18px;
    font-weight: 500;
    color: #000;
    @include r(767) {
        font-size: 17px; } }

.go-top {
    position: fixed;
    right: 20px;
    bottom: 30px;
    width: 44px;
    height: 44px;
    background: #fff;
    border-radius: 3px;
    font-size: 0;
    color: #8d8c8c;
    transition: color .2s, box-shadow .2s;
    z-index: 49;
    @include shadow;
    @include r(1023) {
        right: 20px;
        bottom: 20px; }
    @include r(767) {
        bottom: 15px;
        right: 10px; }
    &:hover {
        box-shadow: 0 3px 7px rgba(0,0,0,.2);
        color: $cl; }
    .icon {
        font-size: 10px; } }

.aside-stick {
    position: fixed;
    left: 0;
    top: 50%;
    width: 330px;
    margin-left: -148px;
    transform: rotate(-90deg);
    font-size: 0;
    z-index: 1000;
    @include r(1023) {
        display: none; }
    &__btn {
        display: inline-block;
        border-radius: 0 0 3px 3px;
        color: #FFF;
        font-size: 13px;
        padding: 0 15px;
        border: none;
        outline: none;
        height: 35px;
        line-height: 35px;
        &_subscr {
            background-color: #004b85; }
        &_reviews {
            background-color: #85b715; }
        .icon {
            display: inline-block;
            transform: rotate(90deg);
            margin-bottom: -3px;
            margin-left: 3px; }
        .icon-mail {
            font-size: 14px; }
        .icon-telegram {
            font-size: 16px; } } }

.title {
    margin-bottom: 30px;
    font-weight: 300;
    font-size: 36px;
    text-align: center;
    line-height: 1.2;
    @include r(767) {
        font-size: 26px; }
    &_condensed {
        @include r(767) {
            font-size: 22px!important; } }
    &_2 {
        margin-bottom: 20px;
        font-size: 22px;
        text-align: left; }
    &_mb-small {
        margin: 0 0 15px;
        @include r(1023) {
            margin: 25px 0 15px; }
        @include r(767) {
            margin: 15px 0 20px; } }
    &_bolder {
        font-weight: 700;
        line-height: 1.3;
        @include r(767) {
            font-size: 18px; } }
    &_left {
        text-align: left!important;
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
        text-transform: uppercase;
        @include r(767) {
            font-size: 18px!important;
            text-transform: none; } }
    &_small {
        font-size: 26px;
        text-align: left; }
    &_reselection {
        margin-bottom: 20px;
        font-size: 24px;
        text-align: left;
        font-weight: 400;
        @include r(1159) {
            display: none; }
        .icon-arrow-two-sides {
            font-size: 6px;
            vertical-align: middle; } } }

.checkbox {
    display: block;
    user-select: none;
    cursor: pointer;
    color: #fff;
    &__input {
        display: none; }
    &__input {
        &:checked {
            + .checkbox__in {
                .checkbox__icon {
                    .icon {
                        opacity: 1; } } } } }
    &__in {
        display: block;
        position: relative;
        padding-left: 34px;
        @include clr; }
    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 3px;
        @include vertical;
        color: $cl;
        .icon {
            font-size: 10px;
            vertical-align: middle;
            opacity: 0;
            transition: opacity .2s; }
        &_loader {
            top: 3px;
            width: 15px;
            height: 15px;
            border: 1px solid #c7c7c7;
            .icon {
                font-size: 7px; } } }
    &__content {
        display: block;
        padding: 1px 0;
        font-size: 13px;
        font-weight: 700; }
    &_gray &__icon {
        background: #f6f6f6; }
    &_gray &__content {
        color: $cl; }
    &_thin &__content {
        font-weight: 400; }
    &_thin &__in {
        padding-left: 25px; } }

.page-container {
    &_mt {
        margin-top: 30px;
        @include r(1023) {
            margin-top: 0; } } }

.page-body {
    display: table;
    width: 100%;
    table-layout: fixed;
    @include r(1023) {
        display: block; }
    &>div:last-child {
        padding-right: 0; } }

.page-aside,
.page-center {
    display: table-cell;
    vertical-align: top;
    @include r(1023) {
        display: block; } }

.page-aside-wrap {
    margin: 0 -10px; }

.page-aside {
    position: relative;
    width: 300px;
    @include r(1023) {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        min-width: 320px;
        background: rgba(#000,.7);
        overflow: auto;
        visibility: hidden;
        opacity: 0;
        z-index: 9; }
    @include r(767) {
        min-width: 300px; }
    &+.page-center {
        .too-many {
            margin-left: inherit; } }
    &:first-child {
        @include r(1160) {
            width: 24%; }
        @include r(1023) {
            width: 100%; } }
    &_w250 {
        width: 300px!important;
        @include r(1023) {
            width: 100%!important;
            min-width: 320px; }
        @include r(767) {
            min-width: 300px; } }
    &_fix {
        &.open {
            @include r(1023) {
                .filter-new__heading {
                    position: absolute; }
                .filter-new__wrap {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    -webkit-overflow-scrolling: touch;
                    overflow-y: auto;
                    padding-top: 110px; }
                .filter-new {
                    position: fixed;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    max-width: 375px;
                    z-index: 999;
                    overflow: auto; }

                .aside-filter {
                    pointer-events: inherit; } } } }

    &.open {
        @include r(1023) {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s; }
        .aside-filter {
            transform: translateX(0); } }
    .aside-filter.aside-filter_new {
        transform: inherit!important;
        @include r(1023) {
            padding-top: 110px;
            background: #ffffff; }
        .filter-new__heading {
            background-color: #ffffff;
            position: absolute;
            top: 0;
            z-index: 3;
            border-bottom: 1px solid #d3d3d3;
            padding-top: 50px;
            margin-top: -50px; }
        .filter-new__heading-btn,
        .filter-new__heading-icon,
        .filter-new__heading-title,
        .filter-new__heading-action {
            display: inline-block;
            vertical-align: middle; }
        .filter-new__heading-btn {
            min-height: 55px;
            height: 55px;
            padding: 0 20px; }
        .filter-new__heading-text {
            float: left;
            padding: 20px 0 20px 20px; }
        .filter-new__heading-text,
        .filter-new__heading-action {
            font-size: 13px;
            line-height: 15px; }
        .filter-new__heading-title {
            padding: 18px 20px;
            float: left; }
        .filter-new__heading-icon {
            margin: 0 5px 0 0; } }
    &_right {
        width: 160px;
        @include r(1159) {
            display: none; } }
    &_wide {
        width: 300px;
        @include r(1023) {
            position: relative;
            top: auto;
            left: auto;
            z-index: 7;
            display: block;
            width: 100%;
            visibility: visible;
            overflow: visible;
            opacity: 1;
            background: none; } }
    &__item {
        margin-bottom: 15px;
        @include r(767); }
    &__inner {
        @include rm(1024) {
            display: flex;
            flex-flow: column;
            .page-aside__item {
                float: none;
                width: 100%;
                &:nth-child(1) {
                    order: 1; }
                &:nth-child(2) {
                    order: 3; }
                &:nth-child(3) {
                    order: 2; }
                &:nth-child(4) {
                    order: 5; }
                &:nth-child(5) {
                    order: 4; } } }
        @include r(1023) {
            @include clr;
            margin: 0 -10px;
            .page-aside__item {
                float: left;
                width: 50%;
                padding: 0 10px;
                margin-bottom: 20px;
                &:nth-child(1) {
                    width: 100%; }
                &:nth-child(2) {
                    float: right; }
                &:nth-child(3),
                &:nth-child(4) {
                    height: 200px; }
                &:nth-child(5) {
                    width: 100%; } } }
        @include r(767) {
            display: flex;
            flex-flow: column;
            margin: 0;
            .page-aside__item {
                float: none;
                width: 100%;
                padding: 0;
                margin-bottom: 15px;
                &:nth-child(1) {
                    order: 1; }
                &:nth-child(2) {
                    order: 3; }
                &:nth-child(3),
                &:nth-child(4) {
                    height: auto; }
                &:nth-child(3) {
                    order: 2; }
                &:nth-child(4) {
                    order: 5; }
                &:nth-child(5) {
                    order: 4; } } } } }

.page-center {
    padding: 0 20px;
    &_no-lpad {
        padding-left: 0; }
    &_no-rpad {
        padding-right: 0; }
    @include r(1023) {
        width: 100%;
        padding: 0; }
    @include r(767) {
        padding-left: 0;
        padding-right: 0; } }

.aside-filter {
    min-height: 100%;
    @include r(1023) {
        width: 100%;
        max-width: 375px;
        transform: translateX(-100%);
        transition: transform .4s .2s; } }

.flight-new__stops-cell > span {
    position: relative;
    .tooltip {
        position: absolute;
        top: -15px;
        height: 35px;
        padding: 5px;
        width: 100%;
        z-index: 999; } }

.clear-filters {
    margin-bottom: 10px;
    border-radius: 3px;
    background: $blue;
    padding: 17px 15px;
    color: #fff;
    position: relative;
    padding-right: 90px;
    &_pda-hidden {
        @include r(1023) {
            display: none; } }
    @include r(767) {
        display: none;
        padding-right: 15px;
        text-align: center; }
    &__text {
        color: #fff;
        font-size: 13px; }
    &__button {
        position: absolute;
        right: 0;
        top: 0;
        text-transform: uppercase;
        cursor: pointer;
        padding: 15px;
        @include r(767) {
            position: relative; }
        span {
            border-bottom: 1px dotted #fff; }
        &_bold {
            span {
                font-size: 13px;
                font-weight: 700; } } } }

.sorter {
    width: 100%;
    margin-bottom: 10px;
    font-size: 13px;
    white-space: nowrap;
    background: #fff;
    border-radius: 3px;
    &__subs {
        width: 215px;
        display: inline-block;
        vertical-align: top; }
    &__text {
        position: absolute;
        left: 0;
        top: -17px;
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: $cl; }
    &_new {
        .sorter__item {
            text-align: center;
            &:nth-child(1) {
                width: 15%; }
            &:nth-child(2) {
                width: 25%; }
            &:nth-child(3) {
                width: 25%; }
            &:nth-child(4) {
                width: 25%; } }
        .sorter__list {
            .sorter__item {
                border-radius: inherit;
                width: 105%; } }
        .sorter__link {
            padding: 0 10px; } }
    &_nomb {
        margin-bottom: 0; }
    &_pad {
        padding: 0 20px;
        border-radius: 3px;
        .sorter__item {
            &:first-child, &:last-child {
                border-radius: 0; } } }
    @include r(970) {
        display: none; }
    &_flight {
        @include r(1023) {
            display: none; } }
    &__title {
        float: left;
        padding: 16px 0 16px;
        margin-right: 10px;
        font-size: 13px;
        line-height: (18/13);
        font-weight: 400; }
    &__counter {
        float: right;
        margin-left: 5px;
        padding: 16px 0 16px;
        font-size: 14px;
        &-value {
            font-weight: 700;
            margin-right: 5px; } }
    &__item {
        position: relative;
        display: table-cell;
        background: #fff;
        transition: background .2s;
        &:first-child {
            border-radius: 3px 0 0 3px; }
        &:last-child {
            border-radius: 0 3px 3px 0; }
        &:hover {
            background: #F7F7F7;
            .sorter__list {
                opacity: 1;
                visibility: visible; } }
        &.is-active {
            background: #3D7CDA;
            a {
                color: #fff; } } }
    &__link {
        &_bold {
            font-weight: bold; } }
    a {
        display: block;
        position: relative;
        padding: 0 15px;
        height: 48px;
        line-height: 48px;
        color: #000;
        span {
            display: inline-block;
            position: relative;
            &:after {
                content: '';
                display: inline-block;
                vertical-align: middle;
                margin-left: 5px;
                @include arr(6,4,#000,b); } } }
    &__list {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 10;
        opacity: 0;
        visibility: hidden;
        transition: opacity .2s;
        a {
            height: 40px;
            line-height: 40px; }
        .sorter__item {
            display: block;
            &:hover {
                background: #ECECEC; } } }
    &.sorter--ab {
        &.sorter_pad {
            padding: 0; }
        background: transparent;
        position: relative;
        .sorter {
            display: table;
            width: calc(100% - 215px);
            border-radius: 3px;
            @include shadow;
            a {
                height: inherit; }
            &__item {
                vertical-align: middle;
                height: 65px;
                color: #000000;
                position: relative;
                width: 33.3%;
                &::before {
                    content: '';
                    width: 1px;
                    height: 120%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background: #EDEDED; }
                &:last-child::before {
                    display: none; }
                &.is-active {
                    background: #3D7CDA;
                    color: #ffffff;
                    .sorter__link-title,
                    .sorter__link-cost,
                    .sorter__link-time {
                        color: #ffffff; } }

                &:first-child {
                    border-radius: 3px 0 0 3px; }
                &:last-child {
                    border-radius: 0 3px 3px 0; } }
            &__counter {
                width: 210px;
                text-align: center;
                padding: 20px 0 20px;
                position: absolute;
                right: 0;
                top: 0;
                font-size: 16px;
                line-height: 20px; }
            &__link {
                padding: 15px 20px;
                &-title {
                    color: #000000;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 700;
                    svg {
                        float: right;
                        font-size: 18px;
                        max-height: 18px;
                        max-width: 18px;
                        @include r(1086) {
                            display: none; } } }
                &-cost {
                    font-size: 13px;
                    line-height: 20px;
                    font-weight: 700;
                    display: inline-block;
                    color: #000000;
                    vertical-align: top; }
                &-time {
                    display: inline-block;
                    font-size: 12px;
                    line-height: 20px;
                    color: #AEAEAE;
                    margin-left: 5px;
                    vertical-align: top; } } } }
    &.sorter_2-2 {
        padding: 0;
        background: transparent;
        position: relative;
        margin-bottom: 15px;

        @include r(1023) {
            display: block; }
        @include r(767) {
            display: block;
            overflow-x: scroll;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
                display: none;
                -ms-overflow-style: none;
                overflow: -moz-scrollbars-none;
                color: transparent;
                background: transparent; }
            &::-webkit-scrollbar {
                display: none;
                width: 0;
                color: transparent;
                background: transparent; }
            &::-webkit-scrollbar-track {
                display: none;
                width: 0;
                color: transparent;
                background: transparent; }
            &::-webkit-scrollbar-thumb {
                display: none;
                width: 0;
                color: transparent;
                background: transparent; } }
        @include r(519) {
            padding: 0 10px;
            margin: 0 -10px 15px;
            width: calc(100% + 20px);
            border-radius: 0; }
        .sorter {
            display: table;
            table-layout: fixed;
            width: calc(100% - 215px);
            border-radius: 3px;
            background: transparent;
            @include shadow;
            @include r(1023) {
                width: 100%; }
            @include r(508) {
                display: inline-table;
                width: calc(100% - 10px);
                &::after {
                    content: ' ';
                    display: inline-table;
                    width: 0; } }
            a {
                height: inherit; }
            &__item {
                vertical-align: middle;
                height: 70px;
                color: #000000;
                position: relative;
                width: 33.3%;
                @include r(767) {
                    height: 54px; }
                @include r(519) {
                    width: 168px; }
                &::before {
                    content: '';
                    width: 1px;
                    height: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background: #EDEDED; }
                &:last-child::before {
                    display: none; }
                &.is-active {
                    background: #3D7CDA;
                    color: #ffffff;
                    .sorter__link-title,
                    .sorter__link-cost {
                        color: #ffffff; }
                    .sorter__link-time {
                        color: #A4C9E3; } }
                &:first-child {
                    border-radius: 3px 0 0 3px; }
                &:last-child {
                    border-radius: 0 3px 3px 0; } }
            &__counter {
                width: 210px;
                text-align: center;
                padding: 20px 0 20px;
                position: absolute;
                right: 0;
                top: 0;
                font-size: 16px;
                line-height: 20px;
                @include r(1023) {
                    display: none; } }
            &__link {
                padding: 15px 20px;
                @include r(767) {
                    padding: 10px 20px; }
                &-title {
                    color: #000000;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 700;
                    svg {
                        float: right;
                        font-size: 18px;
                        max-height: 18px;
                        max-width: 18px;
                        @include r(1120) {
                            display: none; }
                        @include r(1023) {
                            display: inline-block; }
                        @include r(767) {
                            display: none; } }
                    @include r(767) {
                        font-size: 14px;
                        line-height: 16px; } }
                &-cost {
                    font-size: 13px;
                    line-height: 20px;
                    font-weight: 700;
                    display: inline-block;
                    color: #000000;
                    vertical-align: top; }
                &-time {
                    display: inline-block;
                    font-size: 13px;
                    line-height: 20px;
                    color: #AEAEAE;
                    margin-left: 5px;
                    vertical-align: top; } } } } }

.sort-filter {
    font-size: 0;
    &__label {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        margin-bottom: 5px; }
    &__btn {
        width: 215px;
        height: 44px;
        line-height: 44px;
        vertical-align: top;
        font-size: 14px;
        padding: 0 10px;
        span {
            @include r(767) {
                display: inline-block; } } } }

.sort-mob {
    margin-bottom: 10px;
    @include r(1023) {
        margin-bottom: 15px; }
    @include r(767) {
        margin-bottom: 10px; }
    .select {
        display: inline-block;
        vertical-align: top;
        width: 300px;
        float: right;
        @include shadow;
        @include r(767) {
            width: 200px; }
        @include r(560) {
            width: 100%;
            float: none;
            margin-top: 10px; }
        &__head {
            height: 44px;
            line-height: 44px;
            padding: 0 30px 0 15px; }
        &__item {
            font-weight: 400; }
        &__drop {
            @include r(767) {
                left: initial;
                right: 0; } } }
    &__wrap {
        display: inline-block;
        vertical-align: top;
        @include r(560) {
            display: table;
            width: 100%;
            font-size: 0; } }
    &__btn {
        height: 44px;
        line-height: 44px;
        vertical-align: middle;
        background: #ffffff;
        border-radius: 3px;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        padding: 0 10px;
        min-width: 145px;
        @include shadow;
        @include r(560) {
            font-size: 14px;
            display: table-cell;
            vertical-align: top;
            min-width: inherit;
            width: calc(50% - 5px); }
        &:nth-child(1) {
            @include r(560) {
                margin-right: 5px; } }
        &:nth-child(2) {
            margin-left: 10px;
            @include r(560) {
                margin-left: 5px; } }

        svg {
            font-size: 20px;
            vertical-align: middle; }
        &-text {
            display: inline;
            margin-left: 5px; } }
    &_no-subscribe {
        .select {
            @include r(767) {
                width: 300px; }
            @include r(560) {
                width: 60%;
                float: right;
                margin-top: 0; } }
        .sort-mob__wrap {
            @include r(560) {
                width: 40%;
                display: inline-block; } }
        .sort-mob__btn {
            @include r(767) {
                min-width: 230px; }
            @include r(560) {
                min-width: inherit;
                width: calc(100% - 10px); } } } }

.sorter-2 {
    font-size: 12px;
    background: transparent;
    display: inline-table;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 6px;
    @include shadow;
    &__link {
        padding: 0 35px 0 15px;
        height: 60px;
        line-height: 60px;
        color: #000000;
        position: relative;
        display: table-cell;
        vertical-align: middle;
        width: 25%;
        background-color: #ffffff;
        font-size: 17px;
        font-weight: 700;
        border-right: 1px solid #EAEAEA;
        transition: background .2s;
        &-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
        &:first-child {
            border-radius: 6px 0 0 6px; }
        &:last-child {
            border-radius: 0 6px 6px 0;
            border-right: 0; }
        svg, span {
            position: absolute;
            right: 15px;
            top: 50%;
            margin-top: -10px;
            float: right;
            font-size: 18px; }
        span.percent {
            font-size: 19px;
            line-height: 19px;
            font-weight: 700; }
        &.active {
            color: #ffffff;
            background: #3D7CDA;
            border-color: #3D7CDA;
            cursor: default;
            &:hover {
                background: #3D7CDA; } }
        &:hover,
        &:active {
            background: #F7F7F7; } }
    &_no-subscribe {
        width: 100%; }
    &+.sort-filter__btn {
        display: none;
        margin-left: 10px;
        color: $blue;
        background: #ffffff;
        box-shadow: 0 0 5px rgba(0,26,44,.15); } }

.page-filter-wrap {
    display: none;
    &_mod {
        display: block; }
    @include r(1023) {
        display: block; }
    .page-filter {
        @include r(1023) {
            display: block; }
        @include r(767) {
            div, a {
                height: auto;
                line-height: 1;
                padding: 9px 0; } }
        @include r(479) {
            margin-bottom: 0!important; } } }

.page-filter {
    display: none;
    margin: 0 -30px;
    border-bottom: 1px solid #D2D2D2;
    clear: both;
    background: #F7F7F7;
    @include clr;
    font-size: 0;
    text-align: center;
    @include r(970) {
        display: block;
        margin: 0 -20px; }
    a,div {
        width: 25%;
        text-align: center;
        color: #000;
        height: 44px;
        line-height: 44px;
        display: inline-block;
        font-size: 13px;
        color: #000;
        font-weight: bold; }
    strong {
        font-weight: bold;
        margin-left: 5px;
        display: inline-block;
        vertical-align: middle;
        @include r(440) {
            display: none; } }
    &_flight {
        @include r(1023) {
            display: block;
            margin: 0 -20px; }
        &.is-fixed {
            @include r(1023) {
                position: fixed;
                top: 44px;
                left: 0;
                right: 0;
                margin: 0 -10px;
                z-index: 12; } }
        &.fixed-bottom {
            @include r(1023) {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 -10px;
                z-index: 12;
                border-bottom: none;
                box-shadow: 0 0 5px rgba(0,0,0,.3); } } }
    &_half {
        a, div {
            width: 50%; }
        strong {
            @include r(767) {
                width: 100%;
                line-height: 1;
                margin-top: 5px;
                margin-left: 0;
                font-weight: 400;
                font-size: 11px; }
            @include r(440) {
                display: inline-block; } } }
    &_3-el {
        a, div {
            width: 33%; }
        strong {
            @include r(767) {
                width: 100%;
                line-height: 1;
                margin-top: 5px;
                margin-left: 0;
                font-weight: 400;
                font-size: 11px; }
            @include r(440) {
                display: inline-block; } } }
    &_2-2 {
        background: transparent;
        border-bottom: none;
        margin: 15px 0!important;
        text-align: right;
        a {
            background-color: #FFFFFF;
            border-radius: 3px;
            display: inline-block;
            text-align: center;
            margin-left: 10px;
            width: auto;
            padding: 0 20px;
            @include shadow;
            @include r(767) {
                padding: 0 10px!important;
                height: 44px!important;
                line-height: 44px!important; }
            @include r(479) {
                margin-left: 0!important; }
            strong {
                display: inline!important; }
            &:first-child {
                @include r(479) {
                    width: calc(40% - 10px);
                    margin-right: 10px; } }
            &:nth-child(2) {
                @include r(479) {
                    width: 60%; } } }
        .page-filter__result {
            font-weight: 400;
            @include r(1023) {
                float: left;
                width: auto;
                text-align: left;
                padding: 0!important;
                height: 44px!important;
                line-height: 44px!important; } }

        &.page-filter_dashboard {
            a:nth-child(3) {
                width: 44px!important;
                padding: 0; }
            strong {
                margin-left: 10px!important; }
            @include r(479) {
                a {
                    margin-right: 5px!important;
                    &:nth-child(1) {
                        width: calc(50% - 27px)!important; }
                    &:nth-child(2) {
                        width: calc(50% - 27px)!important; }
                    &:nth-child(3) {
                        margin-right: 0!important; } } } } }


    &-wrap_bottom {
        position: fixed;
        bottom: 20px;
        width: 100%;
        z-index: 11;
        display: none;
        @include r(767) {
            bottom: 15px; }
        @include r(499) {
            padding-right: 65px;
            padding-left: 10px; }
        .page-filter_2-2 {
            margin: 0 auto!important;
            max-width: 340px;
            background-color: #3D7CDA;
            border-radius: 3px;
            @include shadow;
            @include r(499) {
                max-width: 100%; }
            a {
                width: 50%;
                border-radius: 0;
                color: #FFFFFF;
                background-color: #3D7CDA;
                margin-left: 0;
                padding: 0 5px;
                text-transform: uppercase;
                box-shadow: none;
                position: relative;
                font-size: 12px;
                .icon {
                    color: #ffffff!important; }
                .icon-set {
                    color: #ffffff; }
                &:nth-child(1) {
                    border-radius: 3px 0 0 3px;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 5px;
                        right: 0;
                        width: 1px;
                        height: calc(100% - 10px);
                        background-color: #77B4DF; }
                    @include r(499) {
                        width: 45%; }
                    @include r(479) {
                        margin-right: 0!important; } }
                &:nth-child(2) {
                    border-radius: 0 3px 3px 0;
                    @include r(499) {
                        width: 55%; } } } } }

    &__number {
        width: 22px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        font-weight: bold;
        vertical-align: middle;
        display: inline-block;
        font-size: 12px;
        color: #fff;
        border-radius: 50%;
        background: #00bfff;
        margin-left: 5px; }
    &__select {
        position: relative;
        select {
            opacity: 0;
            appearance: none;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            width: 100%;
            height: 100%; } }
    &__result {
        display: block;
        width: 100%;
        font-size: 14px;
        text-align: center;
        font-weight: 400;
        height: 50px;
        line-height: 50px;
        &_cal {
            text-align: left;
            display: inline-block;
            font-size: 13px;
            line-height: (18/13);
            &:after {
                content: "";
                display: inline-block;
                height: 100%;
                vertical-align: middle; }
            span {
                display: inline-block;
                vertical-align: middle; }
            @include r(1023) {
                text-align: center; } }
        .page-filter__result_bold {
            font-weight: bold;
            @include r(1023) {
                font-weight: 400;
                vertical-align: top; } } }
    &__counter {
        font-weight: 700; }
    .icon {
        color: #000;
        font-size: 20px;
        vertical-align: middle; } }

.promo-new {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 140px;
    margin-bottom: 10px;
    border-radius: 3px;
    position: relative;
    &_aside {
        text-align: center;
        .promo-new__content-title {
            font-size: 20px;
            font-weight: 300; }
        .promo-new__content-text {
            margin-bottom: 30px;
            font-size: 52px;
            font-weight: 700;
            line-height: 1.2; }
        .promo-new__btn {
            width: 100%;
            height: 44px;
            background-color: #ffa400;
            &-search {
                display: inline-block;
                margin-right: 10px;
                .icon {
                    font-size: 19px;
                    vertical-align: sub; } } } }
    &__logo-wrap {
        display: none;
        margin-bottom: 30px;
        @include r(767) {
            display: none; } }
    &__logo {
        display: inline-block;
        vertical-align: top;
        font-size: 0;
        width: 120px;
        height: 15px;
        background: url(../img/logo-white.svg) no-repeat;
        background-size: contain; }
    &__img {
        &-mail {
            position: absolute;
            left: 15px;
            top: 50%;
            margin-top: -30px;
            @include r(767) {
                display: none; } }
        &-telegram {
            float: left;
            margin-right: 20px;
            margin-left: 30px;
            @include r(767) {
                display: none; } }
        &-thumbsup {
            float: left;
            margin-right: 10px;
            margin-left: 10px;
            @include r(767) {
                display: none; } }
        &-click {
            position: absolute;
            right: 310px;
            top: 20px;
            @include r(767) {
                display: none; } } }
    &__title-mob {
        display: none;
        color: #ffffff;
        font-size: 22px;
        line-height: 25px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 10px;
        @include r(767) {
            display: block; } }
    &__in {
        padding: 20px;
        @include r(767) {
            padding: 20px 10px; } }
    &__in-table {
        display: table;
        width: 100%;
        margin-top: 50px;
        @include r(767) {
            display: block;
            margin-top: 0;
            text-align: center; } }
    &__left, &__right {
        display: table-cell;
        vertical-align: bottom;
        @include r(767) {
            display: block; } }
    &__right {
        text-align: right;
        @include r(767) {
            text-align: center; } }
    &__content {
        @include r(767) {
            margin-bottom: 15px; }
        &-title {
            font-size: 24px;
            line-height: 28px;
            font-weight: 700;
            color: #FFF;
            @include r(767) {
                font-size: 22px;
                line-height: 25px; } }
        &-text {
            font-size: 14px;
            line-height: 16px;
            color: #FFF;
            @include r(767) {
                font-size: 13px;
                line-height: 15px; } } }
    &__btn {
        height: 40px;
        line-height: 42px;
        padding: 0 25px;
        font-size: 15px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.24);
        min-width: 175px;
        @include r(1023) {
            min-width: inherit;
            span {
                font-size: 14px; } }
        @include r(767) {
            span {
                display: inline-block; } }
        &.blue {
            background-color: #3D7CDA;
            @include r(767) {
                padding: 0 10px; }
            svg {
                vertical-align: text-top;
                margin-right: 10px; } } }
    &_mod {
        @include r(767) {
            background-size: inherit; } }
    &_subscribe {
        min-height: inherit;
        .promo-new__in {
            padding: 45px 20px 55px;
            @include r(767) {
                padding: 15px 15px 10px; } }
        .subscribe {
            margin-bottom: 0;
            @include r(767) {
                max-width: 440px;
                margin: 0 auto; }
            &__input {
                padding-right: 235px;
                padding-left: 105px;
                @include r(767) {
                    padding: 0; }
                .input {
                    height: 40px;
                    font-size: 16px;
                    box-shadow: 0 2px 4px 0 rgba(#000000, .24);
                    @include r(767) {
                        text-align: center; } } }
            &__btn {
                width: 180px;
                @include r(767) {
                    width: 100%; }
                .btn {
                    height: 40px;
                    line-height: 40px;
                    font-size: 16px;
                    box-shadow: 0 2px 4px 0 rgba(#000000, .24); } } } }
    &_telegram,
    &_facebook {
        .promo-new__in-table {
            margin-top: 25px;
            @include r(767) {
                margin-top: 0; } } }
    &_facebook {
        .promo-new__content {
            padding-right: 10px; }
        .promo-new__left,
        .promo-new__right {
            vertical-align: top; }
        .promo-new__img-thumbsup {
            margin-top: -25px; } } }

.agencies {
    text-align: center;
    margin: 60px auto;
    padding: 20px;
    background: #fff;
    @include r(767) {
        margin: 0; }
    &_mod {
        margin: 20px auto;
        padding: 25px 30px 20px;
        border-radius: 3px;
        background-color: #f6f7f7;
        .agencies__list {
            margin: 0 -20px -35px;
            @include r(1023) {
                padding-top: 35px; } }
        .agencies__item {
            margin: 0 20px 35px;
            @include r(767) {
                margin: 0 0 35px; } } }
    &_addpadding {
        padding: 100px 20px 110px;
        @include r(1023) {
            padding: 100px 20px 110px; }
        @include r(767) {
            padding: 30px 10px 30px; } }
    &__title {
        font-size: 20px;
        font-weight: bold;
        span {
            display: inline-block; } }
    &__list {
        padding: 20px 0;
        font-size: 0;
        margin-top: 40px; }
    &__item {
        display: inline-block;
        margin-right: 20px;
        @include r(767) {
            margin-right: 0;
            width: 50%;
            text-align: center;
            margin-bottom: 15px; }
        &:last-child {
            margin-right: 0; }
        img {
            vertical-align: middle; } } }

.destinations {
    margin-bottom: 20px;
    padding: 30px 30px 45px;
    border-radius: 3px;
    background-color: #f8fafc;
    @include r(767) {
        padding: 30px 20px; }
    &__title {
        margin-bottom: 30px;
        color: #000;
        font-size: 22px;
        font-weight: 700;
        line-height: (30/22);
        text-align: center; }
    &__body {
        font-size: 0; }
    &__col {
        display: inline-block;
        vertical-align: top;
        width: 33.3%;
        padding: 0 20px;
        @include r(767) {
            display: block;
            width: 100%;
            @include r(767) {
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0; } } } }
    &__list {
        margin-bottom: 25px;
        font-size: 0;
        @include r(767) {
            margin-bottom: 15px; } }
    &__item {
        margin-bottom: 10px; }
    &__link {
        color: $cl;
        font-size: 14px;
        line-height: (19/14);
        &:hover {
            color: #3D7CDA; } }
    &__all {
        display: inline-block;
        color: $cl;
        font-size: 14px;
        font-weight: 500;
        text-decoration: underline;
        &:hover {
            text-decoration: none; } } }

.is-highlighted {
    animation: highlight-filter 1.5s ease-in-out;
    animation-iteration-count: 2; }

@keyframes highlight-filter {
    0% {
        box-shadow: 0 0 2px 3px transparent; }
    50% {
        box-shadow: 0 0 2px 3px #ff6700; }
    100% {
        box-shadow: 0 0 2px 3px transparent; } }

input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0; }

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

.invert {
    .price__old {
        color: $cl!important; }
    .price__actual {
        color: #f6331e!important; } }
.red {
    color: #f6331e!important; }

.black {
    color: $cl!important; }

.optimize-text {
    max-width: 900px;
    margin: 30px auto 20px;
    padding: 40px 20px;
    &__container {
        background-color: #f8fafc; }
    &__title {
        font-size: 22px;
        font-weight: 500;
        color: #000000;
        text-align: center; }
    &__text {
        font-size: 15px;
        color: $cl;
        margin-top: 20px; }
    h2 {
        margin: 0; } }

.faq-help {
    display: inline-block;
    background-color: $blue;
    color: #ffffff;
    padding: 10px 5px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 4px 0 0 4px;
    position: fixed;
    top: 300px;
    transform: rotate(180deg);
    z-index: 12;
    writing-mode: tb-rl;
    left: -1px;
    span {
        vertical-align: middle; }
    svg {
        vertical-align: middle;
        font-size: 22px;
        margin-top: 5px; }
    @include r(1023) {
        display: none; } }

.icon-bell-shake,
.shake {
    transform-origin: 50% 0;
    animation: shake 2.5s ease infinite;
    animation-iteration-count: 3;
    animation-delay: 3s;
    @keyframes shake {
        0%, 100%, 50% {
            transform: rotate(0); }
        10%, 15%, 20%, 25%, 30%, 35%, 40%, 5% {
            transform: rotate(6deg); }
        45% {
            transform: rotate(4deg); }
        12.5%, 17.5%, 22.5%, 27.5%, 32.5%, 37.5%, 42.5%, 7.5% {
            transform: rotate(-6deg); }
        47.5% {
            transform: rotate(-2deg); } } }

.progress {
    position: absolute;
    width: 100%;
    height: 5px;
    overflow: hidden;
    z-index: 11;
    &__line {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-image: linear-gradient(-45deg, $orange 25%, #ffa54a 25%, #ffa54a 50%, $orange 50%, $orange 75%, #ffa54a 75%, #ffa54a);
        background-size: 50px 50px;
        animation: loading-bar 2s linear infinite reverse; } }


.aside-filter_wait {
    @include rm(1024) {
        .filter-new__head {
            display: inline-block;
            padding: 0;
            margin-left: 5px;
            margin-top: 15px; }
        .filter-new__check-content,
        .filter-new__check-price,
        .filter-new__check-link,
        .filter-new__head {
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeHolderShimmer;
            animation-timing-function: linear;
            background: #E1E1E1;
            background: linear-gradient(to right, #E1E1E1 0%, #D9D8D8 18%, #E1E1E1 33%);
            background-size: 800px 104px;
            position: relative;
            color: transparent!important;
            border-radius: 3px; }

        .filter-new__check-content {
            &::after {
                content: '';
                display: inline-block;
                width: 20px;
                height: 10px; } }
        .filter-new__check-price,
        .filter-new__check-link {
            animation-delay: .15s; }
        .filter-new_whith-tabs .filter-new__check:hover:before {
            background: transparent!important; }
        .filter-new__check-tick,
        .filter-new__title,
        .filter-new__city,
        .filter-new__switch,
        .filter-new__slider {
            display: none; }
        .filter-new__wrap {
            position: relative; } } }


@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0; }
    100% {
        background-position: 468px 0; } }

.feedback {
    position: fixed;
    right: 20px;
    bottom: 40px;
    z-index: 100;
    @include r(767) {
        right: 15px; }
    &.active {
        .feedback__bubble {
            opacity: 0;
            visibility: hidden; }
        .feedback__cancel,
        .feedback__wrap {
            opacity: 1;
            visibility: visible;
            transform: translateY(0); } }
    &.done {
        .feedback__bubble,
        .feedback__cancel {
            opacity: 1;
            visibility: visible; }
        .feedback__cancel {
            box-shadow: none; }
        .feedback__bubble {
            .tooltip__wrap {
                display: none!important; } } }
    &.open {
        .feedback__wrap {
            @include r(767) {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 0;
                box-shadow: none;
                width: 100%;
                padding-top: 25px;
                z-index: 2; } }
        .feedback__container {
            height: 310px;
            opacity: 1;
            visibility: visible;
            border-top: 1px solid #DADADA;
            @include r(767) {
                height: 100%; } }
        .feedback__cancel-mob {
            @include r(767) {
                display: block; } } }
    &__bubble,
    &__cancel {
        position: absolute!important;
        right: 0;
        bottom: 0;
        background: #3D7CDA;
        width: 50px;
        height: 40px;
        box-shadow: 0 0 6px 0 rgba(#000000, .3);
        border-radius: 4px;
        color: #ffffff;
        padding: 0;
        z-index: 2;
        @include rm(768) {
            transition: all .2s; }
        &:hover {
            box-shadow: 0 0 30px 0 rgba(#000000, .3);
            background: #0093E5; }
        svg {
            vertical-align: middle; } }
    &__cancel-mob {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        padding: 0;
        font-size: 12px;
        z-index: 3;
        svg {
            vertical-align: middle; } }
    &__bubble {
        border-radius: 4px 4px 0 4px;
        opacity: 1;
        visibility: visible;
        z-index: 3;
        &::before {
            content: '';
            position: absolute;
            bottom: -10px;
            right: 0;
            width: 0;
            height: 0;
            border: 12px solid transparent;
            border-top-color: #3D7CDA;
            border-bottom: 0;
            border-right: 0;
            @include rm(768) {
                transition: all .2s; } }
        &:hover {
            &:before {
                border-top-color: #3D7CDA; } }
        svg {
            font-size: 20px; } }
    &__cancel {
        visibility: hidden;
        opacity: 0;
        svg {
            font-size: 12px; } }
    &__head {
        padding: 15px; }
    &__wrap {
        visibility: hidden;
        opacity: 0;
        transform: translateY(50px);
        position: absolute;
        bottom: 50px;
        right: 0;
        width: 340px;
        border-radius: 6px;
        background: #ffffff;
        box-shadow: 0 0 40px 0 rgba(#000000, .4);
        @include rm(768) {
            transition: all .5s; }
        @include r(767) {
            width: 290px;
            z-index: 2; } }
    &__title {
        text-align: center;
        font-size: 15px;
        line-height: 18px;
        @include r(767) {
            font-size: 17px;
            line-height: 20px; } }
    &__buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        @include r(767) {
            margin-top: 15px; } }
    &__btn {
        position: relative;
        height: 40px;
        width: 50px;
        border-radius: 3px;
        background: #FFC200;
        color: #000000;
        padding: 0;
        @include r(767) {
            width: 40px; }
        &.nohover {
            background: #FFDA66;
            color: #8A8A8A;
            &:hover {
                color: #000000; } }
        &.disable {
            background: #E3E3E3;
            color: #8A8A8A;
            &:hover {
                color: #000000; } }
        &.active {
            background: #FFC200;
            color: #000000;
            &::after {
                content: '';
                position: absolute;
                border-style: solid;
                border-width: 0 10px 10px;
                border-color: #ffffff transparent;
                display: block;
                width: 0;
                z-index: 1;
                bottom: -16px;
                left: 50%;
                margin-left: -10px; }
            &::before {
                content: '';
                position: absolute;
                border-style: solid;
                border-width: 0 10px 10px;
                border-color: #DADADA transparent;
                display: block;
                width: 0;
                z-index: 0;
                bottom: -15px;
                left: 50%;
                margin-left: -10px; } }
        svg {
            font-size: 20px;
            vertical-align: middle; }
        .icon-smile {
            &-2 {
                font-size: 19px; }
            &-3,
            &-5 {
                font-size: 20px; }
            &-4 {
                font-size: 15px; }
            &-6 {
                font-size: 18px; } } }
    &__container {
        position: relative;
        height: 0;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        border-top: 1px solid transparent;
        @include rm(768) {
            transition: all .5s; }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 20px;
            width: 100%;
            background: rgba(255,255,255,1);
            background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            //background: -webkit-gradient(top, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,0)))
            background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); } }
    &__textarea {
        width: 100%;
        padding: 0;
        resize: none;
        padding: 15px 15px 5px;
        font-size: 13px;
        line-height: 15px;
        @include r(767) {
            font-size: 17px;
            line-height: 19px; } }
    &__submit {
        display: block;
        font-size: 14px;
        background: #FFC200;
        padding: 0 10px;
        min-width: 105px;
        border-radius: 3px;
        font-weight: 700;
        height: 40px;
        line-height: 40px;
        float: right;
        margin-right: 15px;
        margin-top: 5px;
        &:hover,
        &:active {
            background: #FFAF00; }
        &:disabled {
            background: #E3E3E3;
            color: #8A8A8A; } } }
