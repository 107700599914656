.bookimed-call {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    box-shadow: 0 0 26px 0 rgba(#000000, .07);
    margin: 50px auto 40px;
    padding: 20px 30px 30px 120px;
    max-width: 820px;
    @include r(767) {
        padding: 20px 14px;
        margin: 20px 0 40px;
        text-align: center; }
    .icon-bookimed-error {
        display: none;
        font-size: 22px;
        color: #C34634;
        position: absolute;
        right: 10px;
        top: 11px; }
    &__call-icon {
        position: absolute;
        left: 30px;
        top: 20px;
        color: #A3CC0E;
        font-size: 83px;
        @include r(767) {
            line-height: 1;
            position: static;
            margin-top: 10px; } }
    &__title {
        font-size: 24px;
        line-height: 1.2;
        font-weight: 700;
        margin-top: 10px;
        @include r(767) {
            font-size: 22px; } }
    &__subtitle {
        font-size: 16px;
        line-height: 1.2; }
    &__text {
        margin-top: 15px;
        color: #929292;
        font-size: 12px;
        line-height: 1.2;
        a {
            color: #929292; } }
    &__form {
        margin-top: 20px;
        &.error {
            .bookimed-call__input-container {
                box-shadow: 0 0 0 1px #C34634;
                margin-bottom: 15px;
                @include r(767) {
                    margin-bottom: 35px; } }
            .bookimed-call__error,
            .icon-bookimed-error {
                display: block; } } }
    &__button {
        display: inline-block;
        vertical-align: top;
        background: #FB765F;
        color: #FFFFFF;
        font-size: 18px;
        height: 44px;
        line-height: 44px;
        padding: 0 20px;
        font-weight: 700;
        border-radius: 3px;
        text-transform: uppercase;
        margin-left: 20px;
        transition: all .5s;
        @include r(767) {
            display: block;
            margin-left: 0;
            width: 100%;
            margin-top: 20px; }
        &:hover,
        &:active {
            background: #f85e43; }
        &[disabled] {
            background: #9F9D9F!important;
            cursor: default; } }
    &__input {
        padding: 0 35px 0 65px;
        font-size: 15px; }
    &__input-container {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 285px;
        height: 42px;
        line-height: 42px;
        border-radius: 3px;
        box-shadow: 0 0 0 1px #CFCFCF;
        @include r(767) {
            display: block;
            margin-top: 20px;
            width: 100%; } }
    &__error {
        position: absolute;
        bottom: -20px;
        font-size: 12px;
        line-height: 1.2;
        color: #C34634;
        margin-top: 5px;
        &.hide {
            display: none; } }
    &_landing {
        padding: 30px 40px 45px;
        border-radius: 13px;
        margin: 0;
        max-width: 510px;
        .bookimed-call {
            &__title {
                margin-top: 0; }
            &__form {
                margin-top: 30px;
                font-size: 0; }
            &__input-container {
                width: calc(100% - 210px);
                margin-top: 1px;
                @include r(767) {
                    width: 100%;
                    margin-top: 0; } }
            &__button {
                width: 200px;
                margin-left: 10px;
                font-size: 16px;
                @include r(767) {
                    width: 100%;
                    margin-left: 0; } }
            &__text {
                margin-top: 20px;
                a {
                    text-decoration: underline; } } }
        .bookimed-call__done {
            position: static;
            margin: 20px -40px -45px!important;
            @include r(767) {
                margin: 20px -15px -20px!important; } } }

    &.bookimed-aside_done {
        overflow: hidden;
        .bookimed-call__done {
            display: block; }
        .bookimed-call__form,
        .bookimed-call__text {
            display: none; } }

    .bookimed-call__done {
        display: none;
        background: #A2CC10;
        font-size: 14px;
        line-height: 1.2;
        text-align: center;
        padding: 20px;
        margin: 35px -30px -30px -120px;
        @include r(767) {
            margin: 20px -15px -20px; }
        &-title {
            font-weight: 700; } } }
