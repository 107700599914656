.flights {
	@include clr;
	line-height: 18px;
	color: $cl;
	.flights__block {
		margin-top: 38px;
		&_clear {
			overflow: hidden; }
		&_smm {
			margin-top: -30px; }
		&_lmt {
			margin-top: 0;
			@include r(767) {
				margin-top: 0; } }
		&:first-child {
			margin-top: 0; } } }
.sidebar {
	float: right;
	width: 340px;
	margin-left: 40px;
	@include r(1023) {
		display: none; } }
.widget-box {
	float: right;
	width: 340px;
	margin-left: 40px;
	margin-top: 60px;
	@include r(1023) {
		display: none; } }
.widget {
	margin-bottom: 20px; }

.subscribe-box {
	padding: 30px;
	background-image: linear-gradient(135deg, $blue-light 0%, $blue 100%);
	border-radius: 3px;
	font-size: 15px;
	color: #fff;
	text-align: center;
	&_green {
		padding: 18px 26px 33px;
		background: #65b004; }
	&_left {
		text-align: left; }
	&_smpad {
		padding: 20px; }
	&_low {
		@include r(1023) {
			.subscribe-box__form {
				max-width: 420px;
				margin: 0 auto;
				input, button {
					display: inline-block;
					vertical-align: top; }
				input {
					max-width: 260px;
					margin-right: 6px;
					text-align: left; }
				button {
					width: 150px; } } }
		@include r(767) {
			.subscribe-box__form {
				input, button {
					display: block;
					width: 100%;
					max-width: none; }
				input {
					text-align: center; } } } }
	&.subscribe-box_mid {
		margin-bottom: 15px;
		padding: 20px 10px;
		text-transform: none;
		text-align: left;
		.subscribe-box__img {
			margin-right: 10px; }
		.subscribe-box__title {
			font-size: 20px;
			font-weight: 700; }
		p {
			font-size: 15px;
			padding: 0 5px; }
		.subscribe-box__form {
			padding: 15px 5px 0;
			input, button {
				height: 44px;
				font-size: 16px; }
			input {
				margin-bottom: 20px; } } }
	&.subscribe-box_done {
		margin-bottom: 15px;
		padding: 20px 10px;
		max-height: 240px;
		overflow: hidden;
		.subscribe-box__title {
			font-size: 16px;
			line-height: 20px;
			text-transform: uppercase;
			font-weight: 500; }
		.subscribe-box__text {
			font-size: 15px;
			line-height: 18px;
			font-weight: 400;
			text-transform: none;
			margin-bottom: 10px; } }
	&.subscribe-box_new {
		margin-bottom: 15px;
		padding: 20px 15px;
		.subscribe-box__form {
			padding: 10px 0 0;
			input, button {
				height: 44px;
				font-size: 16px; }
			input {
				margin-bottom: 10px;
				text-align: center; } }
		.subscribe-box__title {
			font-size: 16px;
			line-height: 20px;
			text-transform: uppercase;
			font-weight: 500; }
		.subscribe-box__or {
			font-size: 13px;
			line-height: 15px;
			text-transform: lowercase;
			color: #fff;
			position: relative;
			z-index: 1;
			overflow: hidden;
			text-align: center;
			margin: 15px auto 10px;
			@include r(767) {
				margin: 10px auto 10px; }
			&:before,
			&:after {
				content: '';
				display: inline-block;
				width: 100%;
				height: 1px;
				background: #ffffff;
				z-index: -1;
				position: relative;
				vertical-align: middle; }
			&:before {
				right: 15px;
				margin: 0 0 0 -100%; }
			&:after {
				left: 15px;
				margin: 0 -100% 0 0; } }
		.subscribe-box__social-link {
			display: inline-block;
			background-color: #314F98;
			color: #ffffff;
			width: 100%;
			height: 40px;
			line-height: 40px;
			font-size: 13px;
			font-weight: 500;
			text-transform: none;
			border-radius: 3px;
			padding-left: 10px;
			svg {
				font-size: 24px!important;
				vertical-align: middle;
				float: left;
				margin-top: 8px; }
			span:nth-child(2) {
				@include r(1080) {
					display: none; } }
			span:nth-child(3) {
				display: none;
				@include r(1080) {
					display: inline; } } } }

	.subscribe-box__title {
		margin-bottom: 11px;
		font-size: 24px;
		font-weight: 500;
		line-height: 28px;
		&_lmb {
			margin-bottom: 20px;
			padding-left: 75px; }
		&_up {
			margin-bottom: 0;
			font-size: 16px;
			line-height: (21/16);
			text-transform: uppercase; } }
	.subscribe-box__form {
		padding-top: 15px;
		input {
			display: block;
			width: 100%;
			height: 45px;
			margin-bottom: 10px;
			border-radius: 3px;
			border: 2px solid #fff;
			text-align: center;
			color: #fff;
			@include placeholder {
				font-size: 15px;
				color: #fff; }
			&:focus {
				@include placeholder {
					font-size: 0; } } }
		button {
			width: 100%;
			height: 45px;
			text-align: center;
			background: #fff;
			border-radius: 3px;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 700;
			color: $blue; }
		&_new {
			padding-top: 27px;
			input {
				height: 58px;
				margin-bottom: 15px;
				padding: 0 10px 0 20px;
				background-color: #f9f9f9;
				color: #878787;
				text-align: left;
				border: 1px solid transparent;
				@include placeholder {
					color: #878787; }
				&:focus {
					border: 1px solid #ff7200; } }
			button {
				height: 58px;
				background-color: #ff7200;
				color: #ffffff;
				font-size: 18px;
				&:hover {
					background-color: #f6a623; }
				&:active {
					background-color: #e95d00; } } }
		&_white {
			input {
				background-color: #FFF;
				color: #a7a7a7;
				@include placeholder {
					color: #a7a7a7; } }
			button {
				background-color: #ff7200;
				color: #fff; } } }
	.subscribe-box__img {
		float: left;
		display: inline-block;
		width: 55px;
		margin-right: 20px;
		margin-top: 17px; } }
.subscribe-btn {
	visibility: hidden;
	opacity: 0;
	position: fixed;
	left: 50%;
	bottom: 20px;
	width: 300px;
	height: 45px;
	margin-left: -150px;
	background: #57c1ff;
	border-radius: 3px;
	text-align: center;
	vertical-align: middle;
	box-shadow: 0 1px 6px -2px #494949;
	color: #fff;
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
	text-shadow: 0 1px 5px #494949;
	text-transform: uppercase;
	transition: background 200ms, opacity 200ms, transform 200ms;
	transform: translateY(65px);
	z-index: 99;
	&:hover {
		background: darken(#57c1ff, 5%); }
	&.visible {
		@include r(1023) {
			visibility: visible;
			opacity: 1;
			transform: translateY(0); } } }
.flights-info {
	@include clr;
	padding: 15px;
	border: 1px solid #d1d1d1;
	border-radius: 3px;
	&_h320px {
		min-height: 378px;
		overflow: hidden;
		@include r(1023) {
			min-height: inherit; } }
	@include r(767) {
		margin-bottom: 60px;
		border: none;
		padding: 0; }
	.flights-info__tabs {
		float: left;
		width: 40%;
		li {
			margin-top: 1px;
			&:first-child {
				margin-top: 0; }
			a {
				position: relative;
				display: block;
				overflow: hidden;
				padding-right: 20px;
				color: $blue;
				font-size: 0;
				span {
					position: relative;
					display: block;
					background: transparent;
					padding: 14px 13px;
					font-size: 13px;
					border-radius: 3px 6px 6px 3px;
					&:after {
						content: "";
						position: absolute;
						top: 50%;
						right: -20px;
						width: 50px;
						height: 50px;
						margin-top: 1px;
						border-radius: 6px;
						background: #899298;
						transform: rotate(60deg);
						transform-origin: 100% 0;
						z-index: -1;
						opacity: 0; }
					&:before {
						content: "";
						position: absolute;
						top: 50%;
						right: -20px;
						width: 50px;
						height: 50px;
						margin-top: -2px;
						border-radius: 6px;
						background: #899298;
						transform: rotate(30deg);
						transform-origin: 100% 0;
						z-index: -1;
						opacity: 0; } }
				&:hover, &.active {
					color: #fff;
					span {
						background: #899298;
						&:before, &:after {
							opacity: 1; } } } } }
		@include r(767) {
			display: none; } }
	.slick-dots {
		position: absolute;
		bottom: -66px;
		left: 50%;
		transform: translateX(-50%); }
	.flights-info__content {
		float: right;
		width: 60%;
		padding-left: 4%;
		@include r(767) {
			float: none;
			width: 100%;
			padding-left: 0;
			margin: 0 -5px;
			.slick-list {
				overflow: visible; } } }
	.flights__slide {
		@include r(767) {
			margin: 0 5px;
			border: 1px solid #e9e9e9;
			padding: 15px;
			border-radius: 3px; } }
	.flights-info__item {
		border-top: 1px solid #e9e9e9;
		&:first-child {
			border-top: none; } }
	.flights-info__row {
		display: table;
		width: 100%;
		min-height: 45px;
		font-size: 15px;
		line-height: 45px; // for vertical-align - firefox
		@include r(414) {
			position: relative;
			display: block;
			padding-right: 50px; } }
	.flights-info__row_border {
		.flights-info__route, .flights-info__date, .flights-info__price {
			border-bottom: 1px solid #e9e9e9;
			@include r(767) {
				border: none; } } }
	.flights-info__route, .flights-info__date, .flights-info__price {
		display: table-cell;
		vertical-align: middle;
		@include r(414) {
			display: block; } }
	.flights-info__route {
		width: 55%;
		a {
			font-size: 15px;
			color: $blue;
			font-weight: 700;
			text-decoration: underline;
			&:hover {
				text-decoration: none; } }
		@include r(414) {
			width: auto; } }
	.flights-info__date {
		color: #282828; }
	.flights-info__price {
		text-align: right;
		@include r(414) {
			position: absolute;
			top: 3px;
			right: 0; } } }
.flights-map {
	border-radius: 3px;
	overflow: hidden;
	background: #f4f4f4;
	.flights-map__bottom {
		padding: 20px;
		@include clr;
		@include r(767) {
			padding: 12px; } }
	.flights-map__left {
		float: left; }
	.flights-map__right {
		float: right;
		@include r(767) {
			display: none; } }
	.flights-map__map {
		position: relative;
		height: 340px; } }
#flight-map {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0; }
.transfer-icon {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 33px;
	height: 3px;
	margin: 5px 9px 5px 0;
	background: $blue-light;
	&:after {
		content: "";
		position: absolute;
		top: -5px;
		left: 50%;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		transform: translateX(-50%);
		background: $blue-light; } }
.charts {
	.row__col {
		width: 50%;
		padding: 0 10px;
		@include r(767) {
			width: 320px; } }
	.charts__item {
		position: relative;
		width: 100%;
		background: #f5f6f7;
		margin-bottom: 20px;
		@include r(767) {
			margin-bottom: 0; } }
	.charts__in {
		position: relative;
		padding: 5%;
		text-align: center; }
	.charts__icon {
		display: inline-block;
		width: auto;
		height: 39px; }
	.charts__title {
		margin-bottom: 10px;
		color: #3d3f41;
		font-size: 18px;
		font-weight: 500; }
	.charts__canvas {
		position: relative;
		height: 200px;
		& > div {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0; }
		@include r(767) {
			margin: 0 -10px; } } }
.chart-box {
	position: relative;
	height: 200px;
	& > div {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0; } }
.flights-details {
	&.flights-details_mb {
		margin-bottom: 30px;
		@include r(680) {
			margin-bottom: 0; }
		&.flights-details_wide {
			@include r(1024) {
				.row__col {
					&:last-child {
						.flights-details__cell {
							min-height: 340px; } } } } } }
	.row__col {
		width: 50%;
		padding: 0 10px;
		@include r(680) {
			width: 100%; } }
	.flights-details__header {
		min-height: 60px;
		.title-h3 {
			margin: 0 0 10px; }
		p {
			margin-bottom: 15px;
			font-size: 13px;
			color: #778393;
			line-height: 15px; } }
	.flights-details__cell {
		min-height: 240px;
		border: 1px solid #e0e0e0;
		padding: 15px;
		margin-bottom: 20px;
		border-radius: 3px;
		& > p {
			margin-bottom: 15px;
			font-size: 13px;
			color: #778393;
			line-height: 15px; }
		@include r(680) {
			min-height: unset; } }
	&.flights-details_h320 {
		.flights-details__cell {
			min-height: 320px; } }
	&.flights-details_wide {
		padding: 60px 0 40px;
		.row__col {
			width: 33.3%; }
		.flights-details__cell {
			min-height: 340px; }
		@include r(1024) {
			text-align: center;
			.row__col {
				width: 50%;
				text-align: left;
				&:last-child {
					.flights-details__cell {
						min-height: unset; } } } }
		@include r(767) {
			.row__col {
				width: 100%;
				.flights-details__cell {
					min-height: unset; } } } }
	&.flights-details_nopt {
		padding-top: 0; }
	&.flights-detail_wide-2x {
		.flights-details__cell {
			min-height: 340px; } }
	&_table {
		display: table;
		width: 100%;
		@include r(680) {
			display: block; }
		.flights-details__cell {
			display: table-cell;
			width: 49%;
			table-layout: fixed;
			@include r(680) {
				display: block;
				width: 100%; } }
		&-3x {
			margin-bottom: 30px;
			@include r(767) {
				display: block; }
			.flights-details__cell {
				width: 32%;
				@include r(767) {
					display: block;
					width: 100%; } } } } }
.table-list {
	width: 100%;
	border-collapse: collapse;
	& > tbody {
		& > tr {
			& > td {
				padding: 11px 15px;
				height: 45px;
				vertical-align: middle;
				strong {
					display: block;
					font-size: 15px;
					color: #000; }
				a {
					font-size: 13px;
					text-decoration: underline;
					&:hover {
						text-decoration: none; } }
				img {
					vertical-align: middle;
					margin-right: 13px; }
				i {
					color: #778393; }
				.icon-plane-top-left {
					width: 17px;
					height: 17px;
					vertical-align: middle;
					margin-right: 13px;
					color: #7f8489; }
				&:last-child {
					text-align: right; } }
			&:nth-child(2n+1) {
				background: #f5f6f7; } } }
	&.table-list_small {
		& > tbody {
			& > tr {
				& > td {
					padding: 10px 15px;
					font-size: 13px;
					strong, i {
						font-size: 13px; } } } } } }
.table-info {
	width: 100%;
	border-collapse: collapse;
	font-size: 13px;
	& > tbody {
		& > tr {
			& > td {
				&:first-child {
					text-align: left; }
				strong {
					display: block;
					color: $cl;
					font-size: 13px;
					font-weight: 700; }
				i {
					color: #778393; } } } } }

.weekdays {
	@include clr;
	margin-top: 17px;
	.weekdays__title {
		float: left; }
	.weekdays__days {
		float: right;
		font-size: 0;
		li {
			display: inline-block;
			vertical-align: middle;
			margin: 0 1px;
			width: 25px;
			height: 25px;
			line-height: 25px;
			text-align: center;
			font-size: 12px;
			color: $cl;
			background: #dcdfe1;
			&.active {
				color: #fff;
				background: $blue-light; } } } }

.transfers-list {
	font-size: 0; }

.transfer {
	width: 100%;
	height: 45px;
	display: block;
	vertical-align: middle;
	padding: 12px;
	font-size: 13px;
	color: $cl;
	box-shadow: none;
	@include r(767) {
		padding: 12px; }
	&:nth-child(2n+1) {
		background: #f5f6f7; }
	.transfers__type {
		display: inline-block;
		span {
			display: inline-block;
			font-size: 13px;
			vertical-align: bottom; } }
	.transfer__price {
		display: inline-block;
		float: right;
		font-size: 15px;
		color: $cl;
		font-weight: 700;
		small {
			font-size: 15px; } }
	.transfer__time {
		display: inline-block; } }

.about {
	display: table;
	width: 100%;
	border-collapse: collapse;
	@include r(1023) {
		@include clr;
		display: block; }
	.about__col {
		display: table-cell;
		vertical-align: top;
		width: 50%;
		background: #e2e3e8;
		&:first-child {
			background: #edeff1;
			.about__in {
				float: right;
				padding: 40px 40px 40px 0;
				@include r(1180) {
					padding: 40px; }
				@include r(767) {
					padding: 20px; }
				@include r(414) {
					padding: 10px; } } }
		@include r(1023) {
			display: block;
			width: 100%;
			&:first-child {
				.about__in {
					float: none; } } } }
	.about__in {
		padding: 40px 0 40px 40px;
		width: 560px;
		text-align: left;
		font-size: 15px;
		color: $cl;
		& > p {
			margin-bottom: 20px; }
		.h2 {
			margin-bottom: 20px; }
		@include r(1180) {
			width: 100%;
			padding: 40px; }
		@include r(767) {
			padding: 20px; }
		@include r(414) {
			padding: 10px; } }
	.about__gallery {
		font-size: 0;
		margin: 0 -5px 20px; }
	.about__img {
		display: inline-block;
		vertical-align: top;
		width: 25%;
		padding: 0 5px;
		margin-bottom: 10px;
		border-radius: 3px;
		overflow: hidden;
		img {
			width: 100%; }
		@include r(480) {
			width: 50%; } }
	.about__more {
		color: $orange;
		text-decoration: underline;
		&:hover {
			text-decoration: none; } } }

.faq {
	padding: 0;
	list-style-type: none;
	@include counter(1, '.');
	color: #3D7CDA;
	font-size: 15px;
	li {
		margin-top: 20px;
		cursor: pointer;
		&:before {
			margin-right: 5px;
			font-size: 20px;
			font-weight: 700; }
		&:first-child {
			margin-top: 0; } }
	.faq__answer {
		padding-top: 20px;
		color: $cl;
		font-size: 15px;
		cursor: default;
		p {
			margin-bottom: 15px; } } }

.list-rounded {
	& > li {
		position: relative;
		padding-left: 15px;
		margin-bottom: 10px;
		&:before {
			content: "";
			position: absolute;
			top: 8px;
			left: 0;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			margin-top: -4px;
			background: $blue-light; }
		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none; }
			@include r(767) {
				font-size: 13px; } } } }

.flights-article {
	margin-bottom: 30px;
	font-size: 15px;
	color: $cl;
	line-height: 18px;
	@include r(767) {
		font-size: 13px; } }

.info__content,
.flights-article {
	h1, h2, h3, h4, h5, p, ul, ol {
		margin-bottom: 10px; }
	ul,ol {
		padding-left: 20px; }
	ul {
		list-style-type: inherit; }
	ol {
		list-style-type: decimal; } }

.hotels {
	position: relative;
	@include r(600) {
		text-align: center; }
	.hotels__topper {
		position: relative;
		@include clr;
		margin-bottom: 20px;
		text-align: left; }
	.hotels__more {
		position: absolute;
		top: 12px;
		right: 0;
		color: $orange;
		text-decoration: underline;
		&:hover {
			text-decoration: none; }
		@include r(600) {
			position: relative;
			display: inline-block;
			top: auto;
			right: auto;
			padding: 23px 0 0; } }
	.hotels__filters {
		font-size: 0;
		li {
			display: inline-block;
			vertical-align: middle;
			a {
				display: inline-block;
				height: 40px;
				padding: 11px 30px;
				border-radius: 3px;
				transition: background 200ms, color 200ms;
				text-decoration: underline;
				color: $blue-light;
				font-size: 15px;
				&:hover, &.active {
					background: #dddee3;
					color: #000;
					text-decoration: none; }
				@include r(480) {
					padding: 11px 13px;
					font-size: 13px; } } } } }

.hotel {
	width: 208px;
	margin-right: 20px;
	text-align: center;
	&_resel {
		width: auto;
		margin-right: 0;
		text-align: left; }
	@include r(480) {
		width: 170px;
		margin-right: 10px; }
	.hotel__img {
		height: 208px;
		border-radius: 3px;
		overflow: hidden;
		margin-bottom: 7px;
		img {
			object-fit: cover; }
		@include r(480) {
			height: 170px; } }
	.hotel__title, .hotel__price {
		margin-bottom: 7px;
		font-weight: 700;
		font-size: 16px; }
	.hotel__title {
		@include text-overflow; }
	.hotel__price {
		margin-bottom: 11px; }
	.btn {
		width: 100%;
		font-size: 14px; } }
.rating {
	font-size: 0;
	text-align: right;
	li {
		display: inline-block;
		vertical-align: middle;
		font-size: 12px;
		color: #ffa400; } }

.press-list {
	@include clr;
	border-top: 1px solid #d8d8d8;
	border-left: 1px solid #d8d8d8;
	&_mod {
		border: none;
		.press {
			line-height: 90px;
			height: 105px;
			border: none;
			@include r(500) {
				display: block;
				width: 100%;
				height: auto;
				line-height: 1;
				margin-bottom: 30px; } } } }

.press-list.main {
	@include clr;
	border-top: none;
	border-left: none;
	.press {
		border: none; } }

.press {
	float: left;
	width: 25%;
	height: 150px;
	line-height: 150px;
	border: 1px solid #d8d8d8;
	border-top: none;
	border-left: none;
	text-align: center;
	font-size: 0;
	img {
		vertical-align: middle;
		max-width: 90%;
		max-height: 90%;
		opacity: 0.5;
		@include gray;
		transition: filter 200ms, opacity 200ms;
		@include gpu; }
	&:hover {
		img {
			@include gray-zero;
			opacity: 1; } }
	@include r(767) {
		width: 50%; } }

.press-more {
	padding-top: 29px;
	text-align: center;
	a {
		position: relative;
		color: $orange;
		font-size: 15px;
		text-decoration: underline;
		&:hover {
			text-decoration: none; } }
	&_dark {
		padding-top: 9px;
		a {
			color: $cl;
			font-size: 18px;
			line-height: 21px; } } }

.rating-bl {
    @include clr;
    @include r(1023) {
        max-width: 270px; }
    &__value {
        float: right;
        font-size: 13px;
        color: $cl; }
    &__value_big {
        font-size: 30px;
        line-height: 1.2;
        color: #000; }
    &__stars, &__value {
        display: inline-block; }
    &__stars {
        float: left;
        position: relative;
        width: 165px;
        height: 33px;
        padding: 0;
        list-style: none; }
    &__stars-bg, &__stars-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%; }
    &__stars-bg {
        width: 100%;
        background: url(../img/rating.png) no-repeat; }
    &__stars-overlay {
        background: url(../img/rating.png) -165px 0 no-repeat; }
    &.rating-bl_sm {
        max-width: 140px;
        float: right;
        .rating-bl__stars {
            height: 21px;
            width: 105px;
            margin-right: 15px; }
        .rating-bl__stars-bg {
            background: url(../img/rating.png) -330px 0 no-repeat; }
        .rating-bl__stars-overlay {
            background: url(../img/rating.png) -595px 0 no-repeat; }
        .rating-bl__value {
            line-height: 1.4; } }
    &.rating-bl_black {
        max-width: 115px;
        display: inline-block;
        float: right;
        @include r(767) {
            display: none; }
        .rating-bl__stars {
            height: 16px;
            width: 80px;
            margin-right: 15px; }
        .rating-bl__stars-bg {
            background: url(../img/rating.png) -435px 0 no-repeat; }
        .rating-bl__stars-overlay {
            background: url(../img/rating.png) -515px 0 no-repeat; } } }

.rating-bl {
	&__subtitle {
		float: left;
		font-size: 15px;
		font-weight: 700;
		margin-top: 15px; }
	&__progress {
		width: 100px;
		height: 6px;
		border-radius: 3px;
		overflow: hidden;
		position: relative;
		float: left;
		margin-top: 16px;
		background: #E6E6E6;
		&-line {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			background: #FFC900;
			border-radius: 3px; } } }

.comp-rating {
	&.comp-rating_upd {
		padding: 25px 30px 30px;
		@include r(767) {
			padding: 25px 10px 30px; }
		.gen-rating {
			padding: 0;
			margin-bottom: 10px; }
		.serv-rating {
			padding: 0;
			&__cell {
				&:nth-child(1) {
					width: 45%; }
				&:nth-child(2) {
					width: 55%; } } }
		.rating-bl {
			max-width: inherit;
			&__value {
				font-size: 30px;
				line-height: 35px;
				font-weight: 700;
				span {
					font-size: 13px;
					color: #8A8A8A;
					font-weight: 400; } }
			&.rating-bl_sm {
				width: 100%;
				max-width: 150px;
				.rating-bl__value {
					font-size: 13px;
					font-weight: 500;
					span {
						font-weight: 400;
						color: #8A8A8A; } } } }
		.btn-primary {
			width: calc(100% + 20px);
			position: relative;
			left: -10px;
			@include r(1023) {
				float: left; }
			@include r(767) {
				width: 100%;
				left: 0;
				float: none; } } } }

// tooltip
.tooltip-item {
	position: relative;
	&:hover {
		&:before, &:after {
			opacity: 1;
			visibility: visible; } }
	&:before, &:after {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		z-index: 1;
		transition: opacity .5s, visibility .5s; }
	&:before {
		content: attr(data-tooltip);
		bottom: 100%;
		left: 50%;
		margin-left: -45px;
		margin-bottom: 14px;
		width: 90px;
		padding: 15px 0;
		box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
		background-color: #282828;
		border-radius: 3px;
		color: #FFF;
		font-size: 12px;
		font-weight: 400;
		text-align: center; }
	&:after {
		content: "";
		top: -14px;
		left: 50%;
		margin-left: -7px;
		width: 0;
		border: 7px solid transparent;
		border-top: 7px solid #282828; } }

.loader {
	position: relative;
	&__overlay {
		position: absolute;
		top: 0;
		left: -10px;
		right: 0;
		bottom: 0;
		z-index: 30;
		background: rgba(#EAEAEA,.8);
		display: none; }
	&.is-on {
		.loader__overlay,.loader__center {
			display: block; } }
	&__center {
		position: absolute;
		top: 150px;
		left: 50%;
		width: 230px;
		margin-left: -110px;
		background: #fff;
		border-radius: 3px;
		text-align: center;
		display: none;
		z-index: 31;
		padding: 30px 0; }
	&__text {
		font-weight: bold;
		color: #284A61;
		font-size: 16px;
		margin-top: 15px; }
	&__spinner {
		display: inline-block;
		width: 60px;
		height: 60px;
		margin: 0 auto;
		border-top: 3px solid #ECECEC;
		border-right: 3px solid #ECECEC;
		border-bottom: 3px solid #ECECEC;
		border-left: 3px solid #ff6700;
		border-radius: 50%;
		animation: spin-right 1s infinite linear 0.1s;
		vertical-align: middle; }
	&_popup {
		.loader__center {
			top: 50%;
			margin-top: -60px;
			@include r(1023) {
				top: 100px;
				margin-top: 0; } }

		.loader__overlay {
			left: 0;
			background: #ffffff; } }
	&_center {
		.loader {
			&__center {
				top: 50%;
				margin-top: -80px; }
			&__overlay {
				left: 0; } } } }

//.bottom-panel
.bottom-panel {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 300px;
	z-index: 100;
	transition: height .5s;
	@include r(767) {
		height: 290px; }
	&__progress {
		height: 2px;
		position: absolute;
		background-color: #3D7CDA;
		width: 20%;
		bottom: 0; }
	&.minimize {
		height: 78px;
		.subscribe-blog__title,
		.subscribe-blog__text,
		.subscribe-blog__social-wrap,
		.subscribe-blog__or,
		.subscribe-box__form input {
			display: none!important; }
		.subscribe-box__form button {
			margin-top: 5px; } }
	&.collapsed {
		height: 0!important;
		.popup__close {
			display: none; } }
	.subscribe-blog__wrap {
		max-width: 400px;
		margin: 0 auto; }
	.subscribe-blog__text {
		max-width: none;
		@include r(767) {
			margin-top: 5px; } }
	.subscribe-box {
		padding: 0;
		background: transparent; }
	.subscribe-box__form {
		margin-top: 0;
		padding-top: 0;
		input, button {
			display: block;
			width: 360px;
			margin: 0 auto;
			text-align: center;
			border-radius: 3px;
			height: 40px;
			margin-bottom: 15px;
			padding: 0 10px 0 20px;
			background-color: #f9f9f9;
			color: #878787;
			border: 1px solid transparent;
			@include placeholder {
				font-size: 15px;
				color: #878787; }
			&:focus {
				border: 1px solid #ff7200;
				transition: .3s;
				@include placeholder {
					font-size: 0; } }
			@include r(767) {
				width: 260px;
				margin: 0 auto;
				height: 38px;
				line-height: 38px; } }
		button {
			margin-top: 10px;
			font-size: 16px;
			width: 360px;
			height: 40px;
			text-align: center;
			border-radius: 3px;
			text-transform: uppercase;
			font-weight: 700;
			background-color: #ff7200;
			color: #ffffff;
			transition: .3s;
			&:hover {
				background-color: #f6a623;
				transition: .3s; }
			&:active {
				background-color: #e95d00;
				transition: .3s; }
			@include r(767) {
				margin-top: 10px;
				padding: 0 10px; } } }
	.subscribe-blog {
		margin-top: 0;
		padding: 15px;
		@include r(767) {
			padding: 15px;
			min-height: 290px; } }
	.subscribe-blog__or {
		font-size: 13px;
		line-height: 15px;
		text-transform: lowercase;
		color: #fff;
		position: relative;
		z-index: 1;
		overflow: hidden;
		text-align: center;
		margin: 15px auto 10px;
		width: 360px;
		@include r(767) {
			margin: 10px auto 10px;
			width: 260px; }
		&:before,
		&:after {
			content: '';
			display: inline-block;
			width: 100%;
			height: 1px;
			background: #ffffff;
			z-index: -1;
			position: relative;
			vertical-align: middle; }
		&:before {
			right: 15px;
			margin: 0 0 0 -100%; }
		&:after {
			left: 15px;
			margin: 0 -100% 0 0; } }
	.subscribe-blog__social-link {
		display: inline-block;
		background-color: #314F98;
		color: #ffffff;
		width: 100%;
		height: 40px;
		line-height: 40px;
		font-size: 15px;
		font-weight: 500;
		text-transform: none;
		border-radius: 3px;
		padding-left: 10px;
		svg {
			font-size: 24px!important;
			vertical-align: middle;
			float: left;
			margin-top: 9px; } }
	.subscribe-blog__social-wrap {
		margin: 20px auto 0;
		width: 360px;
		@include r(767) {
			width: 260px;
			margin-top: 15px; }
		&:after {
			content: "";
			display: table;
			clear: both; } }
	.popup__close {
		right: 20px;
		background: #EAEAEA;
		width: 30px;
		height: 30px;
		top: -15px;
		.icon {
			font-size: 14px; } }
	&_telegram {
		background: #ffffff;
		height: 270px;
		&.minimize {
			height: 100px;
			.bp-telegram__img,
			.bp-telegram__text,
			.btn_cancel {
				display: none; } } }
	&_upd {
		height: 270px;
		.popup__close {
			background: rgba(#000000, .5);
			top: 10px;
			right: 10px;
			color: #ffffff;
			@include r(767) {
				top: -15px; }
			.icon {
				font-size: 12px; } }
		.subscribe-blog {
			padding: 10px;
			min-height: 270px;
			position: relative;
			border-radius: 3px;
			box-shadow: 0 0 10px 1px rgba(0,0,0,.15);
			&__title {
				font-size: 26px;
				line-height: 30px;
				@include r(767) {
					font-size: 22px;
					line-height: 26px; } }
			&__text {
				font-size: 14px;
				line-height: 16px;
				font-weight: 400; }
			.popup__close {
				background: rgba(#000000, .5);
				top: 10px;
				right: 10px;
				color: #ffffff;
				@include r(767) {
					top: -15px; }
				.icon {
					font-size: 12px; } } }
		.trigger-form__input {
			background: #ffffff;
			text-align: left; } } }

.bottom-social {
	position: fixed;
	bottom: 10px;
	width: 100%;
	z-index: 100;
	padding: 0 30px;
	transition: all 1.5s;
	transform: translateY(0);
	@include r(1023) {
		padding: 0 40px; }
	@include r(767) {
		padding: 0 10px; }
	&.collapsed {
		transform: translateY(300px); }
	&__content {
		margin: 0 auto;
		max-width: 1070px;
		padding: 10px;
		border-radius: 3px;
		font-size: 14px;
		line-height: 16px;
		box-shadow: 0 0 10px 1px rgba(0,0,0,.15);
		background: #ffffff; }
	&__table {
		display: table;
		width: 100%;
		@include r(767) {
			display: block; } }
	&__cell {
		display: table-cell;
		vertical-align: middle;
		position: relative;
		@include r(767) {
			display: block; }
		&:nth-child(1) {
			padding-left: 55px;
			@include r(767) {
				padding-top: 5px; } }
		&:nth-child(2) {
			text-align: right;
			white-space: nowrap;
			@include r(767) {
				margin-top: 10px;
				text-align: center; } } }
	&__btn {
		display: inline-block;
		vertical-align: middle;
		margin-left: 10px;
		@include r(767) {
			display: block;
			width: 175px;
			margin: 0 auto; }
		&.btn_cancel {
			padding: 0 10px; }
		.icon {
			vertical-align: middle;
			margin-right: 5px; } }
	&__icon {
		position: absolute;
		top: 0;
		left: 0;
		width: 40px;
		height: 40px;
		.icon {
			font-size: 40px; } }
	&_youtube {
		.bottom-social__text {
			max-width: 520px; }
		@include r(1023) {
			.bottom-social__cell {
				display: block;
				&:nth-child(1) {
					padding-top: 5px; } } } } }
