.res {
    margin-bottom: 10px;
    &__label {
        padding: 12px 20px;
        border-radius: 3px 3px 0 0;
        background: #48B102;
        font-weight: 700;
        color: #FFF;
        @include r(767) {
            padding: 6px 15px; }
        &_red {
            background: #DA0000; } }
    &__label + &__wrap &__aside {
        border-radius: 0 0 3px 0;
        @include r(767) {
            border-radius: 0 0 3px 3px; } }
    &__label + &__wrap &__container {
        border-radius: 0 0 0 3px;
        @include r(767) {
            border-radius: 0; } }
    &__wrap {
        display: table;
        position: relative;
        width: 100%;
        @include shadow; }
    &__container,
    &__aside {
        display: table-cell;
        vertical-align: top;
        @include r(767) {
            display: block; } }
    &__container {
        padding: 15px 20px;
        background: #FFF;
        border-radius: 3px 0 0 3px;
        @include r(767) {
            padding: 20px 15px;
            border-radius: 3px 3px 0 0; } }
    &__aside {
        width: 185px;
        padding: 20px;
        background: #FAFAFA;
        border-radius: 0 3px 3px 0;
        text-align: center;
        @include r(767) {
            width: 100%;
            padding: 9px 15px;
            border-radius: 0 0 3px 3px;
            @include clr; } }
    &__baggage {
        margin-bottom: 45px;
        font-size: 0;
        color: #777;
        @include r(767) {
            float: left;
            margin: 3px 0 0 0; }
        &-item {
            display: inline-block;
            margin: 0 4px;
            vertical-align: middle;
            @include r(767) {
                margin-right: 8px; }
            .icon-bag {
                font-size: 18px; }
            .icon-handbag {
                font-size: 16px; } } }
    &__price {
        font-size: 24px;
        font-weight: 700;
        @include r(767) {
            float: right;
            margin-left: 8px;
            font-size: 18px;
            color: $orange; } }
    &__tickets,
    &__site {
        font-size: 12px;
        @include r(767) {
            float: right;
            font-size: 10px;
            color: #878787; } }
    &__site {
        margin-bottom: 10px;
        @include r(767) {
            margin: -7px 0 0;
            clear: left; } }
    &__buy {
        position: relative;
        @include r(767) {
            position: static; }
        &-head {
            font-size: 0; }
        &-btn,
        &-arrow {
            vertical-align: top; }
        &-btn {
            width: 79%;
            border-radius: 3px 0 0 3px;
            @include r(767) {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0; } }
        &-arrow {
            width: 21%;
            height: 40px;
            padding: 0;
            border-left: 1px solid #fff;
            border-radius: 0 3px 3px 0;
            font-size: 0;
            @include r(767) {
                display: none; }
            &:before {
                content: '';
                display: inline-block;
                @include arr(10,5,#fff,b);
                vertical-align: middle; } }
        &-list {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            background: #FFF;
            border-radius: 3px;
            box-shadow: 0 14px 40px rgba(#000,.35);
            z-index: 10;
            @include r(1023) {
                left: auto;
                right: 0; }
            @include r(767) {
                display: none; } }
        &-table {
            display: table; }
        &-item {
            display: table-row;
            color: $cl; }
        &-item.active &-price {
            color: #EE3841; }
        &-price,
        &-site {
            display: table-cell;
            padding: 10px 15px;
            vertical-align: top;
            white-space: nowrap; } }
    &.open &__buy-btn {
        width: 100%;
        border-radius: 3px; }
    &.open &__buy-arrow {
        display: none; }
    &.open &__buy-table {
        width: 100%;
        table-layout: fixed; }
    &.open &__buy-list {
        display: block;
        position: static;
        padding-top: 10px;
        background: none;
        box-shadow: none;
        @include r(767) {
            display: none; } }
    &.open &__buy-item {
        font-size: 12px;
        color: $blue; }
    &.open &__buy-price {
        padding-left: 0;
        font-weight: 700; }
    &.open &__buy-site {
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden; }
    &.open .schedule {
        display: block; } }

.aside-bl {
    margin-bottom: 10px; }


.cheaper {
    position: relative;
    margin-bottom: 10px;
    background: url('../img/cheaper.jpg') 50% 50% / cover;
    border-radius: 3px;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000,.5);
        border-radius: 3px; }
    &__wrap {
        position: relative;
        max-width: 375px;
        margin: 0 auto;
        padding: 15px 0 13px;
        color: #FFF;
        text-align: center;
        z-index: 2;
        @include r(767) {
            padding: 15px 20px 13px; } }
    &__title {
        font-size: 40px;
        font-weight: 700;
        @include r(767) {
            font-size: 26px; } }
    &__text {
        margin-bottom: 15px;
        font-size: 15px;
        @include r(767) {
            margin-bottom: 5px; } }
    &__input {
        width: 100%;
        height: 40px;
        padding: 0 10px;
        background: #FFF;
        border-radius: 3px;
        font-size: 15px;
        @include placeholder-color(#787878);
        text-align: center;
        @include r(1023) {
            height: 54px; } }
    &__checkbox {
        margin-bottom: 10px; }
    &__field {
        margin-bottom: 10px;
        &-btn {
            width: 100%;
            height: 40px;
            font-size: 16px;
            text-align: center;
            @include r(1023) {
                height: 54px; }
            span {
                @include r(767) {
                    display: inline-block; } } }
        &:last-child {
            margin-top: 13px;
            @include r(767) {
                margin: 0; } } } }

.popup .cheaper {
    box-shadow: 0 14px 40px rgba(0,0,0, .35);
    .cheaper__wrap {
        padding: 50px 0 20px;
        @include r(767) {
            padding: 15px 20px 13px; } }
    .checkbox__content {
        text-align: left!important;
        font-size: 13px;
        line-height: 15px;
        font-weight: 400; }
    .checkbox__icon {
        width: 15px;
        height: 15px;
        svg {
            font-size: 7px; } }
    .checkbox__in {
        padding-left: 24px; } }


