.money {
    max-width: 480px;
    margin: 0 auto;
    text-align: center;
    user-select: none;
    @include r(1023) {
        max-width: 340px; }
    &__wrap {
        display: inline-block;
        position: relative;
        min-width: 170px;
        height: 74px;
        margin: 0 auto 30px;
        padding: 8px 59px 0 15px;
        background: white;
        border-radius: 3px;
        font-size: 0;
        white-space: nowrap;
        vertical-align: top;
        @include r(767) {
            display: block;
            height: 54px;
            padding: 10px 15px 0;
            margin-bottom: -4px;
            border-radius: 3px 3px 0 0; } }
    &__btn {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 44px;
        border-radius: 0 3px 3px 0;
        background: #eee;
        font-size: 0;
        color: #5F5F5F;
        transition: background .2s;
        &:hover {
            background: lighten(#eee,2); }
        &:active {
            background: darken(#eee,2); }
        .icon {
            font-size: 15px; } }
    &__value,
    &__currency {
        display: inline-block; }
    &__value {
        font-size: 30px;
        font-weight: 700;
        @include r(767) {
            font-size: 20px; } }
    &__currency {
        padding-left: 5px;
        font-size: 20px;
        @include r(767) {
            font-weight: 700; } }
    &__desc {
        font-size: 12px;
        color: #A7A7A7;
        @include r(767) {
            display: none; } }
    &__sl {
        position: relative;
        height: 4px;
        margin-bottom: 25px;
        background: #fff;
        border-radius: 4px;
        @include r(767) {
            margin-bottom: 22px;
            background: #d8d8d8;
            border-radius: 0; }
        &-range {
            height: 4px;
            border-radius: 4px;
            background: $orange;
            @include r(767) {
                border-radius: 0; } }
        &-handle {
            position: absolute;
            top: -13px;
            width: 30px;
            height: 30px;
            margin-left: -15px;
            background: $orange;
            border-radius: 50%;
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
            cursor: pointer; } }
    &__counter {
        display: table;
        position: relative;
        margin: 0 auto;
        color: #FFF;
        text-align: left;
        transition: color .2s;
        &.processing {
            color: transparent; }
        &-value,
        &-title {
            display: table-cell;
            vertical-align: middle; }
        &-value {
            padding-right: 15px;
            font-size: 36px;
            font-weight: 700;
            @include r(767) {
                font-size: 32px; } }
        &-title {
            max-width: 110px;
            font-size: 15px;
            font-weight: 300;
            @include r(767) {
                font-size: 13px; } } }
    &__help {
        position: absolute;
        top: 19px;
        right: 12px; }
    &__head {
        margin-bottom: 15px;
        text-align: center;
        &-title {
            font-size: 30px;
            font-weight: 700; }
        &-text {
            font-size: 20px; } }
    &__field {
        position: relative;
        margin-bottom: 15px;
        border-radius: 3px;
        background: #FFF;
        &-input {
            position: relative;
            width: 100%;
            height: 94px;
            padding-bottom: 18px;
            border-radius: 3px;
            border: 2px solid $orange;
            background: transparent;
            font-size: 40px;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            z-index: 2; }
        &-text {
            position: absolute;
            left: 10px;
            right: 10px;
            bottom: 15px;
            font-size: 12px;
            color: #A7A7A7;
            text-align: center; } }
    &__foot {
        padding-top: 15px;
        @include clr;
        &-btn {
            float: right;
            @include r(445) {
                float: none;
                margin-bottom: 10px; }
            min-width: 155px;
            border-radius: 27px;
            &:first-child {
                float: left;
                @include r(445) {
                    float: none; } } } }
    &_sm {
        max-width: 100%;
        margin: 0; }
    &_sm &__wrap {
        display: block;
        max-width: 100%;
        height: 40px;
        margin: 0;
        padding: 0 50px 0 10px;
        border-radius: 3px 3px 3px 0; }
    &_sm &__help {
        top: 10px; }
    &_sm &__desc {
        display: none; }
    &_sm &__value {
        display: block;
        font-size: 15px;
        line-height: 40px; }
    &_sm &__btn {
        width: 40px; }
    &_sm &__sl {
        margin: -4px 40px 0 0;
        border-radius: 0;
        &-range {
            border-radius: 0; }
        &-handle {
            top: -10px;
            width: 24px;
            height: 24px;
            &.is-right {
                transform: translateX(-100%); } } }
    &_popup {
        width: 100%;
        max-width: 100%;
        padding: 40px 50px;
        background: #E5E7E9;
        border-radius: 3px;
        @include r(1023) {
            max-width: 100% !important; }
        @include r(350) {
            padding: 40px; } }
    &_popup &__counter {
        color: $cl; } }
