.switch {
    font-size: 0;
    white-space: nowrap;
    vertical-align: middle;
    &__label {
        display: block; }
    &__input {
        display: none; }
    &__input:checked + &__list &__item {
        &:first-child {
            color: $cl; }
        &:last-child {
            color: #fff; } }
    &__input:checked + &__list &__item {
        &:before {
            left: 0; } }
    &__title {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        @include r(767) {
            display: block;
            margin: 0 0 7px; } }
    &__wrap {
        position: relative; }
    &__list {
        display: table;
        margin: 0 auto;
        position: relative;
        background: white;
        border-radius: 22px;
        border: 3px solid #fff;
        user-select: none;
        cursor: pointer;
        transform: translate3d(0,0,0);
        overflow: hidden;
        table-layout: fixed; }
    &__item {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        height: 38px;
        padding: 0 15px;
        border-radius: 19px;
        font-size: 12px;
        text-transform: uppercase;
        transition: color .2s;
        line-height: 38px;
        z-index: 2;
        width: 50%;
        @include r(767) {
            padding: 0 10px; }
        &:first-child {
            color: #fff;
            z-index: 4; }
        &:last-child {
            color: $cl;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: -100%;
                width: 100%;
                height: 100%;
                border-radius: 22px;
                background: $blue-light;
                transition: all .2s;
                z-index: -1; } } }
    &__mob {
        margin-left: 3px;
        @include r(767) {
            display: none; } }
    &__selected {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 15px;
        background: #FFF;
        border-radius: 22px;
        text-align: center;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 44px;
        @include text-overflow;
        z-index: 4; }
    &_sm &__label {
        @include r(1023) {
            display: none; } }
    &_sm &__wrap {
        @include r(1023) {
            background: none;
            border-radius: 0;
            border: none; } }
    &_sm &__list {
        @include r(1023) {
            display: none; } }
    &_sm &__title {
        display: none;
        @include r(1023) {
            display: block; } }
    &_price {
        text-align: left;
        .switch {
            &__text {
                font-size: 10px;
                line-height: 11px;
                color: #9f9f9f;
                text-transform: uppercase;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
                &_two {
                    display: none; } }
            &__label {
                cursor: pointer; }
            &__list {
                display: inline-block;
                vertical-align: middle;
                margin: 0;
                padding: 2px;
                background: linear-gradient(to bottom, rgba(192,192,192,1) 0%, rgba(219,219,219,1) 100%); }
            &__item {
                width: 21px;
                height: 21px;
                padding: 0;
                &:last-child:before {
                    background: #ffffff;
                    box-shadow: 0 1px 1px 0 rgba(#000000, .32); } } }
        input:checked ~ .switch__text {
            display: none; }
        input:checked ~ .switch__text_two {
            display: inline-block; } }
    &_people {
        text-align: left;
        .switch {
            &__text {
                font-size: 16px;
                line-height: 25px;
                color: #9B9B9B;
                font-weight: 500;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px; }
            &__label {
                cursor: pointer; }
            &__list {
                display: inline-block;
                vertical-align: middle;
                margin: 0;
                padding: 1px;
                background: linear-gradient(to bottom, #777777 0%, #a5a5a5 100%); }
            &__item {
                width: 22px;
                height: 22px;
                padding: 0;
                &:last-child:before {
                    background: #ffffff;
                    box-shadow: 0 1px 1px 0 rgba(#000000, .32); } } }
        input:checked {
            &+.switch__list {
                background: linear-gradient(to bottom, #7BBE06 0%, #2C9B00 100%);
                &+.switch__text {
                    color: $cl; } } } }
    &_new {
        text-align: left;
        font-size: inherit;
        .switch {
            &__text {
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px; }
            &__label {
                cursor: pointer; }
            &__list {
                display: inline-block;
                vertical-align: middle;
                margin: 0;
                padding: 2px;
                background: #B5B5B5;
                border: 0; }
            &__item {
                width: 21px;
                height: 21px;
                padding: 0;
                &:last-child:before {
                    background: #ffffff;
                    box-shadow: 0 1px 1px 0 rgba(#000000, .2); } } }
        input:checked {
            &+.switch__list {
                background: #429321; } } } }
