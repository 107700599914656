.s_mini {
	min-height: 345px;
	height: auto;
	@include r(1023) {
		min-height: 395px;
		height: auto; }
	@include r(767) {
		padding: 0 20px;
		min-height: 505px;
		height: auto; }
	&-hotels {
		min-height: 530px!important;
		@include r(767) {
			min-height: 390px!important; }
		.s__head {
			top: 160px!important;
			@include r(1023) {
				top: 100px!important; }
			@include r(767) {
				top: 30px!important; } }
		.s__line {
			top: 260px!important;
			@include r(1023) {
				top: 185px!important; }
			@include r(767) {
				top: 120px!important;
				padding: 0; } }
		.s__btn-title+.s__btn-title {
			display: none;
			@include r(1023) {
				display: inline-block;
				margin-left: 5px; } }
		.s__head-title {
			font-size: 52px;
			line-height: 61px;
			@include r(1023) {
				font-size: 36px;
				line-height: 42px; }
			@include r(767) {
				font-size: 30px;
				line-height: 35px; } } }

	.s {
		&__step_zero {
			display: none;
			text-align: center;
			.btn {
				min-width: 180px; } }
		&__category {
			top: 15px;
			@include r(767) {
				top: 10px;
				left: 10px;
				right: 10px; } }
		&__category-btn {
			height: 34px;
			line-height: 34px;
			padding: 0;
			@include r(767) {
				margin: 0 3px;
				width: 90px;
				border-radius: 3px; }
			&:after {
				display: none; } }
		&__category-title {
			padding: 0;
			@include r(767) {
				font-size: 10px; } }
		&__params-cell {
			@include r(767) {
				margin-bottom: 10px; } }
		&__head {
			top: 70px;
			transform: none;
			z-index: 3;
			height: auto; }
		&__head-title {
			@include r(767) {
				font-size: 21px; } }
		&__foot {
			transform: translate(-50%, 0);
			top: 265px;
			padding: 12px 15px;
			width: 670px;
			height: 50px;
			.s__line-checkbox {
				margin-top: 0; }
			@include r(1023) {
				top: 305px; }
			@include r(767) {
				width: 100%;
				left: 0;
				transform: translate(0, 0); } }
		&__form-back {
			display: none;
			margin: 25px 0 0;
			text-align: center;
			button {
				color: #fff;
				font-size: 15px;
				text-transform: uppercase;
				font-weight: 700; }
			@include r(1023) {
				display: block; } }
		&__foot-action {
			padding: 15px 0 3px;
			.btn {
				margin: 0 8px; } }
		&__wrap {
			z-index: 3;
			top: 133px;
			transform: none; }
		&__types-name {
			font-size: 14px;
			font-weight: 500;
			@include r(767) {
				font-size: 13px; } }
		&__types-item {
			width: 90px;
			@include r(767) {
				width: 80px; } }
		&__types-icon {
			height: 90px;
			border-radius: 30px;
			@include r(767) {
				height: 80px;
				border-radius: 25px; }
			.icon-s-sea,
			.icon-s-exotic {
				font-size: 54px; }
			.icon-s-ski {
				font-size: 39px; }
			.icon-s-sightseeing {
				font-size: 59px; }
			.icon-s-spa {
				font-size: 44px; }
			.icon-s-destination {
				font-size: 30px; } }
		&__types-item {
			&:last-child {
				display: inline-block; } }
		&__step-form {
			&:not(.active) {
				@include r(1023) {
					position: absolute;
					visibility: hidden;
					opacity: 0; } } }
		&__step-types {
			@include r(767) {
				margin: 0 -20px; }
			&:not(.active) {
				@include r(1023) {
					position: absolute;
					visibility: hidden;
					opacity: 0; } } }
		&__line {
			transform: none;
			top: 165px;
			@include r(1023) {
				top: 143px;
				transform: translateX(-50%); }
			@include r(767) {
				transform: none; }
			&_hotels {
				.s__line-cell {
					&:nth-child(4) {
						float: none;
						margin: 0 auto; }
					.s__btn {
						@include r(767) {
							top: auto; } } } } }
		&__line-center {
			&_wider {
				@include r(1023) {
					&.center {
						padding: 0 30px; } } } }
		&__line-wrap {
			&.disabled {
				@include r(767) {
					display: none; } } }
		&__find {
			margin-top: -13px;
			text-align: center;
			display: none;
			.btn {
				min-width: 180px; }
			&.active {
				@include r(767) {
					display: block; } } }
		&__line-checkbox {
			@include r(1023) {
				float: none;
				margin-top: -55px; } }
		&__line-cell {
			&:nth-child(4) {
				@include r(1023) {
					padding-top: 0;
					width: 50%;
					float: right; }
				@include r(767) {
					width: 100%;
					float: none; } }
			.s__btn {
				display: inline-block;
				position: relative;
				@include r(1023) {
					max-width: 100%; }
				@include r(767) {
					width: 100%;
					top: 35px; } } }

		&__or {
			@include r(1023) {
				display: none; } }
		&__params,
		&__money {
			@include r(1023) {
				top: 0; } }
		&__nav {
			@include r(1023) {
				top: 20px; } }
		&__btn_form {
			@include r(767) {
				display: block;
				width: 100%;
				max-width: 100%; } } }

	.checkbox__content {
		font-weight: 400; }
	.money {
		&__wrap {
			margin-bottom: 21px;
			padding-top: 4px;
			height: 54px;
			text-align: center;
			min-width: 244px;
			@include r(1023) {
				height: 74px;
				padding-top: 13px; } }
		&__value {
			font-size: 24px; }
		&__currency {
			font-size: 15px; }
		&__desc {
			font-size: 10px; }
		&__counter-title {
			max-width: none; }
		&__counter-value {
			font-size: 22px; }
		&__counter-value,
		&__counter-title {
			vertical-align: baseline; }
		&__sl {
			margin-bottom: 18px; } }
	.city__title {
		@include r(767) {
			top: 5px;
			left: 10px;
			color: #a9a9a9; } }

	&.is-extended {
		height: calc(100vh - 50px);
		min-height: 1015px;
		@include r(1023) {
			min-height: 1055px; }
		.s__foot {
			background: #E5E7E9;
			height: auto; }
		.s__filters,
		.checkbox {
			color: #000; }
		.link-dots_light {
			color: $blue;
			span {
				display: none;
				&:last-child {
					display: block; } } }
		.filter-checkboxes {
			display: block; } }
	&.is-open-popup {
		z-index: 100;
		.s__inner {
			z-index: 5; } }
	&.is-zero-step {
		@include r(767) {
			min-height: 200px;
			height: auto;
			.s__step_zero {
				display: block; }
			.s__wrap {
				top: 130px; } } }
	&.upper {
		@include r(1023) {
			z-index: 999; } } }

.filter-checkboxes {
	display: none; }

.link-dots {
	display: inline-block;
	line-height: 1;
	border-bottom: 1px dotted;
	text-decoration: none;
	color: $blue;
	font-weight: bold;
	transition: border .2s;
	position: relative;
	&:hover {
		border-color: transparent; }
	&_light {
		color: #fff; }
	span {
		display: none;
		&:first-child {
			display: block; } } }
.checkbox-group {
	margin-bottom: -1px;
	border-top: 1px solid #D7D7D7;
	border-bottom: 1px solid #D7D7D7;
	padding: 10px 0;
	text-align: left;
	@include clr;
	&__title {
		float: left;
		padding: 5px 15px 0 0;
		width: 24%;
		font-weight: 700;
		font-size: 14px; }
	&__main {
		float: left;
		width: 76%;
		@include clr; }
	&__cell {
		float: left;
		width: 33%;
		&.is-width {
			width: 100%; } }
	&__all {
		@include clr; }
	.checbox_extended {
		&:first-child {
			margin: 0 4px 0 -4px; } } }

.checbox_extended {
	position: relative;
	padding: 4px;
	border-radius: 3px;
	transition: background .3s;
	&:hover {
		background: #F5F5F5;
		.checkbox__helper {
			opacity: 1;
			visibility: visible; } }
	.checkbox__in {
		padding-left: 28px; }
	.checkbox__content {
		position: relative;
		font-size: 14px; }
	.checkbox__helper {
		position: absolute;
		right: 0;
		top: -2px;
		opacity: 0;
		visibility: hidden;
		padding: 4px 4px 4px 10px;
		background-image: linear-gradient(to right, rgba(#F5F5F5, .8), #F5F5F5 15%);
		font-size: 13px;
		font-weight: 400;
		transition: opacity .3s;
		.link-dots {
			font-weight: 400; } }
	.checkbox__icon {
		border: 1px solid #C8C8C8; } }

.filters_checkbox {
	.filters__col {
		padding: 3px 0 15px; } }

.money_new {
	&.money_popup {
		@include r(1023) {
			max-width: none; }
		.money__foot-btn {
			border-radius: 3px; }
		.money__head-text {
			color: #ff6700; } } }

.select_popup-new {
	@include r(767) {
		.select__drop {
			top: 0;
			padding: 0;
			overflow: hidden; }
		.select__wrap {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			background: #fff; }
		.select__top {
			background: #3E474F; }
		.switch {
			width: 100%;
			.switch__wrap {
				width: 100%; }
			.switch__item {
				width: 50%;
				text-align: center; } } } }

.popup_new {
	background: #E5E7E9;
	.popup__center {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #E5E7E9; }
	.filter-checkboxes {
		display: block; }
	.checkbox-group {
		&__title,
		&__main {
			float: none;
			width: 100%; } }
	.popup__title {
		color: #fff;
		background: #3F474F;
		text-align: center;
		line-height: 40px;
		font-size: 16px;
		font-weight: bold; }
	.popup__close {
		position: absolute;
		right: 0;
		left: auto;
		top: 4px;
		background: none;
		box-shadow: none;
		.icon {
			fill: #fff; } }
	.popup__head {
		display: table;
		width: 100%;
		background: #F3F3F3;
		&-cell {
			display: table-cell;
			vertical-align: middle;
			padding: 15px;
			&:last-child {
				text-align: right; } } }
	.popup__wrap {
		padding: 0 15px 20px; }
	.checkbox-group__cell {
		width: 50%; }
	.checkbox__content {
		color: #000;
		font-weight: normal; }
	.checkbox-group__title {
		margin-bottom: 5px; }
	.s__foot-action {
		margin-top: 20px;
		.btn_md {
			width: 48%;
			&:last-child {
				float: right; } } } }
.date_back {
	.date__drop {
		left: 0; } }
.s {
	&__foot {
		top: 50%;
		transform: translate(-50%, 160px);
		padding: 12px 15px;
		width: 670px;
		height: 50px;
		.s__line-checkbox {
			margin-top: 0; }
		@include r(1023) {
			top: 50%;
			padding: 12px 15px;
			transform: translate(-50%, 130px); }
		@include r(767) {
			width: 100%;
			padding: 12px 15px;
			left: 0;
			transform: translate(0, 0); } }
	&__filters {
		color: #fff;
		white-space: nowrap;
		font-size: 15px;
		&.filters {
			display: none; }
		&.filters_checkbox {
			display: table;
			width: 100%; }
		.filters__col {
			width: 33%;
			padding-left: 0;
			&:first-child {
				text-align: left; }
			&:last-child {
				text-align: center;
				@include r(767) {
					text-align: center; } }
			@include r(767) {
				width: 100%; } }
		.s__line-checkbox {
			float: none;
			text-align: left; } } }



.select__foot {
	.selector__list {
		background: #E5E7E9;
		border-radius: 22px;
		.selector__item {
			.selector__type {
				background: #E5E7E9; } }
		.selector__input:checked+.selector__type {
			background: #16bcff; } } }
