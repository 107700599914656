@import "components/switch";

.selector {
    &__head {
        &-title {
            padding: 20px 30px 10px;
            font-size: 15px;
            text-transform: uppercase;
            font-weight: 700;
            @include r(767) {
                padding: 13px 20px 12px;
                text-align: center; } }
        &-stats {
            padding: 10px 30px;
            background: $bg;
            @include clr;
            @include r(767) {
                padding: 10px 20px;
                text-align: center; } } }
    &__count {
        float: left;
        font-size: 13px;
        &-value {
            margin-right: 3px;
            font-weight: 700; }
        &-r {
            @include r(767) {
                display: none; } } }
    &__reset {
        float: right;
        padding: 0;
        border-bottom: 1px dotted;
        font-size: 13px;
        font-weight: 700;
        color: $blue;
        &-r {
            padding-left: 3px;
            @include r(767) {
                display: none; } } }
    &__wrap {
        padding-bottom: 50px;
        background: #e5e7e9;
        border-radius: 0 0 5px 5px;
        @include r(767) {
            padding-bottom: 20px; } }
    &__group {
        padding: 15px 40px 13px;
        border-bottom: 1px solid #BCCDDB;
        @include r(767) {
            padding: 15px 20px 13px; }
        &:last-child {
            padding-bottom: 0;
            border: none; } }
    &__title {
        margin-bottom: 6px;
        font-size: 15px;
        font-weight: 700; }
    &__list {
        font-size: 0; }
    &__item {
        display: inline-block;
        margin: 0 10px 8px 0;
        vertical-align: top;
        user-select: none;
        cursor: pointer; }
    &__input {
        display: none; }
    &__input:checked + &__type {
        background: $blue-light!important;
        color: #fff; }
    &__type {
        display: block;
        height: 32px;
        padding: 0 16px;
        border-radius: 19px;
        background: #FFF;
        font-size: 12px;
        text-transform: uppercase;
        vertical-align: top;
        line-height: 32px;
        transition: all .2s; }
    &__foot {
        height: 28px;
        margin-top: -28px;
        text-align: center;
        @include r(767) {
            height: auto;
            margin: 0;
            padding: 0 20px 40px;
            background: #e5e7e9; } }
    &__btn {
        height: 56px;
        padding: 0 30px;
        background: $orange;
        border-radius: 56px;
        font-size: 0;
        color: #FFF;
        @include r(767) {
            width: 100%;
            padding: 0 20px; }
        &-title,
        &-icon {
            display: inline-block;
            vertical-align: middle; }
        &-title {
            font-size: 18px;
            font-weight: 700; }
        &-icon {
            padding-right: 10px;
            .icon {
                font-size: 29px;
                @include r(767) {
                    font-size: 22px; } } }
        &-r {
            padding: 0 3px;
            @include r(767) {
                display: none; } } }
    &_types {
        text-align: center; }
    &_types &__item {
        margin: 0;
        width: 50%; }
    &_types &__type {
        height: 38px;
        padding: 0 14px;
        line-height: 38px; } }

.toggle {
    display: inline-block;
    user-select: none;
    font-size: 0;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    &__input {
        display: none; }
    &__input:checked + &__wrap &__item {
        &:first-child {
            color: $cl; }
        &:last-child {
            color: #fff; } }
    &__input:checked + &__wrap &__item {
        &:before {
            left: 0; } }
    &__input:checked + &__wrap &__title {
        .icon {}
        opacity: 1; }
    &__wrap {
        display: block;
        position: relative;
        background: white;
        border-radius: 22px;
        border: 3px solid #fff;
        overflow: hidden; }
    &__list {
        position: relative;
        z-index: 2; }
    &__item {
        position: relative;
        display: inline-block;
        height: 38px;
        padding: 0 15px;
        border-radius: 19px;
        font-size: 12px;
        text-transform: uppercase;
        vertical-align: top;
        transition: color .2s;
        line-height: 38px;
        z-index: 2;
        &:first-child {
            color: #fff;
            z-index: 4; }
        &:last-child {
            color: $cl;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: -100%;
                width: 100%;
                height: 100%;
                border-radius: 22px;
                background: $blue-light;
                transition: all .2s;
                z-index: -1; } } }
    &_direction &__wrap {
        border-color: #1a2f41;
        background: #1a2f41; }
    &_direction &__input:checked + &__wrap &__item,
    &_direction &__wrap &__item {
        color: #FFF; }
    &_direction &__wrap {
        border-radius: 23px; }
    &_direction &__item {
        height: 42px;
        padding: 0 25px;
        font-size: 18px;
        text-transform: none;
        line-height: 42px; } }

.filters {
    &__col {
        display: table-cell;
        vertical-align: middle;
        &:not(:first-child) {
            padding-left: 20px;
            @include r(767) {
                padding: 0; } }
        @include r(767) {
            display: block;
            &:not(:last-child) {
                display: none; } } }
    &__more {
        position: relative;
        padding: 0;
        font-size: 15px;
        font-weight: 700;
        color: #FFF;
        vertical-align: middle;
        white-space: nowrap;
        @include r(1023) {
            margin-right: 20px; }
        @include r(767) {
            margin: 0;
            font-size: 14px; }
        &:before {
            content: "+";
            display: inline-block;
            margin-right: 5px; }
        &-title {
            border-bottom: 1px dotted; }

        &-icon {
            display: none;
            font-size: 0;
            .icon {
                font-size: 20px; } }
        &-counter {
            position: absolute;
            top: -2px;
            left: 100%;
            min-width: 20px;
            height: 20px;
            margin-left: 5px;
            padding: 0 5px;
            background: $blue-light;
            border-radius: 20px;
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            @include r(1023) {
                top: -10px;
                margin-left: -5px; }
            @include r(767) {
                top: 0;
                margin-left: 5px; } }
        &_icon {
            &:before {
                @include r(1023) {
                    display: none; } } }
        &_icon &-title {
            @include r(1023) {
                display: none; }
            @include r(767) {
                display: none; }
            &.mob {
                display: none;
                @include r(767) {
                    display: block; } } }
        &_icon &-icon {
            @include r(1023) {
                display: block; }
            @include r(767) {
                display: none; } } }
    &__row {
        display: table;
        @include r(1023) {
            width: auto;
            margin: 0 auto; }
        @include r(767) {
            display: block;
            margin: 0; } }
    &__cell {
        display: table-cell;
        padding-right: 35px;
        vertical-align: middle;
        @include r(767) {
            display: block;
            margin-bottom: 15px;
            padding: 0; }
        &:nth-child(1) {
            padding-right: 100px;
            @include r(1023) {
                display: none; } }
        &:last-child {
            @include r(767) {
                display: table;
                margin: 0 auto 25px; } } }
    &__counter {
        margin-left: -5px;
        color: #a9d0ec;
        font-size: 14px;
        &-value {
            margin: 0 5px;
            font-size: 18px;
            font-weight: 700; } } }
