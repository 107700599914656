.s {
	position: relative;
	height: calc(100vh - 100px);
	min-height: 600px;
	background: #3D7CDA;
	&_new-head {
		height: calc(100vh - 100px);
		@include r(1023) {
			height: calc(100vh - 100px); } }
	&.s_plus-50 {
		height: calc(100vh + 50px);
		min-height: 650px;
		.s__head {
			transform: translateY(-320px)!important;
			@include r(1023) {
				transform: translateY(-357px)!important; } }
		.s__line {
			transform: translateY(-180px)!important;
			@include r(1023) {
				transform: translate(-50%, -211px)!important; }
			@include r(767) {
				transform: none!important;
				position: relative;
				top: 0; } }
		@include r(1023) {
			height: calc(100vh + 50px);
			min-height: 800px; }
		@include r(767) {
			height: auto;
			min-height: 510px; } }
	&.s_plus-100 {
		height: calc(100vh + 100px);
		min-height: 750px;
		.s__head {
			transform: translateY(-345px)!important;
			@include r(1023) {
				transform: translateY(-382px)!important; } }
		.s__line {
			transform: translateY(-205px)!important;
			@include r(1023) {
				transform: translate(-50%, -236px)!important; }
			@include r(767) {
				transform: none!important;
				position: relative;
				top: 0; } }
		@include r(1023) {
			height: calc(100vh + 100px);
			min-height: 800px; }
		@include r(767) {
			height: auto;
			min-height: 510px; } }
	@include r(1023) {
		min-height: 650px; }
	@include r(767) {
		height: 575px;
		min-height: 620px; }
	&_new {
		@include r(767) {
			height: auto;
			min-height: 510px; } }
	&__collapsed {
		font-size: 14px;
		color: #fff;
		display: none;
		@include r(1023) {
			display: block; }
		.center {
			position: relative;
			padding: 15px 0;
			@include r(767) {
				margin: 0 -10px; } }
		strong {
			font-weight: 700;
			@include r(767) {
				display: block;
				margin-bottom: 2px; } }
		&-value {
			padding-left: 20px; }
		&-icon {
			color: #fff;
			position: absolute;
			width: 44px;
			right: 0;
			top: 0;
			bottom: 0;
			background: #3D7CDA;
			.icon {
				position: absolute;
				left: 50%;
				top: 50%;
				font-size: 17px;
				transform: translate(-50%,-50%); } } }
	&__category {
		position: absolute;
		top: 50px;
		left: 0;
		right: 0;
		font-size: 0;
		text-align: center;
		z-index: 4;
		@include rm(1024) {
			@include rh(720) {
				top: 20px; } }
		@include r(767) {
			top: 0;
			left: 0;
			right: 0; }
		&-btn {
			position: relative;
			width: 120px;
			height: 56px;
			margin: 0 3px;
			padding-top: 7px;
			border-radius: 3px;
			background: rgba(#000,.2);
			color: #fff;
			vertical-align: top;
			transition: background .2s;
			@include r(767) {
				width: 33.33%;
				height: 54px;
				margin: 0;
				padding-bottom: 7px;
				border-radius: 0; }
			&:after {
				content: '';
				position: absolute;
				top: 100%;
				left: 50%;
				margin-left: -7px;
				@include arr(14,7,#3D7CDA,b);
				opacity: 0;
				transition: opacity .2s;
				@include r(767) {
					display: none; } }
			&:hover {
				background: rgba(#000,.4); }
			&.active {
				background: #3D7CDA;
				&:after {
					opacity: 1; } } }
		&-icon {
			display: block;
			height: 23px;
			.icon {
				font-size: 17px; }
			.icon-flighthotel {
				font-size: 23px; }
			.icon-aviatickets,
			.icon-hotels,
			.icon-aviapromo {
				margin-top: 3px; }
			.icon-aviapromo {
				font-size: 18px; } }
		&-title {
			display: block;
			padding-top: 4px;
			font-size: 12px;
			@include r(767) {
				padding-top: 4px;
				font-size: 11px;
				font-weight: 400; } }
		&_aviapromo {
			.s__category-btn {
				@include r(767) {
					width: 25%; } } } }
	&__inner,
	&__container,
	&__bg {
		&,
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0; }
		&:before {
			content: '';
			background: rgba(#000,.6); } }
	&__inner {
		z-index: 2;
		@include r(767) {
			position: inherit; } }
	&__container {
		opacity: 0;
		transition: opacity .2s;
		&.active {
			opacity: 1;
			z-index: 2; } }
	&__bg {
		background-size: cover;
		background-position: 50% 50%; }
	&__head {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		height: 120px;
		text-align: center;
		color: #FFF;
		transform: translateY(-195px);
		z-index: 2;
		@include rm(1024) {
			@include rh(720) {
				transform: translateY(-175px); } }
		@include r(1023) {
			transform: translateY(-242px);
			height: 110px; }
		@include r(767) {
			top: 80px;
			transform: translateY(0);
			line-height: 1.14;
			height: 85px; }
		&-title {
			font-size: 40px;
			font-weight: 700;
			margin: 0;
			a {
				color: #fff7e9;
				text-decoration: underline;
				&:hover {
					text-decoration: none; } }
			span {
				display: inline-block;
				margin-left: 10px; }
			@include r(1023) {
				font-size: 32px; }
			@include r(767) {
				margin-bottom: 4px;
				font-size: 22px;
				font-weight: 700;
				text-transform: none; } }
		&-title_name {
			@include r(767) {
				display: block; } }
		&-text {
			margin-top: -2px;
			font-size: 18px;
			font-weight: 300;
			@include r(1023) {
				max-width: 668px;
				margin: -2px auto 0;
				font-size: 16px; }
			@include r(767) {
				font-size: 14px; } }
		&_below {
			transform: translateY(-153px);
			@include rm(1024) {
				@include rh(720) {
					transform: translateY(-153px); } }
			@include r(1023) {
				transform: translateY(-212px); } }
		&_down {
			transform: translateY(-164px); } }
	&__wrap {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		height: 140px;
		transform: translateY(-50%);
		z-index: 2;
		@include r(1023) {
			height: 250px; }
		@include r(767) {
			top: 170px;
			height: auto;
			transform: none; } }
	&__step {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		visibility: visible;
		opacity: 0;
		transform: translateX(20%);
		transition: opacity 0.2s, transform 0.4s;
		z-index: 2;
		@include r(767) {
			transform: none;
			transition: opacity .2s; }
		&.prev {
			transform: translateX(-20%);
			@include r(767) {
				transform: none; } }
		&.active {
			visibility: visible;
			opacity: 1;
			transform: translateX(0%);
			z-index: 4;
			@include r(767) {
				transform: none; } } }
	&__step-types,
	&__step-form {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		visibility: hidden;
		opacity: 0;
		transition: all .2s;
		@include r(1023) {
			position: relative;
			visibility: visible;
			opacity: 1; }
		&.active {
			visibility: visible;
			opacity: 1; } }
	&__step-form.active &__form {
		width: 990px;
		border-radius: 50px;
		visibility: visible;
		opacity: 1;
		transition-delay: 0.2s;
		@include r(1023) {
			width: 100%; } }
	&__step-form.active &__form-close,
	&__step-form.active &__form-wrap {
		opacity: 1;
		transition-delay: 0.4s; }
	&__step-center {
		position: relative;
		@include r(767) {
			padding: 0 20px; } }
	&__or {
		display: none;
		padding: 15px 0 20px;
		font-size: 15px;
		color: #fff;
		text-align: center;
		overflow: hidden;
		@include r(767) {
			padding: 10px 0 20px;
			font-size: 14px; }
		&-title {
			display: inline-block;
			position: relative;
			vertical-align: top;
			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				width: 140px;
				height: 1px;
				margin: 0 15px;
				background: #FFF;
				vertical-align: middle; }
			&:before {
				right: 100%; }
			&:after {
				left: 100%; } }
		@include r(1023) {
			display: block; } }
	&__types {
		margin: 0 -20px;
		text-align: center;
		font-size: 0;
		@include r(639) {
			max-width: 380px;
			margin: 0 auto; }
		&-item {
			position: relative;
			display: inline-block;
			width: 140px;
			min-height: 190px;
			margin: 0 15px;
			padding-bottom: 30px;
			color: #FFF;
			vertical-align: top;
			@include r(1100) {
				margin: 0 14px; }
			@include r(1023) {
				width: 110px;
				min-height: inherit;
				&:last-child {
					display: none; } }
			@include r(767) {
				width: 80px;
				margin: 0 10px;
				padding-bottom: 10px; } }
		&-item:hover &-icon {
			background: rgba($orange,.7); }
		&-item:hover &-popover {
			opacity: 1;
			visibility: visible; }
		&-icon {
			position: relative;
			height: 140px;
			margin-bottom: 8px;
			background: rgba(#fff,.2);
			border-radius: 50px;
			transition: background .2s;
			@include vertical;
			@include r(1023) {
				height: 110px;
				margin-bottom: 2px;
				border-radius: 40px; }
			@include r(767) {
				height: 80px;
				border-radius: 30px; }
			.icon {
				vertical-align: middle; }
			.icon-s-sea {
				font-size: 85px;
				@include r(1023) {
					font-size: 66px; }
				@include r(767) {
					font-size: 48px; } }
			.icon-s-exotic {
				font-size: 83px;
				@include r(1023) {
					font-size: 64px; }
				@include r(767) {
					font-size: 47px; } }
			.icon-s-ski {
				font-size: 60px;
				@include r(1023) {
					font-size: 41px; }
				@include r(767) {
					font-size: 34px; } }
			.icon-s-sightseeing {
				font-size: 91px;
				@include r(1023) {
					font-size: 72px; }
				@include r(767) {
					font-size: 52px; } }
			.icon-s-spa {
				font-size: 68px;
				@include r(1023) {
					font-size: 49px; }
				@include r(767) {
					font-size: 39px; } }
			.icon-s-destination {
				font-size: 46px; } }
		&-name {
			font-size: 16px;
			font-weight: 500;
			@include r(1023) {
				font-size: 14px;
				font-weight: 400; }
			@include r(767) {
				margin: 0 -10px;
				font-size: 13px; } }
		&-popover {
			position: absolute;
			top: 100%;
			left: 50%;
			width: 244px;
			padding: 20px;
			background: linear-gradient(rgba(#000,.24), rgba(#000,.51));
			border-radius: 3px;
			opacity: 0;
			visibility: hidden;
			transition: opacity .2s, visibility .2s;
			font-size: 14px;
			font-weight: 300;
			transform: translateX(-50%);
			@include r(1023) {
				display: none; }
			&:after {
				content: "";
				position: absolute;
				top: -14px;
				left: 50%;
				margin-left: -14px;
				@include arr(28,14,rgba(#000,.24),t); } } }
	&__form {
		position: relative;
		width: 140px;
		height: 140px;
		margin: 0 auto;
		background: rgba(#fff,.2);
		border-radius: 70px;
		visibility: hidden;
		opacity: 0;
		transition: all 0.2s;
		transition-delay: 0.2s;
		@include r(1023) {
			width: 100%;
			max-width: 670px;
			height: auto;
			margin: 0 auto;
			background: none;
			visibility: visible;
			opacity: 1; }
		&-wrap {
			display: table-cell;
			width: 990px;
			height: 140px;
			padding: 25px 50px;
			vertical-align: middle;
			@include r(1023) {
				display: table;
				width: 100%;
				height: auto;
				padding: 0; } }
		&-wrap,
		&-close {
			opacity: 0;
			transition: opacity 0.2s;
			@include r(1023) {
				opacity: 1;
				transition-delay: 0.4s; } }
		&-row {
			display: table;
			width: 100%; }
		&-left,
		&-right {
			display: table-cell;
			vertical-align: top; }
		&-left {
			width: 735px;
			@include r(1023) {
				width: auto; }
			@include r(767) {
				display: block;
				width: 100%; } }
		&-right {
			width: 155px;
			padding-left: 20px;
			@include r(1023) {
				width: 100px; }
			@include r(767) {
				display: block;
				width: 100%;
				padding: 0; } }
		&-close {
			position: absolute;
			top: -10px;
			right: -10px;
			width: 48px;
			height: 48px;
			background: #FFF;
			border-radius: 50%;
			font-size: 0;
			color: #000;
			@include r(1023) {
				display: none; }
			.icon {
				font-size: 16px; } } }
	&__btn {
		width: 100%;
		max-width: 100%;
		height: 54px;
		background: $orange;
		border-radius: 6px;
		font-size: 0;
		color: #FFF;
		transition: background 200ms;
		&:hover {
			background: darken($orange, 5%); }
		&:active {
			background: darken($orange, 10%); }
		&-title,
		&-text,
		&-icon {
			display: inline-block;
			vertical-align: middle; }
		&-title,
		&-text {
			font-size: 15px;
			text-transform: uppercase;
			font-weight: 700; }
		&-title + &-icon,
		&-icon + &-title {
			margin-left: 17px; }
		&-icon .icon {
			font-size: 22px; }
		&_form {
			@include r(1023) {
				position: relative;
				z-index: 6; }
			@include r(767) {
				display: none;
				position: relative;
				width: 100%;
				margin-top: 20px;
				&.visible {
					display: block; } } }
		&_form &-title {
			@include r(1023) {
				display: none; }
			@include r(767) {
				display: inline-block; } }
		&_form &-title + &-icon,
		&_form &-icon + &-title {
			@include r(1023) {
				margin: 0; }
			@include r(767) {
				margin-left: 17px; } }
		&_res {
			width: auto;
			height: 46px;
			padding: 0 26px 0 19px;
			@include r(767) {
				min-width: 230px;
				height: 54px; } }
		&_res &-title + &-icon,
		&_res &-icon + &-title {
			margin-left: 14px; }
		&_res &-icon .icon {
			font-size: 18px;
			@include r(767) {
				font-size: 24px; } }
		&_res &-title {
			@include r(767) {
				font-size: 22px; } }
		.res-topper & {
			@include r(1023) {
				width: 180px; }
			@include r(767) {
				width: 100%; } } }
	&__params {
		position: relative;
		top: 6px;
		max-width: 480px;
		margin: 0 auto;
		@include r(1023) {
			top: 62px; }
		@include r(767) {
			top: 0; }
		.center_mid {
			@include r(1023) {
				padding: 0; } }
		&_sm {
			display: none;
			@include r(1023) {
				display: block;
				top: 0;
				max-width: none;
				padding: 18px 20px;
				.s__params-data {
					padding-left: 5px; } }
			@include r(767) {
				padding: 10px 0 8px;
				min-height: 54px;
				.s__params-wrap {
					padding-right: 40px; }
				.s__params-data {
					padding-left: 0; } } }
		&_new {
			background-color: #3D7CDA;
			max-width: 100%;
			padding: 10px 0;
			top: 0;
			@include r(1023) {
				top: 0; }
			@include r(767) {
				padding: 10px 0; }
			&.fixed {
				position: fixed;
				width: 100%;
				top: 0;
				z-index: 15; } }
		&-row {
			@include clr; }
		&-cell {
			float: left;
			margin-bottom: 20px;
			&:nth-child(1) {
				width: 310px;
				@include r(767) {
					width: 100%; } }
			&:nth-child(2) {
				float: right;
				width: 150px;
				@include r(767) {
					float: left;
					width: 50%;
					padding-right: 5px; } }
			&:nth-child(3) {
				float: left;
				width: 160px;
				clear: both;
				@include r(1023) {
					width: 150px; }
				@include r(767) {
					float: right;
					width: 50%;
					padding-left: 5px;
					clear: none; } }
			&:nth-child(4) {
				float: right;
				width: 300px;
				@include r(1023) {
					width: 310px; }
				@include r(767) {
					width: 100%; } } }
		&-edit {
			position: absolute;
			top: 0;
			right: 0;
			width: 44px;
			height: 100%;
			background-color: #3D7CDA;
			transition: opacity .15s;
			.icon {
				position: absolute;
				top: 50%;
				left: 50%;
				margin-top: -8px;
				margin-left: -8px;
				font-size: 19px;
				width: 19px;
				height: 20px;
				color: #FFF; }
			@include r(767) {
				padding-top: 8px; } }
		&-wrap {
			overflow: hidden;
			font-size: 0;
			color: #fff; }
		&-code,
		&-del,
		&-title {
			display: inline-block;
			vertical-align: middle; }
		&-code,
		&-title {
			font-size: 22px;
			font-weight: 700;
			line-height: 1;
			@include r(767) {
				font-size: 20px; } }
		&-title {
			@include r(1023) {
				font-size: 18px; } }
		&-del {
			width: 13px;
			height: 2px;
			margin: 0 5px;
			background: #FFF;
			vertical-align: middle; }

		&-data {
			display: inline-block;
			vertical-align: bottom;
			padding-left: 15px;
			font-size: 13px;
			@include r(767) {
				display: block;
				padding-left: 0; } }
		&-char {
			@include r(767) {
				display: none; } } }
	&__money {
		position: relative;
		top: -15px;
		@include rm(1024) {
			@include rh(720) {
				top: 0; } }
		@include r(1023) {
			top: 40px; }
		@include r(767) {
			top: 0;
			min-height: 222px; } }
	&__nav {
		position: absolute;
		top: 32px;
		min-width: 76px;
		height: 76px;
		padding: 0 30px;
		background: rgba(#FFF,.2);
		border-radius: 76px;
		transition: background .2s;
		font-size: 0;
		color: #FFF;
		z-index: 9;
		@include r(1023) {
			top: 88px; }
		@include r(767) {
			display: none; }
		&-title,
		&-icon {
			display: inline-block;
			vertical-align: middle; }
		&-title {
			font-size: 18px;
			font-weight: 700;
			text-transform: uppercase; }
		&-title + &-icon {
			margin: 0 0 0 8px; }
		&-icon + &-title {
			margin: 0 0 0 8px; }
		&-icon .icon {
			font-size: 35px;
			line-height: 35px;
			.icon-search {
				font-size: 29px;
				line-height: 29px; } }
		&_prev {
			left: 20px;
			@include r(1023) {
				left: 30px; } }
		&_next {
			right: 20px;
			transition: color .2s;
			@include r(1023) {
				right: 30px; }
			&.processing {
				background: $orange;
				color: transparent; } }
		&_next.processing &-spinner {
			opacity: 1; }
		&_next &-icon {
			font-size: 0;
			line-height: 0;
			.icon {
				font-size: 22px;
				line-height: 22px;
				@include r(1023) {
					font-size: 35px;
					line-height: 35px; } } }
		&_no_title &-title {
			@include r(1023) {
				display: none; } }
		&_no_title &-title + &-icon {
			@include r(1023) {
				margin: 0; } }
		&_go {
			background: $orange; } }
	&__foot {
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 30px 50px;
		background: rgba(#000,.5);
		border-radius: 3px;
		text-align: center;
		opacity: 0;
		transform: translate(-50%,132px);
		@include r(1023) {
			transform: translate(-50%,160px);
			padding: 30px 20px; }
		@include r(767) {
			top: 305px;
			left: 10px;
			right: 10px;
			padding: 0;
			background: none;
			transform: translate(0,0); }
		&.visible {
			opacity: 1;
			transition: opacity .2s .4s;
			z-index: 5; } }
	&__counter {
		display: table;
		margin: 0 auto;
		color: #FFF;
		text-align: left;
		&-value,
		&-title {
			display: table-cell;
			vertical-align: middle; }
		&-value {
			padding-right: 15px;
			font-size: 36px;
			font-weight: 700;
			@include r(767) {
				font-size: 32px; } }
		&-title {
			max-width: 110px;
			font-size: 15px;
			font-weight: 300;
			@include r(767) {
				font-size: 14px; } } }
	&__mselect {
		@include r(1023) {
			margin-right: -3px; }
		@include r(767) {
			margin: 0; } }
	&__pager {
		display: none;
		text-align: left;
		@include r(767) {
			display: block;
			text-align: center; }
		&-next {
			display: block;
			width: 100%;
			height: 54px;
			background: $orange;
			border-radius: 3px;
			font-size: 0;
			color: #fff; }
		&-title,
		&-icon {
			display: inline-block;
			vertical-align: middle; }
		&-title {
			font-size: 18px;
			text-transform: uppercase; }
		&-icon .icon {
			font-size: 22px; }
		&-title + &-icon,
		&-icon + &-title {
			margin-left: 15px; }
		&-prev {
			margin: 20px 0 0 -10px;
			padding: 10px;
			font-size: 14px;
			font-weight: 700;
			color: #fff;
			@include r(767) {
				margin: 20px 0 0 0;
				font-size: 15px;
				text-transform: uppercase; } } }
	&__res {
		&-center {
			position: relative; }
		&-head {
			padding: 20px 0 5px;
			background: #3D7CDA; }
		&-foot {
			padding: 30px 0;
			background: #0E5587;
			@include r(767) {
				padding: 20px 0 75px; } }
		&-row {
			margin: 0 -5px;
			font-size: 0;
			text-align: center; }
		&-cell {
			display: inline-block;
			padding: 0 5px 15px;
			text-align: left;
			font-size: 14px;
			vertical-align: top;
			@include r(1023) {
				padding-bottom: 20px; }
			&:nth-child(1) {
				width: 315px;
				@include r(1023) {
					width: 45%; }
				@include r(767) {
					width: 100%; } }
			&:nth-child(2) {
				width: 280px;
				@include r(1023) {
					width: 35%; }
				@include r(767) {
					width: 100%; } }
			&:nth-child(3) {
				width: 145px;
				@include r(1023) {
					width: 20%; }
				@include r(767) {
					float: left;
					width: 50%; } }
			&:nth-child(4) {
				width: 295px;
				@include r(1023) {
					width: 40%; }
				@include r(767) {
					width: 100%;
					clear: both; } }
			&:nth-child(5) {
				width: 130px;
				@include r(1023) {
					width: 20%; }
				@include r(767) {
					float: right;
					width: 50%;
					margin-top: -120px; } }
			&:nth-child(6) {
				width: 280px;
				@include r(1023) {
					width: 40%; }
				@include r(767) {
					width: 100%; } } }
		&-btn {
			padding-top: 25px;
			text-align: center; }
		&-close {
			position: absolute;
			right: 14px;
			bottom: -5px;
			@include r(1023) {
				bottom: -15px; }
			@include r(767) {
				right: 20px;
				bottom: -50px; } } }
	&__res &__money {
		top: 0;
		max-width: 100%;
		min-height: inherit; }
	&__close {
		float: right;
		font-size: 0;
		color: #FFF;
		@include r(767) {
			margin-top: 10px; }
		&-title,
		&-icon {
			display: inline-block;
			vertical-align: middle; }
		&-title {
			margin-right: 5px;
			padding-bottom: 2px;
			font-size: 14px; }
		&-icon {
			.icon {}
			font-size: 16px; } }
	&__data {
		position: relative;
		padding: 8px 0;
		background: #3D7CDA;
		color: #fff;
		font-size: 14px;
		@include r(767) {
			font-size: 13px; }
		&-row {
			display: table;
			margin: 0 auto;
			@include r(1023) {
				width: 100%; } }
		&-cell {
			display: table-cell;
			vertical-align: top;
			@include r(767) {
				display: block; }
			&:first-child {
				padding: 3px 20px 3px 0;
				@include r(767) {
					padding-right: 0; } }
			&:last-child {
				text-align: right;
				@include r(767) {
					text-align: left; } } }
		&-bold {
			display: inline-block;
			font-weight: 700;
			&_title {
				@include r(1023) {
					display: block;
					margin-bottom: 9px;
					font-size: 20px; }
				@include r(767) {
					font-size: 18px; } } }
		&-r {
			@include r(1023) {
				display: none; } } }
	&__edit {
		height: 24px;
		padding: 0 10px;
		background: #3D7CDA;
		border-radius: 3px;
		color: #fff;
		font-size: 0;
		white-space: nowrap;
		vertical-align: top;
		@include r(1023) {
			height: 46px;
			margin: -8px -20px 0 0;
			padding: 0 15px;
			border-radius: 0; }
		@include r(767) {
			height: 30px;
			margin: 5px 0 0 0;
			padding: 0 10px;
			border-radius: 3px; }
		&-icon,
		&-title {
			vertical-align: middle; }
		&-icon {
			display: none;
			margin-right: 15px;
			@include r(1023) {
				display: inline-block; }
			@include r(767) {
				display: none; }
			.icon {
				font-size: 20px; } }
		&-title {
			display: inline-block;
			border-bottom: 1px dotted;
			font-size: 14px;
			line-height: 1; }
		&-r {
			display: none;
			@include r(767) {
				display: inline; } } }
	&__line {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-27px);
		@include r(1023) {
			left: 50%;
			right: auto;
			width: 100%;
			transform: translate(-50%,-64px); }
		@include r(767) {
			top: 200px;
			left: 0;
			right: 0;
			max-width: 100%;
			padding: 0 10px;
			transform: none; }
		&__tabs {
			&_33 {
				.s__line__tabs-item {
					@include r(767) {
						width: 33.33% !important; }
					&:last-child {
						display: inline-block!important;
						border-bottom: none!important;
						&.active {
							@include r(767) {
								border-bottom: 5px solid #FF7E00!important; } } } } } }
		&_table {
			top: 70%;
			transform: translateY(-12px);
			@include r(1023) {
				top: 83%; } }
		&_down {
			transform: translateY(-4px); }
		&_rel {
			position: relative;
			transform: none; }
		&_hotel {
			body & {
				@include r(767) {
					top: 120px; } }
			.s__btn {
				padding-top: 0;
				padding-bottom: 0; }
			@include r(1023) {
				top: 0;
				left: 0;
				transform: none;
				max-width: none; }
			.s__line-wrap {
				padding: 20px 0 20px;
				@include r(767) {
					padding: 20px 0 40px; } }
			.s__line-wrap {
				vertical-align: baseline; }
			.city__input, .s__btn,
			.date__value, .select__head {
				height: 40px;
				line-height: 40px;
				font-size: 14px; }
			.city__input {
				line-height: 1; } }
		&_aviapromo {
			.s__line-center {
				max-width: 900px; }
			.s__line-cell:nth-child(2) {
				width: 145px;
				@include r(767) {
					width: 100%; } }
			.city_group .city__field {
				width: 100%;
				border-radius: 3px; }
			.city_group .city__field:first-child {
				border-radius: 3px; }
			.s__line-cell {
				@include r(1023) {
					float: none; }
				@include r(767) {
					display: block; }
				&:nth-child(2) {
					@include r(767) {
						text-align: center; } } }
			.s__btn {
				@include r(767) {
					max-width: 280px;
					margin-top: 45px; } }
			.s__line-checkbox {
				@include r(1023) {
					margin-top: 15px; }
				@include r(767) {
					margin-top: -100px; } } }
		&_top {
			top: 0;
			@include r(767) {
				top: 0; }
			@include r(1023) {
				transform: translate(-50%, 0);
				&.active {
					display: block; } }
			.s__line-wrap {
				padding: 15px 0;
				@include r(1023) {
					padding: 30px 0 50px; } }
			.city__input, .s__btn,
			.date__value, .select__head,
			.city__res-code, .city__res-wrap {
				height: 40px;
				line-height: 40px;
				font-size: 14px;
				@include r(1023) {
					height: 54px;
					line-height: 54px;
					font-size: 15px; } }
			.city__input {
				line-height: 1;
				@include r(1023) {
					line-height: 1; } }

			.s__btn {
				font-size: 24px; }
			.s__toggle {
				right: 20px;
				bottom: 12px; } }

		&_top &-cell:nth-child(4) {
			@include r(1023) {
				padding-top: 0; } }
		&-mob {
			@include r(1023) {
				position: relative;
				left: 0;
				top: 0;
				transform: none;
				max-width: none; }
			@include r(767) {
				top: 0; } }
		&-container {
			margin: 0 -10px;
			color: $cl; }
		&-head {
			margin-bottom: 70px;
			padding: 0 10px;
			@include r(1023) {
				margin-bottom: 40px; }
			@include r(767) {
				margin-bottom: 25px; }
			@include rm(768) {
				margin-bottom: 35px; } }
		&-title {
			font-size: 48px;
			font-weight: 700;
			text-transform: uppercase;
			@include r(1023) {
				font-size: 36px; }
			@include r(767) {
				font-size: 20px;
				text-transform: none; } }
		&-text {
			font-size: 18px;
			@include r(1023) {
				display: none; } }
		&-row {
			display: table;
			width: 100%;
			table-layout: fixed; }
		&-cell {
			display: table-cell;
			padding: 0 10px;
			vertical-align: top;
			@include r(1023) {
				float: left;
				padding: 0 10px;
				width: 100%;
				margin-bottom: 20px; }
			@include r(767) {
				margin-bottom: 5px; }
			&:nth-child(2) {
				width: 305px;
				@include r(1023) {
					width: 50%; }
				@include r(767) {
					width: 100%;
					margin-bottom: 5px; } }
			&:nth-child(3) {
				width: 215px;
				@include r(1023) {
					width: 50%; }
				@include r(767) {
					width: 100%;
					margin-bottom: 20px;
					.res-topper & {
						margin-bottom: 5px; } } }
			&:nth-child(4) {
				width: 145px;
				@include r(1023) {
					display: block;
					float: none;
					width: 100%;
					max-width: 50%;
					margin: 0 auto;
					padding-top: 40px;
					text-align: center;
					clear: both;
					.res-topper & {
						padding-top: 0; } }
				@include r(767) {
					max-width: 100%; } } }
		&-foot {
			position: relative;
			padding: 15px 10px 0;
			@include clr;
			@include r(1023) {
				top: -108px; } }
		&-checkbox {
			float: right;
			margin-top: 15px;
			@include r(1023) {
				margin-top: -94px; }
			@include r(767) {
				float: none; } }
		&_hotels &-cell:nth-child(4) {
			@include r(1023) {
				padding-top: 0; } }
		&.fixed {
			@include rm(1024) {
				transform: none;
				.s__line-wrap {
					position: fixed;
					top: 50px;
					left: 0;
					right: 0;
					padding: 15px 0;
					background: #1D83CB;
					box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
					z-index: 99; }
				.s__line-checkbox {
					display: none; }
				.city__res-wrap,
				.date__value,
				.select__head {
					height: 40px;
					line-height: 40px;
					font-size: 14px;
					.city__bold {
						font-size: 14px; } }
				.city__res-code {
					line-height: 40px; }
				.city__input,
				.s__btn {
					height: 40px; } } } }

	&__tabs {
		padding: 15px 0;
		text-align: center;
		li {
			display: inline-block;
			vertical-align: middle;
			&.is-active {
				a {
					background: #0E5587; }
				span {
					border-color: transparent; } } }
		a {
			display: block;
			padding: 5px 10px;
			font-size: 15px;
			font-weight: 400;
			color: #fff;
			border-radius: 4px; }
		span {
			display: inline-block;
			border-bottom: 1px dotted;
			transition: border .2s;
			&:hover {
				border-color: transparent; } } }
	&__tabs-content {
		display: none;
		padding: 0 0 10px;
		text-align: center;
		&.is-active {
			display: block;
			text-align: center; } }
	&.upper {
		@include r(767) {
			z-index: 999; } }
	&.upper &__category {
		@include r(767) {
			z-index: 0; } }
	&_res {
		min-height: inherit;
		height: auto;
		@include r(1023) {
			min-height: inherit;
			height: auto; }
		@include r(767) {
			min-height: inherit;
			height: auto; } }
	&_res-new {
		.s__res-head {
			padding-bottom: 15px; }
		.s__res-row {
			text-align: left;
			@include r(1023) {
				margin: 0 -10px; }
			@include r(767) {
				margin: 0 -6px; } }
		.s__res-cell {
			text-align: left;
			padding-bottom: 25px;
			@include r(1023) {
				padding: 0 10px 20px; }
			@include r(767) {
				padding: 0 3px 6px; }
			&:nth-child(1) {
				width: 25%;
				@include r(1023) {
					width: 50%; }
				@include r(767) {
					width: 100%; } }
			&:nth-child(2) {
				width: 13%;
				@include r(1023) {
					width: 25%; }
				@include r(767) {
					width: 50%; } }
			&:nth-child(3) {
				width: 11.5%;
				@include r(1023) {
					width: 25%; }
				@include r(767) {
					float: none;
					width: 50%; } }
			&:nth-child(4) {
				width: 26.5%;
				@include r(1023) {
					width: 50%; }
				@include r(767) {
					width: 100%; } }
			&:nth-child(5) {
				width: 24%;
				@include r(1023) {
					width: 50%; }
				@include r(767) {
					float: none;
					margin-top: 0;
					width: 100%; } } }
		.select__head,
		.date__value {
			font-size: 14px;
			@include r(767) {
				font-size: 15px; } }
		.select__head,
		.date__value,
		.money__wrap,
		.money__value,
		.city__res-wrap,
		.city__res,
		.city__res-code {
			@include r(1023) {
				height: 54px;
				line-height: 54px; } }

		.city__input {
			@include r(1023) {
				height: 54px; } }
		.money__btn {
			.icon {
				vertical-align: middle; } }

		.date__title,
		.city__title {
			@include r(767) {
				top: 5px;
				left: 10px;
				color: #9F9F9F; } }
		.mselect {
			z-index: 7; } }
	&_short {
		min-height: 0;
		height: auto;
		@include r(1023) {
			min-height: inherit;
			height: inherit; }
		@include r(767) {
			margin-bottom: 0;
			min-height: inherit;
			height: inherit; }
		&_nomb {
			@include r(1159) {
				margin-bottom: 0; } }
		.s__line_hotel {
			@include r(767) {
				position: static; }
			.s__line-cell {
				@include r(767) {
					margin-bottom: 15px; }
				@include r(600) {
					margin-bottom: 5px; }
				&:nth-child(4) {
					@include r(1023) {
						padding-top: 0; } }
				&:nth-child(2),&:nth-child(3) {
					@include r(767) {
						width: 50%; }
					@include r(600) {
						width: 100%; } }
				.city__res-wrap,
				.city__res,
				.city__res-code {
					height: 40px;
					line-height: 40px; } } }
		.s__btn {
			max-width: 300px;
			line-height: 35px;
			@include r(600) {
				max-width: none; }
			.icon {
				margin-right: 10px;
				font-size: 17px;
				position: relative;
				top: 3px;
				display: none;
				@include r(1023) {
					display: inline-block; } }
			&-moretext {
				display: none;
				font-size: 18px;
				font-weight: 700;
				margin-left: 5px;
				vertical-align: middle;
				text-transform: uppercase;
				@include r(1023) {
					display: inline; } } } }
	&_flight {
		margin-bottom: 30px;
		@include r(1023) {
			margin-bottom: 0; } }
	&__toggle {
		display: none;
		text-align: right;
		color: #fff;
		font-size: 14px;
		position: absolute;
		bottom: 10px;
		right: 62px;

		@include r(767) {
			bottom: 10px;
			right: 37px; }
		&-text {
			display: inline-block;
			vertical-align: middle;
			cursor: pointer; }
		&-icon {
			display: inline-block;
			vertical-align: middle;
			font-size: 120%;
			margin-left: 6px;
			cursor: pointer;
			position: relative;
			top: 2px; }
		@include r(1023) {
			display: block; } }
	&__bg-new {
		background: rgba(0,0,0,.7);
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		height: 300px;
		-webkit-transform: translateY(-195px);
		-ms-transform: translateY(-195px);
		transform: translateY(-195px);
		z-index: 1; } }

.radio-bubble {
	display: inline-block;
	margin: 0 3px 5px 0;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	input {
		display: none;
		&:checked {
			+ span {
				background: #fff;
				color: #000; } } }
	span {
		position: relative;
		display: block;
		padding: 10px 22px;
		font-size: 15px;
		color: #fff;
		font-weight: 500;
		line-height: 1;
		border-radius: 22px;
		transition: background, color, .2s;
		background: rgba(#fff, .3);
		&:hover {
			background: rgba(#fff, .4); } } }

.filters_center {
	.filters__row {
		position: relative;
		display: block;
		@include clr;
		text-align: center; }
	.filters__cell {
		display: inline-block;
		vertical-align: middle;
		padding: 0 15px;
		&:first-child {
			position: absolute;
			left: 0;
			top: 0;
			padding: 0 10px 0 0; } } }

.s_new .s__container_flights,
.s_new .s__container_hotels,
.s_flight-new {
	transition: opacity 0s;
	.city_group .city__drop.back {
		@include r(767) {
			top: 105px; } }
	.icon-arrow-right-multi {
		display: none; }
	.select {
		&.active {
			.input-name {
				display: block; } }
		&__drop {
			width: 290px;
			@include r(1023) {
				width: 270px; }
			@include r(767) {
				background-color: #ffffff;
				padding: 0;
				width: 100%;
				height: 100vh;
				.select__wrap {
					width: 100%; }
				&::before {
					display: none; }
				.select__body {
					border-radius: 0; } } } }
	.date {
		&__value {
			padding: 0 10px;
			@include r(767) {
				height: 50px;
				line-height: 50px; } }
		&__field {
			&.active {
				.input-name {
					display: block;
					@include r(767) {
						display: none; } } } } }

	.select__foot {
		padding: 10px 0;
		border-top: 1px solid #EDEDED; }
	.select__btn {
		padding-top: 0;
		border-top: 1px solid #EDEDED;
		.btn_stroke {
			border: none;
			width: auto;
			color: #3D7CDA;
			float: right;
			padding-right: 0;
			&:hover {
				background-color: #ffffff; }
			@include r(767) {
				float: none;
				border: 2px solid #3D7CDA;
				padding: 0 30px;
				margin-top: 10px; } } }
	.selector_types {
		.selector__item {
			padding: 5px; }
		.selector__type {
			height: 30px;
			line-height: 30px;
			padding: 0 5px; } }
	.city__drop {
		min-width: 390px;
		@include r(767) {
			min-width: 100%;
			top: 50px; }
		.city__res-everywhere {
			padding: 8px 15px;
			height: 50px; }
		&.city__drop__everywhere {
			min-width: 50%;
			@include r(767) {
				min-width: 100%;
				margin-top: 5px; } }
		&.city_back {
			@include r(767) {
				top: 148px; } } }
	.s__head-title {
		@include r(1023) {
			font-size: 35px; } }
	.select__head {
		@include r(1023) {
			padding: 0 35px 0 10px; }
		@include r(767) {
			height: 50px;
			line-height: 50px; } }
	.checkbox {
		margin-top: 0;
		@include r(767) {
			display: none; }
		&__icon {
			width: 19px;
			height: 19px; }
		&__content {
			font-size: 13px;
			line-height: 15px;
			font-weight: 400;
			color: #C1C1C1; }
		&__in {
			padding-left: 25px;
			padding-right: 5px; } }
	.city__field {
		&.active {
			.input-name {
				display: block;
				@include r(767) {
					display: none; } } } }

	.city__res-wrap {
		padding-left: 10px;
		@include r(767) {
			height: 50px;
			line-height: 50px; } }
	.city__res-code {
		@include r(767) {
			height: 50px;
			line-height: 50px; } }
	.city__input {
		padding-left: 10px!important;
		@include r(767) {
			height: 50px; } }
	.input-name {
		display: none;
		color: #ffffff;
		font-size: 13px;
		line-height: 14px;
		position: absolute;
		margin-top: -20px;
		font-weight: 400!important;
		@include r(1023) {
			display: block; }
		@include r(767) {
			display: none; } }
	.city__example {
		position: absolute;
		margin-top: 5px;
		font-size: 15px;
		line-height: 18px;
		color: #ffffff!important;
		* {
			display: inline;
			margin-right: 3px; }
		&-item {
			color: #ffffff;
			font-weight: 500;
			border-bottom: 1px dashed #ffffff; }
		@include r(1023) {
			display: none; } }
	.s {
		&__head {
			transform: translateY(-270px);
			@include r(1023) {
				transform: translateY(-320px); }
			@include r(767) {
				display: none; } }
		&__bg {
			@include r(767) {
				background-image: none!important;
				background-color: #3D7CDA; } }
		&__bg:before {
			background-color: transparent; }
		&__line {
			&__title {
				display: none;
				font-size: 20px;
				line-height: 24px;
				font-weight: 700;
				color: #ffffff;
				text-align: center;
				margin: 20px auto;
				@include r(767) {
					display: block; } }
			&__bg {
				background-color: rgba(#0A1D28,.8);
				border-radius: 6px;
				padding: 15px 35px 30px;
				@include r(1023) {
					padding: 10px 35px 30px; }
				@include r(767) {
					padding: 0 10px;
					background: transparent; } }
			&-center.center {
				max-width: 1180px;
				padding: 0 30px;
				@include r(767) {
					padding: 0; } }
			&__radiobuttons {
				margin-bottom: 25px;
				@include r(767) {
					margin: 0 auto;
					overflow: hidden;
					background-color: rgba(#0E5587, .4);
					border-radius: 5px;
					padding: 3px;
					display: table;
					width: calc(100% - 10px); }
				input {
					display: none; }
				label {
					white-space: nowrap;
					position: relative;
					cursor: pointer;
					padding: 0 0 0 30px;
					color: #ffffff;
					font-size: 12px;
					line-height: 18px;
					margin-right: 15px;
					text-transform: uppercase;
					font-weight: 700;
					@include r(767) {
						width: 33.3%;
						padding: 5px 10px;
						font-size: 12px;
						line-height: 14px;
						height: 38px;
						display: table-cell;
						vertical-align: middle;
						text-align: center;
						white-space: normal;
						border-radius: 3px; } }

				label:before {
					content: "";
					display: inline-block;
					width: 19px;
					height: 19px;
					border-radius: 50%;
					margin-right: 10px;
					position: absolute;
					left: 5px;
					top: -3px;
					background-color: #FFFFFF;
					@include r(767) {
						display: none; } }
				input:checked + label {
					@include r(767) {
						background-color: #50AEEF; } }
				input:checked + label:after {
					content: "";
					display: inline-block;
					width: 9px;
					height: 9px;
					border-radius: 50%;
					position: absolute;
					left: 10px;
					top: 2px;
					background-color: #000000;
					@include r(767) {
						display: none; } } }
			&__tabs {
				text-align: center;
				@include r(767) {
					font-size: 0;
					margin: 0 -10px; }
				&-drop {
					display: none;
					position: absolute;
					right: 0;
					background-color: #ffffff;
					border-radius: 3px 0 0 3px;
					z-index: 5;
					margin-top: 6px;
					box-shadow: 0 14px 40px 0 rgba(#000000, .35);
					.s__line__tabs-item {
						display: block;
						width: 100%;
						background-color: #ffffff;
						color: #000000;
						border-bottom: 0;
						position: relative;
						padding: 0 20px 0 50px;
						height: 50px;
						line-height: 50px;
						text-align: left;
						border-radius: 3px 0 0 3px;
						span {
							display: inline-block;
							vertical-align: middle;
							font-size: 13px;
							line-height: 15px;
							sup {
								color: #8F8F8F; } }
						svg {
							position: absolute;
							left: 20px;
							top: 15px; }
						&:hover,
						&:active,
						&.active {
							background-color: #ffffff;
							color: #000000;
							border-color: #ffffff; } } }
				&-item {
					display: inline-block;
					vertical-align: top;
					height: 44px;
					line-height: 44px;
					padding: 0 25px;
					border-radius: 6px 6px 0 0;
					font-size: 13px;
					font-weight: 700;
					color: #FFFFFF;
					background-color: rgba(#0A1D28,.3);
					cursor: pointer;
					text-transform: uppercase;
					min-width: 170px;
					@include r(1023) {
						min-width: inherit;
						padding: 0 15px; }
					@include r(767) {
						height: 58px;
						line-height: 21px;
						width: 25%;
						white-space: nowrap;
						overflow: hidden;
						border-radius: 0;
						background-color: #0E5587;
						text-align: center;
						padding: 10px 0;
						border-bottom: 5px solid #0E5587;
						box-shadow: 0 0 0 0 #ffffff;
						svg {
							margin-right: 0; }
						span {
							display: block;
							font-size: 11px;
							font-weight: 400;
							text-transform: none; } }
					svg {
						vertical-align: middle;
						margin-right: 6px;
						font-size: 20px;
						&.icon-new-flights {
							font-size: 19px; }
						&.icon-new-tickets {
							font-size: 16px; }
						&.icon-new-hotels {
							font-size: 18px; }
						&.icon-new-excursions {
							font-size: 19px; }
						&.icon-new-arrow-down {
							font-size: 8px; } }
					span {
						vertical-align: middle; }
					&:hover,
					&:active,
					&.active {
						background-color: rgba(#0A1D28,.8);
						color: #FF7E00;
						position: relative;
						overflow: visible;
						@include r(767) {
							background-color: #0E5587;
							color: #ffffff;
							border-bottom: 5px solid #FF7E00;
							.s__line__tabs-drop {
								display: block;
								min-width: 100%; } } }
					&:last-child {
						display: none;
						&:hover,
						&:active,
						&.active {
							border-bottom: 5px solid #ffffff; }
						@include r(767) {
							display: inline-block; } } } }

			transform: translateY(-130px);
			@include r(1023) {
				transform: translate(-50%,-174px); }
			@include r(767) {
				transform: none;
				position: relative;
				top: 0; }
			&-cell {
				padding: 0 5px;
				&:nth-child(2),
				&:nth-child(3) {
					@include r(1023) {
						margin-top: 20px;
						margin-bottom: 0;
						width: 50%; }
					@include r(767) {
						width: 100%;
						margin-top: 0; } }

				&:nth-child(2) {
					width: 265px;
					@include r(1023) {
						padding-right: 10px; }
					@include r(767) {
						padding-right: 5px; } }
				&:nth-child(3) {
					width: 235px;
					@include r(1023) {
						padding-left: 10px; }
					@include r(767) {
						margin-top: 5px;
						padding-left: 5px; } } }

			&-center {
				@include rm(767) {
					max-width: 1200px;
					padding: 0 40px; } }
			&-row.s__line-row_multi {
				.date__drop {
					@include r(1023) {
						transform: translate(-163px,0); }
					@include r(767) {
						transform: translate(0); }
					.date__arr {
						@include r(1023) {
							left: initial;
							right: 16px; } } }
				.city__res-code+.city__res-wrap {
					@include r(500) {
						display: none; } }
				.city__res-code {
					@include r(500) {
						float: none;
						color: $cl;
						font-weight: 700;
						font-size: 15px; } }
				.input-close {
					font-size: 16px;
					position: absolute;
					right: -33px;
					top: 17px;
					color: #ffffff;
					cursor: pointer;
					@include r(767) {
						right: -30px;
						top: 15px; }
					&.disable {
						opacity: .5;
						cursor: default; } }
				.date__field {
					width: 100%;
					border: none;
					border-radius: 3px; }
				.s__line-more {
					background-color: rgba(255,255,255,.22);
					height: 54px;
					line-height: 54px;
					text-align: center;
					border-radius: 3px;
					color: #ffffff;
					cursor: pointer;
					font-size: 15px;
					span {
						font-weight: 500;
						line-height: 18px;
						display: inline-block;
						border-bottom: 1px dashed #ffffff; } }
				.s__line-cell {
					@include r(1023) {
						float: none; }
					&:first-child {
						padding: 0 0 0 5px;
						@include r(767) {
							width: 52%;
							.city__field {
								width: calc(50% - 10px);
								margin-top: 0;
								&:first-child {
									margin-right: 20px;
									.icon-arrow-right-multi {
										display: inline-block;
										position: absolute;
										right: -14px;
										top: 19px;
										font-size: 14px;
										color: #ffffff; } } }
							.city__drop {
								min-width: 290px; } } }
					&:last-child {
						padding: 0 30px 0 20px;
						width: 310px;
						@include r(1023) {
							padding: 0 35px 0 10px;
							width: 225px; }
						@include r(767) {
							width: 48%; } } }
				.s__line-people {
					position: relative;
					.s__btn {
						position: absolute;
						width: 74px;
						height: 54px;
						line-height: 54px;
						right: -85px; }
					@include r(767) {
						margin: 0 5px; } } }
			&-row {
				padding-top: 10px;
				position: relative;
				&:first-child {
					@include r(1023) {
						padding-top: 20px; }
					@include r(767) {
						padding-top: 0; } }
				&_multi {
					@include r(767) {
						padding-top: 10px; }
					&:nth-last-child(2) {
						@include r(767) {
							.s__line-cell {
								display: table-row;
								.s__line-more {
									height: 46px;
									line-height: 46px;
									margin: 0 5px 30px; } } } } }

				&_multi+.s__line-row:last-child {
					text-align: center;
					@include r(767) {
						.s__line-cell {
							width: 100%;
							padding: 0; } }
					.s__btn {
						width: initial;
						max-width: initial;
						height: 54px;
						line-height: 54px;
						right: 0;
						bottom: 10px;
						font-size: 17px;
						padding: 0 40px;
						@include r(767) {
							padding: 0 20px;
							font-size: 17px;
							height: 50px;
							line-height: 50px;
							width: calc(100% - 10px);
							margin-top: 15px; } } }
				&_s-btn {
					margin-top: 20px;
					.s__line-cell {
						text-align: center; }
					.s__btn {
						display: inline-block;
						vertical-align: middle;
						max-width: 215px;
						@include r(767) {
							height: 50px;
							width: 100%;
							max-width: inherit; }
						&-title {
							font-size: 16px; } }

					.press-me {
						position: absolute;
						display: inline-block;
						vertical-align: middle;
						height: 54px;
						line-height: 54px;
						margin-left: 10px;
						max-width: 300px;
						color: #C7C8E0;
						text-align: left;
						font-size: 14px;
						padding-left: 40px;
						@include r(767) {
							display: none; }
						span {
							line-height: 16px;
							display: inline-block;
							vertical-align: middle;
							margin-left: 10px; }
						svg {
							font-size: 12px;
							position: absolute;
							left: 0;
							top: 50%;
							margin-top: -6px; } } } } } } }



//.s_flight-new
.s_flight-new {
	.s__line-row:nth-child(2) {
		margin-top: 0;
		@include r(1023) {
			margin-top: 10px; } }
	.s__line {
		background-color: #1C75B4;
		transform: translate(0);
		padding-bottom: 40px;
		z-index: 12;
		@include r(1023) {
			transform: translate(-50%, 0); }
		@include r(767) {
			padding-top: 20px; } }
	.s__line__radiobuttons label {
		font-size: 14px;
		text-transform: none; }
	.s__line-center.center {
		max-width: 1170px;
		@include r(1023) {
			padding: 0 25px; }
		@include r(767) {
			padding: 0 10px; } }
	.s__line__radiobuttons label:before {
		@include rm(1024) {
			width: 15px;
			height: 15px;
			top: 0; } }
	.s__line__radiobuttons input:checked+label:after {
		@include rm(1024) {
			width: 7px;
			height: 7px;
			left: 9px;
			top: 4px; } }
	.checkbox__icon {
		@include rm(1024) {
			width: 15px;
			height: 15px;
			top: 3px;
			.icon-tick {
				font-size: 8px; } } }
	.city__field .checkbox {
		margin-top: 5px; }
	.s__line-cell:nth-child(2) {
		width: 295px; }
	.s__line-cell:nth-child(3) {
		width: 226px; }
	.s__line-cell:nth-child(4) {
		width: 144px;
		@include r(1023) {
			width: 190px;
			padding-top: 30px; }
		.s__btn-title {
			display: inline-block;
			font-size: 18px; } }
	.s__line__radiobuttons label {
		@include rm(1024) {
			padding-left: 25px;
			margin-right: 20px; } }
	.s__line_top .s__line-wrap {
		@include r(1023) {
			padding: 0; } }
	.s__line-row.s__line-row_multi {
		.input-close {
			right: -28px;
			top: 13px; }
		.s__line-cell {
			&:last-child {
				@include r(767) {
					.date__value {
						@include text-overflow; } } } }
		&:nth-child(2) {
			margin-top: 0;
			@include r(1023) {
				margin-top: 10px; }
			.s__btn {
				position: absolute;
				width: 134px;
				right: 5px;
				top: 0; } }
		&:last-child {
			.s__line-cell {
				@include r(1023) {
					display: block;
					margin: 0 auto;
					padding: 0;
					.s__btn {
						width: 190px;
						.s__btn-title {
							font-size: 22px; } } }
				@include r(767) {
					.s__btn {
						.s__btn-title {
							font-size: 17px; } } } } }
		.s__line-more {
			height: 40px;
			line-height: 40px;
			@include r(1023) {
				height: 54px;
				line-height: 54px; } }

		.s__line-cell:last-child {
			width: 415px;
			padding: 0 150px 0 10px;
			position: relative;
			@include r(1023) {
				width: 240px; } } }
	.s__line-row.s__line-row_aviapromo {
		.s__line-cell {
			width: 270px;
			&:nth-child(4) {
				width: 240px; } }
		.city__field {
			width: 100%; } } }

//.s_new .s__container_hotels
.s_new .s__container_hotels {
	transition: opacity 0s;
	.select__head {
		padding-left: 10px; }
	.s__line {
		@include r(767) {
			transform: translateY(0);
			position: relative;
			top: 0; } }
	.s__line__bg {
		padding: 35px 35px 30px;
		@include r(1023) {
			padding: 30px 35px 35px; }
		@include r(767) {
			padding: 45px 10px; } }
	.city_group {
		.city__field {
			width: 100%;
			border-radius: 3px;
			&:first-child {
				border-right: none;
				@include r(767) {
					margin-bottom: 0; } }
			&:nth-child(1) .city__input {
				border-radius: 3px; } }
		.city__drop {
			@include r(1023) {
				max-width: 100%; } } }
	//.city__drop
	//	min-height: 200px
	.city__field {
		.input-name {
			display: block;
			@include r(767) {
				display: none; } } }

	.s__line-cell:nth-child(4) {
		@include r(1023) {
			width: 210px; }
		@include r(767) {
			width: 100%;
			padding-top: 5px;
			text-align: left; } }
	.s__line-cell:first-child {
		@include r(767) {
			margin-bottom: 0; } }
	.s__line-cell:nth-child(2),
	.s__line-cell:nth-child(3) {
		@include r(1023) {
			margin-top: 20px; }
		@include r(767) {
			margin-top: 5px; } }
	.s__line-row_s-btn {
		@include r(1023) {
			margin-top: 20px; }
		.s__line-cell {
			@include r(1023) {
				margin-bottom: 0; } } } }

.s_new .s__container_aviapromo,
.s_new .s__container_excursions {
	transition: opacity 0s;
	.s__line__bg {
		padding: 35px 35px 30px;
		@include r(1023) {
			padding: 30px 35px 30px; }
		@include r(767) {
			padding: 80px 10px; } }
	.city__field {
		width: 100%;
		border-radius: 3px; }
	.input-name {
		font-size: 13px; } }

.s_new .s__container_aviapromo {
	.s__line-row_s-btn {
		@include r(1023) {
			margin-top: 0; }
		.s__line-cell {
			margin-bottom: 0;
			@include r(767) {
				margin-top: 10px; } } } }

.s_new .s__container_excursions {
	.input-name {
		display: block; }
	.city__field {
		border-radius: 3px; }
	.s__line-row_s-btn {
		@include r(1023) {
			margin-top: 0; }
		@include r(767) {
			margin-top: 15px; }
		.s__line-cell {
			@include r(1023) {
				margin-bottom: 0; } } } }


.s_new .s__container_multi {
	.city {
		@include r(767) {
			position: inherit; } }
	@include r(767) {
		position: relative;
		padding-bottom: 30px;
		min-height: 510px; }
	.city__field {
		&.focus {
			@include r(767) {
				width: calc(100% - 40px)!important;
				position: absolute;
				z-index: 5;
				box-shadow: 0 0 0 2px #ff7e00;
				left: 5px;
				.icon-arrow-right-multi {
					display: none!important; } } } }
	.city__drop {
		@include r(767) {
			top: 100%;
			left: 5px;
			max-width: calc(100% - 10px);
			width: 100%; }
		&.back {
			@include r(767) {
				top: 100%!important;
				left: 5px!important; } } }

	.city_group .city__field:first-child {
		@include r(767) {
			margin-bottom: 0; } } }


.s_new {
	&-flights {
		@include r(767) {
			height: 560px;
			min-height: 560px; } }
	&-hotels {
		@include r(767) {
			height: 380px;
			min-height: 380px; } } }





//.date-shift
.date-shift {
	color: #ffffff;
	&__search-icon {
		width: 30px;
		height: 30px;
		line-height: 30px;
		border-radius: 30px;
		background-color: #3D7CDA;
		text-align: center;
		display: inline-block;
		vertical-align: top;
		padding: 0;
		margin-right: 10px;
		svg {
			font-size: 15px;
			color: #ffffff;
			vertical-align: middle;
			@include r(1023) {
				font-size: 19px; } }
		.icon-pencil {
			transform: rotate(-15deg); }
		@include rm(1024) {
			display: none; }
		@include r(1023) {
			width: 40px;
			height: 40px;
			line-height: 40px; }
		@include r(767) {
			float: right;
			margin-right: 0;
			margin-left: 5px; } }
	&__edit-btn {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		margin-left: 10px;
		color: #ffffff;
		svg {
			font-size: 14px;
			vertical-align: middle;
			margin-right: 5px;
			&.icon-bell-shake {
				font-size: 22px;
				margin-right: 0; }
			&.icon-pencil {
				transform: rotate(-15deg);
				font-size: 16px;
				margin-right: 2px; } }
		span {
			font-size: 14px;
			border-bottom: 1px dotted #ffffff; }
		&:hover {
			span {
				border: none; } }
		@include r(767) {
			display: none; }
		&_bg {
			background-color: #3D7CDA;
			border-radius: 3px;
			span {
				border-bottom: 0; } } }
	&__info-wrap {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		@include r(1023) {
			top: 3px; }
		@include r(767) {
			display: block;
			top: 0; } }
	&__info {
		font-size: 14px;
		line-height: 14px;
		display: inline-block;
		vertical-align: middle;
		padding: 7px 13px;
		border-left: 1px solid #72AFDB;
		border-right: 1px solid #72AFDB;
		margin-left: 12px;
		@include r(1023) {
			margin-left: 0;
			display: block;
			padding: 0;
			border: none; }
		@include r(767) {
			padding-left: 0;
			margin-left: 0; } }
	&__destination {
		font-size: 18px;
		line-height: 15px;
		font-weight: 700;
		display: inline-block;
		vertical-align: middle;
		@include r(1023) {
			display: block;
			line-height: 21px; }

		@include r(767) {
			margin-left: 0; }
		&-item {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			&:nth-child(2) {
				margin-left: 30px;
				&:before {
					content: '— ';
					position: absolute;
					left: -22px;
					top: 14px; } } }
		&-item-title,
		&-and-more {
			font-size: 11px;
			line-height: 13px;
			font-weight: 400; }
		&-and-more {
			margin-left: 5px; } }
	.select {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		background: #3D7CDA;
		border-radius: 0;
		&__wrap {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			float: right;
			margin-right: 32px;
			@include r(1023) {
				top: 6px; }
			@include r(767) {
				top: 0;
				float: none;
				margin: 20px auto 0;
				display: block; }
			.date-shift__edit-btn {
				padding: 0 15px;
				height: 30px;
				line-height: 30px;
				padding-left: 10px;
				border-bottom: 0;
				&_bell {
					padding: 0;
					width: 34px; } } }
		@include r(767) {
			width: calc(50% - 73px); }
		&::after {
			display: none; }
		&:first-child {
			@include r(767) {
				margin-left: 30px; } }
		&:nth-child(2) {
			margin-left: 85px;
			&::before {
				content: '';
				width: 10px;
				height: 1px;
				position: absolute;
				left: -47px;
				top: 15px;
				background-color: #ffffff; } }
		&__head {
			position: relative;
			height: 30px;
			padding: 0 2px!important;
			font-size: 14px;
			font-weight: 400;
			width: 80px;
			text-align: center;
			@include text-overflow;
			line-height: 30px;
			@include r(1023) {
				height: 30px!important;
				line-height: 30px!important;
				font-size: 14px; }
			@include r(767) {
				width: auto; } }
		&__body {
			padding: 10px 20px 20px; }
		&__arrow {
			position: absolute;
			top: 0;
			height: 30px;
			padding: 8px 10px;
			border-radius: 3px 0 0 3px;
			font-size: 15px;
			font-weight: 700;
			cursor: pointer;
			z-index: 2;
			background-color: #3D7CDA;
			transition: background-color .2s;
			&_left {
				right: 100%; }
			&_right {
				left: 100%;
				border-radius: 0 3px 3px 0; }
			&.disable,
			&.empty {
				color: #AFDEFF;
				cursor: auto;
				&:hover {
					background-color: #3D7CDA; } }
			&:hover {
				background-color: lighten(#3D7CDA, 5%); } }
		&.select_single {
			@include r(767) {
				width: calc(100% - 61px); } } } }


//.multicity
.multicity {
	padding: 5px 0;
	@include r(1023) {
		padding: 0; }
	.date-shift__search-icon {
		margin-right: 0;
		vertical-align: middle; }
	&__item {
		display: inline-block;
		vertical-align: middle;
		padding: 0 15px;
		border-right: 1px solid #72AFDB;
		@include r(1023) {
			display: none; }
		&:last-child {
			border: none;
			@include r(1023) {
				display: inline-block; }
			@include r(767) {
				padding: 0;
				margin-top: 10px; } }
		&_short {
			display: none;
			@include r(1023) {
				display: inline-block;
				.multicity__city {
					position: relative;
					min-width: 50px;
					.multicity__date {
						position: absolute;
						bottom: -16px;
						white-space: nowrap; } } }
			@include r(767) {
				display: table;
				vertical-align: middle;
				height: 40px;
				padding: 0;
				border: none;
				.multicity__city {
					min-width: inherit; } } } }
	&__title {
		color: #ffffff;
		font-size: 16px;
		line-height: 19px;
		font-weight: 700;
		svg {
			width: 17px;
			height: 7px;
			vertical-align: middle; } }
	&__city {
		display: inline-block;
		vertical-align: middle;
		@include rm(1024) {
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 90px;
			white-space: nowrap; } }
	&__date,
	&__people,
	&__class {
		color: #ffffff;
		font-size: 14px;
		line-height: 16px;
		font-weight: 400; }
	&__date {
		@include r(767) {
			display: none; }
		&_short {
			display: none;
			@include r(767) {
				display: block; } } }
	&__people,
	&__class {
		@include r(767) {
			display: inline-block; } }
	&__people {
		@include r(767) {
			&::after {
				content: ',';
				margin-right: 5px; } } } }


//.refinement
.refinement {
	position: absolute;
	top: 100%;
	width: 270px;
	border-radius: 3px;
	background: #ffffff;
	box-shadow: 0 2px 4px 0 rgba(#000000, .5);
	font-size: 12px;
	line-height: 14px;
	color: $cl;
	border: 1px solid #3D7CDA;
	box-sizing: border-box;
	margin-top: 10px;
	text-align: center;
	z-index: 13;
	left: 50%;
	margin-left: -135px;
	@include r(767) {
		margin-left: -150px; }
	&::before {
		content: '';
		position: absolute;
		top: -20px;
		left: 50%;
		margin-left: -7px;
		width: 0;
		height: 0;
		border: 10px solid transparent;
		border-bottom: 10px solid #ffffff; }
	&__close {
		width: 40px;
		height: 40px;
		line-height: 40px;
		color: #3D7CDA;
		text-align: center;
		position: absolute;
		right: 0;
		top: 0; }
	&__wrap {
		padding: 40px 10px 15px; }
	&__footer {
		padding: 15px 10px;
		background: #EBEDEF;
		&::after {
			content: "";
			display: table;
			clear: both; } }
	&__btn {
		font-size: 12px;
		height: 40px;
		line-height: 40px;
		padding: 0 5px;
		font-weight: 500;
		span {
			font-size: 12px;
			line-height: 14px;
			vertical-align: middle;
			@include r(767) {
				display: inline; } }
		&:nth-child(1) {
			padding: 0;
			color: #3D7CDA;
			float: left;
			width: 135px;
			span {
				border-bottom: 1px dashed #3D7CDA; }
			&:hover,
			&:active {
				span {
					border-bottom: 0; } } }
		&:nth-child(2) {
			width: 110px;
			float: right; } } }


//.s-new
.s-new {
	padding: 20px;
	min-width: 320px;
	color: $cl;
	box-shadow: 0 2px 8px 0 rgba(#000000, .35);
	background: #e4f2ff;
	border-radius: 6px;
	@include r(767) {
		padding: 15px;
		box-shadow: none; }
	&__head {
		position: relative;
		padding: 5px 0 35px;
		&::after {
			content: '';
			height: 1px;
			background: #D8D8D8;
			display: table;
			position: absolute;
			width: 100%;
			bottom: 0;
			clear: both;
			@include r(767) {
				width: calc(100% + 30px);
				margin-left: -15px; } } }
	&__footer {
		padding: 20px;
		margin: 0 -20px -20px;
		border-radius: 0 0 6px 6px;
		border-top: 1px solid #D8D8D8;
		@include r(767) {
			padding: 15px;
			margin: 0 -15px -15px; }
		&-container {
			max-width: 380px;
			margin: 0 auto; }
		&-text {
			font-size: 14px;
			line-height: 16px;
			a {
				display: inline-block;
				font-weight: 500;
				border-bottom: 1px dashed #3D7CDA;
				&:hover,
				&:active {
					border-bottom: 0; } } } }
	&__title {
		font-size: 20px;
		line-height: 24px;
		font-weight: 500;
		float: left;
		@include r(767) {
			font-size: 14px;
			line-height: 16px; } }
	&__close {
		width: 44px;
		height: 46px;
		line-height: 46px;
		text-align: center;
		float: right;
		margin-top: -15px;
		margin-right: -12px;
		svg {
			font-size: 14px;
			color: #3D7CDA; } }
	&__wrap {
		max-width: 1160px;
		margin: 0 auto;
		padding: 0 20px;
		@include r(767) {
			padding: 0 10px; } }
	&__radiobuttons {
		margin-top: 10px;
		@include r(767) {
			margin-top: 20px;
			overflow: hidden;
			background-color: #F7F7F7;
			border: 2px solid #EAEAEA;
			border-radius: 5px;
			padding: 1px;
			display: table;
			width: 100%; }
		input {
			display: none; }
		label {
			white-space: nowrap;
			position: relative;
			cursor: pointer;
			padding: 10px 0 10px 25px;
			color: #ffffff;
			font-size: 13px;
			line-height: 15px;
			margin-right: 25px;
			font-weight: 700;
			vertical-align: middle;
			@include r(767) {
				padding: 5px 10px;
				font-size: 12px;
				line-height: 14px;
				height: 38px;
				display: table-cell;
				vertical-align: middle;
				text-align: center;
				white-space: normal;
				border-radius: 3px;
				text-transform: uppercase; } }
		label:before {
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 19px;
			height: 19px;
			border-radius: 50%;
			margin-right: 10px;
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -9px;
			background-color: #FFFFFF;
			@include r(767) {
				display: none;
				margin-top: 0; } }
		input:checked + label {
			@include r(767) {
				background-color: #50AEEF; } }
		input:checked + label:after {
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 9px;
			height: 9px;
			border-radius: 50%;
			position: absolute;
			left: 5px;
			top: 50%;
			margin-top: -4px;
			background-color: #000000;
			@include r(767) {
				display: none; } } }
	&__container {
		margin: 0 -10px; }
	&__row {
		display: table;
		width: 100%;
		padding: 15px 0;
		font-size: 0;
		@include r(767) {
			padding: 0; }
		&_s-btn {
			position: relative;
			padding-bottom: 0;
			margin-top: 10px;
			@include r(767) {
				padding: 15px 0 0; }
			&::before {
				content: '';
				display: block;
				height: 1px;
				background: #D8D8D8;
				position: absolute;
				top: 0;
				width: 100%;
				@include r(767) {
					width: calc(100% + 30px);
					margin-left: -15px; } } }
		&_buttons {
			position: relative;
			padding-bottom: 0;
			text-align: right;
			@include r(767) {
				padding: 15px 0 0; }
			&::before {
				content: '';
				display: block;
				height: 1px;
				background: #D8D8D8;
				position: absolute;
				top: 0;
				width: 100%;
				@include r(767) {
					width: calc(100% + 30px);
					margin-left: -15px; } }
			.btn_red-new,
			.btn_gray-dark-new {
				padding: 0;
				width: 130px; } }
		&_pm {
			@include r(767) {
				padding: 0 0 15px; } }
		&_profile-popup {
			@include r(767) {
				padding: 10px 0; } }
		&_select-percent {
			padding-right: 100px;
			position: relative;
			@include r(767) {
				padding: 10px 0!important; }
			.select {
				width: 95px;
				position: absolute;
				right: 0;
				top: 0;
				@include r(767) {
					position: relative;
					margin-top: 10px; }
				&__head {
					height: 36px!important;
					line-height: 36px!important;
					text-align: center;
					font-weight: 400!important;
					font-size: 14px!important; } } }
		&_dinamic {
			.alert {
				margin: 0!important;
				box-shadow: none;
				&__row_change {
					display: block;
					padding: 0;
					border: 0!important; }
				&__dates {
					display: block;
					border: 0!important; }
				&__content {
					padding: 0 10px;
					@include r(767) {
						padding: 0; } }
				&__change-row {
					&:first-child {
						.alert__change-cell {
							border: 0!important; } } }
				&__cell {
					@include r(767) {
						padding: 10px 0!important; } }
				&__graph {
					margin-top: 10px;
					height: 230px;
					background-image: url(../img/pricealert__graph2.jpg); } }
			&+.s-new__row_buttons {
				@include r(767) {
					text-align: center!important; } } } }

	&__text {
		font-size: 15px;
		line-height: 18px;
		@include r(767) {
			font-size: 13px;
			line-height: 15px; }
		&_center {
			text-align: center; } }
	&__cell,
	&__col {
		display: inline-table;
		vertical-align: top;
		padding: 0 10px;
		.refinement {
			text-align: left;
			left: 0;
			margin-left: 0;
			@include r(767) {
				margin-left: 0; }
			&::before {
				left: 35px; }
			&__btn {
				&:first-child {
					text-align: left; } } } }

	&__cell {
		padding: 0 20px;
		width: 50%;
		@include r(767) {
			width: 100%;
			padding: 15px 0; }
		&:nth-child(1) {
			padding-left: 0; }
		&:nth-child(2) {
			padding-right: 0; }
		&_cost {
			.s-new__col {
				&:nth-child(1) {
					width: calc(100% - 120px); }
				&:nth-child(2) {
					width: 120px; } } }
		&_nights {
			@include r(767) {
				padding-left: 25px; }
			.s-new__col {
				&:nth-child(1) {
					width: 60%;
					.s-new__subcol {
						padding: 0 25px; } }
				&:nth-child(2) {
					width: 40%;
					@include r(767) {
						width: 100%;
						margin-left: -25px;
						padding: 15px 0; } } } } }

	&__col {
		width: 50%;
		padding: 0 10px;
		&:nth-child(1) {
			padding-left: 0; }
		&:nth-child(2) {
			padding-right: 0; } }
	&__subcol {
		font-size: 13px;
		display: table-cell;
		vertical-align: top;
		padding: 0 15px;
		&:nth-child(1) {
			padding-left: 0!important; }
		&:nth-child(2) {
			padding-right: 0!important; } }
	&__example {
		position: relative;
		height: 32px;
		line-height: 32px;
		border-radius: 4px;
		font-size: 15px;
		vertical-align: middle;
		display: inline-block;
		margin: 10px 10px 0 0;
		color: #ffffff;
		background-color: #3d7cda;
		padding: 0 40px 0 10px;
		svg {
			font-size: 10px;
			color: #ffffff; }
		&-icon {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 32px;
			border-radius: 0 4px 4px 0;
			text-align: center;
			background: rgba(0,0,0,.1); }
		&:hover {
			.s-new__example-icon {
				&:before {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					border-radius: 4px;
					background: rgba(0,0,0,.1); } } } }
	&__notice-btn {
		display: block;
		width: 100%;
		text-align: center;
		height: 46px;
		line-height: 46px;
		color: #ffffff;
		margin-top: 5px;
		@include r(767) {
			margin-top: 60px; }
		&:active {
			.s-new__notice-btn-text {
				border-bottom: 0; } }
		&-text {
			border-bottom: 1px dashed #ffffff;
			display: inline-block;
			vertical-align: middle;
			font-size: 16px;
			line-height: 19px;
			font-weight: 700;
			margin-left: 10px; }
		svg {
			font-size: 22px;
			vertical-align: middle; } }
	&__checkbox {
		display: block;
		z-index: 1;
		margin-top: 27px;
		@include r(767) {
			margin: 0; }
		label {
			display: inline-block;
			user-select: none;
			cursor: pointer;
			color: #fff; }
		svg {
			font-size: 8px!important;
			color: #ffffff;
			margin-left: 1px; }
		.checkbox__icon {
			width: 18px;
			height: 18px;
			border: 1px solid #eaeaea;
			background: #f9f9f9; }
		.checkbox__in {
			padding-left: 27px; }
		.checkbox__content {
			font-weight: 400;
			color: $cl; }
		.checkbox__input {
			transition: background .2s; }
		.checkbox__input:checked+.checkbox__in {
			.checkbox__icon {
				background: #3D7CDA;
				border-color: #3D7CDA; } } }
	//fix
	.select {
		color: $cl;
		margin-top: 15px;
		font-size: 15px;
		&__head,
		&__input {
			height: 46px;
			min-width: 70px;
			line-height: 46px;
			font-size: 15px;
			border-radius: 6px;
			font-weight: 500;
			z-index: 0;
			background: #ffffff; }
		&__name {
			color: $cl; }
		&__list {
			max-height: 300px;
			overflow-y: scroll;
			padding: 0; }
		&__drop {
			width: inherit; }
		&__input {
			line-height: initial; }
		&__item {
			font-weight: 400;
			font-size: 15px;
			line-height: 40px;
			padding: 0 15px;
			&.active {
				background: #ffffff;
				color: #9B9B9B;
				cursor: default; } } }
	.s__btn {
		height: 46px;
		line-height: 46px;
		padding: 0 5px;
		width: 240px;
		margin: 0 auto;
		display: inherit;
		&_alarm {
			.s__btn-title {
				font-size: 16px;
				line-height: 19px;
				text-transform: none;
				margin-left: 10px; }
			svg {
				font-size: 22px;
				vertical-align: middle; } } }
	.s-new__radiobuttons {
		label {
			color: $cl;
			font-weight: 400;
			@include r(767) {
				font-weight: 700; }
			&::before {
				border: 1px solid #EAEAEA; } }
		input:checked+label {
			@include r(767) {
				color: #ffffff; }
			&:after {
				background-color: #3D7CDA; } } }
	&_top {
		background: #e4f2ff;
		padding: 0!important;
		box-shadow: none;
		.select {
			margin-top: 20px;
			@include r(1023) {
				margin-top: 20px; }
			@include r(767) {
				margin-top: 15px; }
			&__name {
				font-size: 14px;
				margin-top: -20px; } }
		.s-new {
			box-shadow: none;
			padding: 0;
			&__cell {
				&:nth-child(1) {
					width: calc(100% - 180px);
					border-right: 0;
					padding-right: 15px;
					@include r(1023) {
						padding-right: 20px; }
					@include r(767) {
						padding-right: 10px;
						width: calc(100% - 44px);
						display: table-cell;
						vertical-align: top;
						&:after {
							display: none; } } }
				&:nth-child(2) {
					width: 180px;
					padding: 0;
					@include r(767) {
						width: 44px;
						margin-top: 40px;
						display: table-cell;
						vertical-align: top; } } } }
		.s__btn {
			margin-top: 20px;
			width: 180px;
			&-icon {
				display: none;
				margin-left: 0;
				svg {
					vertical-align: middle;
					font-size: 19px; }
				@include r(767) {
					display: inline-block; } }
			&-title {
				@include r(767) {
					display: none; } }
			@include r(767) {
				width: 44px;
				margin-top: 30px; } } }

	.aviapromo-popup__text {
		text-align: center;
		margin: 0 auto; }
	.singup {
		&__form {
			display: table;
			width: 100%;
			font-size: 0; }
		&__input,
		&__form-btn {
			margin-top: 10px!important;
			display: table-cell;
			vertical-align: top;
			font-size: 14px; }
		&__form-btn {
			width: 120px;
			margin-left: 10px;
			padding: 0 5px;
			text-transform: none;
			@include r(767) {
				width: 100%;
				margin-left: 0; } }
		&__input {
			width: calc(100% - 130px);
			@include r(767) {
				width: 100%; } }
		&__link {
			padding: 13px;
			padding-left: 45px;
			.icon-facebook {
				left: 20px; }
			span:last-child {
				display: inline-block; } }
		&__or {
			width: 150px; } }
	.aviapromo-popup {
		box-shadow: none;
		position: relative;
		&::after {
			content: '';
			width: 100%;
			height: 1px;
			background: #D8D8D8;
			position: absolute;
			bottom: -10px;
			@include r(767) {
				bottom: 0;
				width: calc(100% + 30px);
				margin-left: -15px; } }
		&__text {
			max-width: 550px;
			font-size: 16px;
			line-height: 19px;
			@include r(767) {
				font-size: 12px;
				line-height: 14px; } }
		&__subtitle {
			margin-bottom: 20px;
			@include r(767) {
				font-size: 16px;
				line-height: 19px;
				font-weight: 500;
				margin-top: 20px;
				margin-bottom: 10px; } }
		&__wrap {
			padding: 0; }
		&__destination {
			font-size: 16px;
			line-height: 19px;
			@include r(767) {
				font-size: 12px;
				line-height: 14px; }
			&::before,
			&::after {
				display: none; }
			&-head {
				color: #9B9B9B;
				width: 75px; }
			&-value {
				font-weight: 400;
				@include r(767) {
					font-weight: 500; } } } }
	.btn_back {
		@include r(767) {
			margin: 20px auto; } } }


.s.s_transparent {
	@include r(767) {
		min-height: 500px;
		height: calc(100vh - 50px); }
	.s {
		&__container {
			&:before {
				z-index: 1; } }
		&__inner {
			@include r(767) {
				position: initial; } }
		&__line {
			z-index: 1;
			&__tabs {
				@include r(767) {
					display: none; } }
			&-row {
				&:nth-child(2) {
					@include r(767) {
						padding-top: 20px; } }
				&_s-btn {
					@include r(767) {
						padding-top: 0; } } }
			&__title {
				@include r(767) {
					margin-top: 55px; } }
			&__radiobuttons {
				@include r(767) {
					background: rgba(#000000, .4); }
				input:checked+label {
					@include r(767) {
						background: #FFFFFF!important;
						color: #000000!important; } }
				label {
					@include r(767) {
						&:nth-child(2) {
							&::after {
								content: '';
								position: absolute;
								top: 3px;
								bottom: 3px;
								width: 1px;
								background: rgba(#ffffff ,.32);
								right: -1px; } }
						&:nth-child(6) {
							&::after {
								content: '';
								position: absolute;
								top: 3px;
								bottom: 3px;
								width: 1px;
								background: rgba(#ffffff ,.32);
								left: -1px; } } } }
				input {
					@include r(767) {
						&:nth-child(1),
						&:nth-child(5) {
							&:checked+label {
								&:after {
									display: none; } } } } } } } } }
