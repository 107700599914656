@mixin svg($name, $width, $height) {
	display: inline-block;
	width: #{$width}px;
	height: #{$height}px;
	background: url("../img/#{$name}.svg") no-repeat 0 0;
	background-size: #{$width}px #{$height}px;
 }	//background-image: inline-image('icons/svg/'+$sprite+'.svg','image/svg'); // to switches sprites to base64, elegant

// width
@mixin r($width) {
	@media only screen and (max-width: $width + "px") {
		@content; } }
@mixin rm($width) {
	@media only screen and (min-width: $width + "px") {
		@content; } }

// height
@mixin rh($height) {
	@media only screen and (max-height: $height + "px") {
		@content; } }

// width + height
@mixin rwh($width, $height) {
	@media only screen and (max-width: $width + "px"), only screen and (max-height: $height + "px") {
		@content; } }

@mixin fs($sizeValue: 16) {
	font-size: $sizeValue + px;
	font-size: $sizeValue / 10 + rem; }

@mixin text-overflow {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap; }

@mixin ie {
	*display: inline;
	*zoom: 1; }

@mixin f {
	font-family: "Mazzard H", sans-serif; }

@mixin shadow {
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15); }

@mixin coverdiv {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }

@mixin cover {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%; }

// retina, also available as +hdpi
@mixin retina {
	@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
		@content; } }

//simple gradient
@mixin g($color1, $color2) {
	background: linear-gradient($color1, $color2); }

@mixin gh($color1, $color2) {
	background: linear-gradient(left, $color1, $color2); }

//asdfast font-family
@mixin font($alias, $name) {
	@font-face {
		font-family: $alias;
		src: url("../fonts/" + $name + ".eot");
		src: url("../fonts/" + $name + ".eot?#iefix") format("embedded-opentype"), url("../fonts/" + $name + ".woff2") format("woff2"), url("../fonts/" + $name + ".woff") format("woff"), url("../fonts/" + $name + ".ttf") format("truetype");
		font-weight: normal;
		font-style: normal; } }

// usage:
//+font(black, cytiapro-black-webfont)

@mixin hide-text {
	font: 0 / 0 a;
	text-shadow: none;
	color: transparent; }

@mixin justify {
	text-align: justify;
	line-height: 0;
	font-size: 0;
	text-justify: newspaper;
	text-align-last: justify;
	&:after {
		content: "";
		display: inline-block;
		width: 100%;
		height: 0;
		visibility: hidden;
		overflow: hidden; } }

@mixin vertical {
	text-align: center;
	font-size: 0;
	&:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		width: 0;
		height: 100%; } }

@mixin vert {
	font-size: 0;
	&:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		width: 0;
		height: 100%; } }

@mixin clr {
	&:after {
		content: " ";
		display: table;
		clear: both; }
	*zoom: 1; }

@mixin placeholder-color($color) {
	&:-moz-placeholder,
	&::-moz-placeholder {
		color: $color;
		opacity: 1; }
	&::-webkit-input-placeholder {
		color: $color; } }

@mixin placeholder {
	&:-ms-input-placeholder {
		@content; }
	&:-moz-placeholder {
		@content; }
	&::-moz-placeholder {
		@content; }
	&::-webkit-input-placeholder {
		@content; }
	&::placeholder {
		@content; } }

//=hover
//	&:hover
//		background: lighten($color, 10%)
//		border-color: lighten($color, 10%)
//
//=active
//	&:active
//		background: darken($color, 10%)
//		border-color: darken($color, 10%)

@mixin gray {
	filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
	// Firefox 10+
	filter: gray;
	// IE6-9
	-webkit-filter: grayscale(100%);
	// Chrome 19+ & Safari 6+
	filter: progid:DXImageTransform.Microsoft.BasicImage(grayScale=1);
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(grayScale=1)"; }

@mixin gray-half {
	filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='saturate' values='0.5'/></filter></svg>#grayscale");
	// Firefox 10+
	filter: gray alpha(opacity = 50);
	// IE6-9
	-webkit-filter: grayscale(50%);
 }	// Chrome 19+ & Safari 6+

@mixin gray-zero {
	filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
	-webkit-filter: grayscale(0%); }

@mixin selection {
	& ::selection {
		@content; }
	& ::-moz-selection {
		@content; } }

@mixin gpu {
	transform: translate3d(0, 0, 0); }

//triangle
@mixin arr($width, $height, $bg, $direction) {
	width: 0;
	height: 0;
	border-style: solid;
	@if $direction == t {
		border-width: 0 $width / 2 + px $height + px $width / 2 + px;
		border-color: transparent transparent $bg transparent; }
	@if $direction == r {
		border-width: $height / 2 + px 0 $height / 2 + px $width + px;
		border-color: transparent transparent transparent $bg; }
	@if $direction == b {
		border-width: $height + px $width / 2 + px 0 $width / 2 + px;
		border-color: $bg transparent transparent transparent; }
	@if $direction == l {
		border-width: $height / 2 + px $width + px $height / 2 + px 0;
		border-color: transparent $bg transparent transparent; }
	@if $direction == tl {
		border-width: $height + px $width + px 0 0;
		border-color: $bg transparent transparent transparent; }
	@if $direction == tr {
		border-width: 0 $width + px $height + px 0;
		border-color: transparent $bg transparent transparent; }
	@if $direction == br {
		border-width: 0 0 $height + px $width + px;
		border-color: transparent transparent $bg transparent; }
	@if $direction == bl {
		border-width: $height + px 0 0 $width + px;
		border-color: transparent transparent transparent $bg; } }

// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

@mixin counter($var, $sep) {
	counter-reset: list + $var;
	> li {
		position: relative;
		&:before {
			content: counter(list + $var) $sep;
			counter-increment: list + $var; } } }
