.icon {
    fill: currentColor;
}
.icon-alert {
  width: 1.2em;
  height: 1em;
}
.icon-arrow-back {
  width: 1.3em;
  height: 1em;
}
.icon-arrow-down-new {
  width: 2.5em;
  height: 1em;
}
.icon-arrow-down {
  width: 1.4em;
  height: 1em;
}
.icon-arrow-left {
  width: 0.7em;
  height: 1em;
}
.icon-arrow-long-vertical {
  width: 0.6em;
  height: 1em;
}
.icon-arrow-long {
  width: 3.5em;
  height: 1em;
}
.icon-arrow-right-multi {
  width: 0.6em;
  height: 1em;
}
.icon-arrow-right-new {
  width: 1.8em;
  height: 1em;
}
.icon-arrow-right-side {
  width: 2.4em;
  height: 1em;
}
.icon-arrow-right {
  width: 0.7em;
  height: 1em;
}
.icon-arrow-two-sides {
  width: 2.6em;
  height: 1em;
}
.icon-attention {
  width: 1.2em;
  height: 1em;
}
.icon-aviapromo {
  width: 1.5em;
  height: 1em;
}
.icon-aviatickets {
  width: 1.8em;
  height: 1em;
}
.icon-badge {
  width: 1em;
  height: 1em;
}
.icon-bag {
  width: 0.8em;
  height: 1em;
}
.icon-baggage-new-no {
  width: 0.8em;
  height: 1em;
}
.icon-baggage-new {
  width: 0.8em;
  height: 1em;
}
.icon-baggage-no {
  width: 0.8em;
  height: 1em;
}
.icon-baggage-yes {
  width: 0.8em;
  height: 1em;
}
.icon-baggage {
  width: 0.8em;
  height: 1em;
}
.icon-bed {
  width: 1.5em;
  height: 1em;
}
.icon-bell-shake {
  width: 1em;
  height: 1em;
}
.icon-bell {
  width: 1em;
  height: 1em;
}
.icon-big-star-empty {
  width: 1em;
  height: 1em;
}
.icon-big-star-half {
  width: 1em;
  height: 1em;
}
.icon-bookimed-call {
  width: 0.9em;
  height: 1em;
}
.icon-bookimed-error {
  width: 1em;
  height: 1em;
}
.icon-booking-avia {
  width: 1em;
  height: 1em;
}
.icon-booking-hotel {
  width: 1em;
  height: 1em;
}
.icon-bort-audio {
  width: 1em;
  height: 1em;
}
.icon-bort-baby {
  width: 0.7em;
  height: 1em;
}
.icon-bort-food {
  width: 0.9em;
  height: 1em;
}
.icon-bort-rozetka {
  width: 0.8em;
  height: 1em;
}
.icon-bort-video {
  width: 1.3em;
  height: 1em;
}
.icon-bort-wifi {
  width: 1.5em;
  height: 1em;
}
.icon-bus {
  width: 0.9em;
  height: 1em;
}
.icon-cars {
  width: 1.9em;
  height: 1em;
}
.icon-certain-date {
  width: 1em;
  height: 1em;
}
.icon-certain-date_new {
  width: 1em;
  height: 1em;
}
.icon-change {
  width: 1.1em;
  height: 1em;
}
.icon-chat {
  width: 1.2em;
  height: 1em;
}
.icon-check {
  width: 0.8em;
  height: 1em;
}
.icon-circle-airport {
  width: 1em;
  height: 1em;
}
.icon-circle-car {
  width: 1em;
  height: 1em;
}
.icon-circle-customs {
  width: 1em;
  height: 1em;
}
.icon-circle-dishes {
  width: 1em;
  height: 1em;
}
.icon-circle-events {
  width: 1em;
  height: 1em;
}
.icon-circle-hand {
  width: 1em;
  height: 1em;
}
.icon-circle-transfer {
  width: 1em;
  height: 1em;
}
.icon-circle-transport {
  width: 1em;
  height: 1em;
}
.icon-circle-visa {
  width: 1em;
  height: 1em;
}
.icon-circle-vocabulary {
  width: 1em;
  height: 1em;
}
.icon-clock-full-fill {
  width: 1em;
  height: 1em;
}
.icon-close {
  width: 1em;
  height: 1em;
}
.icon-comment {
  width: 1em;
  height: 1em;
}
.icon-compare-done {
  width: 1em;
  height: 1em;
}
.icon-compare {
  width: 1em;
  height: 1em;
}
.icon-compass {
  width: 1em;
  height: 1em;
}
.icon-date {
  width: 1em;
  height: 1em;
}
.icon-del {
  width: 1em;
  height: 1em;
}
.icon-depositphotos {
  width: 5.3em;
  height: 1em;
}
.icon-diagram {
  width: 1.1em;
  height: 1em;
}
.icon-dollar {
  width: 1em;
  height: 1em;
}
.icon-down {
  width: 0.6em;
  height: 1em;
}
.icon-dude {
  width: 1.1em;
  height: 1em;
}
.icon-dynpacks {
  width: 1em;
  height: 1em;
}
.icon-earth {
  width: 1em;
  height: 1em;
}
.icon-edit {
  width: 1em;
  height: 1em;
}
.icon-engine {
  width: 1em;
  height: 1em;
}
.icon-error {
  width: 0.8em;
  height: 1em;
}
.icon-eye {
  width: 1.6em;
  height: 1em;
}
.icon-facebook-blog {
  width: 1em;
  height: 1em;
}
.icon-facebook {
  width: 0.6em;
  height: 1em;
}
.icon-family {
  width: 1.2em;
  height: 1em;
}
.icon-faq-hotels {
  width: 1em;
  height: 1em;
}
.icon-faq-insurance {
  width: 1em;
  height: 1em;
}
.icon-faq-more {
  width: 1.2em;
  height: 1em;
}
.icon-faq-plane {
  width: 1em;
  height: 1em;
}
.icon-faq-top {
  width: 1em;
  height: 1em;
}
.icon-faq-train {
  width: 0.9em;
  height: 1em;
}
.icon-faq-why {
  width: 1.1em;
  height: 1em;
}
.icon-fb {
  width: 0.6em;
  height: 1em;
}
.icon-filter {
  width: 1.1em;
  height: 1em;
}
.icon-filters {
  width: 1.1em;
  height: 1em;
}
.icon-fitnes {
  width: 2em;
  height: 1em;
}
.icon-flash {
  width: 0.8em;
  height: 1em;
}
.icon-flash2 {
  width: 0.6em;
  height: 1em;
}
.icon-flighthotel {
  width: 0.9em;
  height: 1em;
}
.icon-full-mounth {
  width: 1em;
  height: 1em;
}
.icon-full-mounth_new {
  width: 1em;
  height: 1em;
}
.icon-full-screen {
  width: 1em;
  height: 1em;
}
.icon-gift {
  width: 1em;
  height: 1em;
}
.icon-go-top {
  width: 1.5em;
  height: 1em;
}
.icon-google-big {
  width: 1em;
  height: 1em;
}
.icon-googleplus {
  width: 1.1em;
  height: 1em;
}
.icon-guarantee {
  width: 1.1em;
  height: 1em;
}
.icon-guest {
  width: 0.5em;
  height: 1em;
}
.icon-handbag-new {
  width: 1.1em;
  height: 1em;
}
.icon-handbag-thin {
  width: 0.8em;
  height: 1em;
}
.icon-handbag {
  width: 1em;
  height: 1em;
}
.icon-heart {
  width: 1.2em;
  height: 1em;
}
.icon-help {
  width: 1em;
  height: 1em;
}
.icon-highest {
  width: 1.5em;
  height: 1em;
}
.icon-hiking {
  width: 0.7em;
  height: 1em;
}
.icon-hint {
  width: 1em;
  height: 1em;
}
.icon-hotels {
  width: 1.3em;
  height: 1em;
}
.icon-hourglass {
  width: 0.8em;
  height: 1em;
}
.icon-htl-arrow-l {
  width: 0.4em;
  height: 1em;
}
.icon-htl-arrow-r {
  width: 0.4em;
  height: 1em;
}
.icon-i {
  width: 1em;
  height: 1em;
}
.icon-info-new {
  width: 1em;
  height: 1em;
}
.icon-info {
  width: 1em;
  height: 1em;
}
.icon-inspiration {
  width: 1em;
  height: 1em;
}
.icon-keyboard {
  width: 1.5em;
  height: 1em;
}
.icon-lamp {
  width: 1em;
  height: 1em;
}
.icon-left-arrow {
  width: 0.6em;
  height: 1em;
}
.icon-libra {
  width: 1.5em;
  height: 1em;
}
.icon-like-fill {
  width: 1.2em;
  height: 1em;
}
.icon-like {
  width: 1.2em;
  height: 1em;
}
.icon-link {
  width: 1.1em;
  height: 1em;
}
.icon-lm-arrow-right {
  width: 0.7em;
  height: 1em;
}
.icon-lm-menu {
  width: 1.4em;
  height: 1em;
}
.icon-lock {
  width: 0.8em;
  height: 1em;
}
.icon-logo {
  width: 9.7em;
  height: 1em;
}
.icon-lowest {
  width: 1.5em;
  height: 1em;
}
.icon-m-cross {
  width: 1em;
  height: 1em;
}
.icon-mail {
  width: 1.3em;
  height: 1em;
}
.icon-marker {
  width: 0.7em;
  height: 1em;
}
.icon-meal {
  width: 1em;
  height: 1em;
}
.icon-menu-advice {
  width: 0.9em;
  height: 1em;
}
.icon-menu-cost {
  width: 1.2em;
  height: 1em;
}
.icon-menu-date {
  width: 0.9em;
  height: 1em;
}
.icon-menu-events {
  width: 1.1em;
  height: 1em;
}
.icon-menu-hotels {
  width: 1em;
  height: 1em;
}
.icon-menu-inspiration {
  width: 1em;
  height: 1em;
}
.icon-menu-places {
  width: 1.1em;
  height: 1em;
}
.icon-menu-tick {
  width: 1.4em;
  height: 1em;
}
.icon-menu-tickets {
  width: 1.3em;
  height: 1em;
}
.icon-menu-transport {
  width: 1.4em;
  height: 1em;
}
.icon-menu-visa {
  width: 0.7em;
  height: 1em;
}
.icon-menu {
  width: 1.4em;
  height: 1em;
}
.icon-minus-thin {
  width: 15em;
  height: 1em;
}
.icon-money-new {
  width: 1.3em;
  height: 1em;
}
.icon-money {
  width: 1.2em;
  height: 1em;
}
.icon-new-ambry {
  width: 1.2em;
  height: 1em;
}
.icon-new-arrow-down {
  width: 1.5em;
  height: 1em;
}
.icon-new-arrow-left_pressme {
  width: 3.4em;
  height: 1em;
}
.icon-new-attention {
  width: 1.2em;
  height: 1em;
}
.icon-new-barcode {
  width: 5.9em;
  height: 1em;
}
.icon-new-basket {
  width: 1em;
  height: 1em;
}
.icon-new-beer {
  width: 1.1em;
  height: 1em;
}
.icon-new-blog {
  width: 1em;
  height: 1em;
}
.icon-new-bookmarks {
  width: 0.9em;
  height: 1em;
}
.icon-new-brain {
  width: 0.9em;
  height: 1em;
}
.icon-new-butter {
  width: 1.4em;
  height: 1em;
}
.icon-new-butter4 {
  width: 1.2em;
  height: 1em;
}
.icon-new-calendar-heart {
  width: 1em;
  height: 1em;
}
.icon-new-cite {
  width: 0.7em;
  height: 1em;
}
.icon-new-clock {
  width: 1em;
  height: 1em;
}
.icon-new-close {
  width: 1em;
  height: 1em;
}
.icon-new-dynpacks {
  width: 1em;
  height: 1em;
}
.icon-new-excursions {
  width: 1em;
  height: 1em;
}
.icon-new-eye-thin {
  width: 1.5em;
  height: 1em;
}
.icon-new-eye {
  width: 1.7em;
  height: 1em;
}
.icon-new-facebook {
  width: 0.6em;
  height: 1em;
}
.icon-new-fav {
  width: 1em;
  height: 1em;
}
.icon-new-flights {
  width: 1.1em;
  height: 1em;
}
.icon-new-google {
  width: 1em;
  height: 1em;
}
.icon-new-graph {
  width: 0.9em;
  height: 1em;
}
.icon-new-guidebooks {
  width: 1em;
  height: 1em;
}
.icon-new-help {
  width: 1em;
  height: 1em;
}
.icon-new-hotels {
  width: 1.2em;
  height: 1em;
}
.icon-new-instagram {
  width: 1em;
  height: 1em;
}
.icon-new-lock {
  width: 0.9em;
  height: 1em;
}
.icon-new-login {
  width: 1em;
  height: 1em;
}
.icon-new-off {
  width: 1em;
  height: 1em;
}
.icon-new-papers {
  width: 1em;
  height: 1em;
}
.icon-new-pig {
  width: 1em;
  height: 1em;
}
.icon-new-plane {
  width: 1em;
  height: 1em;
}
.icon-new-ringer {
  width: 1.2em;
  height: 1em;
}
.icon-new-search-no {
  width: 1em;
  height: 1em;
}
.icon-new-settings {
  width: 1em;
  height: 1em;
}
.icon-new-share {
  width: 1em;
  height: 1em;
}
.icon-new-thumbs {
  width: 1.2em;
  height: 1em;
}
.icon-new-thumbs2 {
  width: 1.2em;
  height: 1em;
}
.icon-new-ticket {
  width: 1.6em;
  height: 1em;
}
.icon-new-tickets {
  width: 1.4em;
  height: 1em;
}
.icon-new-timer {
  width: 1em;
  height: 1em;
}
.icon-new-triangle {
  width: 1.8em;
  height: 1em;
}
.icon-new-twitter {
  width: 1.3em;
  height: 1em;
}
.icon-new-youtube {
  width: 1.5em;
  height: 1em;
}
.icon-next {
  width: 0.6em;
  height: 1em;
}
.icon-no-wifi {
  width: 1.3em;
  height: 1em;
}
.icon-nosmoking {
  width: 1em;
  height: 1em;
}
.icon-notific {
  width: 1em;
  height: 1em;
}
.icon-old-session {
  width: 1.5em;
  height: 1em;
}
.icon-parking {
  width: 1em;
  height: 1em;
}
.icon-pencil {
  width: 1em;
  height: 1em;
}
.icon-people {
  width: 0.5em;
  height: 1em;
}
.icon-pin {
  width: 0.8em;
  height: 1em;
}
.icon-plane-top-left {
  width: 1em;
  height: 1em;
}
.icon-plane {
  width: 1em;
  height: 1em;
}
.icon-play {
  width: 1em;
  height: 1em;
}
.icon-plus-thin {
  width: 1em;
  height: 1em;
}
.icon-plus {
  width: 1em;
  height: 1em;
}
.icon-point {
  width: 0.8em;
  height: 1em;
}
.icon-prefix {
  width: 0.6em;
  height: 1em;
}
.icon-prev {
  width: 0.6em;
  height: 1em;
}
.icon-question {
  width: 0.7em;
  height: 1em;
}
.icon-return-cash {
  width: 1em;
  height: 1em;
}
.icon-return-time-new {
  width: 1.1em;
  height: 1em;
}
.icon-return-time {
  width: 1.2em;
  height: 1em;
}
.icon-route {
  width: 1em;
  height: 1em;
}
.icon-s-arrow-left {
  width: 0.3em;
  height: 1em;
}
.icon-s-arrow-right {
  width: 0.3em;
  height: 1em;
}
.icon-s-avia-hotel {
  width: 3.1em;
  height: 1em;
}
.icon-s-avia {
  width: 1em;
  height: 1em;
}
.icon-s-citybreak {
  width: 1em;
  height: 1em;
}
.icon-s-destination {
  width: 2.4em;
  height: 1em;
}
.icon-s-exotic {
  width: 1.1em;
  height: 1em;
}
.icon-s-hotel {
  width: 1.4em;
  height: 1em;
}
.icon-s-sea {
  width: 1em;
  height: 1em;
}
.icon-s-sightseeing {
  width: 0.8em;
  height: 1em;
}
.icon-s-ski {
  width: 1.7em;
  height: 1em;
}
.icon-s-spa {
  width: 1.4em;
  height: 1em;
}
.icon-screamer {
  width: 0.3em;
  height: 1em;
}
.icon-sea-beach {
  width: 1em;
  height: 1em;
}
.icon-sea-bill {
  width: 1em;
  height: 1em;
}
.icon-sea-enter {
  width: 1em;
  height: 1em;
}
.icon-sea-sand {
  width: 1em;
  height: 1em;
}
.icon-sea-security {
  width: 1em;
  height: 1em;
}
.icon-sea-temperature {
  width: 1em;
  height: 1em;
}
.icon-sea-waves {
  width: 1em;
  height: 1em;
}
.icon-search {
  width: 1em;
  height: 1em;
}
.icon-seat {
  width: 0.9em;
  height: 1em;
}
.icon-seat2 {
  width: 0.9em;
  height: 1em;
}
.icon-set {
  width: 1em;
  height: 1em;
}
.icon-settings {
  width: 1em;
  height: 1em;
}
.icon-share-lg {
  width: 1.3em;
  height: 1em;
}
.icon-share {
  width: 1.2em;
  height: 1em;
}
.icon-sightseeing-bill {
  width: 1em;
  height: 1em;
}
.icon-sightseeing-counter {
  width: 1em;
  height: 1em;
}
.icon-sightseeing-cup {
  width: 1em;
  height: 1em;
}
.icon-sightseeing-museum {
  width: 1em;
  height: 1em;
}
.icon-sightseeing-route {
  width: 1em;
  height: 1em;
}
.icon-sightseeing-transport {
  width: 1em;
  height: 1em;
}
.icon-simple {
  width: 1.1em;
  height: 1em;
}
.icon-small-star-empty {
  width: 1em;
  height: 1em;
}
.icon-small-star-half {
  width: 1em;
  height: 1em;
}
.icon-smile-1 {
  width: 1.1em;
  height: 1em;
}
.icon-smile-2 {
  width: 1.2em;
  height: 1em;
}
.icon-smile-3 {
  width: 1.2em;
  height: 1em;
}
.icon-smile-4 {
  width: 1.4em;
  height: 1em;
}
.icon-smile-5 {
  width: 1.2em;
  height: 1em;
}
.icon-smile-6 {
  width: 1.3em;
  height: 1em;
}
.icon-snow-height {
  width: 1em;
  height: 1em;
}
.icon-snow-instructor {
  width: 1em;
  height: 1em;
}
.icon-snow-lift {
  width: 1em;
  height: 1em;
}
.icon-snow-pass {
  width: 1em;
  height: 1em;
}
.icon-snow-track {
  width: 1em;
  height: 1em;
}
.icon-snow-tracks {
  width: 1em;
  height: 1em;
}
.icon-social-edit {
  width: 1em;
  height: 1em;
}
.icon-social-facebook {
  width: 1em;
  height: 1em;
}
.icon-social-googleplus {
  width: 1em;
  height: 1em;
}
.icon-social-instagram {
  width: 1em;
  height: 1em;
}
.icon-social-rss {
  width: 1em;
  height: 1em;
}
.icon-social-telegram {
  width: 1em;
  height: 1em;
}
.icon-social-thin-facebook {
  width: 1em;
  height: 1em;
}
.icon-social-thin-instagram {
  width: 1em;
  height: 1em;
}
.icon-social-thin-telegram {
  width: 1.2em;
  height: 1em;
}
.icon-social-thin-youtube {
  width: 1.5em;
  height: 1em;
}
.icon-social-twitter {
  width: 1em;
  height: 1em;
}
.icon-social-youtube {
  width: 1em;
  height: 1em;
}
.icon-sort {
  width: 1em;
  height: 1em;
}
.icon-spa-bill {
  width: 1em;
  height: 1em;
}
.icon-spa-counter {
  width: 1em;
  height: 1em;
}
.icon-spa-mud {
  width: 1em;
  height: 1em;
}
.icon-spa-source {
  width: 1em;
  height: 1em;
}
.icon-spa-temperature {
  width: 1em;
  height: 1em;
}
.icon-spa-water {
  width: 1em;
  height: 1em;
}
.icon-star-empty {
  width: 1em;
  height: 1em;
}
.icon-star {
  width: 1em;
  height: 1em;
}
.icon-success {
  width: 1em;
  height: 1em;
}
.icon-superdeal {
  width: 1em;
  height: 1em;
}
.icon-swimming {
  width: 1.2em;
  height: 1em;
}
.icon-tape-left {
  width: 1.4em;
  height: 1em;
}
.icon-taxi {
  width: 1.2em;
  height: 1em;
}
.icon-telegram-new {
  width: 1em;
  height: 1em;
}
.icon-telegram {
  width: 1.2em;
  height: 1em;
}
.icon-thumbs {
  width: 0.9em;
  height: 1em;
}
.icon-tick-sm {
  width: 1.2em;
  height: 1em;
}
.icon-tick {
  width: 1.4em;
  height: 1em;
}
.icon-tickets {
  width: 1.7em;
  height: 1em;
}
.icon-time {
  width: 1em;
  height: 1em;
}
.icon-tmd-icon {
  width: 0.9em;
  height: 1em;
}
.icon-tmd {
  width: 2.9em;
  height: 1em;
}
.icon-total-flight {
  width: 1.2em;
  height: 1em;
}
.icon-total-hotel {
  width: 1.5em;
  height: 1em;
}
.icon-train {
  width: 0.8em;
  height: 1em;
}
.icon-twitter {
  width: 1.3em;
  height: 1em;
}
.icon-upload {
  width: 1em;
  height: 1em;
}
.icon-video {
  width: 1.4em;
  height: 1em;
}
.icon-vkontakte {
  width: 1.8em;
  height: 1em;
}
.icon-wait-graph {
  width: 1em;
  height: 1em;
}
.icon-weather-cloud {
  width: 1.4em;
  height: 1em;
}
.icon-weather-haze {
  width: 1.2em;
  height: 1em;
}
.icon-weather-rain-snow {
  width: 1.2em;
  height: 1em;
}
.icon-weather-rain {
  width: 1.2em;
  height: 1em;
}
.icon-weather-snow {
  width: 1.2em;
  height: 1em;
}
.icon-weather-sun-half {
  width: 1.4em;
  height: 1em;
}
.icon-weather-sun {
  width: 1em;
  height: 1em;
}
.icon-weather-wind {
  width: 1.6em;
  height: 1em;
}
.icon-why-1 {
  width: 1.1em;
  height: 1em;
}
.icon-why-2 {
  width: 1.1em;
  height: 1em;
}
.icon-why-3 {
  width: 1.3em;
  height: 1em;
}
.icon-why-inspiration {
  width: 1em;
  height: 1em;
}
.icon-why-money {
  width: 1.2em;
  height: 1em;
}
.icon-why-time {
  width: 1.5em;
  height: 1em;
}
.icon-wifi {
  width: 1.3em;
  height: 1em;
}
.icon-zero {
  width: 1.1em;
  height: 1em;
}
