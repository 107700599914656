// styles for header
.header {
	width: 100%;
	min-width: 320px;
	height: 50px;
	background: #FFF;
	transition: box-shadow 200ms;
	z-index: 99;
	&.is-shadow {
		box-shadow: 0 0 5px rgba(0,0,0,.3);
		&.sticky {
			box-shadow: none; } }
	@include r(1023) {
		display: none; }
	&__logo {
		float: left;
		margin: 16px 33px 0 20px;
		font-size: 0;
		@include svg(logo,181,19); }
	&__nav {
		float: left;
		&-link {
			float: left;
			padding: 0 15px;
			line-height: 50px;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 500;
			color: $cl;
			transition: background .2s, color .2s;
			&:hover {
				background: #f5f5f5; }
			&.active {
				background: #e9e9e9;
				cursor: default; } } }
	&__settings {
		float: right; }
	&__select {
		position: relative;
		float: left;
		font-weight: 500;
		&-head {
			display: table-cell;
			height: 50px;
			padding: 0 15px 0 20px;
			transition: background .2s;
			vertical-align: middle;
			cursor: default;
			&:before {
				content: "";
				position: absolute;
				top: 42px;
				left: 50%;
				margin-left: -8px;
				@include arr(16,8,#fff,t);
				visibility: hidden;
				opacity: 0;
				transition: visibility .2s, opacity .2s; }
			&:after {
				content: "";
				display: inline-block;
				position: relative;
				top: -1px;
				margin-left: 4px;
				@include arr(7,4,#000,b);
				vertical-align: middle; } }
		&-list {
			position: absolute;
			top: 100%;
			right: 0;
			min-width: 100%;
			padding: 10px 0;
			background: #FFF;
			border-radius: 3px;
			box-shadow: 0 3px 5px rgba(#000,.34);
			white-space: nowrap;
			visibility: hidden;
			opacity: 0;
			transition: visibility .2s, opacity .2s; }
		&-item {
			padding: 6px 20px;
			transition: background .2s;
			cursor: pointer;
			overflow: hidden;
			&.active,
			&:hover {
				background: #E1E1E1; } }
		&_last &-list {
			right: 10px; }
		&:hover &-head {
			background: #E1E1E1;
			&:before {
				visibility: visible;
				opacity: 1; } }
		&:hover &-list {
			visibility: visible;
			opacity: 1; } } }

.m-header {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	min-width: 320px;
	height: 44px;
	background: #FFF;
	transition: box-shadow 200ms;
	z-index: 99;
	transition: z-index .1s .4s;
	&.is-shadow {
		box-shadow: 0 0 5px rgba(0,0,0,.3);
		&.sticky {
			box-shadow: none; } }
	@include r(1023) {
		display: block; }
	&__btn {
		position: relative;
		float: right;
		width: 44px;
		height: 44px;
		padding: 5px;
		font-size: 0;
		color: #868686;
		&:first-child {
			float: left; }
		&.active {
			box-shadow: 0 14px 40px rgba(0, 0, 0, 0.34);
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: #FFF;
				z-index: 2; } }
		.icon {
			position: relative;
			z-index: 4; }
		.icon-settings {
			font-size: 22px; }
		.icon-menu {
			font-size: 16px; } }
	&__logo {
		display: table;
		margin: 11px auto 0;
		font-size: 0;
		color: #fff;
		@include r(767) {
			margin: 15px auto 0; }
		&-color {
			width: 212px;
			height: 22px;
			background: url(../img/logo.svg) no-repeat 50% 50% / 100% 100%;
			@include r(767) {
				width: 145px;
				height: 15px; } }
		.icon {
			font-size: 16px; } }
	&__drop {
		display: none;
		position: absolute;
		top: 44px;
		left: 0;
		right: 0;
		background: #fff;
		box-shadow: 0 14px 40px rgba(0, 0, 0, 0.34); }
	&__group {
		border-bottom: 1px solid #ededed;
		&:last-child {
			border: none; } }

	&__nav {
		text-align: center;
		&-link {
			display: block;
			padding: 12px 20px;
			font-size: 0;
			color: $cl;
			&:first-child {
				padding-top: 25px; }
			&:last-child {
				padding-bottom: 25px; } }
		&-link.active {
			color: $orange; }
		&-link.active &-icon {
			opacity: 1; }
		&-title,
		&-icon {
			display: inline-block;
			vertical-align: middle; }
		&-title {
			font-size: 15px;
			font-weight: 500;
			transition: color .2s; }
		&-icon {
			width: 25px;
			text-align: right;
			opacity: 0;
			transition: opacity .2s;
			.icon {
				font-size: 10px; } } }
	&__settings {
		width: 280px;
		margin: 0 auto;
		&-item {
			display: block;
			padding: 12px 0;
			&:first-child {
				padding-top: 25px; }
			&:last-child {
				padding-bottom: 25px; } }
		&-input {
			display: none; }
		&-input:checked + &-wrap &-icon {
			opacity: 1; }
		&-input:checked + &-wrap &-type,
		&-input:checked + &-wrap &-desc {
			color: $orange; }
		&-wrap {
			display: table;
			width: 100%; }
		&-type,
		&-desc,
		&-icon {
			display: table-cell;
			color: $cl;
			vertical-align: middle; }
		&-type,
		&-desc {
			font-weight: 500;
			transition: color .2s; }
		&-type {
			font-size: 15px;
			font-weight: 500;
			text-transform: uppercase; }
		&-desc {
			font-weight: 500;
			color: #868686; }
		&-desc,
		&-icon {
			text-align: right; }
		&-icon {
			width: 25px;
			opacity: 0;
			transition: opacity .2s;
			color: $orange;
			.icon {
				font-size: 10px; } }
		&-foot {
			padding: 15px 20px;
			text-align: center; }
		&-btn {
			min-width: 160px; } } }
.m-header__btn.wind-header-drop-menu-btn.open {
	z-index: 1;
	background-color: #fff; }

.header-new {
	//position: fixed
	width: 100%;
	top: 0;
	z-index: 14;
	background-color: #0c4ead;
	color: #ffffff;
	min-width: 320px;
	&.scrolled,
	&.header-new_single {
		.header-new__link_pda {
			@include r(1023) {
				display: inline-block;
				vertical-align: middle; } }
		.header-new__link_help {
			.scrolled {
				display: none; }
			@include r(767) {
				display: none; } }
		.header-new__link_language {
			.no-scrolled {
				display: none; }
			.scrolled {
				display: inline;
				vertical-align: middle;
				@include r(374) {
					display: none; } } }
		.header-new__menu {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			visibility: visible!important;
			opacity: 1!important; }
		&.header-new_first {
			.header-new__menu {
				@include r(1023) {
					display: none; } } }
		.flash {
			animation: inherit; } }
	&__logo {
		display: inline-block;
		vertical-align: middle;
		height: 50px;
		line-height: 50px;
		color: #ffffff;
		@include r(767) {
			padding-left: 15px; }
		img {
			width: 141px;
			vertical-align: middle;
			@include r(767) {
				width: 115px; } } }
	&__center {
		margin: 0 auto;
		padding: 0 20px;
		@include r(767) {
			padding: 0; } }
	&__menu {
		display: none;
		vertical-align: middle;
		margin-left: 20px;
		li {
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			&:hover,
			&:active,
			&.active {
				.header-new__menu-drop {
					display: block; } }
			&:last-child {
				.header-new__menu-link {
					padding-right: 40px; } } }
		@include r(1023) {
			display: none;
			width: 100%;
			margin-left: 0; }
		&-link {
			padding: 15px 13px;
			position: relative;
			color: #ffffff;
			font-size: 14px;
			font-weight: 500;
			height: 50px;
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			@include r(767) {
				padding: 15px 10px; }
			span {
				position: relative;
				vertical-align: middle; }
			sup {
				font-size: 11px;
				line-height: 13px;
				font-weight: 500;
				color: $red;
				position: absolute; }
			&.active {
				span {
					border-bottom: 5px solid #F37D20;
					@include r(767) {
						border-bottom: none; } } } }
		&-drop {
			display: none;
			position: absolute;
			background: #ffffff;
			color: $cl;
			border-radius: 6px;
			box-shadow: 0 7px 20px rgba(0,0,0,.35);
			padding: 10px 0;
			border-top: 1px solid #ededed;
			margin-left: -10px;
			.header-new__menu-link {
				width: 100%;
				text-align: left;
				padding: 0 20px;
				white-space: nowrap;
				height: 40px;
				line-height: 40px;
				color: $cl;
				svg {
					position: absolute;
					left: 15px;
					top: 50%;
					margin-top: -8px; }
				span {
					border-bottom: none!important; } }
			li {
				display: block;
				&:hover,
				&:active,
				&.active {
					background-color: #f5f8fa; } } } }
	&__link {
		display: inline-block;
		vertical-align: middle;
		height: 50px;
		line-height: 50px;
		text-align: center;
		color: #ffffff;
		font-size: 14px;
		padding: 0 10px;
		font-weight: 500;
		position: relative;
		span {
			vertical-align: middle; }
		@include r(767) {
			padding: 0 5px; }
		&-group {
			float: right;
			position: relative;
			display: inline-table;
			.header-new__link-item {
				display: table-cell;
				vertical-align: middle;
				.header-new__link {
					padding: 0 10px!important; } }
			.header-new__link {
				&:last-child {
					padding-right: 0; } } }
		&_login {
			.header-new__link {
				&-avatar-bg {
					display: inline-block;
					vertical-align: middle; }
				&-username {
					display: inline-block;
					vertical-align: middle;
					@include r(767) {
						display: none; } }
				&-text {
					display: none; } } }
		&_help {
			@include r(767) {
				display: none; } }
		&_language {
			.no-scrolled {
				@include r(767) {
					display: none; } }
			.scrolled {
				display: none;
				@include r(767) {
					display: inline-block;
					vertical-align: middle; }
				@include r(374) {
					display: none; } }
			.mob {
				display: none;
				@include r(374) {
					display: inline-block;
					vertical-align: middle; } } }
		&_pda {
			display: none;
			@include r(767) {
				display: inline-block;
				float: right;
				padding-right: 15px; }
			span {
				@include r(767) {
					display: none; } }
			.header-new__link-icon {
				margin-right: 5px;
				@include r(767) {
					margin-right: 0; }
				svg {
					vertical-align: middle; } } }
		&_alarm {
			.header-new__link-icon {
				margin-right: 0; }
			@include r(767) {
				display: none; } }
		&-container {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			margin-right: -10px;
			@include r(767) {
				margin-right: 0; }
			&_login {
				.header-new__link-icon {
					margin-right: 0; }
				&:hover,
				&:active,
				&.active {
					.header-new__menu-drop {
						display: block;
						@include r(767) {
							display: none; } } } }
			.line {
				height: 1px;
				background-color: #F5F5F5;
				margin: 10px 0; }
			svg {
				font-size: 20px;
				vertical-align: middle; }
			.icon-new-ambry {
				font-size: 17px; }
			.icon-new-ticket {
				font-size: 14px;
				margin-top: -6px!important; }
			.icon-new-ringer {
				font-size: 19px; }
			.header-new__menu-drop {
				right: 10px; }
			.header-new__menu-link {
				padding: 0 50px;
				position: relative;
				.header-new__notification {
					top: 15px;
					margin-left: 0;
					left: inherit;
					right: 15px; } } }
		&-avatar {
			position: absolute;
			top: 2px;
			left: 2px;
			width: 26px;
			height: 26px;
			border-radius: 26px;
			background-position: center;
			background-size: cover;
			&-bg {
				display: none;
				position: relative;
				vertical-align: middle;
				width: 30px;
				height: 30px;
				border-radius: 30px;
				margin-right: 5px;
				background-color: #DCDCDC;
				@include r(767) {
					margin-right: 0; } } }
		&-flag {
			display: inline-block;
			vertical-align: middle;
			height: 16px;
			width: 22px;
			border-radius: 1px;
			background-position: center;
			background-size: cover;
			margin-right: 5px; }
		&-icon {
			font-size: 20px;
			line-height: 20px;
			display: inline-block;
			vertical-align: middle;
			position: relative;
			margin-right: 10px;
			@include r(767) {
				margin-right: 0; }
			svg {
				position: relative;
				vertical-align: middle; }
			.icon-new-butter {
				font-size: 13px;
				margin-left: 10px;
				position: relative; } }
		&-username {
			display: none; }
		&-text {
			vertical-align: middle;
			@include r(767) {
				display: none; } } }
	&__notification {
		position: absolute;
		height: 15px;
		line-height: 15px;
		border-radius: 15px;
		font-size: 10px;
		font-weight: 700;
		color: #FFFFFF;
		padding: 0 5px;
		background-color: #FF2F00;
		box-shadow: 0 0 0 1px #ffffff;
		left: 100%;
		margin-left: -10px;
		top: -4px;
		z-index: 1; }
	&__menu-social {
		display: inline-block;
		width: 60px;
		height: 30px;
		line-height: 30px;
		border-radius: 3px;
		background-color: #527FA3;
		margin-right: 10px;
		font-size: 15px;
		text-align: center;
		vertical-align: middle;
		color: #ffffff;
		svg {
			vertical-align: middle; }
		&_facebook {
			background-color: #3A5BA0; }
		&_twitter {
			background-color: #2CA7E0; }
		&_google {
			background-color: #E13719; }
		&-links {
			font-size: 0;
			padding: 15px 20px; }
		&:last-child {
			margin-right: 0; } }
	&.header-new_first {
		.header-new__menu {
			opacity: 0;
			visibility: hidden;
			transition: visibility .15s .0s, opacity .15s .0s;
			position: absolute;
			display: inline-block;
			vertical-align: middle; } }
	&.header-new_second {
		position: relative;
		border: 0;
		z-index: 12;
		@include r(767) {
			background: rgba(0,0,0,.3);
			opacity: 0;
			visibility: hidden;
			transition: visibility .4s 0s, opacity .4s 0s;
			overflow: auto;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 99; }
		.header-new__center {
			@include r(767) {
				transition: transform .4s .0s;
				transform: translateX(100%);
				background: #ffffff;
				width: 320px;
				position: absolute;
				right: 0;
				z-index: 999;
				height: 100%; } }
		.header-new__menu {
			display: table;
			margin-left: -20px;
			@include r(1023) {
				margin-left: 0; }
			&-group {
				display: none;
				border-top: 1px solid #EDEDED;
				border-bottom: 1px solid #EDEDED;
				&+.header-new__menu-group {
					border-top: none; }
				@include r(767) {
					display: block;
					padding: 10px 0; }
				&:last-child {
					border-bottom: none; } } }
		.header-new__menu-link {
			padding: 15px 20px;
			@include r(1023) {
				padding: 15px 5px;
				display: table-cell; }
			@include r(767) {
				display: block;
				width: 100%;
				padding: 0 20px;
				padding-left: 60px;
				text-align: left;
				margin-top: 0;
				height: 40px;
				line-height: 40px;
				color: $cl;
				.header-new__notification {
					top: 15px;
					right: 15px;
					left: inherit; } }
			&:hover,
			&:active,
			&.active {
				@include r(767) {
					background-color: #f5f5f5; } }
			svg {
				display: none;
				font-size: 20px;
				vertical-align: middle;
				position: absolute;
				left: 20px;
				top: 12px;
				&.icon-new-ticket {
					font-size: 14px;
					top: 14px; }
				&.icon-new-ringer {
					left: 19px; }
				&.icon-new-brain {
					left: 21px; }
				&.icon-new-tickets,
				&.icon-new-hotels {
					font-size: 16px; }
				&.icon-new-excursions,
				&.icon-new-ringer {
					font-size: 19px; }
				&.icon-new-ambry {
					font-size: 17px; }
				@include r(767) {
					display: inline-block; } }
			&.close {
				text-transform: uppercase;
				font-weight: 700;
				text-align: right;
				color: #3D7CDA;
				font-size: 15px;
				height: 51px;
				line-height: 51px;
				svg {
					position: static;
					font-size: 12px;
					vertical-align: middle;
					margin-right: 0;
					color: #3D7CDA; } } }
		&.header-new_single {
			display: none;
			@include r(1023) {
				display: block; }
			.header-new__menu {
				@include r(1023) {
					display: table; } } }
		&.open {
			@include r(767) {
				transition-delay: 0s;
				visibility: visible;
				opacity: 1;
				text-align: left;
				background: rgba(#000,.3);
				overflow: auto;
				margin-top: 0;
				.header-new__center {
					transform: translateX(0); } } } }
	&_full {
		.header-new__menu {
			margin-left: 15px; }
		@include rm(768) {
			.header-new__center {
				max-width: 100%;
				padding: 0 20px; }
			.header-new__menu-drop {
				right: 10px; } } }
	&_cpc {
		@include r(767) {
			.header-new__menu,
			.header-new__link-group {
				@include r(767) {
					display: none!important; } }
			.header-new__logo {
				position: absolute;
				right: 50px;
				left: 50px;
				text-align: center;
				padding: 0; }
			.header-new__link {
				padding: 0;
				width: 50px; }
			.header-new__link-icon {
				vertical-align: baseline;
				svg {
					margin: 0; } } } } }
.flash {
	@include rm(768) {
		animation: flash .15s ease-in-out; } }

@keyframes flash {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }
