//.more
.more {
    position: relative;
    padding: 5px 0;
    text-align: center;
    &__btn {
        min-width: 210px;
        font-size: 15px;
        transition: color .2s; }
    &.processing &__btn {
        opacity: 0;
        pointer-events: none;
        cursor: default; }
    &.processing &__spinner {
        opacity: 1; } }


//.spinner
.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(#fff,0);
    opacity: 0;
    transform: translate(-50%,-50%);
    transition: opacity .2s;
    animation: spinner 1s .2s infinite;
    &.active {
        opacity: 1; }
    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: rgba(#fff,.3); }
    &:before {
        left: -20px;
        animation: spinner 1s 0s infinite; }
    &:after {
        right: -20px;
        animation: spinner 1s .4s infinite; }
    &_pink {
        background: rgba($orange,0);
        animation: spinner-pink 1s .2s infinite;
        &:before,
        &:after {
            background: rgba($orange,.3); }
        &:before {
            animation: spinner-pink 1s 0s infinite; }
        &:after {
            animation: spinner-pink 1s .4s infinite; } }
    &_colours {
        background: rgba(#3D7CDA,0);
        animation: spinner-blue 1s .2s infinite;
        &:before {
            background: rgba(#004B85,.3);
            animation: spinner-dark-blue 1s 0s infinite; }
        &:after {
            background: rgba($orange,.3);
            animation: spinner-orange 1s .4s infinite; } } }

@keyframes spinner {
    0%, 100% {
        background: rgba(#fff,.3); }
    66.66% {
        background: rgba(#fff,1); } }

@keyframes spinner-pink {
    0%, 100% {
        background: rgba($orange,.3); }
    66.66% {
        background: rgba($orange,1); } }

@keyframes spinner-dark-blue {
    0%, 100% {
        background: rgba(#004B85,.3); }
    66.66% {
        background: rgba(#004B85,1); } }

@keyframes spinner-blue {
    0%, 100% {
        background: rgba(#3D7CDA,.3); }
    66.66% {
        background: rgba(#3D7CDA,1); } }

@keyframes spinner-orange {
    0%, 100% {
        background: rgba($orange,.3); }
    66.66% {
        background: rgba($orange,1); } }


@keyframes loadingFade {
    0% {
        opacity: 0.2; }
    50% {
        opacity: 1; }
    100% {
        opacity: 0.2; } }
