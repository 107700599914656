@import "./components/site-label";
@import "./components/bookimed-call";

.highcharts-credits {
	opacity: 0; }

.highcharts-container {
	width: 100% !important; }

.section {
	position: relative;
	padding: 60px 0;
	@include r(1024) {
		padding: 50px 0; }
	@include r(767) {
		padding: 40px 0; }
	&.polygon-footer {
		padding: 100px 0 80px;
		@include r(1023) {
			padding: 80px 0 60px; }
		@include r(767) {
			padding: 60px 0 40px; }
		.bookimed-call {
			border-radius: 13px;
			@include rm(1024) {
				margin-top: 65px; } } }
	&_hotels-subscribe {
		padding: 65px 0!important;
		margin-top: 50px;
		@include r(767) {
			margin-top: 20px!important;
			padding: 5px 0 35px!important; }
		.subtitle {
			margin-bottom: 10px; } }
	&__content {
		h2 {
			font-size: 20px;
			color: $cl;
			margin-bottom: 0; }
		h3 {
			font-size: 18px;
			color: $cl;
			margin-bottom: 0; }
		h4 {
			font-size: 16px;
			color: $cl;
			margin-bottom: 0; }
		p {
			font-size: 14px;
			line-height: 16px;
			color: $cl;
			margin-top: 10px;
			text-align: justify; }
		ul,
		ol {
			font-size: 16px;
			line-height: 19px;
			padding-left: 20px;
			list-style-type: inherit;
			margin-top: 10px;
			li {
				font-size: 14px; } }
		ol {
			list-style-type: decimal; } }
	&__map {
		height: 300px; }
	&__body {
		&_mb-50 {
			margin-bottom: 50px; }
		&_mb-80 {
			margin-bottom: 80px; } }
	&_mt {
		margin-top: 65px;
		@include r(1023) {
			margin-top: 50px; }
		@include r(767) {
			margin-top: 40px; } }
	&_how {
		background: #2F6690; }
	&_directions {
		background: #243e57; }
	&_flights {
		background: #383a4e;
		.title {
			color: #fff; } }
	&_fresh {
		background: #fafafa; }
	&_directions &__title,
	&_how &__title {
		color: #FFF; }
	&_how &__title {
		padding: 0 50px; }
	&.section_white {
		background: #FFF; }
	&.section_gray {
		background: #edeff1;
		.why {
			.why__icon {
				background: #fff; } } }
	&_light {
		background: #F9F9F9; }
	&_black {
		background: #24252E;
		color: #AEAEAE;
		.section__title {
			color: #ffffff; } }
	&_nopadding {
		padding: 0!important;
		@include r(1023) {
			padding: 0!important; }
		@include r(767) {
			padding: 0!important; } }
	&_facebook {
		padding: 68px 0 60px;
		background-color: #3D7CDA; }
	&__title {
		margin-top: 0; } }


.why {
	&__list {
		margin-bottom: 60px;
		font-size: 0;
		text-align: center;
		@include r(767) {
			margin-bottom: 20px; } }
	&__item {
		display: inline-block;
		width: 30%;
		margin-left: 5%;
		vertical-align: top;
		margin-bottom: 20px;
		@include r(767) {
			width: 100%;
			margin: 0 0 30px; }
		&:first-child {
			margin-left: 0; } }
	&__icon {
		width: 150px;
		height: 150px;
		margin: 0 auto 15px;
		background: #f1f5f8;
		border-radius: 50%;
		@include vertical;
		color: #66C0FF;
		.icon {
			vertical-align: middle; }
		.icon-why-1 {
			font-size: 80px; }
		.icon-why-2 {
			font-size: 120px; }
		.icon-why-3 {
			font-size: 70px; } }
	&__title {
		margin-bottom: 12px;
		font-size: 15px;
		font-weight: 300; }
	&__more {
		height: 25px;
		margin-bottom: 25px;
		border-bottom: 1px solid #D8D8D8;
		text-align: center;
		&-btn {
			min-width: 260px;
			height: 50px;
			background: #3D7CDA;
			border-radius: 25px;
			@include r(767) {
				min-width: 1px; }
			&:hover,
			&:active {
				background: #3D7CDA; } } } }

.seo-text {
	font-size: 14px;
	line-height: 16px;
	color: $cl;
	column-count: 2;
	margin-top: 20px;
	@include r(767) {
		margin-top: 0;
		column-count: 1;
		padding: 0 10px; }
	p {
		@include r(767) {
			margin-top: 20px; } } }

.how {
	text-align: center;
	.center {
		@include r(1023) {
			padding: 0; } }
	@include r(767) {
		margin-top: 15px; }
	&__close {
		position: absolute;
		top: 30px;
		right: 30px;
		font-size: 0;
		color: #fff;
		@include r(767) {
			top: 10px;
			right: 10px; }
		.icon {
			font-size: 20px; } }
	&__row {
		font-size: 0; }
	&__col {
		display: inline-block;
		width: 33.33%;
		padding-left: 5%;
		vertical-align: top;
		@include r(767) {
			width: 100%;
			margin-top: 30px;
			padding: 0; }
		&:first-child {
			padding: 0;
			@include r(767) {
				margin: 0; }
			.how__holder {
				margin-left: 10px;
				@include r(767) {
					margin: 0 auto; } } } }
	&__preview {
		height: 230px;
		margin-bottom: 30px;
		@include r(1023) {
			height: 187px; }
		@include r(767) {
			height: auto; }
		&:before {
			content: '';
			display: inline-block;
			width: 0;
			height: 100%;
			vertical-align: bottom; } }
	&__img {
		max-width: 100%;
		vertical-align: bottom;
		@include r(1023) {
			max-width: 80%; }
		@include r(767) {
			max-width: 100%; } }
	&__text {
		font-size: 15px;
		color: #fff;
		@include r(767) {
			font-size: 13px;
			font-weight: 300; } }
	&__holder {
		max-width: 300px;
		margin: 0 auto; }
	&__text_black {
		color: #2F2F2F; } }

.fresh {
	&__types {
		margin-bottom: 30px;
		text-align: center;
		@include r(767) {
			text-align: left;
			margin-bottom: 15px; }
		&-link {
			display: inline-block;
			margin: 0 15px 10px;
			font-weight: 700;
			color: $cl;
			white-space: nowrap;
			@include r(767);

			&.is-active {
				border-bottom: 4px solid $orange; } } }
	&__table {
		&-body {
			display: table;
			width: 100%;
			border-top: 1px solid #eaeaea; }
		&-row {
			display: table-row;
			color: $cl;
			&:hover {
				background: #fff; } }
		&-cell {
			display: table-cell;
			padding: 13px 10px;
			border-bottom: 1px solid #eaeaea;
			white-space: nowrap;
			@include r(767) {
				padding-left: 0; }
			&:nth-child(1) {
				padding-left: 40px;
				white-space: normal;
				@include r(1023) {
					padding-left: 10px; }
				@include r(767) {
					padding-left: 0; } }
			&:nth-child(2) {
				width: 60px;
				text-align: center; }
			&:last-child {
				padding-right: 40px;
				font-size: 18px;
				font-weight: 700;
				color: $orange;
				text-align: right;
				@include r(1023) {
					padding-right: 10px; } } }
		&-logo {
			max-width: 40px; }
		&-foot {
			padding: 10px 40px;
			text-align: right; }
		&-more {
			margin-right: 11px;
			border-bottom: 1px dashed;
			&:after {
				content: '';
				display: inline-block;
				margin: 0 -11px 2px 3px;
				@include arr(8,4,$blue,b);
				vertical-align: middle; } } }
	&__btn {
		display: block;
		margin: 0 auto; } }

.directions {
	&__head {
		margin-bottom: 25px;
		text-align: center; }
	&__list {
		margin: 0 -5px 30px;
		font-size: 0;
		@include r(767) {
			margin-bottom: 20px; } }
	&__col {
		display: inline-block;
		width: 25%;
		margin-bottom: 20px;
		padding: 0 10px;
		vertical-align: top;
		@include r(1023) {
			width: 33.33%; }
		@include r(767) {
			width: 50%; }
		@include r(479) {
			width: 100%; } }
	&__item {
		display: block;
		position: relative;
		padding: 20% 0;
		background: #000;
		color: $cl;
		&_white {
			color: #fff; } }
	&__img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: .8; }
	&__info {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 20px;
		line-height: 1; }
	&__city {
		font-size: 22px;
		font-weight: 500; }
	&__country {
		font-size: 14px; }
	&__s {
		position: relative;
		max-width: 390px;
		margin: 0 auto;
		background: rgba(#3d566e,.3);
		border-radius: 3px;
		box-shadow: 0 0 79px rgba(0, 0, 0, 0.1);
		&-icon {
			position: absolute;
			top: 0;
			left: 0;
			width: 47px;
			height: 100%;
			@include vertical;
			.icon {
				font-size: 22px;
				color: #98adc2;
				vertical-align: middle; } }
		&-input {
			position: relative;
			width: 100%;
			height: 54px;
			padding: 0 15px 0 47px;
			background: none;
			color: #fff;
			font-size: 20px;
			font-weight: 300;
			z-index: 2;
			@include placeholder-color(#7091b1); } } }

.posts {
	position: relative;
	margin: 0 -10px;
	font-size: 0;
	@include r(767) {
		margin: 0 -20px; }
	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		width: 10px;
		z-index: 2; }
	&:before {
		left: 0;
		background: linear-gradient(to left, rgba(#fff,0), rgba(#fff,1)); }
	&:after {
		right: 0;
		background: linear-gradient(to left, rgba(#fff,1), rgba(#fff,0)); }
	&__col {
		padding: 0 10px;
		@include r(767) {
			padding: 0 20px; } }
	&__type {
		margin-bottom: 10px;
		@include clr;
		&-color {
			float: left;
			width: 11px;
			height: 11px;
			margin: 3px 6px 0 0; }
		&-title {
			display: table;
			font-size: 15px;
			font-weight: 700;
			text-transform: uppercase; } }
	&__pop {
		display: block;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-image: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.3) 100%); }
		&-img {
			display: block;
			width: 100%; }
		&-title {
			position: absolute;
			left: 20px;
			right: 20px;
			bottom: 20px;
			font-size: 21px;
			font-weight: 700;
			color: #FFF;
			line-height: 1.2;
			z-index: 2; } }
	&__list {
		padding: 17px 20px 0;
		@include r(767) {
			padding: 15px 0 0; } }
	&__link {
		display: block;
		padding: 10px 0;
		border-bottom: 1px solid $border;
		font-size: 15px;
		color: $cl; }
	&__more {
		display: block;
		padding-top: 15px;
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 700;
		vertical-align: top;
		color: $blue; }
	.slick-prev,
	.slick-next {
		position: absolute;
		top: 28%;
		width: 30px;
		height: 43px;
		margin-top: -22px;
		background-color: $gray;
		transition: background .2s;
		z-index: 4;
		@include r(767) {
			top: 25%; }
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 14px;
			height: 14px;
			margin-top: -7px;
			border: 2px solid #fff;
			border-width: 2px 2px 0 0;
			transition: border-color .2s; }
		&.slick-disabled {
			background: #ededed;
			cursor: default; } }
	.slick-prev {
		left: -40px;
		@include r(1279) {
			left: -10px; }
		@include r(767) {
			left: 0; }
		&:before {
			margin-left: -3px;
			transform: rotate(-135deg); } }
	.slick-next {
		right: -40px;
		@include r(1279) {
			right: -10px; }
		@include r(767) {
			right: 0; }
		&:before {
			margin-left: -11px;
			transform: rotate(45deg); } }
	.slick-dots {
		padding: 20px 0 0 0;
		text-align: center;
		li {
			display: inline-block;
			margin: 0 5px;
			vertical-align: top; }
		button {
			width: 11px;
			height: 11px;
			background-color: $gray;
			border-radius: 50%;
			transform: scale(.5);
			transition: scale .2s; }
		.slick-active {
			button {
				transform: scale(1); } } } }

.promo {
	background: url(../img/bg-video.jpg) no-repeat 50% 50% / cover;
	&__center {
		height: 450px;
		color: #FFF;
		@include vertical; }
	&__wrap {
		display: inline-block;
		padding-top: 25px;
		vertical-align: middle; }
	&__logo {
		margin-bottom: 9px;
		font-size: 0;
		.icon {
			font-size: 26px; } }
	&__text {
		margin-bottom: 37px;
		font-size: 28px;
		font-weight: 300; }
	&__btn {
		height: 55px;
		padding: 0 17px 0 18px;
		border: 3px solid white;
		border-radius: 55px;
		text-align: center;
		font-size: 0;
		color: #FFF;
		transition: border-color .2s, background .2s;
		&:hover {
			background: $blue-light;
			border-color: $blue-light; }
		&-title,
		&-icon {
			display: inline-block;
			vertical-align: middle; }
		&-title {
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase; }
		&-icon {
			margin-left: 6px;
			padding-bottom: 1px;
			.icon {
				font-size: 24px; } } } }

.soon {
	background: #eff0f1;
	&__container {
		display: table;
		width: 100%;
		table-layout: fixed; }
	&__preview,
	&__wrap {
		display: table-cell; }
	&__preview {
		width: 45%;
		padding: 60px 40px 0;
		font-size: 0;
		text-align: center;
		vertical-align: bottom;
		@include r(1023) {
			width: 40%;
			padding-left: 0; }
		@include r(767) {
			display: none; }
		&-img {
			max-width: 100%; } }
	&__wrap {
		padding: 30px 0;
		vertical-align: middle; }
	&__title,
	&__text {
		color: #8000C2; }
	&__title {
		font-size: 48px;
		font-weight: 700;
		line-height: 1;
		@include r(767) {
			font-size: 32px; } }
	&__text {
		margin-bottom: 11px;
		font-size: 36px;
		font-weight: 300;
		@include r(767) {
			font-size: 24px; } }
	&__label {
		margin-bottom: 20px;
		font-size: 15px; }
	&__form {
		position: relative;
		max-width: 450px;
		padding-right: 65px;
		font-size: 0; }
	&__input,
	&__btn {
		height: 54px;
		vertical-align: top;
		@include r(767) {
			height: 44px; } }
	&__input {
		width: 100%;
		padding: 0 15px;
		background: #fff;
		border-radius: 3px 0 0 3px;
		font-size: 15px;
		@include r(767) {
			font-size: 14px; } }
	&__btn {
		position: absolute;
		top: 0;
		right: 0;
		width: 65px;
		border-radius: 0 3px 3px 0;
		background: $red;
		font-size: 24px;
		font-weight: 700;
		color: #FFF;
		@include r(767) {
			font-size: 18px; } } }

.logos {
	padding: 30px 20px 10px;
	background: #f7f8f9;
	font-size: 0;
	text-align: center;
	&__item {
		display: inline-block;
		width: 16.66%;
		padding: 0 20px 20px;
		vertical-align: middle;
		@include r(1023) {
			width: 20%; }
		@include r(767) {
			width: 50%; } }
	&__pic {
		max-width: 100%; } }

.article {
	display: block;
	color: $cl;
	&:hover &__title {
		color: $blue; }
	&__preview {
		margin-bottom: 10px; }
	&__pic {
		display: block;
		width: 100%; }
	&__title {
		margin-bottom: 10px;
		font-size: 18px;
		font-weight: 500; }
	&__text {
		font-size: 14px; } }

.pop {
	&__item {
		display: table;
		margin-bottom: 10px;
		color: $cl; }
	&__item:hover,
	&__item:hover &__title {
		color: $blue; }
	&__title {
		color: #8A8A8A; }
	&__point {
		font-weight: 700; }
	&_cols {
		column-count: 2;
		column-gap: 20px; }
	&_cols &__item {
		display: inline-block;
		width: 100%;
		vertical-align: top; } }

.title {
	&_bold {
		font-weight: 500;
		margin-bottom: 10px;
		@include r(767) {
			font-size: 24px; } }
	&_superbold {
		font-weight: 700;
		margin-bottom: 5px;
		@include r(767) {
			font-size: 24px; } }
	&_mb {
		margin-bottom: 40px; }
	&_small-pda {
		@include r(1023) {
			font-size: 31px; } }
	&_cards {
		text-align: left;
		margin-bottom: 0; } }

.subtitle {
	margin: 0 0 28px;
	text-align: center;
	font-size: 18px;
	font-weight: 300;
	&_condensed {
		font-size: 18px;
		font-weight: 700;
		@include r(767) {
			font-size: 16px;
			margin-top: -10px; } }
	&_small {
		font-size: 15px; }
	&_norm {
		font-weight: 400; }
	&_bold {
		margin: 30px 0 15px;
		font-weight: 500; }
	&_left {
		text-align: left; }
	&_uppercase {
		text-transform: uppercase; }
	&+.sorter {
		margin-top: -10px; }
	@include r(767) {
		font-size: 15px; } }

.why {
	&_tmd {
		color: $cl;
		@include r(767) {
			margin-bottom: -35px; }
		.why__icon {
			width: 144px;
			height: 144px;
			color: #ff6700;
			background: #f3f3f3; }
		.icon-tmd-icon {
			font-size: 100px; }
		.icon-money {
			font-size: 84px; }
		.icon-inspiration {
			font-size: 98px; }
		.icon-zero {
			font-size: 79px; }
		.icon-simple {
			font-size: 120px; }
		.icon-money-new {
			font-size: 69px; }
		.why__item {
			@include r(767) {
				&:last-child {
					margin-bottom: 0; } } } }
	&__head {
		margin-bottom: 3px;
		font-size: 24px;
		font-weight: 500;
		@include r(767) {
			font-size: 18px; } }
	&__text {
		font-size: 15px;
		font-weight: 300;
		@include r(767) {
			font-size: 13px; } }
	&__list {
		margin-bottom: -15px;
		@include r(767) {
			margin-bottom: 0; } }
	.hr {
		margin: 60px auto 0; } }

.section {
	&__body {
		@include clr; }
	&__title {
		text-align: center;
		img {
			margin-bottom: 15px;
			max-width: 100%;
			height: auto; }
		@include r(767) {
			font-size: 30px; } }
	&__title-text {
		font-size: 18px;
		line-height: 21px;
		text-align: center;
		margin: 0 auto;
		max-width: 700px;
		@include r(767) {
			font-size: 16px;
			line-height: 19px; } }
	&_top {
		padding: 10px 0 30px;
		.breadcrumbs {
			margin-bottom: 10px; } }
	&_cover {
		padding: 40px 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		color: #fff; }
	&_dark {
		background: #454958;
		color: #fff;
		.press-more {
			padding-top: 5px;
			a {
				color: #fff;
				font-size: 18px;
				font-weight: 500; } } }
	&_fb {
		color: #000;
		background-size: cover;
		@include r(1023) {
			background-size: contain; } }
	&_subscribe {
		color: #fff; }
	&_gray {
		background: #eee; }
	&_lighten {
		background: #fbfbfb; }
	&_light {
		.press-more {
			a {
				color: #000; } } }
	&_addpadding {
		padding: 165px 0 125px;
		@include r(1023) {
			padding: 110px 0 120px; }
		@include r(767) {
			padding: 80px 0 100px; } }
	.fb-widget {
		margin: 45px auto 0;
		@include r(767) {
			margin-top: 0; } }
	.info {
		margin: 0 auto;
		max-width: 780px;
		&_wide {
			max-width: none; } }
	.btn {
		@include r(767) {
			width: 100%; } } }

.info {
	&_big {
		font-size: 15px;
		@include r(767) {
			font-size: 12px; }
		p {
			margin-bottom: 13px; }
		.info__more {
			color: #3D7CDA;
			text-align: center;
			&-title {
				font-size: 18px; } } }
	&__more-link {
		margin-top: 20px;
		display: inline-block;
		border-bottom: 1px solid;
		font-size: 18px;
		line-height: 1; } }

.stat {
	display: table;
	margin: 45px auto 0;
	width: 100%;
	max-width: 750px;
	text-align: center;
	@include r(767) {
		margin-top: -20px; }
	&__cell {
		position: relative;
		display: table-cell;
		vertical-align: top;
		border-left: 1px solid #D1D8DE;
		padding-bottom: 3px;
		padding-top: 20px;
		&:first-child {
			border: 0; }
		@include r(767) {
			display: block;
			width: 100%;
			border: 0;
			margin-top: 40px;
			&::before {
				content: '';
				display: block;
				position: absolute;
				left: 50%;
				margin-left: -60px;
				bottom: -10px;
				height: 1px;
				width: 120px;
				background: #D1D8DE; } }
		&:last-child {
			@include r(767) {
				&::before {
					display: none; } } } }
	&__number {
		margin-bottom: 15px;
		font-weight: 700;
		font-size: 50px;
		color: #ff6700;
		line-height: 24px; }
	&__info {
		font-size: 15px;
		span {
			display: block; }
		@include r(767) {
			font-size: 13px; } } }

.video-player {
	position: relative;
	margin: 0 auto;
	padding-bottom: 48%;
	padding-top: 25px;
	max-width: 960px;
	height: 0;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; }
	.video__holder {
		display: none; } }

.stars {
	color: #fff;
	font-size: 11px;
	display: inline-block;
	vertical-align: baseline; }

.cards-group {
	margin: 50px 0 90px;
	background: #fff;
	padding: 20px;
	@include r(767) {
		margin-top: 0; }
	.cards-wrap {
		margin: 15px 0 -10px;
		@include r(767) {
			margin-bottom: 0; } } }

.cards-wrap {
	margin-bottom: 20px;
	@include clr; }

.card {
	position: relative;
	padding: 12.5% 0;
	float: left;
	margin: 0 1.7% 1.7% 0;
	width: 32.2%;
	color: #fff;
	font-size: 13px;
	font-weight: 400;
	@include r(1023) {
		padding: 19% 0;
		width: 49%; }
	@include r(767) {
		padding: 18.2% 0;
		width: 100%;
		margin: 0 0 10px;
		margin-bottom: 1px; }
	&:nth-child(3n) {
		margin-right: 0;
		@include r(767) {
			margin-bottom: 1px; } }
	&:nth-child(2n) {
		@include r(1023) {
			margin-right: 0; } }
	&:hover {
		.card__main {
			display: block;
			@include r(1024) {
				display: none; } } }
	&__in,
	&__img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0; }
	&__img {
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-image: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0.45) 29%, rgba(0, 0, 0, 0.25) 49%, rgba(0, 0, 0, 0.19) 67%, rgba(0, 0, 0, 0.71) 100%); }
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover; } }
	&__in {
		z-index: 2; }
	&__head {
		position: relative;
		padding: 10px 20px;
		@include r(1023) {
			padding: 10px 15px; } }
	&__main {
		position: relative;
		display: none; }
	&__info {
		padding-right: 65px; }
	&__place {
		margin: 5px 0 0;
		font-size: 24px;
		font-weight: 500;
		@include r(767) {
			margin: {}
			line-height: 1; } }
	&__date {
		margin: 4px 0;
		font-size: 18px;
		font-weight: 500;
		@include r(767) {
			margin: 0; } }
	&__footer {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 10px 20px 6px;
		@include r(1023) {
			padding: 10px 15px 6px; } }
	.price-old {
		margin-right: 5px; } }

.card_small {
	padding: 9.4% 0;
	font-size: 15px;
	.card__country {
		margin-bottom: 10px;
		font-size: 18px; } }

.card_smaller {
	margin: 0 1% 1% 0;
	width: 24.2%;
	padding: 7.6% 0;
	font-size: 15px;
	line-height: 17px;
	.card__country {
		margin-bottom: 10px;
		font-size: 18px; }
	&:nth-child(3n) {
		margin-right: 1%;
		@include r(1023) {
			margin-right: 1%; } }
	&:nth-child(4n) {
		margin-right: 0;
		@include r(1023) {
			margin-right: 0; } } }

.badge {
	position: absolute;
	right: 20px;
	top: -2px;
	padding: 8px 0 0;
	width: 57px;
	height: 57px;
	text-align: center;
	font-size: 9px;
	z-index: 2;
	text-transform: uppercase;
	strong {
		display: block;
		font-size: 18px; }

	.icon {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		font-size: 57px;
		color: #e42c23; } }

.price-old {
	font-size: 18px;
	color: #C0C0C0;
	text-decoration: line-through; }

.price {
	font-size: 24px;
	font-weight: bold; }

.text-center {
	text-align: center; }

.fb-widget {
	position: relative;
	z-index: 2;
	max-width: 350px;
	text-align: center;
	.subtitle {
		font-size: 24px;
		margin: 0 0 5px;
		@include r(767) {
			font-size: 18px; } }
	&__counter {
		margin: 0 0 10px;
		font-size: 64px;
		font-weight: 900;
		color: #004B85;
		@include r(767) {
			font-size: 42px;
			line-height: 1; } }
	&__body {
		margin: 0 0 38px;
		iframe, img {
			max-width: 100%;
			height: auto; }
		@include r(767) {
			margin: 0 0 20px; } }
	.icon-fb {
		position: absolute;
		left: -115px;
		top: -15px;
		font-size: 280px;
		color: #004b85;
		z-index: -1;
		@include r(767) {
			display: none; } }
	.btn {
		@include r(767) {
			width: 100%;
			text-align: center; } } }

.logo-slider {
	position: relative;
	margin-top: 30px;
	&__slide {
		font-size: 0;
		text-align: center; }
	.slider-prev,
	.slider-next {
		@include r(1023) {
			display: none; } }
	.slider-prev {
		left: -30px; }
	.slider-next {
		right: -30px; } }

.slider-prev,
.slider-next {
	position: absolute;
	top: 50%;
	margin-top: -22px;
	width: 30px;
	height: 44px;
	background: none;
	color: #585b69;
	font-size: 0;
	&:hover {
		background: #585b69;
		color: #fff; }
	.icon {
		font-size: 18px;
		vertical-align: middle; } }

.logo-img {
	display: inline-block;
	vertical-align: middle;
	margin-bottom: 35px;
	width: 25%;
	opacity: .3;
	@include r(1023) {
		width: 33.3%; }
	@include r(767) {
		display: block;
		width: 100%; }
	&:hover {
		opacity: 1; }
	img {
		display: block;
		margin: 0 auto;
		max-width: 100%;
		height: auto; } }

.input {
	display: block;
	padding: 0 20px;
	width: 100%;
	height: 54px;
	background: #fff;
	font-size: 18px;
	@include f;
	border-radius: 3px;
	@include placeholder {
		color: #878787; } }

.masonry {
	font-size: 0;
	margin: 35px -10px 20px;
	@include r(1023) {
		margin-left: -5px;
		margin-right: -5px; }
	@include r(767) {
		margin-left: 0;
		margin-right: 0; } }

.post-card {
	font-size: 13px;
	display: inline-block;
	vertical-align: top;
	position: relative;
	width: 33.3%;
	margin-bottom: 20px;
	padding: 0 10px;
	color: $cl;
	@include r(1023) {
		width: 50%;
		padding: 0 10px; }
	@include r(767) {
		margin-bottom: 10px;
		width: 100%;
		padding: 0; }
	&__wrapper {
		background-color: #fff;
		height: 100%; }
	&__img {
		position: relative;
		display: block;
		height: 202px;
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover; }
		.post-card__date {
			position: absolute;
			left: 20px;
			right: 0;
			bottom: 10px;
			color: #fff;
			font-size: 11px; } }
	&__main {
		padding: 15px 20px 22px;
		background: #fff;
		@include r(767) {
			padding: 15px 10px 30px; } }
	&__title {
		margin-bottom: 10px;
		font-weight: 500;
		font-size: 15px;
		line-height: 1.4; }
	&__text {
		margin-bottom: 15px;
		font-size: 15px;
		font-weight: 300;
		line-height: 1.4;
		@include r(767) {
			font-size: 12px; } }
	&__link {
		display: inline-block;
		border-bottom: 1px solid;
		line-height: 1;
		color: #4a4a4a;
		font-size: 13px;
		font-weight: 500; }
	.tag {
		margin-bottom: 7px; }
	&__date {
		margin-bottom: 3px; }
	&__logo {
		position: absolute;
		right: 0;
		top: 0;
		img {
			width: auto;
			height: auto; } } }

.post-card_news {
	.post-card__img {
		height: auto; }
	.post-card__title {
		font-size: 21px; }
	.post-card__text {
		font-size: 14px; } }

.post-card_small-img {
	.post-card__img {
		height:  120px; } }

.tag {
	position: relative;
	font-size: 15px;
	font-weight: bold;
	text-transform: uppercase;
	&:before {
		content: '';
		display: inline-block;
		margin-right: 5px;
		width: 11px;
		height: 11px; }
	&_travel {
		&:before {
			background: #9b2fae; } }
	&_collect {
		&:before {
			background: #23b0e0; } }
	&_guide,
	&_lifehack {
		&:before {
			background: #ff9800; } }
	&_personal {
		&:before {
			background: #f8e71c; } }
	&_ideas {
		&:before {
			background: #50ae55; } }
	&_news {
		&:before {
			background: #ea437b; } }
	&_unusual {
		&:before {
			background: #91a4ad; } }
	&_events {
		&:before {
			background: #d0021b; } } }

.date {
	font-size: 13px; }

.btn {
	&_upload {
		width: 100%;
		max-width: 324px;
		padding: 0 15px;
		display: block;
		margin: 0 auto;
		font-size: 16px;
		svg {
			font-size: 20px;
			margin-right: 10px;
			vertical-align: middle; } }
	&_blue {
		background: #004b85; } }

.directions {
	&_small {
		.directions__col {
			margin-bottom: 10px;
			padding: 0 5px;
			width: 20%;
			@include r(1023) {
				width: 33.3%; }
			@include r(767) {
				margin-bottom: 6px;
				width: 50%;
				padding: 0 3px; } }

		.directions__city {
			font-size: 18px; }
		.directions__info {
			padding: 15px;
			@include r(767) {
				padding: 10px; } } }
	.directions__item {
		padding: 28.7% 0;
		@include r(767) {
			height: 80px; }
		&.is-light {
			color: #fff;
			.directions__img {
				opacity: 1; } } }
	.directions__city {
		margin-bottom: 3px; }
	.directions__img {
		@include r(767) {
			height: 100%; }
		img {
			@include r(767) {
				height: 100%;
				object-fit: cover; } } } }

.col-3 {
	float: left;
	width: 33%;
	@include r(767) {
		width: 100%;
		float: none; } }

.list-counts {
	font-size: 15px;
	color: $cl;
	&__all {
		margin: 20px 0;
		font-weight: 500;
		text-transform: uppercase; }
	li {
		padding-left: 20px;
		margin-bottom: 5px; }
	a {
		color: $cl;
		text-decoration: underline;
		&:hover {
			color: $blue; } }
	&_2 {
		column-count: 2;
		@include r(1023) {
			column-count: 1; } }
	&_3 {
		column-count: 3;
		@include r(1023) {
			column-count: 2; } }
	&_4 {
		column-count: 4;
		@include r(1023) {
			column-count: 3; }
		@include r(767) {
			column-count: 2; }
		@include r(550) {
			column-count: 1; } }
	&_5 {
		column-count: 5;
		@include r(1023) {
			column-count: 4; }
		@include r(767) {
			column-count: 3; }
		@include r(550) {
			column-count: 2; } }
	&_6 {
		column-count: 6;
		@include r(1023) {
			column-count: 5; }
		@include r(767) {
			column-count: 2; } }
	&_mob1 {
		@include r(767) {
			column-count: 1; } }
	&_mt {
		margin-top: 30px; }
	&_routes {
		margin-top: 30px;
		li {
			margin-bottom: 6px; }
		span {
			color: #a8a9a9; }
		a {
			text-decoration: none; } }
	&_countries {
		li {
			margin-bottom: 10px; } }
	&_no-decor {
		a {
			text-decoration: none; } }
	strong {
		font-weight: 500;
		font-size: 18px; } }

.btn-wrap {
	margin: 65px 0 0;
	text-align: center;
	@include r(767) {
		margin: 25px 0 0; } }

.payment {
	padding: 17px 0;
	background: #EFEFEF;
	@include r(767) {
		padding: 33px 0; }
	&_white {
		background: #FFF; }
	&_lg {
		padding: 40px 0 14px;
		@include r(767) {
			.payment__title {
				margin: 0 auto 15px; } } }
	&_nobg {
		background: none; }
	&__title {
		display: table-cell;
		vertical-align: middle;
		font-size: 13px;
		font-weight: 400;
		@include r(767) {
			display: block;
			margin-bottom: 15px;
			text-align: center; } }
	&__main {
		display: table-cell;
		vertical-align: middle;
		white-space: nowrap;
		@include r(767) {
			display: block;
			font-size: 0; } }
	&__img {
		display: inline-block;
		margin-left: 60px;
		text-align: center;
		vertical-align: middle;
		@include r(1023) {
			margin-left: 10px; }
		@include r(767) {
			margin: 0;
			padding: 0 8px;
			width: 25%; }
		img {
			max-width: 100%;
			height: auto; } } }

.partners {
	position: relative;
	margin-top: 70px;
	padding: 60px 0;
	@include r(767) {
		padding: 40px 0; }
	&__in {
		position: relative;
		margin-top: 30px; }
	&__prev,
	&__next {
		position: absolute;
		top: 0;
		height: 100%;
		font-size: 18px;
		width: 40px;
		opacity: .4;
		&:hover {
			opacity: 1; }
		.icon {
			vertical-align: middle; } }
	&__prev {
		left: -40px; }
	&__next {
		right: -40px; }
	&:before,
	&:after {
		content: '';
		position: absolute;
		left: 50%;
		margin-left: -480px;
		width: 960px;
		height: 1px;
		background: #f5f6f6;
		@include r(1023) {
			left: 0;
			right: 0;
			width: auto;
			margin: 0; } }
	&:before {
		top: 0; }
	&:after {
		bottom: 0; }
	&__slider {
		background: #f6f7f7;
		.slick-slide {
			@include vertical;
			padding: 0 20px;
			height: 104px;
			text-align: center;
			img {
				display: inline-block;
				vertical-align: middle;
				max-width: 100%; } } }
	&__logo {
		font-size: 0;
		padding: 10px 0;
		background: #f6f7f7;
		div {
			display: inline-block;
			vertical-align: middle;
			padding: 10px;
			width: 50%;
			text-align: center;
			img {
				max-width: 100%; } } } }

.how {
	&_dark {
		.how__text {
			color: #2f2f2f;
			font-weight: 300; } } }

.list {
	color: $cl;
	&__item {
		padding-left: 33px;
		margin-bottom: 40px;
		&:last-child {
			margin-bottom: 0; }
		@include r(767) {
			padding-left: 0; } }
	&__title {
		position: relative;
		margin-bottom: 10px;
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 500;
		&:before {
			content: '';
			position: absolute;
			left: -33px;
			top: 3px;
			width: 11px;
			height: 11px;
			background: $blue;
			@include r(767) {
				display: none; } } }
	&__text {
		margin-bottom: 10px;
		font-size: 15px;
		font-weight: 300; }
	a {
		color: $cl;
		text-decoration: underline;
		font-weight: 500;
		font-size: 15px; } }

.counter {
	text-align: center;
	margin: 45px 0 -40px;
	font-size: 18px;
	@include r(767) {
		font-size: 15px;
		margin-bottom: 0; }
	&_single {
		max-width: 960px;
		margin: 60px auto -75px;
		padding-top: 45px;
		border-top: 2px solid #f5f6f6;
		@include r(1023) {
			margin-bottom: 0; }
		@include r(767) {
			margin: 10px auto 0;
			padding-top: 25px; } }
	img {
		display: inline-block;
		vertical-align: middle; }
	img {
		margin-left: 20px;
		@include r(767) {
			width: 80px;
			margin: 0; } }
	&__body {
		display: inline-block;
		white-space: nowrap;
		text-align: left;
		@include r(767) {
			width: 190px;
			white-space: normal;
			text-align: center; } }
	span {
		position: relative;
		display: inline-block;
		margin-right: 3px;
		vertical-align: top;
		width: 50px;
		height: 70px;
		text-align: center;
		line-height: 70px;
		font-weight: 700;
		font-size: 64px;
		background: #F7F7F7;
		@include r(767) {
			font-size: 32px;
			width: 24px;
			height: 36px;
			line-height: 36px; } }
	p {
		margin-top: 20px;
		@include r(767) {
			margin-top: 10px; } } }

.quote-slider {
	position: relative;
	margin-top: 40px;
	.slider-prev {
		left: 0; }
	.slider-next {
		right: 0; } }

.quote {
	position: relative;
	padding: 0 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	@include r(767) {
		display: block; }
	&__img {
		margin: 0 55px 0 15px;
		@include r(1023) {
			margin: 0 45px 0 10px; }
		@include r(767) {
			margin: 0 auto; }
		img {
			margin: 0 auto; }
		&-text {
			font-size: 16px;
			font-weight: 500;
			margin-top: 10px;
			text-align: center; } }
	&__avatar {
		background-position: center;
		background-size: cover;
		height: 100px;
		width: 100px;
		border-radius: 50%;
		margin: 0 auto; }
	&__main {
		position: relative;
		padding: 0 55px;
		color: $cl;
		@include r(1023) {
			padding: 0 10px; }
		@include r(767) {
			padding: 50px 0 0; }
		&:before {
			position: absolute;
			content: '“';
			left: 0;
			top: -7px;
			font-size: 72px;
			font-weight: bold;
			line-height: 1;
			@include r(1023) {
				left: -40px; }
			@include r(767) {
				left: 0; } } }
	&__text {
		margin-bottom: 20px;
		font-size: 18px;
		line-height: 1.2;
		font-weight: 300;
		@include r(767) {
			font-size: 13px; } }
	&__info {
		font-size: 14px;
		color: #9EA0A8;
		@include r(767) {
			font-size: 13px; } } }

.fresh {
	font-size: 14px;
	@include r(767) {
		font-size: 12px;
		font-weight: 300; }
	&__btn {
		margin-top: 20px; }
	&__table-row {
		&:last-child {
			.fresh__table-cell {
				border: 0; } } } }

.fresh {
	&__table-cell {
		&:nth-child(3),
		&:nth-child(4) {
			@include r(1023) {
				display: none; } }
		&:nth-child(2),
		&:nth-child(5) {
			@include r(767) {
				display: none; } }
		&:last-child {
			@include r(767) {
				font-size: 15px; } } } }

.link-wrap {
	margin: 30px 0 0;
	text-align: center;
	font-weight: 500;
	font-size: 15px;
	.link {
		text-decoration: underline; } }

.press-more {
	a {
		font-size: 18px;
		font-weight: 500; }
	&_dark {
		a {
			color: #000; } }
	&_uppercase {
		text-transform: uppercase; } }

.promo-banner {
	margin: 60px 0;
	height: 80px;
	font-size: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	@include r(1023) {
		font-size: 25px; }
	@include r(767) {
		flex-direction: column;
		font-size: 16px;
		height: 105px; }
	&_booking {
		background: #003580;
		color: #fff; }
	img {
		margin: 0 10px;
		vertical-align: middle;
		@include r(767) {
			margin-top: 10px; } } }

.history {
	position: absolute;
	bottom: 50px;
	left: 50%;
	width: 465px;
	transform: translateX(-50%);
	@include rh(900) {
		bottom: 20px; }
	@include rh(800) {
		display: none; }
	@include r(767) {
		display: none; }
	.history__title {
		margin-bottom: 8px;
		text-align: center;
		font-size: 15px;
		font-weight: 500;
		line-height: 18px;
		text-transform: uppercase; }
	.history__in {
		width: 100%;
		border-radius: 3px;
		overflow: hidden;
		font-size: 14px;
		a {
			display: table;
			width: 100%;
			border-top: 1px solid rgba(#fff, 0.15);
			background: #2579ab;
			transition: background 300ms;
			&:first-child {
				border-top: none; }
			&:hover {
				background: #1d6189; } }
		p {
			display: table-cell;
			padding: 15px;
			color: #94c5e1;
			&:first-child {
				color: #fff; } } } }

.hr {
	display: block;
	max-width: 960px;
	margin: 20px auto 40px;
	border: 0;
	height: 2px;
	background: #fff;
	&_gray {
		background: #F5F6F6; } }

.hotel {
	@include r(767) {
		display: flex;
		flex-direction: column; }
	&__container {
		display: table;
		position: relative;
		width: 100%;
		padding: 28px 20px 20px;
		z-index: 4;
		@include r(1023) {
			padding: 20px 15px; }
		@include r(767) {
			padding: 0 15px;
			box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
			border-radius: 0 0 3px 3px; } }
	&__container.visible &__label {
		display: none; }
	&__preview,
	&__wrap {
		display: table-cell;
		vertical-align: top; }
	&__preview {
		width: 205px;
		padding-right: 15px;
		@include r(767) {
			display: none; }
		&-wrap {
			position: relative;
			width: 190px;
			height: 190px;
			background-size: cover;
			background-position: 50%;
			background-repeat: no-repeat;
			background-color: #EBEBEB;
			color: #959595; }
		&-pic {
			display: block;
			width: 100%; }
		.icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			font-size: 50px; } }
	&__wrap {
		@include r(767) {
			display: flex;
			flex-direction: column; } }
	&__label {
		position: absolute;
		top: 40px;
		left: -8px;
		height: 44px;
		padding: 5px 8px 0 8px;
		background: $red;
		border-radius: 4px 0 0 0;
		color: #fff;
		text-align: center;
		z-index: 4;
		@include r(767) {
			top: 10px;
			left: auto;
			right: -5px;
			border-radius: 0; }
		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -8px;
			@include arr(8,8,#c60000,tr);
			@include r(767) {
				left: auto;
				right: 0;
				bottom: -5px;
				border-width: 5px 5px 0 0;
				border-color: #c60000 transparent transparent transparent; } }
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: -10px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 22px 10px 22px 0;
			border-color: $red transparent $red $red;
			@include r(767) {
				right: auto;
				left: -10px;
				border-width: 22px 0 22px 10px;
				border-color: $red $red $red transparent; } }
		&-wrap,
		&-help {
			display: inline-block;
			vertical-align: top; }
		&-value {
			font-size: 18px;
			font-weight: 700;
			line-height: 1;
			letter-spacing: -1px; }
		&-title {
			font-size: 10px;
			font-weight: 500; }
		&-help {
			width: 13px;
			height: 13px;
			margin: -2px -6px 0 0;
			background-color: #ff6d6d;
			font-size: 10px;
			color: #FFF;
			line-height: 13px;
			@include r(767) {
				display: none; } } }
	&__head {
		position: relative;
		margin-bottom: 10px;
		@include r(767) {
			margin: 0 -15px;
			padding-bottom: 45px;
			color: #FFF; }
		&-wrap {
			@include r(767) {
				position: relative;
				height: 190px;
				padding: 15px 65px 15px 15px;
				background: linear-gradient(rgba(#000,.6), rgba(#000,.1), rgba(#000,.6));
				z-index: 2; }
			&_clear {
				@include r(767) {
					background: rgba(#000,.6); } } }
		&-bg {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 45px;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: cover;
			@include r(767) {
				display: block; } }
		&-label {
			display: none;
			position: absolute;
			top: 12px;
			left: -28px;
			height: 44px;
			padding: 5px 8px 0 8px;
			background: $red;
			border-radius: 4px 0 0 0;
			color: #fff;
			text-align: center;
			@include r(767) {
				display: block; }
			&:before {
				content: '';
				position: absolute;
				left: 0;
				bottom: -8px;
				@include arr(8,8,#c60000,tr); }
			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: -10px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 22px 10px 22px 0;
				border-color: $red transparent $red $red; }
			&-wrap,
			&-help {
				display: inline-block;
				vertical-align: top; }
			&-value {
				font-size: 18px;
				font-weight: 700;
				line-height: 1;
				letter-spacing: -1px; }
			&-title {
				font-size: 10px;
				font-weight: 500; } } }
	&__icon {
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		font-size: 0;
		color: #C3C3C3;
		@include r(767) {
			display: block; }
		.icon {
			font-size: 50px; } }
	&__row {
		display: table;
		width: 100%; }
	&_sm &__row {
		margin-bottom: -10px; }
	&__cell {
		display: table-cell;
		vertical-align: top;
		@include r(767) {
			display: block; }
		&:nth-child(2) {
			padding-left: 20px;
			@include r(767) {
				position: absolute;
				left: 0;
				right: 0;
				bottom: -45px;
				min-height: 45px;
				padding: 0; } } }
	&__title {
		display: block;
		margin-bottom: 6px;
		font-size: 22px;
		font-weight: 700;
		line-height: 1.1;
		color: $cl;
		@include r(767) {
			margin-bottom: 4px;
			font-size: 18px;
			color: #FFF; } }
	&__rate {
		margin-bottom: 10px;
		font-size: 0;
		.icon {
			font-size: 12px; } }
	&__info {
		font-size: 0;
		@include r(767) {
			position: absolute;
			left: 15px;
			right: 10px;
			bottom: 10px; }
		&-item {
			display: inline-block;
			vertical-align: middle;
			&:first-child {
				&:after {
					content: '/';
					margin: 0 5px;
					font-size: 13px;
					vertical-align: middle;
					@include r(767) {
						display: none; } } }
			&:nth-child(2) {
				@include r(767) {
					display: none; } }
			&:nth-child(3) {
				display: none;
				float: right;
				@include r(767) {
					display: block; } } }
		&-map,
		&-icon,
		&-title {
			display: inline-block; }
		&-map {
			font-size: 0; }
		&-icon,
		&-title {
			display: inline-block;
			vertical-align: middle; }
		&-icon {
			margin-right: 3px;
			.icon {
				font-size: 12px; } }
		&-title {
			font-size: 13px;
			line-height: 1;
			@include r(767) {
				font-size: 12px; } }
		&-map &-title {
			border-bottom: 1px dotted; }
		&-btn {
			margin-top: -3px;
			padding: 4px 10px;
			background: rgba(#fff,.3);
			border-radius: 3px;
			font-size: 12px;
			text-transform: uppercase;
			color: #FFF; } }
	&__desc {
		margin-bottom: 15px;
		font-size: 13px;
		@include r(767) {
			margin: 0;
			padding: 15px 0 20px;
			order: 3; }
		&-content {
			@include r(767) {
				margin-bottom: 10px; } } }
	&__options {
		margin-bottom: -10px;
		font-size: 0;
		@include r(767) {
			display: none; }
		&-item {
			display: inline-block;
			width: 34px;
			height: 34px;
			margin: 0 10px 10px 0;
			vertical-align: top;
			.icon {
				font-size: 34px; } } }
	&__serv {
		border-top: 1px solid $border; }
	&__rooms-item {
		border-radius: 0 0 0 3px; }
	&__advisor {
		text-align: right;

		@include r(767) {
			background: #F9F9F9; } }
	&_sm &__booking {
		float: right;
		.rating__cell {
			padding-top: 0; } }
	&__section {
		display: table;
		width: 100%;
		&-cell {
			display: table-cell;
			vertical-align: top; } }
	&__price {
		float: right;
		@include r(767) {
			float: none; } }
	&_el {
		margin-bottom: 15px;
		background: #FFF;
		border-radius: 3px;
		@include shadow; }
	&_el &__container {
		padding: 15px;
		@include r(767) {
			padding: 0 15px 15px; } }
	&_el &__head-wrap,
	&_el &__head-bg {
		@include r(767) {
			border-radius: 3px 3px 0 0; } }
	&_el &__desc {
		@include r(767) {
			padding: 15px 0 5px; } }
	&_el &__desc-content {
		margin: 0; }
	&_el &__serv {
		border-radius: 0 0 3px 3px; }
	&_el &__advisor {
		@include r(767) {
			background: none; } }
	&_sm &__preview {
		width: 110px;
		padding-right: 10px;
		&-wrap {
			width: 100px;
			height: 100px;
			.icon {
				font-size: 24px; } } }
	&_sm &__label {
		top: 25px; }
	&_sm &__title {
		margin-bottom: 4px;
		font-size: 18px; } }

.how_hotels {
	.how__col {
		@include r(1023) {
			img {
				height: auto;
				max-width: none; }
			&:first-child {
				img {
					width: 150px; } }
			&:nth-child(2) {
				img {
					width: 137px; } }
			&:nth-child(3) {
				img {
					width: 160px; } } } } }

.how_flight {
	.how__col {
		@include r(1023) {
			img {
				height: auto;
				max-width: none; }
			&:first-child {
				img {
					width: 160px; } }
			&:nth-child(2) {
				img {
					width: 160px; } }
			&:nth-child(3) {
				img {
					width: 160px; } } } } }

.info {
	position: relative;
	max-height: 170px;
	padding: 0 0 45px;
	overflow: hidden;
	@include r(767) {
		font-size: 12px;
		font-weight: 100; }
	&_wide {
		max-height: none;
		padding: 0;
		width: 100%;
		.info__more {
			position: relative;
			width: auto;
			background: none;
			&:before {
				content: none; } }
		.info__more-title {
			text-decoration: underline;
			&:hover {
				text-decoration: none; } }
		.info__content {
			p {
				font-size: 15px; } } }
	&__content {
		float: left;
		max-width: 740px;
		width: calc(100% - 380px);
		@include r(1023) {
			float: none;
			margin: 0 auto;
			max-width: none;
			width: 100%; } }
	&__more {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 45px;
		background: #FFF;
		font-size: 0;
		color: $blue-light;
		&:before {
			content: '';
			position: absolute;
			bottom: 100%;
			left: 0;
			right: 0;
			height: 30px;
			background: linear-gradient(rgba(#fff,.0), rgba(#fff,1)); }
		&-title,
		&-icon {
			display: inline-block;
			vertical-align: middle; }
		&-title {
			font-size: 13px;
			font-weight: 500; }
		&-icon {
			margin-left: 4px;
			.icon {
				display: block;
				font-size: 5px; } } }
	&.open {
		max-height: unset;
		.info__more {
			&:before {
				display: none; }
			.icon {
				transform: rotate(180deg); } } }
	&__img {
		float: right;
		display: inline-block;
		width: 340px;
		margin-top: -50px;
		@include r(1023) {
			float: none;
			display: block;
			margin: 20px auto 0; }
		@include r(767) {
			display: none; } } }

.tabs {
	position: relative;
	background: #eee;
	@include r(767) {
		display: none; }
	.tabs__list {
		font-size: 0;
		text-align: center;
		li {
			display: inline-block;
			vertical-align: middle;
			a {
				display: inline-block;
				padding: 21px 32px;
				color: #000;
				font-size: 14px;
				font-weight: 300;
				transition: background 200ms;
				&.active, &:hover {
					background: #fff;
					cursor: pointer; } } } } }

.rating {
	display: table;
	white-space: nowrap;
	&__cell {
		display: table-cell;
		vertical-align: top;
		@include r(767) {
			vertical-align: baseline; }
		&:first-child {
			line-height: 1.1; }
		&:nth-child(2) {
			padding-left: 10px;
			@include r(767) {
				padding: 0; } }
		&:nth-child(3) {
			display: none;
			@include r(767) {
				display: table-cell; } } }
	&__title {
		font-size: 15px;
		font-weight: 700;
		color: #003580;
		@include r(767) {
			font-size: 13px; } }
	&__text {
		font-size: 12px;
		color: #3D7CDA;
		@include r(767) {
			display: none; } }
	&__value {
		width: 30px;
		height: 30px;
		padding: 0 5px;
		background: #003580;
		border-radius: 4px 4px 4px 0;
		font-size: 15px;
		font-weight: 700;
		text-align: center;
		line-height: 30px;
		color: #FFF;
		@include r(767) {
			width: auto;
			height: auto;
			background: transparent;
			font-size: 17px;
			color: #3D7CDA; }
		&-dot {
			color: #3D7CDA;
			&:after {
				content: '.';
				@include r(767) {
					content: ','; } } } }
	&__counter {
		font-size: 12px;
		color: #3D7CDA; } }

.price {
	text-align: center;
	@include clr;
	&__cell {
		float: right;
		&:first-child {
			margin-left: 15px;
			@include r(767) {
				text-align: left; } }
		&:nth-child(2) {
			float: left; } }
	&__actual {
		font-size: 20px;
		font-weight: 700;
		line-height: 1; }
	&__old {
		display: inline-block;
		margin-right: 5px;
		font-size: 12px;
		color: $red;
		text-decoration: line-through; }
	&__percent {
		padding: 2px 2px;
		background: $red;
		font-size: 12px;
		line-height: 1;
		color: #fff;
		border-radius: 4px; }
	&__discount {
		font-size: 12px;
		font-weight: 700;
		color: $pink;
		white-space: nowrap;
		line-height: 1.1; }
	&__btn {
		min-width: 130px;
		font-size: 16px; }
	&__text {
		padding: 5px 0;
		font-size: 12px;
		vertical-align: top;
		clear: both;
		font-weight: normal;
		@include r(767) {
			float: left;
			width: auto;
			padding: 0;
			clear: left; } }
	&_flight {
		width: 100%;
		text-align: center; }
	&_flight &__actual {
		margin: 0; }
	&_flight &__text {
		float: none;
		width: 100%;
		margin-bottom: 8px;
		color: $cl; }
	&_flight &__btn {
		width: 100%; }
	&__icons {
		margin-bottom: 5px;
		a {
			font-size: 19px;
			margin: 0 2px;
			color: #777777; } }
	&__icons &__check {
		color: #329901; } }

.transfer {
	position: relative;
	padding-top: 68px;
	border-radius: 3px;
	@include shadow;
	@include r(767) {
		padding: 0; }
	&__head {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		border-radius: 3px;
		z-index: 4;
		@include r(767) {
			position: relative; }
		&.open {
			bottom: 0;
			background: rgba(#000,.5); } }
	&__layout {
		max-height: 100%;
		border-radius: 3px 3px 0 0;
		background: #FFF;
		@include clr;
		@include r(767) {
			border-radius: 3px; } }
	&__actions {
		position: relative;
		float: left;
		width: 67px;
		height: 68px;
		@include r(767) {
			display: none; }
		&-btn {
			display: none;
			width: 67px;
			height: 68px;
			font-size: 0;
			&.active {
				display: block; } }
		&-title {
			display: block;
			padding-top: 4px;
			font-size: 12px;
			line-height: 1; }
		&-icon {
			.icon {}
			font-size: 18px; } }
	&__wrap {
		float: left;
		width: 380px;
		border: 1px solid $border;
		border-width: 0 1px;
		@include r(767) {
			float: none;
			width: 100%;
			border: none; } }
	&__order {
		float: right;
		padding: 15px;
		@include r(767) {
			display: none; }
		&-btn {
			height: 38px;
			border-radius: 20px; } }
	&__current {
		@include r(767) {
			display: none; } }
	&__current {
		&.hidden {
			display: none; } }
	&__current &__item {
		border: none;
		cursor: default; }
	&__group {
		display: none;
		&.open {
			display: block; }
		@include r(767) {
			display: block; } }
	&__container {
		@include r(767) {
			display: none; } }
	&__map {
		height: 540px;
		background: gray;
		border-radius: 0 0 3px 3px;
		z-index: 2; }
	&__item {
		padding: 12px 15px;
		border-top: 1px solid $border;
		user-select: none;
		cursor: pointer;
		&:first-child {
			border: none; }
		&.hidden {
			display: none;
			@include r(767) {
				display: block; } }
		&.active {
			border: none;
			@include r(767) {
				border-top: 1px solid $border;
				&:first-child {
					border: none; } } } }
	&__row {
		display: table;
		width: 100%; }
	&__row + &__row {
		margin-top: 4px; }
	&__cell {
		display: table-cell;
		vertical-align: top;
		&:nth-child(2) {
			padding-left: 10px;
			text-align: right; } }
	&__title {
		height: 24px;
		font-weight: 700;
		font-size: 14px; }
	&__time {
		font-size: 12px; }
	&__price {
		font-weight: 700; }
	&__transport {
		display: inline-block;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		@include vertical;
		color: #FFF;
		vertical-align: top;
		&_violet {
			background-color: #734286; }
		&_red {
			background-color: #e0043b; }
		&_orange {
			background-color: #e47225; }
		&_yellow {
			background-color: #ffad00; }
		.icon {
			position: relative;
			font-size: 16px;
			vertical-align: middle;
			z-index: 4; }
		.icon-taxi {
			font-size: 14px; } }
	&__steps {
		display: none;
		padding: 0 15px 15px;
		&.open {
			display: block; }
		&-title {
			position: relative;
			padding: 10px 10px 10px 30px;
			background: #F4F4F4;
			font-weight: 700;
			&:before {
				content: '';
				position: absolute;
				top: 12px;
				left: 6px;
				width: 12px;
				height: 12px;
				background: #595959;
				border: 2px solid #fff;
				border-radius: 50%;
				z-index: 4; } }
		&-row {
			display: table;
			position: relative;
			width: 100%; }
		&-cell {
			display: table-cell;
			position: relative;
			height: 60px;
			vertical-align: middle;
			&:first-child {
				width: 30px; }
			&:nth-child(2) {
				color: #6B6B6B; }
			&:nth-child(3) {
				padding-left: 10px;
				font-weight: 700;
				text-align: right; } } }
	&__steps &__transport {
		&:after {
			content: '';
			position: absolute;
			top: -18px;
			left: 10px;
			bottom: -18px;
			width: 4px;
			background: inherit;
			z-index: 2; } } }

.aviapromo {
	color: $cl;
	text-align: center;
	&__item {
		vertical-align: top;
		display: inline-block;
		width: 33%;
		padding: 10px;
		@include r(1023) {
			width: calc(50% - 2px); }
		@include r(767) {
			display: block;
			width: 100%;
			padding: 10px 0; }
		&:last-child {
			padding-right: 0;
			@include r(1023) {
				display: none; }
			@include r(767) {
				display: block; } }
		&:nth-child(2) {
			@include r(1023) {
				padding-right: 0; } }
		&:first-child {
			padding-left: 0; } }
	&__title {
		font-size: 18px;
		font-weight: 500;
		text-align: left; }
	&__img {
		height: 200px;
		width: 100%;
		margin-top: 10px;
		background-image: url(https://travel.rambler.ru/media/original_images/5031eb2dd0963.jpg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: relative;
		@include r(767) {
			margin-top: 0;
			height: 175px; }
		.side-label__value {
			font-size: 17px; }
		.side-label__title {
			display: block;
			font-size: 8px;
			font-weight: 400;
			@include r(767) {
				float: none; } }
		.aviapromo__table {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			@include r(767) {
				display: block; } }
		.aviapromo__row {
			background-color: rgba(0,0,0,.69);
			color: #ffffff; }
		.aviapromo__price {
			&-old {
				color: #c4c4c4;
				display: inline-block;
				margin-right: 5px; }
			&-actual {
				color: #FF4D44;
				display: inline-block; } }
		.aviapromo__date {
			color: #ffffff; }
		.aviapromo__night {
			color: #ffffff; } }
	&__row {
		background-color: #f8f8f8;
		margin-top: 10px;
		padding: 5px 10px;
		font-size: 0;
		box-sizing: border-box;
		@include r(767) {
			text-align: left; } }
	button.aviapromo__row {
		width: 100%; }
	&__cell {
		display: inline-block;
		vertical-align: top;
		box-sizing: border-box;
		&:first-child {
			text-align: left;
			width: calc(100% - 160px); }
		&:last-child {
			text-align: right;
			width: 160px; } }
	&__city {
		font-size: 15px;
		font-weight: 500; }
	&__country {
		font-size: 13px; }
	&__price {
		&-old {
			font-size: 13px;
			color: #6c6c6c;
			text-decoration: line-through; }
		&-actual {
			color: #E42C23;
			font-size: 18px;
			font-weight: 700; }
		&-discount {
			display: inline-block;
			vertical-align: middle;
			background-color: #E42C23;
			height: 20px;
			line-height: 20px;
			border-radius: 3px;
			padding: 0 5px;
			font-size: 14px;
			font-weight: bold;
			margin-left: 5px;
			color: #ffffff; } }
	&__date {
		color: #9b9b9b;
		font-size: 12px; }
	&__table {
		@include r(767) {
			display: none; } }
	&__btn {
		color: #ffffff;
		height: 54px!important;
		line-height: 54px!important;
		margin: 0 auto;
		margin-top: 20px; } }

.section + hr.hr.hr_gray {
	margin: 0 auto; }

.breadcrumbs {
	font-size: 0;
	margin: 40px 0 0 0;
	@include r(767) {
		margin: 20px 0 0 0; }
	&__item {
		display: inline;
		font-size: 14px;
		color: #9C9C9C; }
	&__item::after {
		content: "/";
		margin: 0 5px; }
	&__item:last-child::after {
		display: none; }
	&__link {
		color: #9e9d9d;
		text-decoration: underline; } }

.lpcalendar {
	background-color: rgba(#ffffff, .8);
	padding: 30px 20px;
	max-width: 780px;
	margin: 0 auto;
	text-align: center;
	color: $cl;
	@include r(767) {
		padding: 20px 10px; }
	&__title {
		font-size: 36px;
		line-height: 42px;
		font-weight: 500;
		@include r(767) {
			font-size: 24px;
			line-height: 28px; } }
	&__btn {
		margin: 35px auto 0;
		padding: 0 50px;
		height: 54px;
		line-height: 54px;
		@include r(767) {
			padding: 0 20px;
			span:last-child {
				display: inline; } }
		svg {
			margin-right: 10px;
			font-size: 18px;
			vertical-align: middle; } }
	&__wrap {
		margin-top: 20px; }
	&__item {
		position: relative;
		display: inline-block;
		vertical-align: top;
		padding: 0 15px;
		width: 33%;
		@include r(767) {
			display: block;
			width: 100%;
			padding: 0;
			margin-top: 30px;
			text-align: left; }
		&::before {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			width: 1px;
			height: 100%;
			background-color: #A6A6A6;
			@include r(767) {
				display: none; } }
		&:last-child::before {
			display: none; } }
	&__head {
		font-size: 16px;
		line-height: 19px;
		font-weight: 500;
		&-item {
			@include r(767) {
				display: inline;
				text-transform: none; }
			&:nth-child(2) {
				text-transform: uppercase; } } }
	&__text {
		margin-top: 15px;
		font-size: 14px;
		line-height: 16px; } }

.wrapper_new-hotels {
	.promo-banner {
		margin: 0; }
	.section {
		padding: 30px 0;
		@include r(1023) {
			padding: 20px 0; }
		@include r(767) {
			margin-top: 0; } }
	.center {
		padding: 0 30px;
		max-width: 1180px;
		@include r(1023) {
			padding: 15px 30px; }
		@include r(767) {
			padding: 15px; } }
	.counter {
		margin: 0; }
	.why__list {
		margin-bottom: 0; }
	.title {
		margin: 25px 0;
		font-weight: 700;
		@include r(1023) {
			margin: 20px 0; } }
	.cards-wrap {
		margin: 20px 0 0; }
	.subtitle,
	.how {
		margin-top: -20px; }
	.breadcrumbs {
		margin: 20px 0; }
	.filter-new__map {
		margin-bottom: 10px!important;
		&-el {
			height: 115px; } }
	.sorter a {
		height: 50px;
		line-height: 50px; }
	.title_small {
		font-size: 30px;
		line-height: 35px;
		margin: 0 0 32px;
		@include r(1023) {
			font-size: 26px;
			line-height: 30px; }
		@include r(767) {
			font-size: 24px;
			line-height: 28px; } }
	.clear-filters {
		padding: 17px 15px; }
	.s_short {
		margin-bottom: 0!important;
		min-height: inherit;
		height: inherit; }
	.page-filter {
		@include r(970) {
			margin: 0 -30px; } } }

.card-new {
	display: inline-block;
	vertical-align: top;
	width: calc(25% - 40px);
	margin: 0 20px;
	@include r(1023) {
		width: calc(50% - 40px);
		margin-top: 30px; }
	@include r(767) {
		margin: 15px 0 0;
		width: 100%; }
	&:first-child {
		@include r(767) {
			margin-top: 0; } }
	&_cities {
		margin: 20px 0;
		.card-new__item {
			margin-bottom: 10px; }
		.card-new__link {
			text-decoration: none;
			span {
				text-decoration: underline; }
			&:hover {
				span {
					text-decoration: none; } } } }
	&__wrap {
		margin: 0 -20px;
		@include r(767) {
			margin: 0; }
		font-size: 0;
		text-align: center; }
	&__head {
		display: block;
		overflow: hidden;
		text-align: center;
		color: #ffffff;
		height: 130px;
		position: relative;
		padding: 30px 10px 10px;
		&-img {
			height: 105%;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			filter: blur(3px);
			position: absolute;
			top: -3px;
			left: -3px;
			right: -3px;
			bottom: -3px;
			z-index: 0; }
		&-text {
			font-size: 14px;
			line-height: 16px;
			position: relative;
			z-index: 0; }
		&-city {
			font-size: 24px;
			line-height: 26px;
			font-weight: 700;
			position: relative;
			z-index: 0; }
		&-price {
			font-size: 14px;
			font-weight: 700;
			line-height: 35px;
			height: 35px;
			position: absolute;
			bottom: 0;
			right: 0;
			left: 0;
			background: rgba(#000000, .4); } }
	&__list {
		padding: 5px 10px; }
	&__item {
		display: table;
		width: 100%;
		height: 30px; }
	&__city {
		display: block;
		font-size: 12px;
		line-height: 16px;
		color: #A7A7A7; }
	&__link {
		display: table-cell;
		font-size: 14px;
		line-height: 15px;
		text-decoration: underline;
		color: #3D7CDA;
		text-align: left;
		vertical-align: middle;
		padding-right: 5px;
		&:hover,
		&:active {
			text-decoration: none; } }
	&__value {
		display: table-cell;
		font-size: 13px;
		line-height: 15px;
		color: #AEAEAE;
		text-align: right;
		vertical-align: middle;
		white-space: nowrap; }
	&__btn {
		display: inline-block;
		cursor: pointer;
		color: #3D7CDA;
		font-size: 14px;
		font-weight: 700;
		height: 47px;
		line-height: 47px;
		text-align: center;
		background: #ffffff;
		border: 1px solid #3D7CDA;
		border-radius: 47px;
		margin: 15px 0;
		padding: 0 10px;
		min-width: 265px;
		transition: background .15s, color .15s;
		&:hover {
			background: lighten(#3D7CDA, 10%);
			border-color: lighten(#3D7CDA, 10%);
			color: #FFFFFF; }
		&:active {
			background: darken(#3D7CDA, 10%);
			border-color: darken(#3D7CDA, 10%);
			color: #ffffff; } }
	&_3col {
		width: calc(100% + 100px);
		column-count: 3;
		margin: 0 -50px;
		@include r(1023) {
			column-count: 2;
			width: calc(100% + 80px);
			margin: 0 -40px; }
		@include r(767) {
			column-count: 1; }
		.card-new__item {
			padding: 0 50px;
			@include r(1023) {
				padding: 0 40px; }
			@include r(767) {
				padding: 0; } }
		.card-new__value {
			font-size: 14px; } } }

.map__header:after, .place:after {
	content: " ";
	clear: both; }

.hotel-map {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-width: 320px;
	&__header {
		background: #FFF;
		border-bottom: 1px solid #EDEDED;
		text-align: center;
		height: 50px;
		@include r(767) {
			height: 44px; } }
	&__breadcrumbs {
		background-color: #ffffff;
		padding: 7px 20px;
		@include r(767) {
			padding: 7px 15px; }
		.breadcrumbs {
			margin: 0;
			ul {
				li {
					font-size: 13px;
					line-height: 15px; } } }
		.hotel-map__title {
			display: none;
			@include r(767) {
				display: block;
				height: auto;
				line-height: 19px;
				padding: 5px 0; } } }
	&__title {
		font-size: 19px;
		font-weight: 700;
		height: 50px;
		line-height: 50px;
		@include r(767) {
			display: none; } }
	&__logo {
		position: absolute;
		left: 20px;
		display: inline-block;
		vertical-align: middle;
		height: 50px;
		line-height: 50px;
		@include r(767) {
			position: static;
			height: 44px;
			line-height: 44px; }
		img {
			width: 173px;
			height: 20px;
			vertical-align: middle;
			@include r(767) {
				width: 145px;
				height: 17px; } } }
	&__wrap {
		position: relative;
		width: 100%;
		height: 100%; } }

.hotel-place {
	position: absolute;
	top: 100px;
	left: 50%;
	margin-left: -150px;
	width: 300px;
	background: #ffffff;
	box-shadow: 0 0 4px 0 rgba(#000000, .5);
	&__img {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		width: 100%;
		height: 120px;
		@include r(1023) {
			display: none; } }
	&__close {
		width: 22px;
		height: 22px;
		line-height: 22px;
		position: absolute;
		right: -10px;
		top: -10px;
		background-color: #ffffff;
		color: #868686;
		border-radius: 22px;
		box-shadow: 0 1px 2px 0 rgba(#000000, .2);
		text-align: center;
		font-size: 10px; }
	&__title {
		font-size: 15px;
		line-height: 18px;
		font-weight: 700; }
	&__wrap {
		padding: 10px 15px; }
	&__rate {
		line-height: 21px;
		svg {
			vertical-align: middle;
			margin-right: -3px; } }
	&__rating {
		font-size: 18px;
		line-height: 21px;
		font-weight: 700;
		float: right; }
	&__review {
		font-size: 12px;
		line-height: 21px;
		font-weight: 400;
		&::before {
			content: ' / '; } }
	&__cell {
		display: table-cell;
		&:first-child {
			text-align: center; }
		&:last-child {
			text-align: right; } }
	&__price {
		display: table;
		width: 100%;
		margin-top: 15px; }
	&__btn {
		display: inline-block;
		vertical-align: top;
		font-size: 13px;
		padding: 0 15px;
		height: 36px;
		line-height: 36px;
		margin-top: 5px; }
	&__old {
		font-size: 12px;
		line-height: 14px;
		color: #E42C23;
		text-decoration: line-through;
		margin-bottom: -4px; }
	&__discount {
		display: inline-block;
		vertical-align: top;
		line-height: 14px;
		font-size: 10px;
		color: #ffffff;
		background-color: #E42C23;
		border-radius: 3px;
		padding: 0 2px;
		margin-left: 5px; }
	&__actual {
		font-size: 18px;
		line-height: 21px;
		font-weight: 700; }
	&__nights {
		font-size: 12px;
		font-weight: 400; } }

.locality {
	color: $cl;
	margin-top: 40px;
	@include r(767) {
		margin-top: 30px; }
	&:first-child {
		margin-top: 0; }
	&__title {
		font-size: 24px;
		line-height: 30px;
		font-weight: 300;
		@include r(767) {
			font-size: 20px; } }
	&__item {
		display: inline-block;
		vertical-align: top;
		font-size: 14px;
		line-height: 30px;
		color: #3D7CDA;
		text-decoration: underline;
		margin-right: 15px;
		@include r(767) {
			font-size: 13px; }
		&:hover,
		&:active {
			text-decoration: none; } } }

.alphabet {
	@include r(1023) {
		text-align: center; }
	&__item {
		font-size: 18px;
		font-weight: 500;
		text-transform: uppercase;
		color: $cl;
		display: inline-block;
		vertical-align: top;
		height: 42px;
		text-align: center;
		padding: 10px;
		margin-right: 5px;
		margin-bottom: 20px;
		@include r(767) {
			font-size: 16px;
			height: 40px;
			margin-right: 0;
			margin-bottom: 0;
			padding: 10px 8px; }
		&:last-child {
			margin-right: 0; }
		&.active {
			background-color: #3D7CDA;
			border-radius: 3px;
			color: #ffffff; } } }

.wrap {
	min-height: 100%;
	overflow: hidden; }

.subs {
	position: relative;
	min-height: 535px;
	height: 100vh;
	transition: height .2s;
	@include r(1023) {
		height: 615px; }
	@include r(767) {
		height: 535px; }
	&__table {
		position: relative;
		display: table;
		width: 100%;
		@include r(767) {
			padding: 0 10px; } }
	&__cell {
		display: table-cell;
		vertical-align: middle;
		@include r(1023) {
			display: block;
			width: 100%;
			max-width: 510px;
			margin: 0 auto; }
		&:nth-child(2) {
			width: 510px;
			@include r(1023) {
				width: 100%; } } }
	&__subtitle {
		color: #FFFFFF;
		font-size: 28px;
		line-height: 1.2;
		font-weight: 100;
		padding-right: 20px;
		max-width: 500px;
		@include r(1023) {
			font-size: 20px;
			margin-bottom: 30px;
			margin-left: 30px; }
		@include r(767) {
			font-size: 19px;
			margin-left: 20px; } }
	&__img {
		display: block;
		color: #FFFFFF;
		font-size: 60px;
		margin-bottom: 15px;
		@include r(1023) {
			margin-top: 140px;
			margin-left: 30px;
			width: 240px; }
		@include r(767) {
			margin-top: 65px;
			margin-left: 20px;
			width: 180px; } }
	&__vertical-center {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-200px);
		@include r(1023) {
			transform: translateY(0);
			position: static; } }
	&.open {
		@include r(767) {
			height: 850px; } }
	&_full {
		@include r(767) {
			height: auto; } }
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-size: cover;
		background-position: 50% 50%;
		clip-path: polygon(0 0, 100% 0, 100% 95%, 0 100%);
		@include r(767) {
			background-image: linear-gradient(to bottom, rgba(#6ACBE0, .82), rgba(#6859EA, .82))!important; }
		&::before {
			content: '';
			background: linear-gradient(to left, rgba(#0E2CA1, .82), rgba(#480D73, .82));
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			@include r(767) {
				background: linear-gradient(to bottom, rgba(#0E2CA1, .82), rgba(#480D73, .82)); } } }
	&__center {
		max-width: 1180px;
		margin: 0 auto;
		padding: 0 10px; }
	&__logo {
		padding: 8px 15px;
		color: #ffffff;
		display: inline-block;
		vertical-align: middle;
		@include rm(1024) {
			float: left; }
		img {
			display: inline-block;
			vertical-align: middle;
			@include r(767) {
				width: 143px; } } }
	&__header {
		position: relative; }
	&__head {
		position: relative;
		top: 40px;
		@include r(767) {
			top: 20px; } }
	&__menu {
		transition: height .2s;
		overflow: hidden;
		@include rm(1024) {
			float: right; }
		@include r(767) {
			height: 0;
			margin-top: 10px;
			padding: 0 10px; }

		&-link {
			display: inline-block;
			vertical-align: middle;
			color: #ffffff;
			text-transform: uppercase;
			font-size: 14px;
			line-height: 16px;
			padding: 10px 20px;
			@include r(767) {
				display: block;
				width: 100%;
				text-align: center;
				border-bottom: 1px solid rgba(#ffffff, .82);
				padding: 14px 20px;
				&:last-child {
					border-bottom: 0; } } }
		&.open {
			@include r(767) {
				height: 305px;
				&+.subs__buttons {
					.subs__button {
						&_open {
							display: none; }
						&_close {
							display: block; } } } } } }
	&__buttons {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		@include r(767) {
			display: block; } }
	&__button {
		color: #ffffff;
		font-size: 16px;
		padding: 10px 25px;
		&_close {
			display: none; } }
	&_bookimed {
		height: calc(100vh - 50px);
		@include r(1023) {
			height: 520px; }
		&+.section {
			@include r(767) {
				padding-top: 150px; } }
		.subs__bg {
			&:before {
				background: linear-gradient(to right,rgba(#2EC800,.82),rgba(#006885,.82)); } } }
	&_push {
		height: auto;
		.subs__bg {
			clip-path: none; } } }

.mpt40 {
	@include r(1023) {
		margin-top: 80px; }
	@include r(767) {
		margin-top: 80px; } }

.subs-block {
	position: relative;
	border-radius: 8px;
	background-color: #ffffff;
	width: 440px;
	float: right;
	z-index: 2;
	padding: 40px 30px 20px;
	color: $cl;
	top: 125px;
	margin-right: 45px;
	@include r(1023) {
		top: 175px;
		margin: 0 auto;
		float: none; }
	@include r(767) {
		width: 280px;
		padding: 45px 20px 20px;
		top: 50px; }
	&__subtitle {
		font-size: 20px;
		line-height: 24px;
		font-weight: 500; }
	&__title {
		font-size: 45px;
		line-height: 34px;
		font-weight: 700;
		margin-bottom: 25px;
		@include r(767) {
			font-size: 32px;
			line-height: 34px; } }
	&__text {
		font-size: 16px;
		line-height: 19px;
		font-weight: 200;
		margin-top: 5px;
		@include r(767) {
			font-size: 13px;
			line-height: 15px; } }
	&__input {
		display: inline-block;
		width: calc(100% - 140px);
		vertical-align: middle;
		height: 50px;
		padding: 0 10px;
		background: #FFF;
		border-radius: 3px 0 0 3px;
		font-size: 15px;
		@include placeholder-color(#787878);
		border: 1px solid #DDDDDD;
		margin-top: 5px;
		@include rm(768) {
			border-right: 0; }
		@include r(767) {
			display: block;
			width: 100%;
			border-radius: 3px;
			margin-top: 30px; } }
	&__field {
		margin-bottom: 10px;
		font-size: 0;
		&-btn {
			display: inline-block;
			vertical-align: middle;
			width: 140px;
			height: 50px;
			font-size: 14px;
			text-align: center;
			text-transform: uppercase;
			font-weight: 700;
			border-radius: 0 3px 3px 0;
			box-shadow: 0 1px 3px 0 rgba(#000000, .2);
			margin-top: 5px;
			@include r(767) {
				display: block;
				width: 100%;
				border-radius: 3px;
				margin-top: 10px; } } }
	&__btn {
		width: calc(50% - 5px);
		box-shadow: 0 1px 3px 0 rgba(#000000, .2);
		text-align: center;
		height: 50px;
		line-height: 50px;
		border-radius: 3px;
		color: #ffffff;
		font-size: 14px;
		font-weight: 700;
		padding: 0 20px;
		svg {
			font-size: 20px;
			vertical-align: middle;
			margin-right: 5px; }
		&_facebook {
			background-color: #3C5A99;
			margin-right: 5px;
			transition: background-color .2s;
			&:hover {
				background-color: lighten(#3C5A99, 5%); }
			&:active {
				background-color: darken(#3C5A99, 5%); } }
		&_google {
			background-color: #4285F4;
			margin-left: 5px;
			transition: background-color .2s;
			&:hover {
				background-color: lighten(#4285F4, 5%); }
			&:active {
				background-color: darken(#4285F4, 5%); } } }
	&__or {
		position: relative;
		overflow: hidden;
		font-size: 15px;
		text-align: center;
		margin: 10px auto 10px;
		width: 160px;
		&:before,
		&:after {
			content: '';
			display: inline-block;
			width: 100%;
			height: 1px;
			background: #A7A7A7;
			z-index: -1;
			position: relative;
			vertical-align: middle; }
		&:before {
			right: 15px;
			margin: 0 0 0 -100%; }
		&:after {
			left: 15px;
			margin: 0 -100% 0 0; } }
	&__list {
		margin-bottom: 30px;
		@include r(767) {
			margin-bottom: 20px; } }
	&__item {
		margin-bottom: 15px;
		padding-left: 40px;
		position: relative;
		@include r(767) {
			margin-bottom: 12px;
			padding-left: 30px; } }
	&__oval {
		width: 28px;
		height: 28px;
		line-height: 28px;
		border-radius: 28px;
		text-align: center;
		background: linear-gradient(to top, rgba(#0932D5, .72), rgba(#860DE1, .72));
		position: absolute;
		left: 0;
		@include r(767) {
			width: 20px;
			height: 20px;
			line-height: 20px;
			top: -2px; }
		svg {
			vertical-align: middle;
			font-size: 12px;
			color: #ffffff;
			@include r(767) {
				font-size: 10px; } } }
	&__advantages {
		color: $cl;
		font-size: 18px;
		line-height: 28px;
		@include r(767) {
			font-size: 15px;
			line-height: 18px; } } }


.stayHome {
	width: 100%;
	position: relative;
	top: 150px;
	z-index: 1;
	@include r(1023) {
		top: 65px; }
	@include r(767) {
		top: 40px; }
	&__wrap {
		display: flex;
		align-items: stretch;
		width: 100%;
		margin-top: 20px;
		@include rm(768) {
			align-items: stretch; }
		@include r(767) {
			flex-direction: column; } }
	&__h1 {
		display: flex;
		justify-content: center;
		font-size: 36px;
		font-weight: 500;
		text-align: center;
		color: #FFFFFF;
		margin: 0;
		width: 100%; }
	&__subtitle {
		font-size: 20px;
		line-height: 24px;
		font-weight: 500; }
	&__title {
		font-size: 45px;
		line-height: 34px;
		font-weight: 700;
		margin-bottom: 25px;
		@include r(767) {
			font-size: 28px;
			line-height: 30px; } }
	&__cell {
		width: 100%;
		padding: 20px;
		@include r(767) {
			padding: 10px; } }
	&__item {
		border-radius: 8px;
		background-color: #ffffff;
		z-index: 2;
		padding: 40px 30px 20px;
		color: $cl;
		box-shadow: 0 0 30px 0 rgba(#000000, .35);
		@include rm(768) {
			min-height: 100%; }
		@include r(767) {
			padding: 30px 20px 20px; } }
	&__list {
		margin-bottom: 30px;
		@include r(767) {
			margin-bottom: 20px; }
		&-item {
			margin-bottom: 15px;
			padding-left: 40px;
			position: relative;
			@include r(767) {
				margin-bottom: 12px;
				padding-left: 30px; } }
		&-text {
			color: $cl;
			font-size: 18px;
			line-height: 28px;
			@include r(767) {
				font-size: 15px;
				line-height: 18px; } }
		&-oval {
			width: 28px;
			height: 28px;
			line-height: 28px;
			border-radius: 28px;
			text-align: center;
			background: linear-gradient(to top, rgba(#0932D5, .72), rgba(#860DE1, .72));
			position: absolute;
			left: 0;
			@include r(767) {
				width: 20px;
				height: 20px;
				line-height: 20px;
				top: -2px; }
			svg {
				vertical-align: middle;
				font-size: 12px;
				color: #ffffff;
				@include r(767) {
					font-size: 10px; } } } }
	&__gray {
		color: #737373; }
	&__players {
		max-height: 320px;
		overflow-y: auto;
		font-size: 16px;
		&-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			min-height: 36px;
			padding: 10px 30px 10px 0;
			width: 100%;
			@include r(767) {
				padding-right: 0; } }
		&-name {
			padding-right: 10px; } }
	&__btn {
		font-size: 16px;
		line-height: 40px;
		min-width: 160px;
		cursor: pointer;
		&-wrap {
			display: flex;
			justify-content: center;
			margin-top: 20px; } } }


.subs-adv {
	margin-top: 30px;
	margin-bottom: 40px;
	@include r(767) {
		margin-bottom: 20px; }
	&__list {
		margin: 0 auto;
		font-size: 0;
		@include r(1023) {
			max-width: 500px; }
		@include r(767) {
			//max-width: 185px
			margin-top: -25px; } }
	&__item {
		display: inline-block;
		vertical-align: top;
		text-align: center;
		width: 25%;
		margin-top: 20px;
		position: relative;
		padding: 0 20px;
		@include r(1023) {
			margin-top: 45px; }
		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 1px;
			background: #E3E1E1;
			top: 30px;
			bottom: 30px;
			right: 0;
			@include r(1023) {
				top: 20px;
				bottom: 20px; }
			@include r(767) {
				display: none; } }
		&:last-child::before {
			display: none; }
		&:nth-child(2)::before {
			@include r(1023) {
				display: none; } }
		@include r(1023) {
			width: 50%; }
		@include r(767) {
			width: 100%; } }
	&__oval {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto 10px;
		height: 100px;
		width: 100px;
		border-radius: 50%;
		border: 1px solid rgba(#613A8D, .3);
		.icon {
			color: #B5A6D9;
			&-new-youtube {
				font-size: 30px; }
			&-new-thumbs {
				font-size: 44px; }
			&-new-pig {
				font-size: 45px; }
			&-new-papers {
				font-size: 49px; } } }
	&__image {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding: 15px;
		border-radius: 50%;
		overflow: hidden;
		img {
			max-width: 100%;
			max-height: 100%; }
		&_bg {
			padding: 0;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat; } }
	&__value {
		font-size: 50px;
		line-height: 58px;
		font-weight: 700;
		background: linear-gradient(to top, rgba(#0932D5, .8), rgba(#860DE1, .8));
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		color: #0932D5; }
	&__title {
		font-size: 20px;
		line-height: 1.2;
		font-weight: 500;
		margin-bottom: 10px; }
	&__text {
		font-size: 16px;
		line-height: 19px;
		color: #737373; }
	&__partner {
		display: inline-block;
		vertical-align: middle;
		margin-left: 5px; }
	&__arrow {
		position: absolute;
		right: -15px;
		top: 50%;
		margin-top: -10px;
		@include r(1023) {
			right: inherit;
			top: 100%;
			margin-top: 20px;
			transform: rotate(90deg);
			margin-left: -13px; } }
	&__img {
		margin: 0 auto 20px; }
	&_bookimed {
		.subs-adv {
			&__list {
				&_three {
					.subs-adv__item {
						width: 33.33%;
						@include r(1023) {
							display: block;
							width: 100%;
							margin: 60px 0; }
						&:before {
							display: none; } } } }
			&__text {
				max-width: 190px;
				margin: 0 auto;
				min-height: 60px; } }
		.stat__number {
			color: #87E902; } } }

.polygon-right10 {
	@include rm(768) {
		clip-path: polygon(0 0, 100% 10%, 100% 90%, 0% 100%); }
	.subs-adv__item:before {
		top: 0!important;
		bottom: 0!important; } }

.polygon-footer {
	@include rm(768) {
		clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 100%); } }

.about-push {
	font-size: 14px;
	&__images {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 60px auto 75px;
		@include r(1023) {
			max-width: 560px; }
		@include r(767) {
			flex-direction: column; } }
	&__img {
		border-radius: 3px;
		margin: 0 10px;
		@include r(767) {
			margin: 10px;
			width: 100%;
			height: auto; } }
	&__descr {
		display: flex;
		@include r(1023) {
			flex-wrap: wrap; }
		@include r(767) {
			display: block; } }
	&__col {
		display: flex;
		padding: 0 20px;
		width: 25%;
		@include r(1023) {
			width: 50%;
			padding: 20px; }
		@include r(767) {
			display: block;
			width: 100%; } }
	&__count {
		font-size: 24px;
		font-weight: bold;
		margin-right: 10px; } }

.push-nbg {
	display: flex;
	align-items: center;
	justify-content: space-around;
	font-size: 16px;
	@include r(1023) {
		flex-wrap: wrap; }
	&__item {
		display: flex;
		align-items: center;
		position: relative;
		max-width: 320px;
		height: 220px;
		text-align: center;
		padding: 0 10px;
		&-bg {
			position: absolute;
			left: 0;
			right: 0;
			font-size: 220px;
			font-weight: 900;
			color: #E9E4F4; }
		&-text {
			position: relative; } } }

.push-social {
	margin-top: 120px;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top: -70px;
		left: 0;
		right: 0;
		width: 200px;
		margin: 0 auto;
		border-top: 1px solid #D8D8D8; }
	&__wrap {
		display: flex;
		margin-top: 20px;
		flex-wrap: wrap;
		margin: 20px auto 0;
		@include r(1023) {
			max-width: 520px; } }
	&__item {
		position: relative;
		width: 25%;
		padding: 0 20px;
		text-align: center;
		@include r(1023) {
			width: 50%;
			margin-top: 60px; }
		@include r(767) {
			width: 100%; }
		&::before {
			content: '';
			position: absolute;
			top: 30px;
			right: 0;
			height: 120px;
			border-right: 1px solid #D8D8D8;
			@include r(767) {
				display: none; } }
		&:last-child:before {
			display: none; }
		&:nth-child(2):before {
			@include r(1023) {
				display: none; } }
		&_youtube {
			.push-social__icon,
			.push-social__btn {
				background: #CC181E; } }
		&_facebook {
			.push-social__icon,
			.push-social__btn {
				background: #365899; } }
		&_telegram {
			.push-social__icon,
			.push-social__btn {
				background: #35A6DE; } }
		&_instagram {
			.push-social__icon,
			.push-social__btn {
				background-image: url(../img/instagram-bg.jpg);
				background-position: center;
				background-size: 260px;
				background-repeat: no-repeat; } } }
	&__text {
		margin-top: 30px; }
	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		margin: 0 auto;
		color: #ffffff;
		.icon-social-thin-youtube {
			font-size: 40px; }
		.icon-social-thin-facebook,
		.icon-social-thin-instagram {
			font-size: 44px; }
		.icon-social-thin-telegram {
			font-size: 37px; } }
	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 15px;
		height: 40px;
		border-radius: 40px;
		width: 100%;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 500;
		color: #ffffff; } }

.subscribe-blog.subscribe-blog_pushLanding {
	padding: 90px 25px 70px;
	.subscribe-blog {
		&__title {
			font-size: 32px; }
		&__subtitle {
			font-size: 22px;
			font-weight: 100;
			margin-top: 5px; } } }

.noAngleMob {
	@include r(767) {
		clip-path: none!important;
		margin: 0 auto;
		padding: 40px 0; } }


.reLink {
	border-radius: 3px;
	border: 1px solid #E0E0E0;
	padding: 20px 10px 30px;
	font-weight: 500;
	margin: 20px auto;
	&__title {
		font-size: 18px;
		padding: 0 10px; }
	&__list {
		column-count: 2;
		margin-top: 30px;
		font-size: 15px;
		@include r(767) {
			column-count: inherit; } }
	&__item {
		display: block;
		color: $cl;
		padding: 10px; } }
