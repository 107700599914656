.error-404 {
  text-align: center;
  color: #4b4b4b;
  height: calc(100% - 285px);
  display: table;
  width: 100%;
  @include r(1023) {
    height: calc(100% - 325px); }
  &__cell {
    display: table-cell;
    vertical-align: middle; }
  &__wrap {
    margin: 0 auto;
    max-width: 740px;
    padding: 20px; }
  &__logo {
    display: block;
    margin: 0 auto;
    width: 180px;
    height: 20px;
    @include r(767) {
      width: 145px;
      height: 17px; } }
  &__icon {
    margin: 65px auto 0;
    @include r(1023) {
      margin-top: 45px; }
    img {
      width: 430px;
      height: 160px;
      @include r(767) {
        width: 160px;
        height: 60px; } } }
  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-top: 35px;
    padding: 0 20px;
    @include r(1023) {
      margin-top: 65px; }
    @include r(767) {
      font-size: 14px;
      line-height: 16px;
      margin-top: 25px; } }
  &__subtitle {
    font-size: 14px;
    line-height: 16px;
    margin-top: 30px;
    @include r(767) {
      font-size: 12px;
      line-height: 14px; } }
  &__list {
    margin: 20px -5px 0; }
  &__link {
    display: inline-block;
    vertical-align: middle;
    height: 44px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 700;
    color: #3D7CDA;
    text-align: center;
    border: 2px solid #3D7CDA;
    margin: 5px;
    border-radius: 44px;
    transition: background .1s, border .1s, color .1s;
    @include r(767) {
      font-size: 12px;
      height: 36px;
      line-height: 32px; }
    &:hover {
      background: lighten(#3D7CDA, 10%);
      border-color: lighten(#3D7CDA, 10%);
      color: #FFFFFF; }
    &:active {
      background: darken(#3D7CDA, 10%);
      border-color: darken(#3D7CDA, 10%);
      color: #FFFFFF; } } }
