.people {
    display: table;
    width: 100%;
    padding: 10px 20px;
    @include r(1023) {
        width: 100%; }
    &__row {
        display: table-row; }
    &__cell {
        display: table-cell;
        font-size: 0;
        vertical-align: middle;
        white-space: nowrap;
        line-height: 1;
        &:nth-child(3) {
            width: 33px;
            padding: 0 2px 0 0;
            font-size: 20px;
            text-align: center;
            font-weight: 500; }
        &:first-child {
            padding: 9px 10px 9px 0;
            font-size: 15px; }
        &:nth-child(2),
        &:nth-child(4) {
            width: 33px;
            padding: 9px 0; } }
    &__btn {
        position: relative;
        width: 29px;
        height: 29px;
        background: white;
        border-radius: 50%;
        border: 2px solid #9f9f9f;
        transition: border-color .2s;
        font-size: 0;
        &:before,
        &:after {
            content: "";
            transition: background .2s; }
        &:before {
            display: inline-block;
            width: 11px;
            height: 1px;
            background: #9f9f9f;
            vertical-align: middle; }
        &:hover:not(.disable) {
            border-color: #3D7CDA;
            &:before,
            &:after {
                background: #3D7CDA; } }
        &.disable {
            opacity: .3;
            cursor: default; }
        &_plus {
            &:after {
                position: absolute;
                top: 6px;
                left: 12px;
                width: 1px;
                height: 11px;
                background: #9f9f9f; } } } }
