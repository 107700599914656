.date {
	position: relative;
	background: #FFF;
	border-radius: 3px;
	&__head {
		position: relative;
		font-size: 0; }
	&__title {
		display: none;
		position: absolute;
		top: 6px;
		left: 15px;
		font-size: 9px;
		text-transform: uppercase;
		color: #9F9F9F;
		z-index: 6; }
	&__field {
		display: inline-block;
		position: relative;
		width: 50%;
		border-radius: 0 3px 3px 0;
		vertical-align: top;
		color: #A7A7A7;
		cursor: pointer;
		&:first-child {
			border-right: 1px solid $border;
			border-radius: 3px 0 0 3px; }
		&.focus {
			box-shadow: 0 0 0 2px $orange;
			border-color: transparent; }
		&.active {
			font-weight: 700;
			color: $cl; }
		&.is-close {
			.icon-close {
				display: inline-block; }
			.icon-date {
				display: none; } } }
	&__field.active &__title {
		display: block; }
	&__icon {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		padding: 0 12px 2px;
		background: none;
		color: #B5B5B5;
		@include vertical;
		.icon {
			font-size: 18px;
			vertical-align: middle; }
		.icon-close {
			display: none;
			color: #008fbf;
			font-size: 14px;
			transition: color 200ms;
			&:hover {
				color: $blue-light; } } }
	&__drop {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		width: 304px;
		margin-top: 9px;
		background: #FFF;
		border-radius: 3px;
		box-shadow: 0 14px 40px rgba(0, 0, 0, 0.34);
		transition: left .4s;
		z-index: 99;
		@include r(767) {
			position: fixed;
			top: 0;
			left: 0 !important;
			width: 100%;
			min-width: 320px;
			height: 100%;
			margin: 0;
			background: rgba(#000,.8);
			padding: 60px 20px;
			overflow: auto;
			border-radius: 0;
			@include vertical;
			z-index: 999; } }
	&__arr {
		position: absolute;
		top: -8px;
		left: 16px;
		@include arr(16,8,#fff,t);
		@include r(767) {
			display: none; } }
	&__dp {
		@include r(767) {
			display: inline-block;
			width: 310px;
			margin: 0 auto;
			text-align: left;
			vertical-align: middle; }
		@include r(360) {
			width: 280px; } }
	&__row {
		display: table;
		width: 100%;
		padding: 10px 20px;
		border-bottom: 1px solid #e1e1e1;
		table-layout: fixed; }
	&__cell {
		display: table-cell;
		vertical-align: middle;
		&:nth-child(2) {
			width: 46%; }
		&:last-child {
			text-align: right; } }
	&__value {
		height: 54px;
		padding: 0 15px;
		font-size: 15px;
		line-height: 54px;
		white-space: nowrap;
		overflow: hidden;
		@include r(767) {
			padding: 0 10px;
			font-size: 14px; } }
	&.date_wide {
		.date__drop {
			width: 670px;
			@include r(767) {
				width: 100%;
				background: #fff;
				padding: 0 2px 0;
				&:before {
					display: none; } } }
		.date__dp {
			width: 50%;
			float: left;
			@include r(767) {
				width: 100%;
				float: none;
				&:nth-child(2) {
					display: none; } } }
		.dp__top {
			background: #E7EDF3;
			.dp__top-title {
				color: #000;
				text-transform: none;
				font-size: 15px; }
			.dp__top-close {
				right: auto;
				left: 0;
				color: #868686;
				.icon {
					font-size: 16px; } } }
		.dp__btn {
			top: 2px;
			&_prev {
				left: 20px; }
			&_next {
				right: 20px; }
			@include r(767) {
				top: 50px;
				&_prev {
					left: 0; }
				&_next {
					right: 0; } } }
		.dp__body {
			@include r(767) {
				padding: 15px 0 20px; } }
		.dp__th {
			@include r(767) {
				font-size: 14px; }
			@include r(600) {
				font-size: 12px; }
			@include r(480) {
				font-size: 10px; } }
		.dp__td {
			@include r(767) {
				height: 0;
				padding-bottom: 14%; } }
		.dp__cell {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			@include vertical; }
		.dp__day {
			display: inline-block;
			vertical-align: middle;
			font-size: 14px;
			@include r(767) {
				font-size: 20px; }
			@include r(600) {
				font-size: 16px; }
			@include r(480) {
				font-size: 14px; } }
		.dp__footer {
			display: none; } }

	&_sm &__value {
		height: 40px;
		padding: 0 10px;
		line-height: 40px; }
	&_sm &__icon {
		padding: 0 10px 2px; }
	&_sm &__title {
		display: block;
		top: -12px;
		left: 0;
		color: #fff; }
	&_back {
		.date__drop {
			left: 50%;
			&:after {
				left: 114px; }
			@include r(1330) {
				&:after {
					left: 118px; } }
			@include r(767) {
				left: 0;
				width: 100%; } } }
	&.date_mod {
		.dp__day {
			font-size: 15px; } }
	&.date_cost {
		.dp__day {
			position: absolute;
			top: 17.39%;
			left: 0;
			right: 0; }
		.dp__sum {
			bottom: 13.04%; } }

	&.date_tabs {
		.dp__top {
			background: #3E474F;
			@include r(767) {
				min-height: 44px;
				&-title {
					line-height: 44px;
					font-size: 15px; }
				&-close {
					height: 44px;
					.icon {
						font-size: 16px; } } } }
		.dp__line {
			@include r(767) {
				padding: 0; } }
		.date__drop {
			width: 670px;
			@include r(767) {
				width: 100%;
				background: #fff;
				padding: 0;
				&:before {
					display: none; } } }
		.date__dp {
			width: 50%;
			float: left;
			@include r(767) {
				width: 100%;
				float: none;
				&:last-child {
					display: none; }
				&:nth-child(2) {
					display: none; } } } } }

.dp {
	user-select: none;
	font-size: 0;
	&_month {
		.dp__head {
			text-align: center; } }
	&__sum {
		.date-picker__sum {
			color: inherit;
			font-size: inherit;
			font-weight: inherit;
			overflow: inherit;
			text-overflow: inherit;
			white-space: inherit;
			text-align: inherit; } }
	&__top {
		display: none;
		position: relative;
		min-height: 50px;
		background: #33A7DE;
		@include r(767) {
			display: block; }
		&-title {
			padding: 0 50px;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 700;
			color: #fff;
			text-align: center;
			line-height: 50px; }
		&-close {
			position: absolute;
			top: 0;
			right: 0;
			width: 50px;
			height: 50px;
			font-size: 0;
			color: #fff;
			.icon {
				font-size: 12px; } } }
	&__body {
		padding: 15px 20px 20px;
		@include r(767) {
			background: #FFF;
			border-radius: 0 0 3px 3px; }
		@include r(360) {
			padding: 15px 18px 20px; } }
	&__line {
		@include clr;
		@include r(767) {
			padding: 0 2px 0; } }
	&__head {
		position: relative;
		margin-bottom: 10px; }
	&__head-title {
		margin-bottom: 15px; }
	&__head-title, &__cell-title {
		font-size: 15px;
		font-weight: bold;
		line-height: (20/15);
		color: #000;
		text-align: center; }
	&__head-btn {
		height: 44px;
		margin-bottom: 20px;
		font-size: 15px;
		border: 2px solid #D4D4D4;
		transition: border .2s;
		min-width: 317px;
		@include r(767) {
			margin-bottom: 10px; }
		&:hover {
			border: 2px solid #3D7CDA;
			background: #ffffff;
			color: #3D7CDA; } }
	&__month {
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 700;
		text-align: center; }
	&__year {
		margin-left: 5px;
		color: #9F9F9F; }
	&__months {
		margin: -1px;
		font-size: 0;
		text-align: left;
		@include r(767) {
			margin: 0; }
		.dp__cell {
			@include coverdiv;
			@include vert; } }
	&__month-item {
		position: relative;
		display: inline-block;
		width: 25%;
		height: 50px;
		border: 1px solid #fff;
		text-align: center;
		background-color: #fcfcfc;
		@include vert;
		@include r(767) {
			width: 50%; }
		&:hover, &:active {
			cursor: pointer;
			color: #3D7CDA;
			border-radius: 3px;
			border: 2px solid #3D7CDA;
			.dp__cell-title {
				color: #3D7CDA; } }
		&.active {
			&:hover, &:active {
				color: #ffffff;
				background: #006496;
				border-radius: 3px;
				border: none;
				.dp__cell-title {
					color: #ffffff; } } } }
	&__cell-content {
		display: inline-block;
		vertical-align: middle; }
	&__cell-value {
		font-size: 11px;
		line-height: (15/11); }
	&__btn {
		position: absolute;
		top: 0;
		width: 22px;
		padding: 15px 25px 15px 15px;
		font-size: 0;
		z-index: 9;
		background: none;
		&_prev {
			left: 0; }
		&_next {
			right: 0; }
		.icon {
			font-size: 14px; } }
	&__days {
		position: relative;
		margin-right: -1px; }
	&__table {
		width: 100%;
		border-spacing: 0; }
	&__th {
		padding-bottom: 10px;
		font-weight: 400;
		text-transform: uppercase;
		text-align: center;
		font-size: 10px;
		color: #A1A1A1; }
	&__td {
		position: relative;
		width: 40px;
		height: 40px;
		background-color: #F5F5F5;
		border: 1px solid #fff;
		border-width: 0 1px 1px 0;
		vertical-align: middle;
		text-align: center;
		font-size: 15px;
		font-weight: 700;
		cursor: pointer;
		z-index: 2;
		@include r(360) {
			width: 35px;
			height: 35px; }
		&.active,
		&.start,
		&.end,
		&.empty,
		&.equal {
			color: #FFF; }
		&.active {
			position: relative;
			&:after {
				content: "";
				position: absolute;
				top: 0;
				right: -1px;
				width: 1px;
				height: 100%;
				background-color: #3D7CDA; } }
		&.empty {
			background: #FFF; }
		&.disable {
			color: #c7c7c7;
			cursor: default; }
		&.disable_active {
			color: #c7c7c7; }
		&.active {
			background: #3D7CDA; }
		&.frame,
		&.start,
		&.end,
		&.equal {
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: 100% 100%; }
		&.frame {
			background-image: url(../img/dp-frame.svg); }
		&.frame-square {
			background: none;
			.dp__cell {
				border: 2px solid #3D7CDA;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				.dp__day {
					position: absolute;
					top: 24.39%;
					left: 0;
					right: 0; }
				.dp__sum {
					bottom: 0; } } }
		&.frame-square-active {
			background-color: #3D7CDA !important;
			color: #fff;
			.dp__cell {
				border: 2px solid #3D7CDA;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				.dp__day {
					position: absolute;
					top: 24.39%;
					left: 0;
					right: 0; }
				.dp__sum {
					bottom: 0; } } }
		&.start {
			background-image: url(../img/dp-start.svg); }
		&.end {
			background-image: url(../img/dp-end.svg); }
		&.equal {
			background-image: url(../img/dp-equal.svg);
			&:after {
			  right: -2px; } }
		&:last-child {
			&.active {
				&:after {
					display: none; } } }
		&.yellow {
			background: #FFDA79; }
		&.red {
			background: #FF8F7D; }
		&.green {
			background: #AAED90; }
		&.gray {
			background: #E3E3E3; } }
	&__price {
		display: block;
		font-size: 10px;
		font-weight: 400; }
	&__oneway {
		margin-top: 20px;
		&-btn {
			width: 100%; } }
	&__footer {
		padding: 0 15px 15px; }
	&__types {
		margin-top: 15px;
		&-item,
		&-color,
		&-title {
			display: inline-block; }
		&-item {
			width: 50%;
			margin-top: 5px;
			padding-right: 20px;
			vertical-align: top; }
		&-color,
		&-title {
			vertical-align: middle; }
		&-color {
			width: 20px;
			height: 7px;
			margin-right: 10px; }
		&-title {
			font-size: 10px;
			text-transform: uppercase; } }

	&__tab-list {
		font-size: 0;
		border-bottom: 1px solid $border; }
	&__tab {
		display: inline-block;
		width: 50%;
		&:first-child {
			text-align: right;
			padding-right: 12px;
			@include r(767) {
				width: 165px;
				padding-right: 0; } }
		&:last-child {
			padding-left: 13px;
			@include r(767) {
				width: 135px;
				padding-left: 0; } }
		&-content {
			@include r(767) {
				padding: 0 1px; } }
		&-link {
			display: inline-block;
			padding: 16px 5px 10px;
			font-size: 15px;
			font-weight: bold;
			line-height: (20/15);
			color: #000;
			border-bottom: 4px solid transparent;
			@include r(767) {
				padding: 16px 8px 10px; }
			.icon, span {
				display: inline-block;
				vertical-align: middle; }
			.icon {
				margin-bottom: 2px;
				font-size: 18px; }
			span {
				margin-left: 10px; } }
		&.active {
			.dp__tab-link {
				color: #3D7CDA;
				border-color: currentColor; } } }
	&__tab-panel {
		position: relative;
		display: none;
		@include clr;
		&.active {
			display: block; }
		.dp__body {
			@include r(767) {
				padding: 15px 0; } }
		.dp__btn {
			@include r(767) {
				top: 12px;
				.icon {
					font-size: 12px; } } }
		.dp__btn_prev {
			left: 20px;
			@include r(767) {
				left: 3px; } }
		.dp__btn_next {
			right: 20px;
			@include r(767) {
				right: 3px; } } } }

.date_new {
	&.date_wide {
		.date__drop {
			width: 670px;
			@include r(767) {
				width: 100%;
				background: #fff;
				padding: 0; } }
		.dp__top {
			@include r(767) {
				background: #3E474F;
				.dp__top-title {
					color: #fff; }
				.dp__top-close {
					left: auto;
					right: 0;
					color: #fff; } } } } }

.date_new-small {
	&.date_wide {
		.date__drop {
			width: 610px; } } }

.dp_mod {
	.dp__head {
		margin-bottom: 25px; }
	.dp__body {
		@include r(767) {
			padding: 25px 0; } }
	.dp__month {
		font-size: 15px; }
	.dp__td {
		width: 42px;
		height: 45px;
		background-color: #FCFCFC;
		&.active {
			background-color: #3D7CDA;
			.dp__sum {
				color: #fff!important; } }
		&.start,
		&.end {
			.dp__sum {
				color: #fff; } }
		&.frame {
			background-image: url(../img/dp-frame-new.svg);
			background-position: 0 0;
			@include r(1023) {
				background-image: none; } }
		&.start {
			background-image: url(../img/dp-start-new.svg);
			background-position: 0 0;
			.dp__sum {
				left: 0; } }
		&.end {
			background-image: url(../img/dp-end-new.svg);
			background-position: 100% 0;
			&:after {
				display: none; }
			.dp__sum {
				right: 0; } }
		&.empty {
			background-color: #FFF;
			&:hover {
				cursor: default; } }
		&.equal {
			background-color: #3D7CDA;
			background-image: url(../img/dp-equal-new.svg);
			.dp__sum {
				color: #fff; } }
		&.green {
			.dp__sum {
				color: #4CB52A; } }
		@include r(767) {
			width: 45px; } }
	.dp__sum {
		position: absolute;
		bottom: 2px;
		left: 2px;
		right: 2px;
		text-align: center;
		font-size: 10px;
		font-weight: 400;
		@include text-overflow;
		color: #919191; }
	.dp__days {
		@include r(767) {
			margin-right: 0; } }
	&:first-child {
		.dp__body {
			padding: 15px 10px 20px 20px;
			@include r(767) {
				padding: 15px 0 20px; } } }
	&:nth-child(2) {
		.dp__body {
			padding: 15px 20px 20px 10px;
			@include r(767) {
				padding: 15px 0 20px; } } } }

.dp_new {
	.dp__td {
		background-color: #FCFCFC;
		width: 45px;
		height: 45px;
		background-size: 100% 100%;
		&.active {
			background-color: #3D7CDA;
			.dp__sum {
				color: #fff; } }
		&.start,
		&.end {
			.dp__sum {
				color: #fff; } }
		&.start {
			background-image: url(../img/dp-start-new.svg);
			background-position: 0 0;
			.dp__sum {
				left: 0; } }
		&.end {
			background-image: url(../img/dp-end-new.svg);
			background-position: 100% 0;
			&:after {
				display: none; }
			.dp__sum {
				right: 0; } }
		&.empty {
			background-color: #FCFCFC; }
		&.equal {
			background-color: #00C0FF;
			background-image: url(../img/dp-equal-new.svg);
			.dp__sum {
				color: #fff; } }
		&.green {
			.dp__sum {
				color: #4CB52A; } } }
	.dp__sum {
		position: absolute;
		bottom: 2px;
		left: 2px;
		right: 2px;
		text-align: center;
		font-size: 10px;
		font-weight: 400;
		@include text-overflow;
		color: #919191; }
	.dp__btn {
		&_next {
			@include r(767) {
				right: 14px; } }
		&_prev {
			@include r(767) {
				left: 14px; } } }
	&:first-child {
		.dp__body {
			padding: 15px 10px 20px 20px;
			@include r(767) {
				padding: 15px 0 20px; } } }
	&:nth-child(2) {
		.dp__body {
			padding: 15px 20px 20px 10px;
			@include r(767) {
				padding: 15px 0 20px; } } } }
.dp_new-small {
	.dp__td {
		height: 38px;
		background-size: 100% 100%; } }

.date__footer {
	padding: 0 0 15px;
	text-align: center;
	clear: both; }

.date__footer-info {
	border-top: 1px solid #E1E1E1;
	padding-top: 10px;
	font-weight: 700; }

.switch_new {
	border-radius: 19px;
	overflow: hidden;
	.switch__list {
		background: #E5E7E9; } }

.switch_mod {
	.switch__list {
		background: #E5E7E9;
		text-align: center;
		@include r(767) {
			width: 100%;
			max-width: 280px; } }
	.switch__item {
		height: 30px;
		line-height: 30px;
		font-size: 12px; } }


.tip {
	display: none;
	position: absolute;
	z-index: 9;
	transform: translate(-50%, -100%);
	padding: 10px;
	width: 120px;
	background: #3C3C3C;
	color: #fff;
	font-size: 13px;
	font-weight: 700;
	text-align: center;
	border-radius: 3px;
	&:after {
		content: '';
		position: absolute;
		left: 50%;
		margin-left: -5px;
		bottom: -5px;
		@include arr(10, 6, #3C3C3C, b); } }

.date_single {
	.date__dp::before {
		content: '';
		width: 100%;
		height: 1px;
		background-color: #EAEAEA;
		position: absolute;
		top: 74px;
		@include r(767) {
			display: none; } }
	.date__dp::after {
		content: '';
		width: 1px;
		height: calc(100% - 45px);
		background-color: #EAEAEA;
		position: absolute;
		top: 45px;
		right: 104px;
		@include r(767) {
			display: none; } }
	.date__dp {
		position: relative;
		width: 100%!important; }
	.date__dp + .date__dp {
		display: none!important; }
	.date__drop {
		width: 344px!important;
		@include r(767) {
			width: 100%!important; } }
	.dp__tab {
		text-align: center;
		padding: 0;
		border-bottom: 3px solid transparent;
		position: relative;
		width: 50%!important;
		&.active {
			border-bottom: 3px solid #3D7CDA;
			.dp__tab-link {
				color: #000000!important;
				border-color: transparent; } }
		&-link {
			font-size: 11px;
			line-height: 13px;
			text-transform: uppercase; }
		&:first-child::before {
			content: '';
			width: 1px;
			height: 30px;
			position: absolute;
			right: 0;
			top: 10px;
			background-color: #EAEAEA; } }
	.dp__head {
		margin-bottom: 15px; }
	.dp__body {
		padding: 15px!important;
		@include r(767) {
			padding: 20px 0!important; } }
	.dp__table {
		border-spacing: 1px; }
	.dp__td {
		border-radius: 3px;
		border: 2px solid #ffffff;
		background-color: #ffffff;
		@include r(767) {
			background-color: #FCFCFC;
			border: 2px solid #FCFCFC; } }
	.dp__td:hover,
	.dp__td:active,
	.dp__td.active {
		border-color:  #3D7CDA; }
	.dp__td.disable,
	.dp__td.empty {
		border-color: #ffffff;
		@include r(767) {
			border-color: #FCFCFC; } }
	.dp__td.disable_active {
		border-color: #ffffff;
		@include r(767) {
			border-color: #FCFCFC; }
		&:hover,
		&:active {
			border-color:  #3D7CDA; } }
	.dp__td.empty {
		@include r(767) {
			border-color: #ffffff; } }
	.dp__top {
		background: #3D7CDA!important;
		&-title {
			text-transform: inherit; } }
	.dp__month-item {
		width: 50%; }
	.dp__head-btn {
		margin-bottom: 0;
		@include r(767) {
			min-width: inherit; } }
	.dp__head-title {
		font-weight: 400; }
	.dp__tab-panel:nth-child(2) {
		.dp__body {
			padding: 20px 15px; } } }


